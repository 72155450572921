import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import CommitmentsIcon from '@mui/icons-material/Task';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import UserChip from 'Components/Library/UserChip';
import DueDateChip from 'Components/Library/DueDateChip';
import StatusSelector from 'Components/Library/StatusSelector';
import Form from 'Components/Library/Forms/';
import FormSelectorField from 'Components/Library/Forms/Elements/FormSelectorField';
import { commitmentsSelectors, commitmentsActions } from 'state/ducks/commitments';
import { peopleSelectors } from 'state/ducks/people';
import { openViewCommitmentPanel } from 'config/ModalProvider/helpers';
import { COM_STATUS_COLORS } from 'config/constants';
import { editSchema } from 'Components/Features/Commitments/schema';

const styles = theme => ({
  commitmentRow: {
    backgroundColor: theme.palette.background.paper,
    cursor: 'pointer',
    // cannot apply border radius to a row, but we need
    // rounded corners for the hover effect:
    '& > td:first-of-type': {
      paddingLeft: theme.spacing(),
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
    },
    '& > td:last-of-type': {
      paddingLeft: theme.spacing(),
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
    },
    '&:hover td': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  commitmentCell: {
    paddingLeft: 0,
  },
});

const handleRowClick = (graphId, navigate, location) => {
  openViewCommitmentPanel(graphId, false, navigate, location);
};

function CommitmentRow(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { classes, graphId, statusFilter } = props;
  const { t } = useTranslation();

  const commitment = useSelector(
    state => commitmentsSelectors.selectCommitment(state.main.commitments, graphId),
    // { stabilityCheck: 'never' },
  ).data;

  const managedTeamList = useSelector(
    state => peopleSelectors.selectTeamsManagedBy(state.main.people, state.auth.userID),
    // { stabilityCheck: 'never' },
  );

  const canEdit = useSelector(
    state =>
      commitmentsSelectors.canEditCommitment({
        slice: state.main.commitments,
        commitmentID: graphId,
        userId: state.auth.userID,
        managedTeamIds: managedTeamList,
        isChangeManager: state.auth.isChangeManager,
      }),
    // { stabilityCheck: 'never' },
  );

  if (!commitment) {
    return null;
  }
  if (statusFilter && !statusFilter.includes(commitment?.status)) {
    return null;
  }

  let initialValues = {};
  if (commitment) {
    initialValues = {
      status: commitment.status,
    };
  }

  return (
    <TableRow
      className={classes.commitmentRow}
      key={`commitment-grid-row-${commitment.id}`}
      onClick={() => handleRowClick(commitment.id, navigate, location)}
      name="commitments-row"
    >
      <TableCell className={classes.commitmentCell}>
        <Form
          name="commitment-row-status-select-form"
          debouncedAutoSubmit={950}
          schema={editSchema}
          initialValues={initialValues}
          stateSlice="main.commitments"
          allowRefreshData
          submitActionCreator={commitmentsActions.editCommitment}
          additionalProperties={{
            id: graphId,
          }}
        >
          <FormSelectorField
            fieldName="status"
            render={fieldProps => (
              <StatusSelector
                {...fieldProps}
                disabled={!canEdit}
                options={Object.keys(COM_STATUS_COLORS).map(status => ({
                  text: t(`commitments.statusValueText.${status}`),
                  option: status,
                  color: COM_STATUS_COLORS[status],
                }))}
                color="transparent"
              />
            )}
          />
        </Form>
      </TableCell>
      <TableCell className={classes.commitmentCell}>
        <Typography variant="subtitle2" sx={{ display: 'flex' }}>
          <CommitmentsIcon color="featureCommitments" sx={{ height: '1rem' }} />
          {commitment.name}
        </Typography>
      </TableCell>
      <TableCell className={classes.commitmentCell}>
        <UserChip color="transparent" sub={commitment.owner} />
      </TableCell>
      <TableCell className={classes.commitmentCell}>
        <DueDateChip
          name="grey-interlock-chip"
          dueDate={commitment.due_date}
          color="default"
          size="medium"
        />
      </TableCell>
    </TableRow>
  );
}

CommitmentRow.propTypes = {
  classes: PropTypes.exact({
    commitmentRow: PropTypes.string,
    commitmentCell: PropTypes.string,
  }),
  graphId: PropTypes.string.isRequired,
  statusFilter: PropTypes.array,
};

export default withStyles(styles)(CommitmentRow);
