import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Popover, Divider, Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import GlobalSearchOverlay from 'Components/Library/GlobalSearch/index';
import InterlockIcon from 'Components/Features/Interlocks/InterlockIcon';
import MenuOption from 'Components/Features/GamePlans/AddGamePlanButton/MenuOption';
import {
  openAddCommitmentPanel,
  openAddInterlockPanel,
  openAddObjectivePanel,
} from 'config/ModalProvider/helpers';
import { Task } from '@mui/icons-material';
import ObjectivesIcon from 'Components/Features/Objectives/ObjectivesIcon';

function AddRelationMenu({ anchorEl, onClose, onSelectHandler, instanceId }) {
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', width: 264 }}>
        <GlobalSearchOverlay onSelect={onSelectHandler} searchFor={['ALL']} navigationPrevented />
        <Divider />
        <MenuOption
          title={t('relations.addMenu.createInterlock')}
          Icon={InterlockIcon}
          onClick={() => openAddInterlockPanel(navigate, location, null, null, instanceId)}
          name="relations-create-interlock"
        />
        <MenuOption
          title={t('relations.addMenu.createCommitment')}
          Icon={Task}
          contextIconProps={{
            sx: {
              color: theme => theme.palette.featureCommitments.main,
              mr: 1,
            },
          }}
          onClick={() => openAddCommitmentPanel(navigate, location, [], instanceId)}
          name="relations-create-commitment"
        />
        <MenuOption
          title={t('relations.addMenu.createObjective')}
          Icon={ObjectivesIcon}
          contextIconProps={{
            sx: {
              color: theme => theme.palette.featureOkr.main,
              mr: 1,
            },
          }}
          onClick={() => {
            openAddObjectivePanel(
              { type: 'personal', stperiod: 'current' },
              navigate,
              location,
              instanceId,
            );
          }}
          name="relations-create-objective"
        />
      </Box>
    </Popover>
  );
}

AddRelationMenu.propTypes = {
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
  instanceId: PropTypes.string,
  onSelectHandler: PropTypes.func,
};

export default AddRelationMenu;
