import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import PropTypes from 'prop-types';
import { get, orderBy } from 'lodash';
import CanEditObjectiveWrapper from 'Components/Features/Objectives/CanEditObjectiveWrapper';
import { openAddKeyResultPanel } from 'config/ModalProvider/helpers';
import { withLocation, withNavigation } from 'withRouter';
import ObjectiveRow from './ObjectiveRow';

const styles = theme => ({
  domainHeadline: {
    borderBottom: `1px solid rgba(155, 155, 155, 1)`,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(),
  },
  scrollable: {
    overflowY: 'auto',
  },
});

class ObjectivesTable extends Component {
  openAddKeyResult = (event, objectiveID) => {
    event.preventDefault();
    event.stopPropagation();
    openAddKeyResultPanel(objectiveID, this.props.navigate, this.props.location);
  };

  dorows(rowGroup, lastDomain, rowsInDomain) {
    return rowGroup.map((objectiveData, index) => (
      <CanEditObjectiveWrapper
        key={`objective-row-${lastDomain.t}-${lastDomain.d}-${objectiveData.data.objectiveID}`}
        objectiveData={this.props.objectiveData}
        render={canEdit => (
          <ObjectiveRow
            canEdit={canEdit}
            domain={this.props.domain}
            isLastRow={index === rowGroup.length - 1}
            objectiveData={objectiveData}
            index={index}
            numRows={rowsInDomain}
            openAddKeyResult={this.openAddKeyResult}
            stperiod={this.props.stperiod}
          />
        )}
      />
    ));
  }

  render() {
    const { classes, t, selectObjective, objectiveIDs, allowScroll } = this.props;

    if (!objectiveIDs || !objectiveIDs.ok) {
      return null;
    }

    const rows = [];
    if (!!objectiveIDs && objectiveIDs.ok && objectiveIDs.data.length === 0) {
      rows.push(
        <TableRow key="noobjectives">
          <TableCell colSpan={3} sx={{ border: 'none' }}>
            {t('objectives.noobjectivesyetinfotext')}
          </TableCell>
        </TableRow>,
      );
    } else {
      /* Group objectives per objective domain (company, teams, users) */
      const uniqueObjectiveIDs = new Set(objectiveIDs.data);

      // Sorting per ID ensures that the OKRs are sorted by "domain"
      const sortedIds = Array.from(uniqueObjectiveIDs).sort();

      let rowGroup = [];
      let rowsInDomain = 0;
      const lastDomain = { t: null, d: null };

      for (const objectiveID of sortedIds) {
        const objectiveData = selectObjective(objectiveID);
        if (objectiveData && objectiveData.ok) {
          const numRows = Math.max(objectiveData.data.keyresults.length, 1);

          const { type } = objectiveData.data;
          const d =
            type === 'team'
              ? objectiveData.data.teamID
              : t === 'personal'
              ? objectiveData.data.owner
              : 'company';
          if (lastDomain.t !== type || lastDomain.d !== d) {
            // The last domain is now finished, push OKR stack from rowGroup to rows
            // Sort alphabetically first

            const sorted = orderBy(
              rowGroup,
              [okrData => get(okrData, 'data.objective').toLowerCase()],
              ['asc'],
            );

            rows.push(this.dorows(sorted, lastDomain, rowsInDomain));
            rowGroup = [];
            rowsInDomain = 0;
            lastDomain.t = type;
            lastDomain.d = d;
          }
          rowsInDomain += numRows;

          rowGroup.push(objectiveData);
        }
      }

      const sorted = orderBy(
        rowGroup,
        [okrData => get(okrData, 'data.objective').toLowerCase()],
        ['asc'],
      );
      rows.push(
        sorted.map((objectiveData, index) => (
          <CanEditObjectiveWrapper
            key={`objective-row-${lastDomain.t}-${lastDomain.d}-${objectiveData.data.objectiveID}`}
            objectiveData={this.props.objectiveData}
            render={canEdit => (
              <ObjectiveRow
                domain={this.props.domain}
                canEdit={canEdit}
                objectiveData={objectiveData}
                index={index}
                numRows={rowsInDomain}
                openAddKeyResult={this.openAddKeyResult}
                stperiod={this.props.stperiod}
              />
            )}
          />
        )),
      );
    }

    return (
      <TableBody name="objectives-table-body" className={allowScroll ? classes.scrollable : null}>
        {rows}
      </TableBody>
    );
  }
}

ObjectivesTable.propTypes = {
  t: PropTypes.func,
  domain: PropTypes.object,
  location: PropTypes.object,
  navigate: PropTypes.func,
  selectObjective: PropTypes.func,
  objectiveIDs: PropTypes.object,
  stperiod: PropTypes.string,
  objectiveData: PropTypes.object,
  allowScroll: PropTypes.bool,
  classes: PropTypes.exact({
    domainHeadline: PropTypes.string,
    row: PropTypes.string,
    avatar: PropTypes.string,
    scrollable: PropTypes.string,
  }),
};

export default withLocation(withNavigation(withStyles(styles)(withTranslation()(ObjectivesTable))));
