import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

import Chip from '@mui/material/Chip';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import UserChip from 'Components/Library/UserChip';
import DataObjectChip from 'Components/Library/DataObjectChip';
import Form from 'Components/Library/Forms/';
import LinearIndicator from 'Components/Library/Forms/Elements/LinearIndicator';
import EmphasizedSection from 'Components/Library/EmphasizedSection';
import FormSelectorField from 'Components/Library/Forms/Elements/FormSelectorField';

import { INTERLOCK_STATUS_COLORS } from 'config/constants';
import StatusSelector from 'Components/Library/StatusSelector';
import Contributors from 'Components/Library/Contributors/index';
import UserSelector from 'Components/Library/UserSelector/index';
import styles from 'Components/Features/Interlocks/DialogViewInterlock/InterlockDetails/styles';
import {
  calendarValue,
  isPastDate,
} from 'Components/Features/Commitments/DialogViewCommitment/CommitmentsDetails/helpers';
import { interlocksActions, interlocksSelectors } from 'state/ducks/interlocks/index';
import OwnerStatus from 'Components/Features/Interlocks/DialogViewInterlock/InterlockDetails/OwnerStatus';
import InterlockTimeline from 'Components/Features/Interlocks/DialogViewInterlock/InterlockDetails/InterlockTimeline/InterlockTimeline';
import InterlockIcon from 'Components/Features/Interlocks/InterlockIcon';
import { editSchema } from '../../schema';

class InterlockDetails extends React.Component {
  renderDate = (params, fieldProps) => {
    const { interlockData, t, theme, canEdit } = this.props;
    const isPast = isPastDate();

    if (interlockData?.data?.due_date) {
      return (
        <Chip
          sx={{
            backgroundColor: 'white',
            color: isPast ? theme.palette.warning.main : '',
            height: 32,
            borderRadius: '10px',
          }}
          label={params.inputProps.value}
          onClick={params.onClick}
          id="interlock-date-selector"
          onDelete={canEdit ? () => fieldProps.onSelect(null) : undefined}
          deleteIcon={
            canEdit ? (
              <HighlightOffIcon
                id="interlocks-date-delete"
                sx={{
                  color: th => th.palette.primary[500],
                  width: 18,
                  height: 18,
                }}
              />
            ) : undefined
          }
          icon={
            <CalendarTodayIcon
              sx={{
                height: 14.67,
                '&.MuiChip-icon': {
                  marginLeft: '0px !important',
                  marginRight: '-10px !important',
                },
              }}
            />
          }
        />
      );
    }

    if (canEdit) {
      return (
        <Button
          variant="text"
          color="secondary"
          onClick={params.onClick}
          id="interlocks-date-selector"
          style={{
            paddingLeft: 0,
            display: 'flex',
            justifyContent: 'flex-start',
            marginLeft: 2,
            width: 'fit-content',
          }}
        >
          {t('interlocks.dateInputLabel')}
        </Button>
      );
    }
    return (
      <DataObjectChip
        name="interlocks-noDate"
        text={
          <Typography align="center" color="text.disabled">
            {t('interlocks.propertyLabels.noDate')}
          </Typography>
        }
        color="darkgrey"
      />
    );
  };

  render() {
    const { classes, interlockData, interlockID, t, onSubmitSuccess, canEdit } = this.props;

    let initialValues = {};
    if (interlockData?.ok) {
      initialValues = {
        status: interlockData.data.status,
        due_date: interlockData.data.due_date,
        owner: interlockData.data.owner,
      };
    }

    return (
      <Form
        name="panel-view-interlocks-details"
        debouncedAutoSubmit={5}
        schema={editSchema}
        initialValues={initialValues}
        stateSlice="main.interlocks"
        allowRefreshData
        submitActionCreator={interlocksActions.editInterlock}
        additionalProperties={{
          id: interlockID,
        }}
        onSubmitSuccess={() => {
          if (!!onSubmitSuccess) {
            onSubmitSuccess();
          }
        }}
      >
        <LinearIndicator positioning="absolute" />
        <div id="interlock-details-container" className={classes.root}>
          <EmphasizedSection className={classes.content}>
            <DataObjectChip
              Icon={InterlockIcon}
              text={t('interlocks.pageTitleSingle')}
              variant="normal"
              contextIconProps={{
                sx: {
                  color: theme => theme.palette.featureInterlocks?.main,
                },
              }}
            />
            <InterlockTimeline interlockData={interlockData?.data} />
            <div className={classes.row} style={{ display: 'flex' }}>
              <div className={clsx(classes.chipLabel)} style={{ width: '50%' }}>
                <Typography color="text.secondary" variant="subtitle2">
                  {t('interlocks.propertyLabels.status')}
                </Typography>
              </div>
              <div className={clsx(classes.chipLabel)} style={{ flexGrow: 1 }}>
                <Typography variant="subtitle2" color="text.secondary">
                  {t('interlocks.propertyLabels.due_date')}
                </Typography>
              </div>
            </div>
            <div className={classes.row} style={{ display: 'flex' }}>
              <div style={{ width: '50%' }}>
                <FormSelectorField
                  fieldName="status"
                  render={fieldProps => (
                    <StatusSelector
                      {...fieldProps}
                      disabled={!canEdit}
                      options={Object.keys(INTERLOCK_STATUS_COLORS).map(status => ({
                        text: t(`interlocks.statusValueText.${status}`),
                        option: status,
                        color: INTERLOCK_STATUS_COLORS[status],
                      }))}
                    />
                  )}
                />
              </div>
              <FormSelectorField
                fieldName="due_date"
                render={fieldProps => (
                  <MobileDatePicker
                    label={t('interlocks.propertyLabels.due_date')}
                    value={calendarValue(interlockData)}
                    inputFormat="DD.MM.YYYY"
                    // note that here we need to use the endOf('day') to make more certain that
                    // the ts is still in the future as expected by the backend in UTC
                    onChange={selectedMoment =>
                      fieldProps.onSelect(selectedMoment.endOf('day').unix())
                    }
                    closeOnSelect
                    disablePast
                    renderInput={params => this.renderDate(params, fieldProps)}
                    disabled={!canEdit}
                    sx={{
                      flexGrow: 1,
                      '& .MuiPickersToolbar-penIconButton': {
                        display: 'none',
                      },
                    }}
                  />
                )}
              />
            </div>
            <Divider sx={{ mb: 1.5, mt: 0.5 }} />
            <div className={classes.row}>
              <div className={clsx(classes.leftColumn, classes.chipLabel)}>
                <Typography variant="subtitle2" color="text.secondary">
                  {t('interlocks.contributorValueText.CREATOR')}
                </Typography>
              </div>
              <div className={classes.rightColumn}>
                <UserChip color="grey" sub={interlockData?.data?.creator} />
              </div>
            </div>
            <Divider sx={{ mb: 1.5, mt: 0.5 }} />
            <div className={classes.row}>
              <div className={clsx(classes.leftColumn, classes.chipLabel)}>
                <Typography variant="subtitle2" color="text.secondary">
                  {t('interlocks.contributorValueText.OWNER')}
                </Typography>
              </div>
              <div className={classes.rightColumn}>
                <div className={classes.ownerRow}>
                  <OwnerStatus interlockID={interlockID} ownerID={interlockData?.data?.owner} />
                  <FormSelectorField
                    fieldName="owner"
                    render={fieldProps => (
                      <UserSelector showRecent {...fieldProps} color="white" disabled={!canEdit} />
                    )}
                  />
                </div>
              </div>
            </div>
            <Divider sx={{ mb: 1.5, mt: 0.5 }} />
            <div className={classes.row}>
              <div className={clsx(classes.leftColumn, classes.chipLabel)}>
                <Typography variant="subtitle2" color="text.secondary">
                  {t('interlocks.propertyLabels.contributors')}
                </Typography>
              </div>
              <div className={classes.rightColumn}>
                <Contributors
                  instanceName="interlocks"
                  selectContributors={interlocksSelectors.selectContributorsWithoutOwner}
                  actions={interlocksActions}
                  instanceData={interlockData}
                  canEdit={canEdit}
                  canDelete={canEdit}
                  onSubmitSuccess={onSubmitSuccess}
                  restrictedUserIds={[interlockData?.data?.owner]}
                />
              </div>
            </div>
          </EmphasizedSection>
        </div>
      </Form>
    );
  }
}

InterlockDetails.propTypes = {
  classes: PropTypes.exact({
    root: PropTypes.string,
    content: PropTypes.string,
    row: PropTypes.string,
    chipLabel: PropTypes.string,
    rightColumn: PropTypes.string,
    leftColumn: PropTypes.string,
    ownerRow: PropTypes.string,
  }),
  interlockData: PropTypes.object,
  interlockID: PropTypes.string.isRequired,
  t: PropTypes.func,
  theme: PropTypes.object,
  onSubmitSuccess: PropTypes.func,
  canEdit: PropTypes.bool,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(InterlockDetails));
