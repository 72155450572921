import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Skeleton from '@mui/material/Skeleton';
import * as constants from 'state/constants/api';
import SavingIndicator from 'Components/Common/SavingIndicatorv2';
import { SLACK_SERVICE_NAME } from 'screens/Features/Admin/IntegrationsView/constants';

const styles = () => ({
  savingIndicator: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
  },
});

class NotificationsTab extends Component {
  state = {
    notifCfgFetched: false,
    integrationsCfgFetched: false,
    notifCfg: {},
  };

  componentDidMount() {
    this.query();
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  query = () => {
    const { tenantID, accessToken, sub } = this.props;
    // This data is of absolutely zero use anywhere outside of this component,
    // fetching here instead of using redux in the spirit of KISS
    axios
      .get(`${constants.API_BASE_URL}/${tenantID}/getnotificationconfiguration`, {
        params: { sub },
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then(response => {
        this.setState({ notifCfg: response.data.result, notifCfgFetched: true });
      })
      .catch(() => {
        // eslint-disable-next-line react/no-unused-state
        this.setState({ notifCfgerror: true });
      });

    axios
      .get(`${constants.INTEGRATIONS_API_BASE_URL}/${tenantID}/getintegrationstatus`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then(response => {
        const integrations = {};
        response.data.result.forEach(service => {
          integrations[service.serviceName] = service;
        });
        this.setState({ integrationsCfg: integrations, integrationsCfgFetched: true });
      })
      .catch(() => {
        // eslint-disable-next-line react/no-unused-state
        this.setState({ integrationsCfgError: true });
      });
  };

  toggleValue = event => {
    const notifCfg = { ...this.state.notifCfg };
    notifCfg[event.target.name] = event.target.checked;
    this.setState({ notifCfg });
  };

  handleSubmit = () => {
    const { tenantID, accessToken, sub } = this.props;
    const { notifCfg } = this.state;
    this.setState({ submitted: true, submitStatus: 0 });
    axios
      .post(
        `${constants.API_BASE_URL}/${tenantID}/setnotificationconfiguration`,
        { sub, cfg: JSON.stringify(notifCfg) },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      )
      .then(() => {
        this.setState({ submitStatus: 1, submitted: false });
        this.timeout = setTimeout(() => {
          if (this.state.submitStatus === 1) {
            const newState = { submitStatus: 0, submitted: false };
            this.setState(newState);
          }
        }, 1000);
      })
      .catch(() => {
        this.setState({
          submitStatus: -1,
          submitted: false,
          lastError: this.props.t('general.errorOccured'),
        });
      });
  };

  render() {
    const { classes, t } = this.props;
    const {
      integrationsCfgFetched,
      notifCfgFetched,
      integrationsCfg,
      notifCfg,
      submitted,
      submitStatus,
      lastError,
    } = this.state;
    return (
      <>
        <SavingIndicator
          className={classes.savingIndicator}
          submitted={submitted}
          submitStatus={submitStatus}
          errorText={lastError}
        />
        <Typography variant="h6" gutterBottom>
          {t('usersettings.notifications.tabTitle')}
        </Typography>
        <br />
        {!(notifCfgFetched && integrationsCfgFetched) ? (
          <>
            <Box mb={1}>
              <Skeleton variant="rectangular" height={38} width="100%" />
            </Box>
            <Box mb={1}>
              <Skeleton variant="rectangular" height={38} width="100%" />
            </Box>
            <Box mb={1}>
              <Skeleton variant="rectangular" height={38} width="100%" />
            </Box>
          </>
        ) : (
          <>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              mb={1}
            >
              <Typography variant="subtitle2" gutterBottom>
                {t('usersettings.notifications.sectionTitleApp')}
              </Typography>
              <Switch
                onChange={this.toggleValue}
                color="secondary"
                checked={notifCfg.fcm}
                name="fcm"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              mb={1}
            >
              <Typography variant="subtitle2" gutterBottom>
                {t('usersettings.notifications.sectionTitleEmail')}
              </Typography>
              <Switch
                onChange={this.toggleValue}
                color="secondary"
                checked={notifCfg.email}
                name="email"
              />
            </Box>
            {!!integrationsCfg.slack?.status && (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                mb={1}
              >
                <Typography variant="subtitle2" gutterBottom>
                  {t('usersettings.notifications.sectionTitleSlack')}
                </Typography>
                <Switch
                  onChange={this.toggleValue}
                  color="secondary"
                  checked={notifCfg.slack}
                  name={SLACK_SERVICE_NAME}
                />
              </Box>
            )}
            {!!integrationsCfg.teams && integrationsCfg.teams.status && (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                mb={1}
              >
                <Typography variant="subtitle2" gutterBottom>
                  {t('usersettings.notifications.sectionTitleTeams')}
                </Typography>
                <Switch
                  onChange={this.toggleValue}
                  color="secondary"
                  checked={notifCfg.teams}
                  name="teams"
                />
              </Box>
            )}
          </>
        )}

        <Button
          id="notifications-dialog-submit-button"
          color="secondary"
          onClick={this.handleSubmit}
          variant="contained"
        >
          {t('general.save')}
        </Button>
      </>
    );
  }
}

const mapStateToProps = state => ({
  accessToken: state.auth.tokens.access_token,
  tenantID: state.auth.tenantID,
  sub: state.auth.userID,
});

NotificationsTab.propTypes = {
  t: PropTypes.func,
  classes: PropTypes.exact({
    savingIndicator: PropTypes.string,
  }),
  // eslint-disable-next-line react/no-unused-prop-types
  dispatch: PropTypes.func,
  accessToken: PropTypes.string,
  tenantID: PropTypes.string,
  sub: PropTypes.string,
};

export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(NotificationsTab)));
