import { PURGE } from 'redux-persist';
import { validatePersistedState, copyState, enrich, fetching, apiError } from 'state/helpers';
import * as types from './types';
import * as constants from '../../constants/api';
import { processActionLogEvents } from './helpers/events';

export const initialState = {
  VERSION: 1.13,
  activitylogs: {},
  actionlog: {},
};

function addFetchedNodeActivitylogsToState(state, payload) {
  const newState = copyState(state);
  newState.activitylogs[payload.id] = enrich({
    fetchStatus: constants.OK,
    lastFetched: Date.now(),
    maxAge: 1000 * 60 * 2, // 2 minutes
    data: processActionLogEvents(payload.id, payload),
  });
  return newState;
}

function addFetchingNodeActivitylogsToState(state, payload) {
  const newState = copyState(state);
  const { id } = payload;
  newState.activitylogs[id] = fetching(newState.activitylogs[id]);

  return newState;
}

function addFailedNodeActivitylogsToState(state, payload) {
  const newState = copyState(state);
  const { id } = payload;
  newState.activitylogs[id] = apiError(newState.activitylogs[id]);
  return newState;
}

// The params need to be in this order, that's what redux gives :)
// eslint-disable-next-line default-param-last
const reducer = (state = JSON.parse(JSON.stringify(initialState)), action) => {
  state = validatePersistedState(state, initialState);
  switch (action.type) {
    case types.FETCH_ACTIVITYLOG:
      return addFetchingNodeActivitylogsToState(state, action.payload);
    case types.RECEIVED_ACTIVITYLOG:
      return addFetchedNodeActivitylogsToState(state, action.payload);
    case types.FAILED_ACTIVITYLOG:
      return addFailedNodeActivitylogsToState(state, action.payload);
    case PURGE:
      return JSON.parse(JSON.stringify(initialState));
    default:
      return state;
  }
};

export default reducer;
