import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isEnabled } from 'config/flags';
import { appstatusSelectors } from 'state/ducks/appstatus';

function FeatureFlag({ flag, children, ...rest }) {
  const appstatus = useSelector(state => appstatusSelectors.selectStatus(state.main.appstatus));
  // , {
  // stabilityCheck: 'never',
  // }

  if (!isEnabled(flag, appstatus)) {
    return null;
  }
  return React.cloneElement(children, rest);
}

FeatureFlag.propTypes = {
  flag: PropTypes.string,
  // Required to be able to use FeatureFlag in MUI Tabs:
  // eslint-disable-next-line react/no-unused-prop-types
  value: PropTypes.string,
};

export default FeatureFlag;
