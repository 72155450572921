import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import Button from '@mui/material/Button';
import { graphSelectors, graphActions } from 'state/ducks/graph';

import EmphasizedSection from 'Components/Library/EmphasizedSection';
import GraphElementChip from 'Components/Library/GraphElementResolver/GraphElementChip';
import HelperText from 'Components/Common/HelperText';
import CanEditNodeWrapper from 'Components/Library/NodeRelations/CanEditNodeWrapper';
import AddRelationMenu from 'Components/Library/NodeRelations/AddRelationMenu';

function NodeRelations(props) {
  const { label, graphId, cta, canEdit, onSelect } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const dispatch = useDispatch();

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      dispatch(graphActions.fetchGraphRelations({ id: graphId, force: true }));
      mounted.current = true;
    } else {
      dispatch(graphActions.fetchGraphRelations({ id: graphId, force: false }));
    }
  });

  const relations = useSelector(
    state => graphSelectors.selectRelations(state.main.graph, graphId),
    // { stabilityCheck: 'never' },
  );

  const removeLink = (event, link) => {
    dispatch(graphActions.editRelations({ changeset: [{ ...link, action: 'DELETE' }] }));
    event.preventDefault();
    event.stopPropagation();
  };

  const openSearch = clickEvent => {
    setAnchorEl(clickEvent.currentTarget);
  };

  const open = Boolean(anchorEl);

  const closeSearch = () => {
    setAnchorEl(null);
  };

  const onSelectHandler = searchResult => {
    closeSearch();
    onSelect(searchResult);
  };

  const infoSection = (
    <EmphasizedSection>
      <HelperText variant="info">{t('relations.placeholder')}</HelperText>
    </EmphasizedSection>
  );

  return (
    <EmphasizedSection sx={{ p: 1 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {label}
        {canEdit && (
          <Button variant="text" color="secondary" onClick={openSearch}>
            {t('relations.add')}
          </Button>
        )}
        <AddRelationMenu
          anchorEl={anchorEl}
          open={open}
          onClose={closeSearch}
          onSelect={onSelectHandler}
          instanceId={graphId}
        />
      </div>
      {!!relations?.ok ? (
        <>
          {relations.data.length > 0 ? (
            <Stack
              sx={{
                backgroundColor: 'background.paper',
                borderRadius: '10px',
                m: 2,
                mt: 1,
                mb: 1,
                p: 1,
              }}
              spacing={1}
            >
              {relations.data.map(r => {
                const otherId = r.from === graphId ? r.to : r.from;
                return (
                  <CanEditNodeWrapper
                    key={`node-relations-${otherId}`}
                    graphID={otherId}
                    render={canEditChild => (
                      <GraphElementChip
                        key={`node-relations-${otherId}`}
                        graphId={otherId}
                        color="transparent"
                        allowNavigate
                        statusComponent={
                          canEditChild ? (
                            <IconButton
                              component="div"
                              onClick={e => removeLink(e, r)}
                              name="remove-relation"
                              aria-label={t('general.unlink')}
                            >
                              <HighlightOffIcon
                                sx={{
                                  color: theme => theme.palette.primary[500],
                                  width: 15,
                                  height: 15,
                                }}
                              />
                            </IconButton>
                          ) : undefined
                        }
                        actionIcon={
                          canEditChild ? (
                            <IconButton
                              component="div"
                              onClick={e => removeLink(e, r)}
                              name="remove-relation"
                              aria-label={t('general.unlink')}
                            >
                              <HighlightOffIcon
                                sx={{
                                  color: theme => theme.palette.primary[500],
                                  width: 15,
                                  height: 15,
                                }}
                              />
                            </IconButton>
                          ) : undefined
                        }
                      />
                    )}
                  />
                );
              })}
            </Stack>
          ) : (
            infoSection
          )}
          {cta}
        </>
      ) : (
        infoSection
      )}
    </EmphasizedSection>
  );
}

NodeRelations.propTypes = {
  graphId: PropTypes.string,
  label: PropTypes.node,
  cta: PropTypes.node,
  canEdit: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default NodeRelations;
