import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { connectionSelectors } from 'state/ducks/connection';
import { VALID, NEEDS_REFRESH, OFFLINE } from 'state/ducks/auth/selectors';
import { withLocation } from 'withRouter';
import { authSelectors, authActions } from 'state/ducks/auth';

export class UnwrappedAuthGate extends Component {
  componentDidMount() {
    this.refreshTokens();
  }

  componentDidUpdate() {
    this.refreshTokens();
  }

  refreshTokens = () => {
    const { dispatch, requireValidToken, authStatus } = this.props;
    if (requireValidToken && authStatus === NEEDS_REFRESH) {
      dispatch(authActions.refreshTokens({ force: true }));
    }
  };

  render() {
    const { authValid, location, isOnline, requireValidToken, authStatus } = this.props;

    if (!authValid && isOnline) {
      // console.log('not authed and online, navigating to login');
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/login" state={{ from: location }} />;
    }

    if (requireValidToken && authStatus === NEEDS_REFRESH) {
      return null;
    }

    // console.log('authed or offline, navigating to Outlet');

    return <Outlet />;
  }
}
const mapStateToProps = state => ({
  authStatus: authSelectors.isAuthValid(state),
  authValid: [VALID, OFFLINE, NEEDS_REFRESH].includes(authSelectors.isAuthValid(state)),
  isOnline: connectionSelectors.selectOnlineStatus(state.main.connection),
});

UnwrappedAuthGate.propTypes = {
  authValid: PropTypes.bool,
  location: PropTypes.object,
  isOnline: PropTypes.bool,
  requireValidToken: PropTypes.bool,
  authStatus: PropTypes.string,
  dispatch: PropTypes.func,
};

export default withLocation(connect(mapStateToProps)(UnwrappedAuthGate));
