// feature flags
import { get } from 'lodash';

// Comply with our usual export schema:
// eslint-disable-next-line import/prefer-default-export
export const isEnabled = (feature, overrides = {}) => {
  // const isProd = process.env.REACT_APP_STAGE === 'production';
  const isBeta = process.env.REACT_APP_STAGE === 'beta';
  const isDev =
    process.env.REACT_APP_STAGE === 'alpha' ||
    process.env.REACT_APP_STAGE === 'test' ||
    process.env.NODE_ENV === 'development';

  switch (feature) {
    case 'GAMEPLANS':
      return isDev || isBeta || get(overrides, 'data.flag_overrides.gameplans', false);
    case 'COMMITMENTS':
      return true;
    case 'INTERLOCKS':
      return true;
    case 'SLACK':
      return true;
    case 'DARKMODE':
      return isDev;
    case 'REDUX-LOGGING':
      return isDev || isBeta;
    case 'AI':
      return isDev || isBeta;
    case 'RELATED-ITEMS':
      return isDev || isBeta;
    case 'SENTRY':
      return isBeta || get(overrides, 'data.flag_overrides.sentry', false);
    case 'PENDO':
      return isDev || isBeta || get(overrides, 'data.flag_overrides.pendo', false);
    // the one below is not an actual feature flag, it's used only for testing
    case 'FAKE_FLAG':
      return false;
    default:
      return true;
  }
};
