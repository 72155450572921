/* eslint-disable no-console */
import React from 'react';
import persistor from 'persistor';
import { channel, pausePersistorAndReload } from 'config/helpers';

class LogoutHandler extends React.Component {
  /*
    This handler ensures that logout happens
    accross all open tabs
  */
  componentDidMount() {
    channel.onmessage = data => {
      // adding console.log to debug upgrade loops on several tabs
      console.log(data);
      if (data.title === 'PURGE_STATE') {
        console.log('PURGE_STATE command received from another tab: logging out');
        pausePersistorAndReload(persistor);
      }
    };
  }

  render() {
    return null;
  }
}

LogoutHandler.propTypes = {};

export default LogoutHandler;
