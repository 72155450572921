import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import ContainedIconButton from 'Components/Library/ContainedIconButton';
import RichTextRenderer from 'Components/Library/RichTextRenderer';

import WidgetFooter from '../Lib/WidgetFooter';

import ImageElement from './ImageElement';
import VideoElement from './VideoElement';

import { ITEMTYPE_IMAGE, ITEMTYPE_RICHTEXT, ITEMTYPE_VIDEO } from './itemtypes';

function CustomWidget({
  variant,
  data,
  canEdit,
  dragHandleProps,
  requestEdit,
  requestRemove,
  name,
}) {
  const contentStyles = {
    ml: 2,
    mr: 2,
    mb: 1.5,
    pb: 1,
    borderRadius: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  };
  if (variant === 'card') {
    contentStyles.maxHeight = '224px';
    contentStyles.WebkitMaskImage = 'linear-gradient(180deg, #000 200px, transparent)';
  }

  const isInteractive = variant === 'card' && canEdit;

  return (
    <>
      <Box
        sx={{
          mt: 0,
          p: 2,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'stretch',
          height: '24px',
          bgColor: '#eee',
        }}
        {...dragHandleProps}
        name={name}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={data.name ? 'space-between' : 'flex-end'}
          sx={{ flexBasis: '100%', overflowX: 'hidden' }}
        >
          <Typography variant="subtitle2" sx={{ flexShrink: 10 }}>
            {data.name}
          </Typography>

          {isInteractive && (
            <Stack direction="row" spacing={1} className="gp-widget-actions">
              <ContainedIconButton onClick={requestRemove} name="gameplans-remove-widget-button">
                <DeleteIcon />
              </ContainedIconButton>
              <ContainedIconButton onClick={requestEdit} name="gameplans-edit-button">
                <EditIcon />
              </ContainedIconButton>
            </Stack>
          )}
        </Stack>
      </Box>
      <Box sx={contentStyles}>
        {data?.elements?.map(contentItem => (
          <section key={`custom-widget-preview-element-${contentItem.id}`}>
            {contentItem.element_type === ITEMTYPE_RICHTEXT && (
              <Box sx={{ p: 0, pt: 0.5, pb: 0.5 }}>
                <RichTextRenderer richTextContent={contentItem.content} />
              </Box>
            )}
            {contentItem.element_type === ITEMTYPE_IMAGE && (
              <ImageElement item={contentItem} widgetId={data.id} />
            )}
            {contentItem.element_type === ITEMTYPE_VIDEO && <VideoElement item={contentItem} />}
          </section>
        ))}
      </Box>
      {variant === 'card' && <WidgetFooter data={data} dragHandleProps={dragHandleProps} />}
    </>
  );
}

CustomWidget.propTypes = {
  teamId: PropTypes.string,
  data: PropTypes.object,
  dragHandleProps: PropTypes.object,
  requestEdit: PropTypes.func,
  requestRemove: PropTypes.func,
  canEdit: PropTypes.bool,
  variant: PropTypes.oneOf(['card', 'dialog']),
  name: PropTypes.string,
};

CustomWidget.defaultProps = {
  variant: 'card',
};

export default CustomWidget;
