import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import moment from 'moment';
import i18n, { LANGS } from 'config/i18n';

import 'moment/locale/fi';
import 'moment/locale/fr';

moment.locale(i18n.language);

// TODO: figure out what is different in this class so that it does not register properly in the coverage measurement

class DialogLanguage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lng: i18n.language.substring(0, 2),
    };
  }

  onChange = event => {
    this.setState({
      lng: event.target.value,
    });
  };

  handleSubmit = () => {
    moment.locale(this.state.lng);
    i18n.changeLanguage(this.state.lng);
  };

  render() {
    const { t } = this.props;
    const { lng } = this.state;
    return (
      <>
        <Typography variant="h6" gutterBottom>
          {t('usersettings.language.tabTitle')}
        </Typography>
        <br />
        <RadioGroup value={lng} onChange={this.onChange}>
          {LANGS.map(lngopt => (
            <FormControlLabel
              key={`set-lang-dialog-opt-${lngopt.code}`}
              name={`set-lang-dialog-opt-${lngopt.code}`}
              value={lngopt.code}
              control={<Radio color="secondary" />}
              label={`${lngopt.localname} (${t(`setlanguage._${lngopt.code}`)})`}
            />
          ))}
        </RadioGroup>
        <Button
          id="set-lang-dialog-submit-button"
          variant="contained"
          color="secondary"
          onClick={this.handleSubmit}
        >
          {t('general.save')}
        </Button>
      </>
    );
  }
}

DialogLanguage.propTypes = {
  t: PropTypes.func,
};

export default withTranslation()(DialogLanguage);
