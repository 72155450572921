/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import EmphasizedSection from 'Components/Library/EmphasizedSection';
import { SORTING_ORDERS } from 'state/ducks/interlocks/types';
import PaginatedDataGrid from './PaginatedDataGrid';

class PaginatedDataGridContainer extends Component {
  componentDidMount() {
    this.fetch(true);
  }

  componentDidUpdate() {
    this.fetch();
  }

  componentWillUnmount() {
    clearTimeout(this.loadMoreTimeout);
  }

  fetch = (force = false) => {
    const { dispatch, fetchActionCreator, data } = this.props;
    dispatch(fetchActionCreator(data, force));
  };

  render() {
    const {
      statusFilterNode,
      domainFilterNode,
      data,
      translationNameSpace,
      RowElement,
      TableHeaderElement,
      statusFilterValue,
      columns,
      sortColumn,
      sortOrder,
      setSortBy,
      setSortOrder,
    } = this.props;

    return (
      <EmphasizedSection sx={{ p: 2 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flexGrow: 10,
          }}
        >
          <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
            {domainFilterNode}
            {statusFilterNode}
          </Stack>
          <Grid container alignItems="stretch">
            <Grid item xs={12}>
              <PaginatedDataGrid
                data={data}
                translationNameSpace={translationNameSpace}
                statusFilterValue={statusFilterValue}
                RowElement={RowElement}
                TableHeaderElement={TableHeaderElement}
                columns={columns}
                sortColumn={sortColumn}
                sortOrder={sortOrder}
                setSortBy={setSortBy}
                setSortOrder={setSortOrder}
              />
            </Grid>
          </Grid>
        </Box>
      </EmphasizedSection>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  data: ownProps.selector(state),
});

PaginatedDataGridContainer.propTypes = {
  data: PropTypes.object,
  domain: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
  }),
  statusFilterValue: PropTypes.array,
  statusFilterNode: PropTypes.node,
  domainFilterNode: PropTypes.node,
  fetchActionCreator: PropTypes.func,
  dispatch: PropTypes.func,
  translationNameSpace: PropTypes.string.isRequired,
  RowElement: PropTypes.elementType.isRequired,
  TableHeaderElement: PropTypes.elementType.isRequired,
  columns: PropTypes.number,
  sortColumn: PropTypes.string,
  sortOrder: PropTypes.oneOf([SORTING_ORDERS.asc, SORTING_ORDERS.desc]),
  setSortBy: PropTypes.func,
  setSortOrder: PropTypes.func,
};

export default connect(mapStateToProps)(PaginatedDataGridContainer);
