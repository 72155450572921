import React, { useEffect } from 'react';
import { get } from 'lodash';

import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import Skeleton from '@mui/material/Skeleton';
import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import PersonasAvatar from 'Components/Common/PersonasAvatar';
import DataObjectChip from 'Components/Library/DataObjectChip';

import * as apiconstants from 'state/constants/api';

import { openViewCommitmentPanel } from 'config/ModalProvider/helpers';
import CommitmentsIcon from 'Components/Features/Commitments/CommitmentsIcon';
import { commitmentsActions, commitmentsSelectors } from 'state/ducks/commitments';

import { COM_STATUS_COLORS } from 'config/constants';

function CommitmentChip(props) {
  const {
    graphId,
    wrapText,
    color,
    allowNavigate,
    onClick,
    ContextIcon,
    sx,
    name,
    placeholder,
    variant,
    statusComponent,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleClick = e => {
    if (!allowNavigate && !!onClick) {
      onClick(e);
    } else if (allowNavigate) {
      openViewCommitmentPanel(graphId, false, navigate, location);
      if (!!onClick) {
        onClick(e);
      }
    }
  };

  useEffect(() => {
    dispatch(commitmentsActions.fetchCommitment({ id: graphId, force: false }));
  });

  const commitmentData = useSelector(
    state => commitmentsSelectors.selectCommitment(state.main.commitments, graphId),
    // { stabilityCheck: 'never' },
  );

  const isDataMissing = !commitmentData || (!commitmentData.ok && !commitmentData.error);

  if (commitmentData?.fetchStatus === apiconstants.DELETED || commitmentData?.error) {
    // The entire commitment has been deleted or something catastrophic is going on with the
    // API connection
    return null;
  }

  if (isDataMissing && !placeholder) {
    // Loading
    return <Skeleton variant="rounded" height={32} sx={sx} />;
  }

  return (
    <DataObjectChip
      name={name ?? `commitment-chip-${graphId}`}
      icon={<PersonasAvatar sub={get(commitmentData, 'data.owner')} size="xtiny" disablePopOver />}
      // It's not duplicate...:
      // eslint-disable-next-line react/jsx-no-duplicate-props
      Icon={ContextIcon}
      sx={sx}
      onClick={handleClick}
      actionable={allowNavigate || Boolean(onClick)}
      text={placeholder && isDataMissing ? placeholder : get(commitmentData, 'data.name')}
      color={color}
      dataChipType="okr-objective"
      statusComponent={
        <>
          <Chip
            label={t(`commitments.statusValueText.${get(commitmentData, 'data.status')}`)}
            color={COM_STATUS_COLORS[get(commitmentData, 'data.status')]}
            variant="outlined"
          />
          {statusComponent}
        </>
      }
      variant={variant}
      wrapText={wrapText || variant === 'xl'}
    />
  );
}

CommitmentChip.propTypes = {
  graphId: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.oneOf(['small', 'normal', 'large', 'xl']),
  classes: PropTypes.exact({
    root: PropTypes.string,
    red: PropTypes.string,
    amber: PropTypes.string,
    green: PropTypes.string,
    status: PropTypes.string,
  }),
  wrapText: PropTypes.bool,
  ContextIcon: PropTypes.elementType,
  onClick: PropTypes.func,
  allowNavigate: PropTypes.bool,
  sx: PropTypes.object,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  statusComponent: PropTypes.node,
};

CommitmentChip.defaultProps = {
  ContextIcon: CommitmentsIcon,
};

export default CommitmentChip;
