import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormRadioGroup from 'Components/Library/Forms/Elements/FormRadioGroup';
import EmphasizedSection from 'Components/Library/EmphasizedSection';
import FormFreeText from './FormFreeText';

class FormMultiChoiceReply extends Component {
  render() {
    const { t, values } = this.props;
    return (
      <div>
        <EmphasizedSection sx={{ mb: 3, mt: 3 }}>
          <FormRadioGroup
            fieldName="score"
            valueCastFunc={v => parseInt(v, 10)}
            render={fieldProps => (
              <RadioGroup {...fieldProps}>
                {Object.keys(values.choiceOptions).map((key, index) => (
                  <FormControlLabel
                    key={index + key}
                    value={key}
                    classes={{ label: 'topic-multichoice-opt-label' }}
                    control={<Radio />}
                    label={values.choiceOptions[key]}
                    className="topic-multichoice-opt"
                    id={`topic-multichoice-opt-${index}`}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </EmphasizedSection>
        <FormFreeText
          audienceType={values.audienceType}
          label={
            !!values.opentextfieldlabel
              ? values.opentextfieldlabel
              : t('insights.defaultopencommentlabel')
          }
        />
      </div>
    );
  }
}

FormMultiChoiceReply.propTypes = {
  values: PropTypes.object,
  t: PropTypes.func,
};

export default withTranslation()(FormMultiChoiceReply);
