import { PURGE } from 'redux-persist';
import { validatePersistedState } from 'state/helpers';
import { isEnabled } from 'config/flags';
import { SET_LOCALE, SET_DARKMODE } from './types';

// initial value for the darkmode is based on user's browser/os setting value, use in initialState once is taken into use and not behind a feature flag
const isBrowserDefaultDark = () => window.matchMedia('(prefers-color-scheme: dark)').matches;

export const initialState = {
  VERSION: 1.03,
  locale: null,
  darkmode: isEnabled('DARKMODE') && isBrowserDefaultDark,
};

// eslint-disable-next-line default-param-last
export default (state = JSON.parse(JSON.stringify(initialState)), action) => {
  state = validatePersistedState(state, initialState);
  switch (action.type) {
    case SET_LOCALE:
      return {
        ...state,
        locale: action.payload.locale,
      };
    case SET_DARKMODE:
      return {
        ...state,
        darkmode: action.payload.darkmode,
      };
    case PURGE:
      return JSON.parse(JSON.stringify(initialState));
    default:
      return state;
  }
};
