import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import MediaQueryWrapper from 'Components/Common/MediaQueryWrapper';
import ObjectivesIcon from './ObjectivesIcon';
import KeyresultsIcon from './KeyresultsIcon';

const styles = theme => ({
  cell: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    '& .MuiSvgIcon-root': {
      fontSize: '1rem',
      color: theme.palette.primary[500],
      transform: 'translateY(2px)',
      marginRight: 7,
    },
  },
  okrCell: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(2),
  },
  smallCell: {
    width: '80px',
    paddingLeft: 0,
    paddingRight: theme.spacing(2),
    textAlign: 'center',
  },
});

class ObjectivesTableHeader extends React.Component {
  render() {
    const { t, xs, classes } = this.props;
    if (xs) {
      return null;
    }
    return (
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: '33%' }} className={clsx(classes.cell, classes.okrCell)}>
            <ObjectivesIcon />
            {t('objectives.genericDataObjectNameObjectives')}
          </TableCell>
          <TableCell sx={{ width: '66%' }} className={clsx(classes.cell, classes.okrCell)}>
            <KeyresultsIcon />
            {t('objectives.genericDataObjectNameKeyresults')}
          </TableCell>
          <TableCell className={clsx(classes.cell, classes.smallCell)}>
            {t('objectives.genericdataobjectnameupdated')}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }
}

ObjectivesTableHeader.propTypes = {
  classes: PropTypes.exact({
    cell: PropTypes.string,
    okrCell: PropTypes.string,
    smallCell: PropTypes.string,
  }),
  xs: PropTypes.bool,
  t: PropTypes.func,
};

export default withStyles(styles)(MediaQueryWrapper()(withTranslation()(ObjectivesTableHeader)));
