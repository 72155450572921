/* OKRS */

export const GET_OBJECTIVE = 'GET_OBJECTIVE';
export const OBJECTIVE_GET_FAILED = 'OBJECTIVE_GET_FAILED';
export const OBJECTIVE_FETCHED = 'OBJECTIVE_FETCHED';

export const GET_PERIODS = 'GET_PERIODS';
export const PERIODS_FETCH_FAILED = 'PERIODS_FETCH_FAILED';
export const PERIODS_FETCHED = 'PERIODS_FETCHED';

export const DELETE_OBJECTIVE = 'DELETE_OBJECTIVE';
export const OBJECTIVE_DELETED = 'OBJECTIVE_DELETED';
export const UPDATE_OBJECTIVE = 'UPDATE_OBJECTIVE';
export const OBJECTIVE_UPDATED = 'OBJECTIVE_UPDATED';

export const GRADE_OBJECTIVE = 'GRADE_OBJECTIVE';
export const GRADED_OBJECTIVE = 'GRADED_OBJECTIVE';

export const GET_COMPANY_OBJECTIVES = 'GET_COMPANY_OBJECTIVES';
export const RECEIVED_COMPANY_OBJECTIVES = 'RECEIVED_COMPANY_OBJECTIVES';
export const FAILED_COMPANY_OBJECTIVES = 'FAILED_COMPANY_OBJECTIVES';
export const CREATE_COMPANY_OBJECTIVE = 'CREATE_COMPANY_OBJECTIVE';

export const CREATE_PERSON_OBJECTIVE = 'CREATE_PERSON_OBJECTIVE';

export const GET_RELATED_OBJECTIVES = 'GET_RELATED_OBJECTIVES';
export const RECEIVED_RELATED_OBJECTIVES = 'RECEIVED_RELATED_OBJECTIVES';
export const FAILED_RELATED_OBJECTIVES = 'FAILED_RELATED_OBJECTIVES';

export const GET_TEAM_OBJECTIVES = 'GET_TEAM_OBJECTIVES';
export const RECEIVED_TEAM_OBJECTIVES = 'RECEIVED_TEAM_OBJECTIVES';
export const FAILED_TEAM_OBJECTIVES = 'FAILED_TEAM_OBJECTIVES';
export const CREATE_TEAM_OBJECTIVE = 'CREATE_TEAM_OBJECTIVE';

export const OBJECTIVE_CREATED = 'OBJECTIVE_CREATED';

export const GET_HIERARCHY = 'GET_HIERARCHY';
export const RECEIVED_HIERARCHY = 'RECEIVED_HIERARCHY';
export const OBJECTIVE_HIERARCHY_FETCH_FAILED = 'OBJECTIVE_HIERARCHY_FETCH_FAILED';

export const UPDATE_OBJECTIVE_PARENT = 'UPDATE_OBJECTIVE_PARENT';
export const OBJECTIVE_PARENT_UPDATED = 'OBJECTIVE_PARENT_UPDATED';

export const GET_KEYRESULTS = 'GET_KEYRESULTS';
export const RECEIVED_KEYRESULTS = 'RECEIVED_KEYRESULTS';
export const CREATE_KEYRESULT = 'CREATE_KEYRESULT';
export const KEYRESULT_CREATED = 'CREATED_KEYRESULT';
export const UPDATE_KEYRESULT = 'UPDATE_KEYRESULT';
export const UPDATE_KEYRESULT_TODOS = 'UPDATE_KEYRESULT_TODOS';
export const EDIT_KEYRESULT = 'EDIT_KEYRESULT';
export const KEYRESULT_UPDATED = 'KEYRESULT_UPDATED';
export const DELETE_KEYRESULT = 'DELETE_KEYRESULT';
export const KEYRESULT_DELETED = 'KEYRESULT_DELETED';

export const GET_OBJECTIVE_LINKS = 'GET_OBJECTIVE_LINKS';
export const RECEIVED_OBJECTIVE_LINKS = 'RECEIVED_OBJECTIVE_LINKS';
export const FETCH_LINKS_FAILED = 'OBJECTIVE_LINKS_FETCH_FAILED';

export const COPY_TO_PERIOD = 'COPY_TO_PERIOD';
export const COPIED_TO_PERIOD = 'COPIED_TO_PERIOD';

export const ERROR_RECEIVED_FROM_API = 'OBJECTIVES_API_ERROR_RECEIVED';
