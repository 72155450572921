import { PURGE } from 'redux-persist';
import { copyState, validatePersistedState, enrich, apiError, fetching } from 'state/helpers';
import * as types from './types';
import * as constants from '../../constants/api';

export const initialState = {
  VERSION: 1.04,
  programs: {}, // { cpID: {...data, }}
  programusers: {},
  actionlog: {},
};

function addFailedProgramsToState(state) {
  const newState = copyState(state);
  newState.programs = apiError(newState.programs);
  return newState;
}

function addProgramsToState(state, action) {
  const programs = action.payload;
  const newState = copyState(state);

  const stateObject = {
    strategy: null,
    data: {},
    lastFetched: Date.now(),
    maxAge: 1000 * 600,
    fetchStatus: constants.OK,
  };

  for (let i = 0; i < programs.length; i++) {
    const prg = programs[i];
    if (prg.type === 'strategy') {
      stateObject.strategy = prg.cpID;
    }
    stateObject.data[prg.cpID] = prg;
  }

  newState.programs = enrich(stateObject);
  return newState;
}

function addEditedProgramRootToState(state, action) {
  const newState = copyState(state);

  newState.actionlog[action.payload.requestID] = { result: 'ok' };

  const program = newState.programs.data[action.payload.cpID];
  program.rootTeamId = action.payload.rootTeamId;

  return newState;
}

function editProgramInState(state, action) {
  const newState = copyState(state);

  newState.actionlog[action.payload.requestID] = { result: 'ok' };

  const program = newState.programs.data[action.payload.cpID];
  program.name = action.payload.name;
  if (!!action.payload.mission) {
    program.mission = action.payload.mission;
  }

  return newState;
}

function disableProgramInState(state, action) {
  const newState = copyState(state);

  newState.actionlog[action.payload.requestID] = { result: 'ok' };

  const program = newState.programs.data[action.payload.cpID];
  program.status = 'DISABLED';

  return newState;
}

function enableProgramInState(state, action) {
  const newState = copyState(state);

  newState.actionlog[action.payload.requestID] = { result: 'ok' };

  const program = newState.programs.data[action.payload.cpID];
  program.status = 'ENABLED';

  return newState;
}

function addCreatedProgramToState(state, action) {
  const newState = copyState(state);
  newState.actionlog[action.payload.requestID] = {
    result: 'ok',
    createdId: action.payload.cpID,
  };
  const program = action.payload;
  program.status = 'ENABLED';
  if (program.type === 'strategy') {
    newState.strategy = program.cpID;
  } else if (program.type === 'engagement') {
    newState.engagement = program.cpID;
  }
  newState.programs.data[program.cpID] = program;
  return newState;
}

function addFetchingUsersToState(state, action) {
  const newState = copyState(state);
  const { cpID } = action.payload;
  newState.programusers[cpID] = fetching(newState.programusers[cpID]);
  return newState;
}

function addReceivedUsersToState(state, action) {
  const newState = copyState(state);
  const { cpID, subs } = action.payload;
  newState.programusers[cpID] = enrich({
    fetchStatus: constants.OK,
    data: subs,
    lastFetched: Date.now(),
    maxAge: 1000 * 600,
  });
  return newState;
}

function addFailedUsersToState(state, action) {
  const newState = copyState(state);
  const { cpID } = action.payload;
  newState.programusers[cpID] = apiError({ ...newState.programusers[cpID] });
  return newState;
}

function addApiErrorToState(state, action) {
  const newState = copyState(state);
  newState.actionlog[action.payload.requestID] = { result: 'error' };
  return newState;
}

// eslint-disable-next-line default-param-last
export default (state = JSON.parse(JSON.stringify(initialState)), action) => {
  state = validatePersistedState(state, initialState);
  switch (action.type) {
    case types.RECEIVED_PROGRAMS:
      return addProgramsToState(state, action);
    case types.FAILED_PROGRAMS:
      return addFailedProgramsToState(state, action);
    case types.PROGRAM_EDITED:
      return editProgramInState(state, action);
    case types.PROGRAM_DISABLED:
      return disableProgramInState(state, action);

    case types.PROGRAM_ENABLED:
      return enableProgramInState(state, action);
    case types.PROGRAM_ROOT_EDITED:
      return addEditedProgramRootToState(state, action);

    case types.PROGRAM_CREATED:
      return addCreatedProgramToState(state, action);

    case types.FAILED_PROGRAM_USERS:
      return addFailedUsersToState(state, action);
    case types.RECEIVED_PROGRAM_USERS:
      return addReceivedUsersToState(state, action);
    case types.GET_PROGRAM_USERS:
      return addFetchingUsersToState(state, action);

    case types.ERROR_RECEIVED_FROM_API:
      return addApiErrorToState(state, action);

    case PURGE:
      return JSON.parse(JSON.stringify(initialState));
    default:
      return state;
  }
};
