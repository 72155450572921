import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Form from 'Components/Library/Forms/';
import { insightsActions } from 'state/ducks/insights';
import {
  TPC_MAX_QUESTION_LENGTH,
  TPC_SRV_MAX_INPUT_LABEL_LENGTH,
  TPC_SRV_MAX_TTL,
  TPC_SRV_MIN_TTL,
  TPC_MAX_DESCRIPTION_LENGTH,
  TPC_MULTI_MAX_QUESTIONS,
  TPC_SLIDERS_MAX_QUESTIONS,
  TPC_MAX_SLIDER_QUESTION_LENGTH,
  TPC_MAX_SLIDER_LABEL_LENGTH,
} from 'config/constants';

const schema = {
  type: 'object',
  properties: {
    topicText: { type: 'string', minLength: 1, maxLength: TPC_MAX_QUESTION_LENGTH },
    topicType: { type: 'string' },
    recurrent: { type: 'number' },
    ttl: { type: 'number', minimum: TPC_SRV_MIN_TTL, maximum: TPC_SRV_MAX_TTL },
    opentextfieldlabel: {
      type: 'string',
      maxLength: TPC_SRV_MAX_INPUT_LABEL_LENGTH,
    },
    description: { type: 'string', maxLength: TPC_MAX_DESCRIPTION_LENGTH },
    xquestion: { type: 'string', minLength: 1, maxLength: 256 },
    xmax: { type: 'string', minLength: 1, maxLength: 12 },
    xmin: { type: 'string', minLength: 1, maxLength: 12 },
    yquestion: { type: 'string', minLength: 1, maxLength: 256 },
    ymax: { type: 'string', minLength: 1, maxLength: 12 },
    ymin: { type: 'string', minLength: 1, maxLength: 12 },
    xminymax: { type: 'string', minLength: 1, maxLength: 24 },
    xminymin: { type: 'string', minLength: 1, maxLength: 24 },
    xmaxymax: { type: 'string', minLength: 1, maxLength: 24 },
    xmaxymin: { type: 'string', minLength: 1, maxLength: 24 },
    choiceOptions: {
      type: 'array',
      maxItems: TPC_MULTI_MAX_QUESTIONS,
      minItems: 2,
      items: { type: 'string', minLength: 1 },
    },
    sliders: {
      type: 'array',
      maxItems: TPC_SLIDERS_MAX_QUESTIONS,
      minItems: 1,
      items: {
        type: 'object',
        properties: {
          question: { type: 'string', minLength: 2, maxLength: TPC_MAX_SLIDER_QUESTION_LENGTH },
          minLabel: { type: 'string', minLength: 2, maxLength: TPC_MAX_SLIDER_LABEL_LENGTH },
          maxLabel: { type: 'string', minLength: 2, maxLength: TPC_MAX_SLIDER_LABEL_LENGTH },
          type: { type: 'string' },
          value: { type: 'number' },
        },
      },
    },
  },
  required: ['topicText', 'topicType', 'ttl', 'opentextfieldlabel'],
  additionalProperties: true,
};

const preValidationTransform = values => {
  // Filter out the values not used by the currently selected form type
  // This could be done with the schema definition, but the schema would
  // get very complex
  const allowedKeys = [
    'topicType',
    'topicText',
    'description',
    'ttl',
    'opentextfieldlabel',
    'requestID',
    'audienceID',
    'audienceType',
    'recurrent',
  ];
  switch (values.topicType) {
    case 'quad':
      allowedKeys.push(
        'xmax',
        'xmin',
        'xquestion',
        'ymax',
        'ymin',
        'yquestion',
        'xminymax',
        'xminymin',
        'xmaxymax',
        'xmaxymin',
      );
      break;
    case 'multiplechoice':
      allowedKeys.push('choiceOptions');
      break;
    case 'sliders':
      allowedKeys.push('sliders');
      break;
    default:
      break;
  }
  const filteredValues = Object.keys(values)
    .filter(key => allowedKeys.includes(key))
    .reduce((obj, key) => {
      obj[key] = values[key];
      return obj;
    }, {});
  return filteredValues;
};

const actionCreator = params => insightsActions.createTopic(preValidationTransform(params));

class TopicForm extends Component {
  render() {
    const { audienceType, audienceID, t, children } = this.props;

    return (
      <Form
        name="add-topic-form"
        schema={schema}
        preValidationTransform={preValidationTransform}
        additionalProperties={{
          audienceID,
          audienceType,
          recurrent: 0,
        }}
        initialValues={{
          topicType: 'opentext',
          ttl: 7,
          topicText: '',
          opentextfieldlabel: t('insights.customOpenTextFieldLabel'),
          choiceOptions: ['', ''],
          xquestion: t('insights.customxquestion'),
          xmin: t('insights.customxmin'),
          xmax: t('insights.customxmax'),
          yquestion: t('insights.customyquestion'),
          ymin: t('insights.customymin'),
          ymax: t('insights.customymax'),
          xminymax: t('insights.customlabelxminymax'),
          xminymin: t('insights.customlabelxminymin'),
          xmaxymax: t('insights.customlabelxmaxymax'),
          xmaxymin: t('insights.customlabelxmaxymin'),
          sliders: [
            {
              question: '',
              minLabel: '',
              maxLabel: '',
              type: 'basic',
            },
          ],
        }}
        stateSlice="main.insights"
        formIdentifier={`${audienceType}_${audienceID}`}
        submitActionCreator={actionCreator}
        onSubmitSuccess={() => this.props.onClose()}
      >
        {children}
      </Form>
    );
  }
}

TopicForm.propTypes = {
  audienceType: PropTypes.string,
  audienceID: PropTypes.string,
  onClose: PropTypes.func,
  t: PropTypes.func,
};

export default withTranslation()(TopicForm);
