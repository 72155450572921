import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const fabContainerStyles = {
  position: { sm: 'absolute', xs: 'fixed' },
  right: { sm: 32, xs: 24 },
  bottom: { sm: -20, xs: 24 },
  zIndex: { sm: 'auto', xs: 100 },
};

function PageTitleBar(props) {
  const { titleIcon, titleStr, actions, fab, tabs, context, name } = props;
  return (
    <Box
      sx={{
        borderBottom: tabs ? 1 : 0,
        borderColor: 'divider',
        position: 'relative',
        flexGrow: 0,
        flexShrink: 0,
        boxSizing: 'border-box',
      }}
      name={name}
    >
      <Box
        sx={{
          pl: 2,
          borderBottom: 1,
          borderColor: 'divider',
          minHeight: 40,
          height: { xs: 'auto', sm: 40 },
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: { xs: 'wrap', sm: 'nowrap' },
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            flexBasis: { xs: 50, sm: 'auto' },
            flexGrow: 3,
            flexShrink: 3,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            '& .MuiSvgIcon-root': {
              fontSize: '20px',
            },
            overflow: { xs: 'hidden', sm: 'auto' },
            whiteSpace: { xs: 'nowrap', sm: 'normal' },
          }}
        >
          {titleIcon && (
            <Box sx={{ mr: 1, display: 'flex', alignItems: 'center' }}>{titleIcon}</Box>
          )}
          {titleStr && (
            <Typography
              variant="subtitle1"
              name="header-information-name"
              sx={{
                height: '40px',
                lineHeight: '40px',
                textOverflow: { xs: 'ellipsis', sm: 'normal' },
                overflow: { xs: 'hidden', sm: 'auto' },
              }}
            >
              {titleStr}
            </Typography>
          )}
        </Box>
        {context && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              order: { xs: 20, sm: 0 },
              width: { xs: '100%', sm: 'auto' },
            }}
          >
            {context}
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {actions}
        </Box>
      </Box>
      <Box sx={fabContainerStyles}>{fab}</Box>
      {tabs && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: 36,
            boxSizing: 'border-box',
            pl: 2,
          }}
        >
          {tabs}
        </Box>
      )}
    </Box>
  );
}

PageTitleBar.propTypes = {
  titleIcon: PropTypes.node,
  titleStr: PropTypes.string,
  context: PropTypes.node,
  actions: PropTypes.node,
  fab: PropTypes.node,
  tabs: PropTypes.node,
  name: PropTypes.string,
};

export default PageTitleBar;
