import React from 'react';
import PropTypes from 'prop-types';
import ObjectivesTableOverviewHeader from 'Components/Features/Objectives/ObjectivesTableOverviewHeader';
import DomainObjectivesFetchWrapper from 'Components/Features/Objectives/DomainObjectivesFetchWrapper';
import PeriodNavigator from 'Components/Features/Objectives/PeriodNavigator';

function ObjectivesWidgetExpanded({
  teamId,
  appliedPeriod,
  selectPeriod,
  prevShortTermPeriod,
  nextShortTermPeriod,
}) {
  const domain = { t: 'team', d: teamId };
  return (
    <div>
      <PeriodNavigator
        selectedPeriod={appliedPeriod}
        handlePeriodChange={p => selectPeriod(p.stPeriodId)}
        prevPeriod={prevShortTermPeriod}
        nextPeriod={nextShortTermPeriod}
      />
      <DomainObjectivesFetchWrapper
        domain={domain}
        forceFetchOnMount={false}
        period={appliedPeriod}
        render={(objectives, hash) => (
          <ObjectivesTableOverviewHeader
            open
            period={appliedPeriod}
            domain={domain}
            objectiveIDs={objectives}
            hash={hash}
            showControls={false}
            showHeader={false}
            alwaysOpen
          />
        )}
      />
    </div>
  );
}

ObjectivesWidgetExpanded.propTypes = {
  teamId: PropTypes.string,
  appliedPeriod: PropTypes.string,
  selectPeriod: PropTypes.func,
  prevShortTermPeriod: PropTypes.string,
  nextShortTermPeriod: PropTypes.string,
};

export default ObjectivesWidgetExpanded;
