import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import MenuItem from '@mui/material/MenuItem';

import StandardContentDialog from 'Components/Library/StandardContentDialog';
import TaskItemDialogContent from 'Components/Library/TaskItemDialogContent';
import DataObjectChip from 'Components/Library/DataObjectChip';
import ActivityLog from 'Components/Library/ActivityLog';
import DialogHeadline from 'Components/Library/DialogHeadline/index';
import ConfirmDeleteDialog from 'Components/Library/ConfirmDeleteDialog/index';
import NotFound from 'Components/Common/NotFoundPanelContent';

import InterlockDetails from 'Components/Features/Interlocks/DialogViewInterlock/InterlockDetails/index';
import { editSchema } from 'Components/Features/Commitments/schema';

import { activitylogActions } from 'state/ducks/activitylog';
import { interlocksActions } from 'state/ducks/interlocks/index';
import * as constants from 'state/constants/api';
import { NODE_TYPE_INTERLOCK } from 'config/constants';
import InterlockIcon from 'Components/Features/Interlocks/InterlockIcon';
import NodeRelations from 'Components/Library/NodeRelations/new';
import RelationshipIcon from 'Components/Library/NodeRelations/RelationshipIcon';
import { graphActions } from 'state/ducks/graph/index';
import { isEnabled } from 'config/flags';

function DialogViewInterlock(props) {
  const { t } = useTranslation();
  const {
    onClose,
    open,
    zIndexModifier = 0,
    notFound,
    interlockID,
    interlockData,
    canDelete,
    canEdit,
  } = props;
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const menuItems = [
    <MenuItem id="interlock-actions-menu-delete-option" onClick={() => setIsDeleteDialogOpen(true)}>
      {t('general.delete')}
    </MenuItem>,
  ];

  const isInterlockDeleted = interlockData.fetchStatus === constants.DELETED;

  useEffect(() => {
    if (isInterlockDeleted) {
      onClose();
    }
  }, [isInterlockDeleted]);

  const dispatch = useDispatch();

  const changeSubmitted = () => {
    dispatch(activitylogActions.fetchNodeActivitylogDebounced({ id: interlockID, force: true }));
  };

  return (
    <>
      <StandardContentDialog
        size="large"
        open={open}
        onBackdropClick={onClose}
        onCloseClick={onClose}
        menuButtonProps={{
          id: 'interlock-actions-menu-toggle',
        }}
        closeButtonProps={{
          id: 'panel-view-interlock-close-btn',
        }}
        menuItems={canDelete ? menuItems : null}
        title={
          <DataObjectChip
            Icon={InterlockIcon}
            text={t('interlocks.pageTitleSingle')}
            contextIconProps={{
              sx: {
                color: theme => theme.palette.featureInterlocks.main,
              },
            }}
          />
        }
        zIndexModifier={zIndexModifier}
      >
        {!!notFound ? (
          <NotFound />
        ) : (
          <TaskItemDialogContent
            loading={!interlockData.ok}
            headlineComponent={
              <DialogHeadline
                dataObject={interlockData}
                name="interlocks"
                editSchema={editSchema}
                additionalProperties={{ id: interlockID }}
                submitActionCreator={interlocksActions.editInterlock}
                canEdit={canEdit}
                onSubmitSuccess={changeSubmitted}
              />
            }
            detailsComponent={
              <InterlockDetails
                interlockData={interlockData}
                interlockID={interlockID}
                submitActionCreator={interlocksActions.editInterlock}
                onSubmitSuccess={changeSubmitted}
                canEdit={canEdit}
              />
            }
            logComponent={
              <ActivityLog nodeId={interlockData.data?.id} nodeType={NODE_TYPE_INTERLOCK} />
            }
            relationsComponent={
              isEnabled('RELATED-ITEMS') ? (
                <NodeRelations
                  label={
                    <DataObjectChip
                      Icon={RelationshipIcon}
                      text={t('relations.relationships')}
                      color="transparent"
                    />
                  }
                  graphId={interlockID}
                  onSelect={data => {
                    dispatch(
                      graphActions.editRelations({
                        changeset: [
                          {
                            action: 'ADD',
                            from: interlockID,
                            to: data.node_id,
                            type: 'GENREL',
                          },
                        ],
                      }),
                    );
                  }}
                  canEdit={canEdit}
                />
              ) : null
            }
          />
        )}
      </StandardContentDialog>
      {!!isDeleteDialogOpen && !!interlockData.data && (
        <ConfirmDeleteDialog
          onCancel={() => setIsDeleteDialogOpen(false)}
          name={interlockData.data.name}
          actionProperties={{ id: interlockData.data.id }}
          instanceName="interlocks"
          submitActionCreator={interlocksActions.deleteInterlock}
        />
      )}
    </>
  );
}

DialogViewInterlock.propTypes = {
  onClose: PropTypes.func,
  interlockData: PropTypes.object,
  interlockID: PropTypes.string,
  open: PropTypes.bool,
  notFound: PropTypes.bool,
  zIndexModifier: PropTypes.number,
  canDelete: PropTypes.bool,
  canEdit: PropTypes.bool,
};

export default DialogViewInterlock;
