import { PURGE } from 'redux-persist';
import { validatePersistedState, enrich, copyState, fetching, apiError } from 'state/helpers';
import * as constants from '../../constants/api';
import * as types from './types';

export const initialState = {
  VERSION: 1.03,
  integrations: null,
  actionlog: {},
};

function addFetchingIntegrationsToState(state) {
  const newState = copyState(state);
  newState.integrations = fetching(newState.integrations);

  return newState;
}

function addFetchedIntegrationsToState(state, payload) {
  const newState = copyState(state);
  newState.integrations = enrich({
    data: payload,
    lastFetched: Date.now(),
    fetchStatus: constants.OK,
    ok: true,
  });
  return newState;
}

function addFailedIntegrationsToState(state) {
  const newState = copyState(state);
  newState.integrations = apiError(newState.integrations);
  return newState;
}

function addApiErrorToStateIntegrations(state, payload) {
  const newState = copyState(state);
  newState.integrations[payload.requestID] = { result: 'error' };
  if (!!payload.error) {
    newState.integrations[payload.requestID].message = payload.error;
  }
  return newState;
}

// eslint-disable-next-line default-param-last
export default (state = JSON.parse(JSON.stringify(initialState)), action) => {
  state = validatePersistedState(state, initialState);
  switch (action.type) {
    case types.GET_INTEGRATIONS:
      return addFetchingIntegrationsToState(state);
    case types.INTEGRATION_FETCH_SUCCESS:
      return addFetchedIntegrationsToState(state, action.payload);
    case types.FAILED_INTEGRATIONS:
      return addFailedIntegrationsToState(state);
    case PURGE:
      return JSON.parse(JSON.stringify(initialState));
    case types.ERROR_RECEIVED_FROM_API_INTEGRATIONS:
      return addApiErrorToStateIntegrations(state, action.payload);
    default:
      return state;
  }
};
