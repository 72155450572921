import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Form from 'Components/Library/Forms/';
import FormTextField from 'Components/Library/Forms/Elements/FormTextField';
import LinearIndicator from 'Components/Library/Forms/Elements/LinearIndicator';
import InlineEditInput from 'Components/Library/InlineEditInput';
import { objectivesActions } from 'state/ducks/objectives';
import { OBJ_DESCRIPTION_MAX_LENGTH, OBJ_NAME_MAX_LENGTH } from 'config/constants';

const schema = {
  type: 'object',
  properties: {
    objective: { type: 'string', minLength: 1, maxLength: OBJ_NAME_MAX_LENGTH },
    description: { type: 'string', maxLength: OBJ_DESCRIPTION_MAX_LENGTH },
  },
  required: ['objective'],
  additionalProperties: true,
};

function ObjectiveHeadline(props) {
  const { objectiveID, objectiveData, canEdit } = props;
  const { t } = useTranslation();
  return (
    <Form
      name="panel-view-objective-headline"
      debouncedAutoSubmit={950}
      submitActionCreator={objectivesActions.updateObjective}
      stateSlice="main.objectives"
      allowRefreshData
      refreshedDataSafeDeltaSeconds={10}
      initialValues={
        !!objectiveData &&
        objectiveData.ok && {
          objective: objectiveData.data.objective,
          description: objectiveData.data.description,
        }
      }
      additionalProperties={{
        objectiveID,
      }}
      schema={schema}
    >
      <LinearIndicator positioning="absolute" />
      <FormTextField
        fieldName="objective"
        render={fieldProps => (
          <InlineEditInput
            id="objectiveNameField"
            fullWidth
            multiline
            variant="h4"
            disabled={!canEdit}
            label={t('objectives.viewObjectiveNameInputPlaceholder')}
            {...fieldProps}
          />
        )}
      />
      <br />

      <FormTextField
        fieldName="description"
        render={fieldProps => (
          <InlineEditInput
            id="objectiveDescriptionField"
            fullWidth
            multiline
            variant="body1"
            disabled={!canEdit}
            label={canEdit ? t('objectives.createNewObjectiveDescriptionInputLabel') : null}
            {...fieldProps}
          />
        )}
      />
    </Form>
  );
}

ObjectiveHeadline.propTypes = {
  objectiveData: PropTypes.object,
  objectiveID: PropTypes.string,
  canEdit: PropTypes.bool,
};

export default ObjectiveHeadline;
