import { PURGE } from 'redux-persist';
import { copyState, validatePersistedState, enrich, apiError, fetching } from 'state/helpers';
import * as types from './types';
import * as constants from '../../constants/api';

export const initialState = {
  VERSION: 1.12,
  actionlog: {},
  insightsPerProgram: {},
  surveysPerAudience: { TEAM: {}, PRG: {} },
  surveysPerTopic: {},
  legacySurveyIds: {},
  surveydata: {},
  topicdata: {},
  insightdata: {},
  surveyStats: {},
  csi: {},
  csidata: {},
  understandingstats: {},
};

function addCreatedInterpretationTopicToState(state, payload) {
  const newState = copyState(state);
  const topic = payload;
  newState.csidata[payload.csiID] = enrich({ ...topic, fetchStatus: constants.OK });
  if (newState.csi[payload.cpID] && newState.csi[payload.cpID].ok) {
    newState.csi[payload.cpID].data.push(payload.csiID);
  }
  newState.actionlog[payload.requestID] = { result: 'ok' };
  return newState;
}

function interpretationTopicsFetched(state, payload) {
  const newState = copyState(state);

  const ids = [];
  for (const csi of payload.topics) {
    ids.push(csi.csiID);
    newState.csidata[csi.csiID] = enrich({ ...csi, fetchStatus: constants.OK });
  }
  newState.csi[payload.cpID] = enrich({
    data: ids,
    fetchStatus: constants.OK,
    lastFetched: Date.now(),
  });
  return newState;
}

function fetchingInterpretationTopics(state, payload) {
  const newState = copyState(state);
  newState.csi[payload.cpID] = fetching(newState.csi[payload.cpID]);
  return newState;
}

function interpretationTopicFetchFailed(state, payload) {
  const newState = copyState(state);
  newState.csi[payload.cpID] = apiError(newState.csi[payload.cpID]);
  return newState;
}

function interpretationStatsFetched(state, payload) {
  const newState = copyState(state);
  const { csiID } = payload;
  newState.understandingstats[csiID] = enrich({
    stats: payload.stats,
    fetchStatus: constants.OK,
    lastFetched: Date.now(),
  });
  return newState;
}

function fetchingInterpretationStats(state, payload) {
  const newState = copyState(state);
  const { csiID } = payload;
  newState.understandingstats[csiID] = fetching(newState.understandingstats[csiID]);
  return newState;
}

function interpretationStatsFetchFailed(state, payload) {
  const newState = copyState(state);
  const { csiID } = payload;
  newState.understandingstats[csiID] = apiError(newState.understandingstats[csiID]);
  return newState;
}

function surveysFetched(state, payload) {
  const newState = copyState(state);
  const surveyIDs = [];

  for (const survey of payload.surveys) {
    newState.surveydata[survey.surveyID] = enrich({
      data: survey,
      fetchStatus: constants.OK,
      lastFetched: Date.now(),
    });
    surveyIDs.push(survey.surveyID);

    if (
      !newState.surveysPerTopic[survey.topicID] ||
      !newState.surveysPerTopic[survey.topicID].data
    ) {
      newState.surveysPerTopic[survey.topicID] = enrich({
        fetchStatus: constants.PARTIAL,
        data: [],
      });
    }
    if (!newState.surveysPerTopic[survey.topicID].data.includes(survey.surveyID)) {
      newState.surveysPerTopic[survey.topicID].data.push(survey.surveyID);
    }
  }

  newState.surveysPerAudience[payload.audienceType][payload.audienceID] = enrich({
    data: surveyIDs,
    fetchStatus: constants.OK,
    lastFetched: Date.now(),
  });
  return newState;
}

function surveysFetching(state, payload) {
  const newState = copyState(state);
  newState.surveysPerAudience[payload.audienceType][payload.audienceID] = fetching(
    newState.surveysPerAudience[payload.audienceType][payload.audienceID],
  );
  return newState;
}

function surveysFetchFailed(state, payload) {
  const newState = copyState(state);
  newState.surveysPerAudience[payload.audienceType][payload.audienceID] = apiError(
    newState.surveysPerAudience[payload.audienceType][payload.audienceID],
  );
  return newState;
}

function topicSurveysFetched(state, payload) {
  const newState = copyState(state);
  const surveyIDs = [];

  newState.surveysPerTopic[payload.topicID] = enrich({
    fetchStatus: constants.OK,
    data: [],
    lastFetched: Date.now(),
  });

  for (const survey of payload.surveys) {
    newState.surveydata[survey.surveyID] = enrich({
      data: survey,
      fetchStatus: constants.OK,
      lastFetched: Date.now(),
    });
    surveyIDs.push(survey.surveyID);

    if (!newState.surveysPerTopic[survey.topicID].data.includes(survey.surveyID)) {
      newState.surveysPerTopic[survey.topicID].data.push(survey.surveyID);
    }
  }

  newState.surveysPerTopic[payload.topicID].data.sort().reverse();

  return newState;
}

function topicSurveysFetching(state, payload) {
  const newState = copyState(state);
  newState.surveysPerTopic[payload.topicID] = fetching(newState.surveysPerTopic[payload.topicID]);
  return newState;
}

function topicSurveysFetchFailed(state, payload) {
  const newState = copyState(state);
  newState.surveysPerTopic[payload.topicID] = apiError(newState.surveysPerTopic[payload.topicID]);
  return newState;
}

function singleSurveyFetched(state, payload) {
  const newState = copyState(state);
  const { survey } = payload;

  if (survey) {
    newState.surveydata[survey.surveyID] = enrich({
      fetchStatus: constants.OK,
      data: payload.survey,
      lastFetched: Date.now(),
    });
    /* Support for legacy surveyIDs, where the sent surveyID might not be the same as the received */
    if (payload.surveyID && payload.surveyID !== survey.surveyID) {
      newState.legacySurveyIds[payload.surveyID] = survey.surveyID;
      if (!!newState.surveydata[payload.surveyID]) {
        delete newState.surveydata[payload.surveyID];
      }
    }

    if (!newState.surveysPerTopic[survey.topicID]) {
      newState.surveysPerTopic[survey.topicID] = enrich({
        fetchStatus: constants.PARTIAL,
        data: [],
      });
    }
    if (!survey.programID) {
      const parts = survey.surveyID.split('_').slice(1, -2);
      survey.programID = parts.join('_');
    }
    if (
      !!newState.surveysPerTopic[survey.topicID] &&
      !!newState.surveysPerTopic[survey.topicID].ok &&
      !newState.surveysPerTopic[survey.topicID].data.includes(survey.surveyID)
    ) {
      newState.surveysPerTopic[survey.topicID].data.push(survey.surveyID);
      newState.surveysPerTopic[survey.topicID].data.sort().reverse();
    }
    if (
      !!newState.surveysPerAudience[survey.audienceType][survey.audienceID] &&
      !!newState.surveysPerAudience[survey.audienceType][survey.audienceID].ok &&
      !newState.surveysPerAudience[survey.audienceType][survey.audienceID].data.includes(
        survey.surveyID,
      )
    ) {
      newState.surveysPerAudience[survey.audienceType][survey.audienceID].data.push(
        survey.surveyID,
      );
      newState.surveysPerAudience[survey.audienceType][survey.audienceID].data.sort().reverse();
    }
  }

  return newState;
}

function singleSurveyFetching(state, payload) {
  const newState = copyState(state);
  newState.surveydata[payload.surveyID] = fetching(newState.surveydata[payload.surveyID]);
  return newState;
}

function singleSurveyFetchFailed(state, payload) {
  const newState = copyState(state);
  if (payload) {
    newState.surveydata[payload.surveyID] = apiError(newState.surveydata[payload.surveyID]);
  }
  return newState;
}

function topicFetched(state, payload) {
  const newState = copyState(state);
  if (payload.topic) {
    newState.topicdata[payload.topic.topicID] = enrich({
      fetchStatus: constants.OK,
      data: payload.topic,
      lastFetched: Date.now(),
    });
  }
  return newState;
}

function fetchingTopic(state, payload) {
  const newState = copyState(state);
  newState.topicdata[payload.topicID] = fetching(newState.topicdata[payload.topicID]);
  return newState;
}

function topicFetchFailed(state, payload) {
  const newState = copyState(state);
  newState.topicdata[payload.topicID] = apiError(newState.topicdata[payload.topicID]);
  return newState;
}

function topicNotFound(state, payload) {
  const newState = copyState(state);
  newState.topicdata[payload.topicID] = enrich({
    fetchStatus: constants.DOES_NOT_EXIST,
  });
  return newState;
}

function surveyStatsFetching(state, payload) {
  const newState = copyState(state);
  newState.surveyStats[payload.surveyID] = fetching(newState.surveyStats[payload.surveyID]);
  return newState;
}

function surveyStatsFetchFailed(state, payload) {
  const newState = copyState(state);
  newState.surveyStats[payload.surveyID] = apiError(newState.surveyStats[payload.surveyID]);
  return newState;
}

function surveyStatsFetched(state, payload) {
  const newState = copyState(state);
  newState.surveyStats[payload.surveyID] = enrich({
    data: payload,
    fetchStatus: constants.OK,
    lastFetched: Date.now(),
  });
  return newState;
}

function repliedToSurvey(state, payload) {
  const newState = copyState(state);
  const { surveyID } = payload;
  if (newState.surveydata[surveyID] && newState.surveydata[surveyID].ok) {
    newState.surveydata[surveyID].data.surveyReply = payload;
  }
  newState.actionlog[payload.requestID] = { result: 'ok' };
  return newState;
}

function repliedToInterpretation(state, payload) {
  const newState = copyState(state);
  const { csiID } = payload;
  if (newState.understandingstats[csiID] && newState.understandingstats[csiID].ok) {
    newState.understandingstats[csiID].stats[payload.teamID] = payload;
  }
  newState.actionlog[payload.requestID] = { result: 'ok' };
  return newState;
}

function addCreatedTopicToState(state, payload) {
  let newState = copyState(state);
  const topic = payload;
  newState.topicdata[topic.topicID] = enrich({
    fetchStatus: constants.OK,
    data: topic,
    lastFetched: Date.now(),
  });
  // Add the survey to state
  newState = singleSurveyFetched(newState, topic);
  newState.actionlog[payload.requestID] = { result: 'ok' };
  return newState;
}

function addCreatedSurveyToState(state, payload) {
  let newState = copyState(state);
  newState = singleSurveyFetched(newState, payload);

  // Refetch the topic for now to ensure it gets updated to a pulse in the state
  if (
    !!newState.topicdata[payload.survey.topicID] &&
    !!newState.topicdata[payload.survey.topicID].ok
  ) {
    newState.topicdata[payload.survey.topicID] = enrich({
      fetchStatus: constants.PARTIAL,
      data: newState.topicdata[payload.survey.topicID].data,
    });
  }
  newState.actionlog[payload.requestID] = { result: 'ok' };
  return newState;
}

function deleteUnderstandingFromState(state, payload) {
  const newState = copyState(state);
  const { csiID } = payload;
  const parts = csiID.split('_');
  const programID = parts[1];

  if (programID in state.csi) {
    state.csi[programID].data = state.csi[programID].data.filter(id => id !== csiID);
  }
  delete newState.understandingstats[csiID];
  newState.csidata[csiID] = enrich({ fetchStatus: constants.DELETED });

  newState.actionlog[payload.requestID] = { result: 'ok' };

  return newState;
}

function deleteTopicFromState(state, payload) {
  const newState = copyState(state);
  const { topicID } = payload;

  newState.topicdata[topicID] = enrich({ fetchStatus: constants.DELETED });

  // TODO: fix this, we're currently invalidating all the different audiences..
  // Instead check if the data includes the survey that was deleted
  for (const audienceID of Object.keys(newState.surveysPerAudience.PRG)) {
    newState.surveysPerAudience.PRG[audienceID] = enrich({ fetchStatus: constants.RETRY });
  }
  for (const audienceID of Object.keys(newState.surveysPerAudience.TEAM)) {
    newState.surveysPerAudience.TEAM[audienceID] = enrich({ fetchStatus: constants.RETRY });
  }

  newState.actionlog[payload.requestID] = { result: 'ok' };

  return newState;
}

function deleteSurveyFromState(state, payload) {
  const newState = copyState(state);
  const { surveyID } = payload;

  delete newState.surveyStats[surveyID];
  let topicID = null;
  if (newState.surveydata[surveyID] && newState.surveydata[surveyID].ok) {
    // eslint-disable-next-line prefer-destructuring
    topicID = newState.surveydata[surveyID].data.topicID;
    delete newState.surveydata[surveyID];
    if (newState.surveysPerTopic[topicID] && !!newState.surveysPerTopic[topicID].data) {
      newState.surveysPerTopic[topicID].data = newState.surveysPerTopic[topicID].data.filter(
        id => id !== surveyID,
      );
    }
  }

  for (const audienceID of Object.keys(newState.surveysPerAudience.PRG)) {
    if (
      !!newState.surveysPerAudience.PRG[audienceID].data &&
      newState.surveysPerAudience.PRG[audienceID].data[surveyID]
    ) {
      delete newState.surveysPerAudience.PRG[audienceID].data[surveyID];
    }
  }

  for (const audienceID of Object.keys(newState.surveysPerAudience.TEAM)) {
    if (
      !!newState.surveysPerAudience.TEAM[audienceID].data &&
      newState.surveysPerAudience.TEAM[audienceID].data[surveyID]
    ) {
      delete newState.surveysPerAudience.TEAM[audienceID].data[surveyID];
    }
  }

  newState.actionlog[payload.requestID] = { result: 'ok' };

  return newState;
}

function addApiErrorToState(state, payload) {
  const newState = copyState(state);
  newState.actionlog[payload.requestID] = { result: 'error' };
  if (!!payload.error) {
    newState.actionlog[payload.requestID].message = payload.error;
  }
  return newState;
}

// eslint-disable-next-line default-param-last
export default (state = JSON.parse(JSON.stringify(initialState)), action) => {
  state = validatePersistedState(state, initialState);
  switch (action.type) {
    /* topics */
    /* GET */

    case types.FETCHED_TOPIC:
      return topicFetched(state, action.payload);
    case types.GET_TOPIC:
      return fetchingTopic(state, action.payload);
    case types.FAILED_TO_GET_TOPIC:
      return topicFetchFailed(state, action.payload);
    case types.TOPIC_NOT_FOUND:
      return topicNotFound(state, action.payload);

    /* POST */
    case types.REPLIED_TO_SURVEY:
      return repliedToSurvey(state, action.payload);
    case types.CREATED_TOPIC:
      return addCreatedTopicToState(state, action.payload);
    case types.CREATED_SURVEY:
      return addCreatedSurveyToState(state, action.payload);
    case types.INSIGHT_DELETED:
      return deleteTopicFromState(state, action.payload);
    case types.SURVEY_DELETED:
      return deleteSurveyFromState(state, action.payload);
    /* surveys */
    case types.FETCHED_SURVEYS:
      return surveysFetched(state, action.payload);
    case types.GET_SURVEYS:
      return surveysFetching(state, action.payload);
    case types.FAILED_TO_GET_SURVEYS:
      return surveysFetchFailed(state, action.payload);

    // pulse
    case types.FETCHED_TOPIC_SURVEYS:
      return topicSurveysFetched(state, action.payload);
    case types.GET_TOPIC_SURVEYS:
      return topicSurveysFetching(state, action.payload);
    case types.FAILED_TO_GET_TOPIC_SURVEYS:
      return topicSurveysFetchFailed(state, action.payload);

    case types.FETCHED_SINGLE_SURVEY:
      return singleSurveyFetched(state, action.payload);
    case types.GET_SINGLE_SURVEY:
      return singleSurveyFetching(state, action.payload);
    case types.FAILED_TO_GET_SINGLE_SURVEY:
      return singleSurveyFetchFailed(state, action.payload);

    /* surveystats */
    /* GET */
    case types.GET_SURVEY_STATS:
      return surveyStatsFetching(state, action.payload);
    case types.FAILED_TO_GET_SURVEY_STATS:
      return surveyStatsFetchFailed(state, action.payload);
    case types.FETCHED_SURVEY_STATS:
      return surveyStatsFetched(state, action.payload);

    /* csi */
    /* GET */
    case types.FETCHED_INTERPRETATION_TOPICS:
      return interpretationTopicsFetched(state, action.payload);
    case types.GET_INTERPRETATION_TOPICS:
      return fetchingInterpretationTopics(state, action.payload);
    case types.FAILED_TO_GET_INTERPRETATION_TOPICS:
      return interpretationTopicFetchFailed(state, action.payload);
    case types.UNDERSTANDING_DELETED:
      return deleteUnderstandingFromState(state, action.payload);
    /* csi stats */
    /* GET */
    case types.FETCHED_INTERPRETATION_STATS:
      return interpretationStatsFetched(state, action.payload);
    case types.GET_INTERPRETATION_STATS:
      return fetchingInterpretationStats(state, action.payload);
    case types.FAILED_TO_GET_INTERPRETATION_STATS:
      return interpretationStatsFetchFailed(state, action.payload);

    /* POST */
    case types.CREATED_INTERPRETATION_TOPIC:
      return addCreatedInterpretationTopicToState(state, action.payload);
    case types.REPLIED_TO_INTERPRETATION:
      return repliedToInterpretation(state, action.payload);

    case types.INSIGHTS_API_ERROR:
      return addApiErrorToState(state, action.payload);
    case PURGE:
      return JSON.parse(JSON.stringify(initialState));
    default:
      return state;
  }
};
