// eslint-disable-next-line default-param-last
import { copyState, validatePersistedState, enrich, fetching, apiError } from '../../helpers';
import * as constants from '../../constants/api';
import * as types from './types';

export const initialState = {
  VERSION: 1.01,
  filters: {
    data: [],
  },
  actionlog: {},
  canFetch: true,
  error: false,
  loading: false,
  ok: false,
};

function createdTeamFilter(state, action) {
  const newState = copyState(state);
  const teamData = action.payload.filter;

  newState.actionlog[action.payload.requestID] = { result: 'ok' };
  if (teamData) {
    newState.filters = { ...newState.filters, data: [...newState.filters.data, teamData] };
  }
  return newState;
}

function removeTeamFilterFromState(state, action) {
  const newState = copyState(state);
  const elementToDeleteIndex = newState.filters.data.findIndex(
    teamFilter => action.payload.id === teamFilter.id,
  );
  newState.filters = { ...newState.filters };
  newState.filters.data.splice(elementToDeleteIndex, 1);

  newState.actionlog[action.payload.requestID] = { result: 'ok' };

  return newState;
}

function editTeamFilterInState(state, action) {
  const newState = copyState(state);

  newState.actionlog[action.payload.requestID] = { result: 'ok' };

  const teamFilter = newState.filters.data.find(filter => filter.id === action.payload.filter.id);
  teamFilter.name = action.payload.filter.name;
  teamFilter.domains = action.payload.filter.domains;

  return newState;
}

function addFetchingFiltersToState(state) {
  const newState = copyState(state);
  newState.filters = fetching(newState.filters);
  return newState;
}

function addFailedFiltersToState(state) {
  const newState = copyState(state);
  newState.filters = apiError(newState.filters);
  return newState;
}

function addFetchedFiltersToState(state, payload) {
  const newState = copyState(state);
  newState.filters = enrich({
    data: payload.filters,
    lastFetched: Date.now(),
    fetchStatus: constants.OK,
    ok: true,
    // Other users cannot edit my filters, do not need to refetch very often:
    maxAge: 1000 * 60 * 20,
  });
  return newState;
}

function addApiErrorToState(state, payload) {
  const newState = copyState(state);
  newState.filters[payload.requestID] = { result: 'error' };
  if (!!payload.error) {
    newState.filters[payload.requestID].message = payload.error;
  }
  return newState;
}

// eslint-disable-next-line default-param-last
const reducer = (state = JSON.parse(JSON.stringify(initialState)), action) => {
  state = validatePersistedState(state, initialState);
  switch (action.type) {
    case types.FETCHED_TEAM_FILTERS:
      return addFetchedFiltersToState(state, action.payload);
    case types.FAILED_TEAM_FILTERS:
      return addFailedFiltersToState(state, action.payload);
    case types.GET_TEAM_FILTERS:
      return addFetchingFiltersToState(state, action.payload);
    case types.CREATED_TEAM_FILTER:
      return createdTeamFilter(state, action);
    case types.TEAM_FILTER_DELETED:
      return removeTeamFilterFromState(state, action);
    case types.TEAM_FILTER_EDITED:
      return editTeamFilterInState(state, action);
    case types.ERROR_RECEIVED_FROM_API:
      return addApiErrorToState(state, action.payload);
    default:
      return state;
  }
};

export default reducer;
