import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import { interlocksSelectors, interlocksActions } from 'state/ducks/interlocks/index';
import InterlockIcon from 'Components/Features/Interlocks/InterlockIcon';
import UserChip from 'Components/Library/UserChip';
import DueDateChip from 'Components/Library/DueDateChip';
import TeamChip from 'Components/Library/TeamChip';
import { INTERLOCK_STATUS_COLORS, CONTRIBUTOR_TYPES } from 'config/constants';
import StatusSelector from 'Components/Library/StatusSelector';

const styles = theme => ({
  interlockRow: {
    backgroundColor: theme.palette.background.paper,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    // cannot apply border radius to a row, but we need
    // rounded corners for the hover effect:
    '& > td:first-of-type': {
      paddingLeft: theme.spacing(),
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
    },
    '& > td:last-of-type': {
      paddingLeft: theme.spacing(),
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
    },
    '&:hover td': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  interlockCell: {
    paddingLeft: 0,
  },
  cellDisplayFlex: {
    display: 'flex',
    paddingTop: '1rem',
  },
});

const handleRowClick = (graphId, navigate) => {
  navigate(`#modal/interlocks/view/${graphId}`, { replace: true });
};

const renderContributor = (contributor, hasSeveralContributors) => {
  let contributorEl = null;
  if (contributor.type === CONTRIBUTOR_TYPES.USER) {
    contributorEl = (
      <UserChip
        variant="normal"
        color="transparent"
        sub={contributor.id}
        key={`interlock-grid-contributor-user-${contributor.id}`}
        showIconOnly={hasSeveralContributors}
        allowNavigate={false}
      />
    );
  }
  if (contributor.type === CONTRIBUTOR_TYPES.TEAM) {
    contributorEl = (
      <TeamChip
        color="transparent"
        teamId={contributor.id}
        key={`interlock-grid-contributor-team-contributor-${contributor.id}`}
        showIconOnly={hasSeveralContributors}
      />
    );
  }

  return contributorEl;
};

function InterlockRow(props) {
  const navigate = useNavigate();
  const { classes, graphId, statusFilter } = props;
  const { t } = useTranslation();

  const dispatch = useDispatch();

  // TODO: this should be optimized... most likely the information should be cached
  // on the nodes
  useEffect(() => {
    dispatch(interlocksActions.fetchContributors({ id: graphId, force: false }));
  }, []);

  const interlock = useSelector(
    state => interlocksSelectors.selectInterlock(state.main.interlocks, graphId).data,
    // { stabilityCheck: 'never' },
  );

  const contributors = useSelector(
    state => interlocksSelectors.selectContributors(state.main.interlocks, graphId),
    // { stabilityCheck: 'never' },
  ).data;

  if (!interlock) {
    return null;
  }

  if (statusFilter && !statusFilter.includes(interlock.status)) {
    return null;
  }

  return (
    <TableRow
      className={classes.interlockRow}
      key={`interlock-grid-row-${interlock.id}`}
      onClick={() => handleRowClick(interlock.id, navigate)}
    >
      <TableCell className={classes.interlockCell}>
        <StatusSelector
          disabled
          options={Object.keys(INTERLOCK_STATUS_COLORS).map(status => ({
            text: t(`interlocks.statusValueText.${status}`),
            option: status,
            color: INTERLOCK_STATUS_COLORS[status],
          }))}
          color="transparent"
          value={interlock.status}
          name={`interlock-statechip-${interlock.id}`}
        />
      </TableCell>
      <TableCell className={classes.interlockCell}>
        <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center' }}>
          <InterlockIcon sx={{ height: '1rem' }} />
          {interlock.name}
        </Typography>
      </TableCell>
      <TableCell className={clsx(classes.cellDisplayFlex, classes.interlockCell)}>
        {!!contributors?.length &&
          contributors.map(contributor => renderContributor(contributor, contributors.length > 1))}
      </TableCell>
      <TableCell className={classes.interlockCell}>
        <DueDateChip
          name="grey-interlock-chip"
          dueDate={interlock.due_date}
          color="default"
          size="medium"
        />
      </TableCell>
    </TableRow>
  );
}

InterlockRow.propTypes = {
  classes: PropTypes.exact({
    interlockRow: PropTypes.string,
    interlockCell: PropTypes.string,
    cellDisplayFlex: PropTypes.string,
  }),
  graphId: PropTypes.string.isRequired,
  statusFilter: PropTypes.arrayOf(PropTypes.string),
};

export default withStyles(styles)(InterlockRow);
