import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ReflectionsIcon from '@mui/icons-material/Psychology';
import EmphasizedSection from 'Components/Library/EmphasizedSection';
import DataObjectChip from 'Components/Library/DataObjectChip';
import { withLocation, withNavigation } from 'withRouter';
import HelperText from 'Components/Common/HelperText';

import { openDialogObjectiveReflection } from 'config/ModalProvider/helpers';

const styles = theme => ({
  red: {
    backgroundColor: theme.palette.confidence.red,
  },
  amber: {
    backgroundColor: theme.palette.confidence.amber,
  },
  green: {
    backgroundColor: theme.palette.confidence.green,
  },
  track: {
    height: 3,
    border: 0,
  },
  thumb: {}, // this needs to exist
  disabled: {
    '& $thumb': {
      height: 12,
      width: 12,
      marginTop: -1,
    },
  },
  mark: {
    height: 11,
    width: 1,
  },
  cardContent: {},
  reflectionHelperText: {
    marginBottom: theme.spacing(2),
  },
  sliderCaptions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
    '& > span': {
      flexGrow: 0,
      flexShrink: 0,
      maxWidth: '28%',
      color: theme.palette.primary[500],
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.625rem',
      },
      '&:first-of-type': {
        textAlign: 'left',
      },
      '&:last-child': {
        textAlign: 'right',
        color: theme.palette.primary.main,
      },
    },
  },
});

class ObjectiveReflections extends Component {
  openDialog = () => {
    openDialogObjectiveReflection(this.props.objectiveID, this.props.navigate, this.props.location);
  };

  render() {
    const { classes, objectiveData, canEdit, selectFullName, t } = this.props;
    if (!objectiveData || !objectiveData.ok) {
      return null;
    }
    const { grade, reflection } = objectiveData.data;

    const isGraded = typeof grade === 'number';

    let barColor = 'green';
    if (grade <= 60) {
      barColor = 'amber';
    }
    if (grade <= 40) {
      barColor = 'red';
    }

    return (
      <EmphasizedSection
        sx={{
          mt: 2,
          p: 2,
          position: 'relative',
          '& > div:first-of-type': {
            mt: -1,
            ml: -1,
            mb: 2,
          },
        }}
      >
        <DataObjectChip
          Icon={ReflectionsIcon}
          text={t('objectives.reflectionsSectionTitle')}
          color="transparent"
        />
        <div className={classes.cardContent}>
          {isGraded && (
            <Box sx={{ p: 1 }}>
              <Slider
                name="objective-reflection-slider"
                value={grade}
                marks
                step={10}
                disabled
                classes={{
                  disabled: classes.disabled,
                  mark: classes.mark,
                  markActive: classes[barColor],
                  track: clsx([classes.track, classes[barColor]]),
                  rail: classes.track,
                  thumb: clsx([classes.thumb, classes[barColor]]),
                }}
              />
              <div className={classes.sliderCaptions}>
                <Typography variant="caption">{t('objectives.reflectstatusred')}</Typography>
                <Typography variant="caption">{t('objectives.reflectstatusamber')}</Typography>
                <Typography variant="caption">{t('objectives.reflectstatusgreen')}</Typography>
              </div>
              {reflection && (
                <>
                  <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                    {t('objectives.objectivereflectionsubtitle')}
                  </Typography>
                  <Typography data-tg-name="objective-reflection-text" variant="body2">
                    {reflection}
                  </Typography>
                </>
              )}
            </Box>
          )}
          {!isGraded &&
            (canEdit ? (
              <HelperText
                data-tg-name="objective-reflection-cta"
                className={classes.reflectionHelperText}
              >
                {t('objectives.objectivereflectioncta')}
              </HelperText>
            ) : (
              <HelperText
                data-tg-name="objective-reflection-cta"
                className={classes.reflectionHelperText}
              >
                {t('objectives.objectivereflectionnotdone', {
                  fullname: selectFullName(objectiveData.data.owner),
                })}
              </HelperText>
            ))}
          {canEdit &&
            (isGraded ? (
              <IconButton
                name="objective-reflection-edit-button"
                onClick={this.openDialog}
                size="small"
                sx={{ position: 'absolute', top: 12, right: 12 }}
                aria-label={t('general.edit')}
              >
                <EditIcon />
              </IconButton>
            ) : (
              <Button
                name="objective-reflection-edit-button"
                color="secondary"
                onClick={this.openDialog}
                sx={{ mb: -1, ml: -1 }}
              >
                {`+ ${t('objectives.objectivereflectionaddbuttonlabel')}`}
              </Button>
            ))}
        </div>
      </EmphasizedSection>
    );
  }
}

ObjectiveReflections.propTypes = {
  classes: PropTypes.exact({
    root: PropTypes.string,
    red: PropTypes.string,
    amber: PropTypes.string,
    green: PropTypes.string,
    track: PropTypes.string,
    disabled: PropTypes.string,
    mark: PropTypes.string,
    cardContent: PropTypes.string,
    reflectionHelperText: PropTypes.string,
    sliderCaptions: PropTypes.string,
    thumb: PropTypes.string,
  }),
  location: PropTypes.object,
  navigate: PropTypes.func,
  objectiveID: PropTypes.string,
  objectiveData: PropTypes.object,
  canEdit: PropTypes.bool,
  selectFullName: PropTypes.func,
  t: PropTypes.func,
};

export default withLocation(
  withNavigation(withTranslation()(withStyles(styles)(ObjectiveReflections))),
);
