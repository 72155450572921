import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import RelationsIcon from '@mui/icons-material/SettingsEthernet';
import { withLocation, withNavigation } from 'withRouter';

import DataObjectChip from 'Components/Library/DataObjectChip';
import EmphasizedSection from 'Components/Library/EmphasizedSection';
import FormCustomEditable from 'Components/Library/Forms/Elements/FormCustomEditable';
import LinearIndicator from 'Components/Library/Forms/Elements/LinearIndicator';
import PanelParentSelector from 'Components/Features/Objectives/PanelParentSelector';

import ObjectiveChip from 'Components/Features/Objectives/ObjectiveChip';
import { openViewObjectivePanel } from 'config/ModalProvider/helpers';

class ObjectiveRelations extends Component {
  state = {
    panelOpen: false,
    contextMenuOpen: null,
  };

  closePanel = () => {
    this.setState({ panelOpen: false });
  };

  openPanel = () => {
    this.setState({ panelOpen: true, contextMenuOpen: null });
  };

  openContextMenu = e => {
    this.setState({ contextMenuOpen: e.currentTarget });
  };

  closeContextMenu = () => {
    this.setState({ contextMenuOpen: null });
  };

  navigateTo = objectiveID => {
    const { navigate, location, objectiveData } = this.props;
    const stPeriodId = get(objectiveData, 'data.stperiod');
    openViewObjectivePanel(stPeriodId, objectiveID, false, navigate, location);
  };

  render() {
    const { relations, objectiveID, period, objectiveData, t } = this.props;
    const { contextMenuOpen } = this.state;

    const parentId =
      objectiveID.substring(0, 2) === 'CO' ? 'mission' : get(relations, 'parent', null);

    const canEdit = this.props.canEdit && !(parentId === 'mission');
    const { panelOpen } = this.state;

    const hasParent = relations.ok && !!parentId && parentId !== 'mission';
    const hasChildren = relations.ok && get(relations, 'children', []).length > 0;

    return (
      <>
        <LinearIndicator positioning="absolute" />
        <FormCustomEditable
          fieldName="parent"
          render={fieldProps => (
            <EmphasizedSection sx={{ mt: 2 }}>
              <DataObjectChip
                Icon={RelationsIcon}
                text={t('objectives.relatedObjectivesSectionTitle')}
                color="transparent"
                sx={{ mt: -2, ml: -2, mb: 0 }}
              />
              {relations.loading && !relations.ok && <Skeleton height={48} />}
              {(hasParent || hasChildren) && (
                <Box
                  sx={{
                    backgroundColor: 'background.paper',
                    borderRadius: 1,
                    p: 1,
                    mb: 1,
                    mt: 1,
                    '& button:last-child': {
                      mb: 0,
                    },
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {hasParent && (
                    <ObjectiveChip
                      objectiveID={parentId}
                      actionable
                      onClick={canEdit ? this.openContextMenu : this.navigateTo(parentId)}
                      stperiod={period}
                      ContextIcon={ArrowUpwardIcon}
                      name="relations-change-parent-button"
                      color="white"
                      sx={{ marginBottom: 1 }}
                      contextIconProps={{ sx: { color: theme => theme.palette.text.secondary } }}
                    />
                  )}
                  {hasChildren &&
                    relations.children.map(childID => (
                      <ObjectiveChip
                        key={`objective-relations-of-${objectiveID}-child-${childID}`}
                        objectiveID={childID}
                        allowNavigate
                        stperiod={period}
                        ContextIcon={ArrowDownwardIcon}
                        color="white"
                        sx={{ marginBottom: 1 }}
                        contextIconProps={{ sx: { color: theme => theme.palette.text.secondary } }}
                      />
                    ))}
                </Box>
              )}
              {canEdit && !!relations.ok && !parentId && (
                <Button
                  color="secondary"
                  variant="text"
                  onClick={this.openPanel}
                  sx={{ mt: 0, mb: -2, ml: -2, alignSelf: 'flex-start' }}
                  name="relations-change-parent-button"
                >
                  {`+ ${t('objectives.alignObjectiveLabel')}`}
                </Button>
              )}
              <Menu
                anchorEl={contextMenuOpen}
                open={Boolean(contextMenuOpen)}
                onClose={this.closeContextMenu}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem
                  name="relations-change-parent-menu-open"
                  onClick={() => this.navigateTo(parentId)}
                >
                  {t('general.open')}
                </MenuItem>
                <MenuItem name="relations-change-parent-menu-change" onClick={this.openPanel}>
                  {t('objectives.alignObjectiveMenuChangeParent')}
                </MenuItem>
                <MenuItem
                  name="relations-change-parent-menu-unlink"
                  onClick={() => {
                    this.closeContextMenu();
                    fieldProps.onChange(null);
                  }}
                >
                  {t('objectives.alignObjectiveMenuUnlink')}
                </MenuItem>
              </Menu>

              {relations && (
                <PanelParentSelector
                  selected={parentId}
                  stPeriodId={objectiveData.data.stperiod}
                  ltPeriodId={objectiveData.data.ltperiod}
                  objectiveID={objectiveID}
                  open={panelOpen}
                  onCancel={this.closePanel}
                  onSelect={selectedObjetiveID => {
                    this.closePanel();
                    fieldProps.onChange(selectedObjetiveID);
                  }}
                />
              )}
            </EmphasizedSection>
          )}
        />
      </>
    );
  }
}

ObjectiveRelations.propTypes = {
  relations: PropTypes.object,
  location: PropTypes.object,
  navigate: PropTypes.func,
  objectiveData: PropTypes.object,
  objectiveID: PropTypes.string,
  t: PropTypes.func,
  period: PropTypes.string,
  canEdit: PropTypes.bool,
};

export default withTranslation()(withLocation(withNavigation(ObjectiveRelations)));
