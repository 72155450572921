import React from 'react';
import { Box, SvgIcon } from '@mui/material';
import PropTypes from 'prop-types';

const DEFAULT_STYLES = {
  borderRadius: '8px',
  backgroundColor: theme => `${theme.palette?.primary['50']}55`,
  height: 24,
  width: 24,
  fontSize: '18px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: 2,
};

function RelationshipIcon(props) {
  return (
    <Box sx={{ ...DEFAULT_STYLES, ...props.sx }}>
      <SvgIcon {...props} viewBox="-1 -3 18 18">
        <rect opacity="0.2" width="24" height="24" rx="8" fill="black" fillOpacity="0.14" />
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.6668 7.33333V2H10.0002V4H6.00016V2H1.3335V7.33333H6.00016V5.33333H7.3335V12H10.0002V14H14.6668V8.66667H10.0002V10.6667H8.66683V5.33333H10.0002V7.33333H14.6668Z"
            fill="black"
            fillOpacity="0.38"
          />
        </svg>
        <defs>
          <clipPath id="clip0_985_6588">
            <rect width="18" height="18" fill="white" transform="translate(3 3)" />
          </clipPath>
        </defs>
      </SvgIcon>
    </Box>
  );
}

RelationshipIcon.propTypes = {
  sx: PropTypes.object,
};

export default RelationshipIcon;
