import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import AnimateOnChange from 'react-animate-on-change';

import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import NotificationIcon from '@mui/icons-material/Notifications';
import { withLocation, withNavigation } from 'withRouter';

import { openNotificationsPanel } from 'config/ModalProvider/helpers';

const styles = theme => ({
  root: {
    position: 'relative',
  },
  icon: {
    // empty class needs to exist
  },
  effectContainer: {},
  notificationEffect: {
    '& $icon': {
      animation: '$wiggle .75s ease-out',
    },
  },
  '@keyframes wiggle': {
    '0%': { transform: 'rotate(20deg)' },
    '25%': { transform: 'rotate(-20deg)' },
    '50%': { transform: 'rotate(10deg)' },
    '75%': { transform: 'rotate(-5deg)' },
    '100%': { transform: 'rotate(0deg)' },
  },
  notificationsBadge: {
    backgroundColor: theme.palette.error.main,
    color: '#FFF',
    marginRight: 3,
    marginTop: 3,
  },
});

class NotificationsIconButton extends Component {
  state = {
    inboxDelta: 0,
    prevInboxLength: 0,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.inbox && nextProps.inbox.inbox) {
      const newLength = nextProps.inbox.inbox.length;
      return {
        inboxDelta: newLength - prevState.prevInboxLength,
        prevInboxLength: newLength,
      };
    }
    return null;
  }

  openNotifications = () => {
    openNotificationsPanel(this.props.navigate, this.props.location);
  };

  render() {
    const { inbox, classes, className, color } = this.props;
    const { inboxDelta } = this.state;
    return (
      <div className={clsx([classes.root, !!className ? className : null])}>
        <AnimateOnChange
          baseClassName={classes.effectContainer}
          animationClassName={classes.notificationEffect}
          animate={inboxDelta > 0}
        >
          {inbox && inbox.inbox && inbox.inbox.length > 0 && (
            <div>
              <IconButton
                id="header-notifications-toggle-button"
                onClick={this.openNotifications}
                size="large"
              >
                <Badge
                  badgeContent={inbox.inbox.length}
                  classes={{ badge: classes.notificationsBadge }}
                  name="home-screen-notifications-badge"
                >
                  <NotificationIcon className={classes.icon} htmlColor={color} />
                </Badge>
              </IconButton>
            </div>
          )}
        </AnimateOnChange>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  inbox: state.main.inbox,
});

NotificationsIconButton.propTypes = {
  inbox: PropTypes.object,
  classes: PropTypes.exact({
    root: PropTypes.string,
    icon: PropTypes.string,
    effectContainer: PropTypes.string,
    notificationEffect: PropTypes.string,
    notificationsBadge: PropTypes.string,
  }),
  color: PropTypes.string,
  location: PropTypes.object,
  navigate: PropTypes.func,
};

export default withLocation(
  withNavigation(connect(mapStateToProps)(withStyles(styles)(NotificationsIconButton))),
);
