import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Form from 'Components/Library/Forms/';
import FormTextField from 'Components/Library/Forms/Elements/FormTextField';
import LinearIndicator from 'Components/Library/Forms/Elements/LinearIndicator';
import InlineEditInput from 'Components/Library/InlineEditInput';
import RichTextEditor from 'Components/Library/RichTextEditor';
import FormRichTextField from 'Components/Library/Forms/Elements/FormRichTextField';
import RichTextRenderer from '../RichTextRenderer';
import { SCHEMA_FULL } from '../RichTextEditor/schemas';

function DialogHeadline(props) {
  const {
    dataObject,
    name,
    editSchema,
    canEdit,
    additionalProperties,
    submitActionCreator,
    onSubmitSuccess,
  } = props;
  const { t } = useTranslation();

  let initialValues = {};
  if (dataObject?.ok) {
    initialValues = {
      name: dataObject.data.name,
      description: dataObject.data.description,
    };
  }

  return (
    <Form
      name={`panel-view-${name}-headline`}
      debouncedAutoSubmit={950}
      schema={editSchema}
      initialValues={initialValues}
      stateSlice={`main.${name}`}
      allowRefreshData
      refreshedDataSafeDeltaSeconds={10}
      submitActionCreator={submitActionCreator}
      additionalProperties={additionalProperties}
      onSubmitSuccess={() => {
        if (!!onSubmitSuccess) {
          onSubmitSuccess();
        }
      }}
    >
      <LinearIndicator positioning="absolute" />
      <FormTextField
        fieldName="name"
        render={fieldProps => (
          <InlineEditInput
            id={`${name}NameField`}
            fullWidth
            multiline
            variant="h4"
            label={t(`${name}.nameInputLabel`)}
            {...fieldProps}
            disabled={!canEdit}
          />
        )}
      />
      <br />
      <Box sx={{ marginLeft: '14px', marginRight: '14px', mt: 2 }}>
        {canEdit ? (
          <FormRichTextField
            fieldName="description"
            render={fieldProps => (
              <RichTextEditor
                {...fieldProps}
                label={t(`${name}.descriptionInputLabel`)}
                placeholder={t(`${name}.descriptionInputLabel`)}
                toolbarVariant="floating"
                variant="inline"
                schema={SCHEMA_FULL}
              />
            )}
          />
        ) : (
          <RichTextRenderer richTextContent={initialValues.description} schema={SCHEMA_FULL} />
        )}
      </Box>
    </Form>
  );
}

DialogHeadline.propTypes = {
  dataObject: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  editSchema: PropTypes.object.isRequired,
  additionalProperties: PropTypes.object.isRequired,
  submitActionCreator: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
  canEdit: PropTypes.bool,
};

DialogHeadline.defaultProps = {
  canEdit: false,
};

export default DialogHeadline;
