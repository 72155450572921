import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const stringsEn = require('./strings-en.json');
const stringsFi = require('./strings-fi.json');
const stringsFr = require('./strings-fr.json');

export const STRINGS = {
  ...stringsEn,
  ...stringsFi,
  ...stringsFr,
};

const I18N_DETECTOR_OPTS = {
  // order and from where user language should be detected
  order: ['localStorage', 'cookie', 'navigator'],
  lookupLocalStorage: 'i18nextLng',
  caches: ['localStorage', 'cookie'],
};

export const LANGS = [
  { code: 'en', localname: 'English' },
  { code: 'fi', localname: 'Suomi' },
  { code: 'fr', localname: 'Français' },
];

const i18nparams = {
  interpolation: {
    escapeValue: false,
  },
  fallbackLng: 'en',
  resources: STRINGS,
  react: {
    useSuspense: true,
    transSupportBasicHtmlNodes: true,
  },
};

if (
  process.env.REACT_APP_STAGE === 'alpha' ||
  process.env.REACT_APP_STAGE === 'test' ||
  process.env.NODE_ENV === 'development'
) {
  i18nparams.missingKeyHandler = (lng, ns, key, fallbackValue, updateMissing, options) => {
    // testing especially one key
    // eslint-disable-next-line no-console
    console.error(
      'Missing localization key: ',
      lng,
      ns,
      key,
      fallbackValue,
      updateMissing,
      options,
    );
    return key;
  };
  i18nparams.saveMissing = true;
}

const languageDetector = new LanguageDetector(null, I18N_DETECTOR_OPTS);

i18n.use(languageDetector).use(initReactI18next).init(i18nparams);

export default i18n;
