import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import { objectivesHelpers, objectivesActions } from 'state/ducks/objectives';
import { round } from 'config/helpers';

import StatusText from 'Components/Common/StatusText';
import CadencePeriodLabel from 'Components/Features/Objectives/CadencePeriodLabel';
import DataObjectChip from 'Components/Library/DataObjectChip';
import ObjectivesIcon from 'Components/Features/Objectives/ObjectivesIcon';
import TeamChip from 'Components/Library/TeamChip';
import UserChip from 'Components/Library/UserChip';
import UserSelector from 'Components/Library/UserSelector';
import Form from 'Components/Library/Forms/';
import FormSelectorField from 'Components/Library/Forms/Elements/FormSelectorField';
import LinearIndicator from 'Components/Library/Forms/Elements/LinearIndicator';
import EmphasizedSection from 'Components/Library/EmphasizedSection';

const EventChart = lazy(() => import('Components/Features/Objectives/EventChart'));

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  content: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    '& > div:first-of-type': {
      marginTop: theme.spacing(-2),
      marginLeft: theme.spacing(-2),
      marginBottom: theme.spacing(2),
    },
  },
  green: {
    color: `${theme.palette.confidence.green} !important`,
  },
  amber: {
    color: `${theme.palette.confidence.amber} !important`,
  },
  red: {
    color: `${theme.palette.confidence.red} !important`,
  },
  leftColumn: {
    width: 100,
    flexShrink: 0,
    color: theme.palette.text.secondary,
  },
  rightColumn: {
    minWidth: 0, // Fix text ellipsis in flexbox
  },
  chipLabel: {
    paddingTop: 3,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  status: {
    display: 'inline-block',
    marginRight: theme.spacing(),
  },
});

const schema = {
  type: 'object',
  properties: {
    owner: { type: 'string' },
  },
  required: ['owner'],
  additionalProperties: true,
};

class ObjectiveDetails extends React.Component {
  render() {
    const { classes, objectiveData, objectiveID, canEdit, t } = this.props;

    const weeklyDelta = objectivesHelpers.getObjectiveWeeklyProgress(objectiveData.data);
    const confidenceString = objectivesHelpers.getObjectiveConfidenceString(objectiveData.data);

    const domainType = get(objectiveData, 'data.type');

    return (
      <Form
        name="panel-view-objective-owner-form"
        debouncedAutoSubmit={5}
        schema={schema}
        initialValues={{
          owner: get(objectiveData, 'data.owner'),
        }}
        stateSlice="main.objectives"
        submitActionCreator={objectivesActions.updateObjective}
        additionalProperties={{
          objectiveID,
        }}
      >
        <LinearIndicator positioning="absolute" />
        <div id="objective-details-container" className={classes.root}>
          {domainType === 'team' && <TeamChip teamId={get(objectiveData, 'data.teamID')} />}
          {domainType === 'company' && <DataObjectChip text={t('objectives.typescompany')} />}
          {domainType === 'personal' && <DataObjectChip text={t('objectives.typespersonal')} />}
          <EmphasizedSection className={classes.content}>
            <DataObjectChip
              Icon={ObjectivesIcon}
              text={t('objectives.genericDataObjectNameObjective')}
              color="transparent"
            />
            <Suspense fallback={null}>
              <EventChart
                eventData={objectiveData.data.eventdata}
                confidence={confidenceString}
                objectiveData={objectiveData}
              />
            </Suspense>
            <Divider sx={{ mb: 1 }} />
            <div className={classes.row}>
              <div className={clsx(classes.leftColumn, classes.chipLabel)}>
                <Typography variant="subtitle2">{t('objectives.progress')}</Typography>
              </div>
              <div className={classes.rightColumn}>
                <StatusText
                  key={`panel-view-obj-status-text-${objectiveID}`}
                  variant="subtitle1"
                  className={classes.status}
                  value={round(objectiveData.data.eventdata.today.y)}
                  goal={100}
                  suffix="%"
                />
                <span>
                  <Typography
                    variant="caption"
                    component="span"
                    display="inline"
                    className={classes[weeklyDelta >= 0 ? 'green' : 'red']}
                  >
                    {`${weeklyDelta >= 0 ? '+' : ''}${weeklyDelta}%`}
                  </Typography>
                  <Typography
                    variant="caption"
                    component="span"
                    display="inline"
                    color="text.secondary"
                  >
                    {t('objectives.objectivedetailslastweek')}
                  </Typography>
                </span>
              </div>
            </div>

            <div className={classes.row}>
              <div className={classes.leftColumn}>
                <Typography variant="subtitle2">{t('general.period')}</Typography>
              </div>
              <div className={classes.rightColumn}>
                <span name="objectivedetails-cadence-period-name">
                  <Typography variant="subtitle2" component="span">
                    <CadencePeriodLabel objectiveData={objectiveData.data} />
                  </Typography>
                </span>
                <span name="objectivedetails-cadence-period-timeleft">
                  <Typography variant="caption" color="text.secondary" sx={{ ml: 1.5, mt: '-1px' }}>
                    {`(${t('general.ndaysleft', {
                      days: Math.ceil(get(objectiveData, 'data.daysLeft', 0), 0),
                    })})`}
                  </Typography>
                </span>
              </div>
            </div>

            <Divider sx={{ mb: 1.5, mt: 0.5 }} />
            <div className={classes.row}>
              <div className={clsx(classes.leftColumn, classes.chipLabel)}>
                <Typography variant="subtitle2">{t('general.owner')}</Typography>
              </div>
              <div name="objectivedetails-owner-row" className={classes.rightColumn}>
                {!!canEdit && domainType !== 'personal' ? (
                  <FormSelectorField
                    fieldName="owner"
                    render={fieldProps => <UserSelector color="white" {...fieldProps} />}
                  />
                ) : (
                  <UserChip color="white" sub={get(objectiveData, 'data.owner')} />
                )}
              </div>
            </div>
          </EmphasizedSection>
        </div>
      </Form>
    );
  }
}

ObjectiveDetails.propTypes = {
  classes: PropTypes.exact({
    root: PropTypes.string,
    content: PropTypes.string,
    green: PropTypes.string,
    amber: PropTypes.string,
    red: PropTypes.string,
    row: PropTypes.string,
    chipLabel: PropTypes.string,
    rightColumn: PropTypes.string,
    leftColumn: PropTypes.string,
    status: PropTypes.string,
  }),
  objectiveData: PropTypes.object,
  objectiveID: PropTypes.string,
  periodcfg: PropTypes.object,
  period: PropTypes.string,
  canEdit: PropTypes.bool,
  onSelectOwner: PropTypes.func,
  t: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(ObjectiveDetails));
