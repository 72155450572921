import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';

import { KR_OUTDATED_TRESHOLD } from 'config/constants';
import { DELETED } from 'state/constants/api';
import { getKeyResultCompletionPct, round } from 'config/helpers';
import {
  getKeyResultConfidenceString,
  getKeyresultWeeklyProgress,
  isObjectiveOwned,
  isKrOwned,
} from 'state/ducks/objectives/helpers';
import { objectivesSelectors } from 'state/ducks/objectives';
import { peopleSelectors, peopleActions } from 'state/ducks/people';
import ObjectivesTableOverviewHeader from './ObjectivesTableOverviewHeader';

class ObjectivesTableOverviewHeaderContainer extends React.Component {
  state = {
    ownedObjectives: 0,
    ownedKrs: 0,
    outdatedKrs: 0,
    redConfidenceKrs: 0,
    amberConfidenceKrs: 0,
    goodKrs: 0,
    status: 0,
    weeklyDelta: 0,
    events: { keyresults: [] },
    loading: true,
    refreshing: false,
    objectiveIdList: [],
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { selectObjective, objectiveIDs, domain, hash } = nextProps;
    let ownedObjectives = 0;
    let ownedKrs = 0;
    let outdatedKrs = 0;
    let redConfidenceKrs = 0;
    let amberConfidenceKrs = 0;
    let goodKrs = 0;
    const events = { keyresults: [] };
    let alldone = true;
    let refreshing = false;
    let sumStatus = 0;
    let sumDelta = 0;
    let sumWeight = 0;
    const lastUpdate = Date.now();
    let objectiveIdList;
    if (!!objectiveIDs && objectiveIDs.ok) {
      if (
        !prevState.loading &&
        isEqual(nextProps.objectiveIDs.data, prevState.objectiveIdList) &&
        prevState.hash === hash
      ) {
        /*
          Skip doing a potentially expensive computation if nothing has changed.

        */
        return null;
      }
      for (const objectiveID of objectiveIDs.data) {
        const objectiveData = selectObjective(objectiveID);
        objectiveIdList = objectiveIDs.data;
        if (!!objectiveData && objectiveData.ok) {
          if (isObjectiveOwned(domain, objectiveData.data)) {
            ownedObjectives += 1;
          }

          if (!!objectiveData.data.keyresults && objectiveData.data.keyresults.length > 0) {
            for (const keyresult of objectiveData.data.keyresults) {
              if (isKrOwned(domain, objectiveData.data, keyresult)) {
                ownedKrs += 1;
                const krConfidence = getKeyResultConfidenceString(keyresult);
                let krIsGood = true;
                if (krConfidence === 'amber') {
                  amberConfidenceKrs += 1;
                  krIsGood = false;
                } else if (krConfidence === 'red') {
                  redConfidenceKrs += 1;
                  krIsGood = false;
                }

                const krStatus = getKeyResultCompletionPct(keyresult);
                if (
                  keyresult.lastModifiedDays >= KR_OUTDATED_TRESHOLD &&
                  objectiveData.data.stage === 'LIVE' &&
                  krStatus < 100
                ) {
                  outdatedKrs += 1;
                  krIsGood = false;
                }

                if (!!krIsGood) {
                  goodKrs += 1;
                }

                events.keyresults.push(keyresult);

                /* Calculate statuses here to work for all domain types */
                const weeklyDelta = getKeyresultWeeklyProgress(keyresult);

                sumStatus += krStatus * keyresult.weight;
                sumWeight += keyresult.weight;

                sumDelta += weeklyDelta * keyresult.weight;
              }
            }
          }
        } else if (objectiveData.fetchStatus !== DELETED) {
          alldone = false;
        }
      }
      if (!!objectiveIDs.loading) {
        refreshing = true;
      }
    } else {
      alldone = false;
    }

    return {
      ownedObjectives,
      ownedKrs,
      outdatedKrs,
      amberConfidenceKrs,
      redConfidenceKrs,
      goodKrs,
      status: sumWeight !== 0 ? round(sumStatus / sumWeight) : 0,
      weeklyDelta: sumWeight !== 0 ? round(sumDelta / sumWeight) : 0,
      events,
      lastUpdate,
      loading: !alldone,
      refreshing,
      objectiveIdList,
      hash,
    };
  }

  componentDidMount() {
    if (!!this.props.domain && this.props.domain.t === 'team') {
      this.props.dispatch(peopleActions.getTeam({ teamId: this.props.domain.d }));
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.hash !== this.state.hash) {
      return true;
    }
    if (!this.props.teamMembers && !!nextProps.teamMembers) {
      return true;
    }
    if (
      !!this.props.teamMembers &&
      !!nextProps.teamMembers &&
      this.props.teamMembers.ok !== nextProps.teamMembers.ok
    ) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <ObjectivesTableOverviewHeader
        objectiveIDs={this.props.objectiveIDs}
        domain={this.props.domain}
        periodcfg={this.props.periodcfg}
        rootTeamSub={this.props.rootTeamSub}
        ownedObjectives={this.state.ownedObjectives}
        ownedKrs={this.state.ownedKrs}
        outdatedKrs={this.state.outdatedKrs}
        redConfidenceKrs={this.state.redConfidenceKrs}
        amberConfidenceKrs={this.state.amberConfidenceKrs}
        goodKrs={this.state.goodKrs}
        status={this.state.status}
        weeklyDelta={this.state.weeklyDelta}
        events={this.state.events}
        loading={this.state.loading}
        refreshing={this.state.refreshing}
        open={this.props.open}
        period={this.props.period}
        onClose={this.props.onClose}
        onExpand={this.props.onExpand}
        showControls={this.props.showControls}
        showHeader={this.props.showHeader}
        alwaysOpen={this.props.alwaysOpen}
      />
    );
  }
}

ObjectivesTableOverviewHeaderContainer.propTypes = {
  selectObjective: PropTypes.func,
  objectiveIDs: PropTypes.object,
  domain: PropTypes.object,
  dispatch: PropTypes.func,
  hash: PropTypes.string,
  teamMembers: PropTypes.object,
  open: PropTypes.bool,
  periodcfg: PropTypes.object,
  rootTeamSub: PropTypes.string,
  period: PropTypes.string,
  onClose: PropTypes.func,
  onExpand: PropTypes.func,
  showControls: PropTypes.bool,
  showHeader: PropTypes.bool,
  alwaysOpen: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => ({
  selectObjective: objectiveID =>
    objectivesSelectors.selectObjective(state.main.objectives, objectiveID),
  teamMembers:
    ownProps?.domain?.t === 'team'
      ? peopleSelectors.selectTeamMembers(state.main.people, ownProps.domain?.d)
      : null,
  periodcfg: objectivesSelectors.selectStPeriodCfg(state.main.objectives, ownProps.period),
  rootTeamSub: peopleSelectors.selectRootTeam(state.main.people),
});

export default connect(mapStateToProps)(ObjectivesTableOverviewHeaderContainer);
