import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';

import StandardSurveyDialog from 'Components/Library/StandardSurveyDialog';
import Form from 'Components/Library/Forms/';
import FormButton from 'Components/Library/Forms/Elements/FormButton';
import LinearIndicator from 'Components/Library/Forms/Elements/LinearIndicator';
import DataObjectChip from 'Components/Library/DataObjectChip';
import TeamWidget from 'Components/Library/TeamWidget';
import TeamChip from 'Components/Library/TeamChip';
import InsightsIcon from 'Components/Features/Topics/InsightsIcon';

import { insightsActions } from 'state/ducks/insights';

import SurveyPreviewScreen from '../PanelCreateTopic/SurveyPreviewScreen';

const schema = {
  type: 'object',
  additionalProperties: true,
};

class PanelCreateSurvey extends Component {
  render() {
    const {
      t,
      audienceType,
      audienceName,
      audienceID,
      topic,
      sub,
      numPeople,
      teamList,
      onClose,
      zIndexModifier,
    } = this.props;

    let sideBarTitleString;
    if (audienceType === 'TEAM') {
      sideBarTitleString = t('insights.createTopicDialog.teamInsightsMemberListTitle', {
        teamName: audienceName,
      });
    } else {
      sideBarTitleString = t('insights.createTopicDialog.programInsightsMemberListTitle', {
        numPeople,
      });
    }

    return (
      <Form
        name="create-survey"
        schema={schema}
        additionalProperties={{
          topicID: topic.topicID,
        }}
        stateSlice="main.insights"
        submitActionCreator={insightsActions.createSurvey}
        onSubmitSuccess={() => onClose()}
      >
        <StandardSurveyDialog
          open
          name="create-topic"
          onBackdropClick={onClose}
          onCloseClick={onClose}
          size="small"
          zIndexModifier={zIndexModifier}
          title={
            <DataObjectChip Icon={InsightsIcon} text={t('insights.featureName')} variant="small" />
          }
          sidebarContent={
            <Box>
              <Typography
                variant="subtitle2"
                component="span"
                display="block"
                color="text.secondary"
                sx={{ mb: 2 }}
              >
                {sideBarTitleString}
              </Typography>

              {audienceType === 'TEAM' && <TeamWidget teamId={audienceID} />}
              {audienceType === 'PRG' &&
                teamList.map(teamId => (
                  <Box sx={{ mb: 2 }} key={teamId}>
                    <TeamChip teamId={teamId} />
                  </Box>
                ))}
            </Box>
          }
          actionbarContent={
            <DialogActions sx={{ borderTop: theme => `1px solid ${theme.palette.divider}` }}>
              <Button onClick={onClose} id="create-survey-cancel" size="small">
                {t('general.cancel')}
              </Button>
              <FormButton
                render={buttonProps => (
                  <Button
                    variant="contained"
                    color="secondary"
                    id="create-survey-send"
                    {...buttonProps}
                  >
                    {t('general.send')}
                  </Button>
                )}
              />
            </DialogActions>
          }
        >
          <SurveyPreviewScreen values={topic} sub={sub} />

          <LinearIndicator positioning="absolute" />
        </StandardSurveyDialog>
      </Form>
    );
  }
}

PanelCreateSurvey.propTypes = {
  classes: PropTypes.exact({
    content: PropTypes.string,
    answersTitle: PropTypes.string,
    savingIndicator: PropTypes.string,
    overline: PropTypes.string,
    previewContainer: PropTypes.string,
    bold: PropTypes.string,
  }),
  audienceType: PropTypes.string,
  audienceName: PropTypes.string,
  audienceID: PropTypes.string,
  onClose: PropTypes.func,
  topic: PropTypes.object,
  numPeople: PropTypes.number,
  open: PropTypes.bool,
  t: PropTypes.func,
  sub: PropTypes.string,
  teamList: PropTypes.array,
  zIndexModifier: PropTypes.number,
};

export default withTranslation()(PanelCreateSurvey);
