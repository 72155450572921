import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import OpenInNew from '@mui/icons-material/OpenInNew';
import capitalize from '@mui/material/utils/capitalize';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import Chip from '@mui/material/Chip';
import TgIcon from 'Components/Library/TgIcon';

const knowledgeBaseRegexp = /\[@KBLINK:([\w-]+)\]/g;

const supportSite = 'https://support.tangible-growth.com/';

const linkToLabel = linkName =>
  capitalize(linkName.split('-').join(' ')).replace(' i ', ' I ').replace('tg', 'TG');

export const parseMessage = message => {
  if (!message) {
    return '';
  }
  const lines = message.split(/\r?\n/);
  const rows = [];
  lines.forEach((line, lineNo) => {
    const matches = line.matchAll(knowledgeBaseRegexp);
    const newLine = [];
    let prevIndex = 0;
    for (const match of matches) {
      // Append the text before this match
      newLine.push(line.substring(prevIndex, match.index));
      // Append the kb link chip
      newLine.push(
        <Link
          key={`ai-answer-link-${lineNo}${match[1]}`}
          target="_blank"
          href={supportSite + match[1]}
          rel="noreferrer"
        >
          <span>
            <OpenInNew sx={{ fontSize: '12px', mr: 0.5 }} />
            {linkToLabel(match[1])}
          </span>
        </Link>,
      );
      prevIndex = match.index + match[0].length;
    }
    if (prevIndex < line.length) {
      newLine.push(line.substring(prevIndex));
    }
    if (newLine.length === 0) {
      // No regexp matches
      rows.push(<p key={`ai-answer-line-${lineNo}`}>{line}</p>);
    } else {
      rows.push(<p key={`ai-answer-line-${lineNo}`}>{newLine}</p>);
    }
  });
  return rows;
};

function AiAnswer({ content }) {
  const { t } = useTranslation();

  const parsedContent = parseMessage(content);
  return (
    <Stack spacing={1}>
      <Box
        sx={{
          m: 3,
          pt: 1.5,
          border: '1px solid #D9D9D9',
          borderRadius: '16px',
          mr: 0,
          mt: 0,
          width: '385px',
        }}
      >
        <Chip
          icon={<TgIcon sx={{ color: theme => theme.palette.text.secondary }} />}
          label={t('ai.answerTitle')}
          sx={{
            backgroundColor: 'secondary.main',
            color: 'white',
            marginTop: '18px',
            marginLeft: '15px',
          }}
        />
        <Box
          sx={{
            p: 2,
          }}
        >
          <Typography component="section" variant="body1">
            {parsedContent}
          </Typography>
        </Box>
        <Box
          sx={{
            borderRadius: 1,
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
            p: 2,
            pt: 1.5,
            pb: 1.5,
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: 'background.box',
          }}
        >
          <ReportProblemOutlinedIcon fontSize="small" color="disabled" sx={{ mr: 2 }} />
          <Typography variant="body2" color="text.disabled">
            {t('ai.betaDisclaimer')}
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
}

AiAnswer.propTypes = {
  content: PropTypes.string,
};

AiAnswer.defaultProps = {
  content: '',
};
export default AiAnswer;
