import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { objectivesSelectors, objectivesActions } from 'state/ducks/objectives';

const fetchData = (props, force = false) => {
  /*
      Fetch the OKRs for the requested domains
      called with force = True on mount to force the fetching on page refresh
  */
  const { domain, isHidden, forceFetchOnMount } = props;
  if (!!domain && !isHidden && !!props.period) {
    if (domain.t === 'team') {
      props.dispatch(
        objectivesActions.getTeamObjectives({
          teamId: domain.d,
          stperiod: props.period,
          force: force && forceFetchOnMount,
        }),
      );
    } else if (domain.t === 'company') {
      props.dispatch(
        objectivesActions.getCompanyObjectives({
          stperiod: props.period,
          force: force && forceFetchOnMount,
        }),
      );
    } else if (domain.t === 'personal') {
      props.dispatch(
        objectivesActions.getRelatedObjectives({
          sub: domain.d,
          stperiod: props.period,
          force: force && forceFetchOnMount,
        }),
      );
    }
  }
};

class DomainObjectivesFetchWrapper extends React.Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    let okrs;
    const { domain, isHidden } = nextProps;
    if (isHidden) {
      return null;
    }
    if (domain.t === 'company') {
      okrs = nextProps.selectCompanyObjectives();
    } else if (domain.t === 'personal') {
      okrs = nextProps.selectPersonObjectives(domain.d);
    } else if (domain.t === 'team') {
      okrs = nextProps.selectTeamObjectives(domain.d);
    }
    if (
      (!!okrs && !!okrs.ok) ||
      !prevState.objectives ||
      !isEqual(okrs.data, prevState.objectives.data)
    ) {
      return {
        objectives: okrs,
        hash: okrs && okrs.hash ? okrs.hash : 'nohash',
        hasBeenSeen: true,
      };
    }
    return null;
  }

  state = {
    objectives: null,
    hash: null,
    hasBeenSeen: false,
  };

  componentDidMount() {
    fetchData(this.props, true);
    this.refreshtimer = setInterval(() => {
      fetchData(this.props);
    }, 1000 * 25);
  }

  componentDidUpdate() {
    if (!this.props.isHidden) {
      fetchData(this.props);
    }
  }

  componentWillUnmount() {
    clearInterval(this.refreshtimer);
  }

  render() {
    const { render, isHidden } = this.props;
    const { objectives, hash, hasBeenSeen } = this.state;
    if (!!isHidden && !hasBeenSeen) {
      /* Only skip rendering for components that have never been seen,
      Otherwise the scrollbar would be going crazy & confuse the user.
      */
      return null;
    }
    return render(objectives, hash);
  }
}

DomainObjectivesFetchWrapper.propTypes = {
  render: PropTypes.func,
  domain: PropTypes.exact({
    t: PropTypes.oneOf(['personal', 'team', 'company']),
    d: PropTypes.string,
  }),
  // eslint-disable-next-line react/no-unused-prop-types
  period: PropTypes.string,
  selectCompanyObjectives: PropTypes.func,
  selectTeamObjectives: PropTypes.func,
  selectPersonObjectives: PropTypes.func,
  isHidden: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  forceFetchOnMount: PropTypes.bool,
};

DomainObjectivesFetchWrapper.defaultProps = {
  forceFetchOnMount: true,
};

const mapStateToProps = (state, ownProps) => ({
  selectTeamObjectives: teamId =>
    objectivesSelectors.selectTeamObjectives(state.main.objectives, teamId, ownProps.period),
  selectPersonObjectives: sub =>
    objectivesSelectors.selectRelatedObjectives(state.main.objectives, sub, ownProps.period),
  selectCompanyObjectives: () =>
    objectivesSelectors.selectCompanyObjectives(state.main.objectives, ownProps.period),
});

export default connect(mapStateToProps)(DomainObjectivesFetchWrapper);
