import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { calculateObjectiveProgressChartValues } from 'state/ducks/objectives/helpers';

const EventChart = lazy(() => import('Components/Features/Objectives/EventChart'));

class MultipleObjectiveChart extends React.PureComponent {
  state = {
    events: [],
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.events &&
      !nextProps.loading &&
      (nextProps.status !== prevState.status ||
        nextProps.amberConfidenceKrs !== prevState.amberConfidenceKrs ||
        nextProps.redConfidenceKrs !== prevState.redConfidenceKrs)
    ) {
      /*
        We're storing the status & confidence numbers and skip computing
        if they haven't changed
      */
      return {
        eventData: calculateObjectiveProgressChartValues({
          ...nextProps.events,
          periodStart: nextProps.periodcfg?.periodStart,
          periodEnd: nextProps.periodcfg?.periodEnd,
        }),
        events: nextProps.events,
        status: nextProps.status,
        amberConfidenceKrs: nextProps.amberConfidenceKrs,
        redConfidenceKrs: nextProps.redConfidenceKrs,
      };
    }
    return null;
  }

  render() {
    const { loading, period, periodcfg, variant } = this.props;
    return (
      <Suspense fallback={null}>
        <EventChart
          loading={loading}
          period={period}
          periodcfg={periodcfg}
          interactive={variant === 'default'}
          fullRange={variant === 'default'}
          events={this.state.events}
          eventData={this.state.eventData}
          minHeight={variant === 'small' ? 24 : undefined}
          maxHeight={variant === 'small' ? 24 : undefined}
        />
      </Suspense>
    );
  }
}

MultipleObjectiveChart.propTypes = {
  events: PropTypes.object,
  loading: PropTypes.bool,
  variant: PropTypes.string,
  period: PropTypes.string,
  periodcfg: PropTypes.object,
  status: PropTypes.number,
  redConfidenceKrs: PropTypes.number,
  amberConfidenceKrs: PropTypes.number,
};

MultipleObjectiveChart.defaultProps = {
  variant: 'default',
};

export default MultipleObjectiveChart;
