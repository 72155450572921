import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Popover from '@mui/material/Popover';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { NODE_TYPE_USER, NODE_TYPE_TEAM } from 'config/constants';
import SearchOverlay from './SearchOverlay';
import TeamChip from '../TeamChip';
import UserChip from '../UserChip';

const styles = () => ({
  searchPopoverPaper: {
    marginTop: -2, // Align the search input text vertically with the chip text
  },
});

function PersonaSelector(props) {
  const { sx, variant, value, color, classes, onSelect, filterFunc, showRecent, name, ctaNode } =
    props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const openSearch = clickEvent => {
    setAnchorEl(clickEvent.currentTarget);
  };

  const open = Boolean(anchorEl);

  const closeSearch = () => {
    setAnchorEl(null);
  };

  const onSelectHandler = searchResult => {
    closeSearch();
    onSelect(searchResult);
  };

  return (
    <>
      {!value &&
        ctaNode &&
        React.cloneElement(ctaNode, {
          onClick: openSearch,
        })}
      {!!value && value.type === NODE_TYPE_USER && (
        <UserChip
          color={color}
          variant={variant}
          sub={value.id}
          actionable
          onClick={openSearch}
          name={name}
          sx={sx}
          actOnDownArrow
          statusComponent={<ExpandMoreIcon fontSize="13px" color="action" />}
        />
      )}
      {!!value && value.type === NODE_TYPE_TEAM && (
        <TeamChip
          color={color}
          variant={variant}
          teamId={value.id}
          actionable
          onClick={openSearch}
          name={name}
          sx={sx}
          actOnDownArrow
          statusComponent={<ExpandMoreIcon fontSize="13px" color="action" />}
        />
      )}
      <Popover
        anchorEl={anchorEl}
        open={open}
        disableAutoFocus
        onClose={closeSearch}
        classes={{
          paper: classes.searchPopoverPaper,
        }}
      >
        <SearchOverlay
          name={name}
          onSelect={onSelectHandler}
          filterFunc={filterFunc}
          showRecent={showRecent}
        />
      </Popover>
    </>
  );
}

PersonaSelector.propTypes = {
  classes: PropTypes.exact({
    searchPopoverPaper: PropTypes.string,
  }),
  filterFunc: PropTypes.func,
  onSelect: PropTypes.func,
  showRecent: PropTypes.bool,
  name: PropTypes.string,
  ctaNode: PropTypes.node,
  value: PropTypes.object,
  color: PropTypes.oneOf(['white', 'grey', 'transparent', 'darkgrey']),
  variant: PropTypes.oneOf(['small', 'normal']),
  sx: PropTypes.object,
};

PersonaSelector.defaultProps = {
  showRecent: true,
};

export default withStyles(styles)(PersonaSelector);
