import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import PersonasAvatar from 'Components/Common/PersonasAvatar';
import DataObjectChip from 'Components/Library/DataObjectChip';
import { peopleSelectors } from 'state/ducks/people';

function TeamChip(props) {
  const {
    id,
    teamId,
    color,
    actionable,
    onClick,
    statusComponent,
    actOnDownArrow,
    selected,
    name,
    sx,
    allowNavigate,
    Icon,
    variant,
    iconSize = 'xtiny',
    showIconOnly,
  } = props;
  const teamName = useSelector(
    state => peopleSelectors.selectTeamName(state.main.people, teamId),
    shallowEqual,
  );
  const managerSub = useSelector(
    state => peopleSelectors.selectTeamManager(state.main.people, teamId),
    shallowEqual,
  );

  const location = useLocation();
  const navigate = useNavigate();
  const navigateTo = () => {
    if (location.pathname.includes('/home/teams/')) {
      // replace the teamID
      const pathParts = location.pathname.split('/');
      pathParts[pathParts.indexOf('teams') + 1] = teamId;
      navigate(pathParts.join('/'));
    } else {
      navigate(`/home/teams/${teamId}`);
    }
  };

  return (
    <DataObjectChip
      color={color}
      text={showIconOnly ? '' : teamName}
      Icon={Icon}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      icon={
        !!managerSub ? (
          <PersonasAvatar disablePopOver sub={teamId} type="team" size={iconSize} />
        ) : null
      }
      actionable={actionable || allowNavigate}
      // Enabling this rule would turn this into a boolean when neither is true
      // eslint-disable-next-line no-unneeded-ternary
      onClick={onClick ? onClick : !!allowNavigate ? navigateTo : undefined}
      statusComponent={statusComponent}
      actOnDownArrow={actOnDownArrow}
      selected={selected}
      name={name || `team-chip-${teamId}`}
      id={id}
      dataChipType="team"
      sx={sx}
      variant={variant}
      showIconOnly={showIconOnly}
    />
  );
}

TeamChip.propTypes = {
  teamId: PropTypes.string,
  color: PropTypes.oneOf(['white', 'grey', 'transparent', 'darkgrey']),
  variant: PropTypes.oneOf(['small', 'normal', 'large', 'xl', 'xxl']),
  iconSize: PropTypes.oneOf(['xtiny', 'tiny', 'small', 'medium', 'large', 'xlarge']),
  Icon: PropTypes.elementType,
  statusComponent: PropTypes.node,
  actionable: PropTypes.bool,
  onClick: PropTypes.func,
  actOnDownArrow: PropTypes.bool,
  selected: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  sx: PropTypes.object,
  allowNavigate: PropTypes.bool,
  showIconOnly: PropTypes.bool,
};

TeamChip.defaultProps = {
  allowNavigate: false,
};

export default TeamChip;
