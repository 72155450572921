import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { graphSelectors, graphActions } from 'state/ducks/graph';

import EmphasizedSection from 'Components/Library/EmphasizedSection';
import GraphElementChip from 'Components/Library/GraphElementResolver/GraphElementChip';

function NodeRelations(props) {
  const { label, graphId, cta, canEdit } = props;
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      dispatch(graphActions.fetchGraphRelations({ id: graphId, force: true }));
      mounted.current = true;
    } else {
      dispatch(graphActions.fetchGraphRelations({ id: graphId, force: false }));
    }
  });

  const relations = useSelector(
    state => graphSelectors.selectRelations(state.main.graph, graphId),
    // { stabilityCheck: 'never' },
  );

  const removeLink = (event, link) => {
    dispatch(graphActions.editRelations({ changeset: [{ ...link, action: 'DELETE' }] }));
    event.preventDefault();
    event.stopPropagation();
  };

  if (!canEdit && (!relations.ok || relations?.data.length === 0)) {
    return null;
  }

  return (
    <EmphasizedSection sx={{ p: 1 }}>
      {label}
      {relations.ok && (
        <>
          {relations.data.length > 0 && (
            <Stack
              sx={{
                backgroundColor: 'background.paper',
                borderRadius: '10px',
                m: 2,
                mt: 1,
                mb: 1,
                p: 1,
              }}
              spacing={1}
            >
              {relations.data.map(r => {
                const otherId = r.from === graphId ? r.to : r.from;
                return (
                  <GraphElementChip
                    key={`node-relations-${otherId}`}
                    ContextIcon={null}
                    graphId={otherId}
                    color="transparent"
                    allowNavigate
                    statusComponent={
                      canEdit ? (
                        <IconButton
                          component="div"
                          onClick={e => removeLink(e, r)}
                          name="remove-relation"
                          aria-label={t('general.unlink')}
                        >
                          <HighlightOffIcon
                            sx={{
                              color: theme => theme.palette.primary[500],
                              width: 15,
                              height: 15,
                            }}
                          />
                        </IconButton>
                      ) : undefined
                    }
                  />
                );
              })}
            </Stack>
          )}
          {cta}
        </>
      )}
    </EmphasizedSection>
  );
}

NodeRelations.propTypes = {
  graphId: PropTypes.string,
  label: PropTypes.node,
  cta: PropTypes.node,
  canEdit: PropTypes.bool,
};

export default NodeRelations;
