import React, { Component } from 'react';
import { connect } from 'react-redux';
import { orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import CanEditKeyResultWrapper from 'Components/Features/Objectives/CanEditKeyResultWrapper';

import { objectivesActions, objectivesSelectors } from 'state/ducks/objectives';

import { validateText } from 'config/helpers';

import KeyresultTodos from './KeyresultTodos';

const initialState = {
  submitStatus: 0,
  submitted: false,
  todos: [],
};

export class KeyresultTodosContainer extends Component {
  constructor(props) {
    super(props);
    if (props.keyresult && props.keyresult.todos) {
      this.state = {
        ...initialState,
        todos: props.keyresult.todos || [],
      };
    } else {
      this.state = { ...initialState };
    }
  }

  componentDidMount() {
    this.updateValues(this.props);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.submitted && !!prevState.requestID) {
      if (!!nextProps.actionlog && prevState.requestID in nextProps.actionlog) {
        if (nextProps.actionlog[prevState.requestID].result === 'ok') {
          const nextTodos = nextProps.keyresult.todos || [];
          return { submitStatus: 1, submitted: false, todos: orderBy(nextTodos, 'todo', 'asc') };
        }
        return {
          submitStatus: -1,
          submitted: false,
          serverError: nextProps.actionlog[prevState.requestID].message,
        };
      }
    }
    return null;
  }

  handleSubmit(todos) {
    const kr = {
      keyresultID: this.props.keyresult.keyresultID,
      todos: todos.filter(todo => validateText(todo.todo)),
    };
    const requestID = nanoid(10);
    kr.requestID = requestID;
    this.setState({ submitted: true, submitStatus: 0, requestID });
    this.props.dispatch(objectivesActions.updateKeyresultTodos(kr));
  }

  onTodoStatusChange = (i, status) => {
    const todos = orderBy([...this.state.todos], ['todo'], 'asc');
    todos[i].status = status;
    this.setState({ todos });
    this.handleSubmit(todos);
  };

  onTodoAdded = () => {
    const todos = orderBy([...this.state.todos], ['todo'], 'asc');
    todos.push({ status: false, todo: '' });
    this.setState({ todos });
  };

  onTodoTextChange = (i, text) => {
    const todos = [...this.state.todos];
    const todo = { ...todos[i] };
    todo.todo = text;
    todos[i] = todo;
    this.setState({ todos: orderBy(todos, ['todo'], 'asc') });
    this.handleSubmit(todos);
  };

  onTodoDeleted = i => {
    const todos = [...this.state.todos];
    todos.splice(i, 1);
    this.setState({ todos: orderBy(todos, ['todo'], 'asc') });
    this.handleSubmit(todos);
  };

  deleteIfEmpty = i => {
    const todos = [...this.state.todos];
    if (!todos[i].todo || todos[i].todo.length === 0) {
      todos.splice(i, 1);
      this.setState({ todos: orderBy(todos, ['todo'], 'asc') });
      this.handleSubmit(todos);
    }
  };

  updateValues(props) {
    if (props.keyresult) {
      this.setState({
        todos: !!props.keyresult.todos ? orderBy([...props.keyresult.todos], ['todo'], 'asc') : [],
      });
    }
  }

  render() {
    if (!this.props.keyresult) {
      return null;
    }
    return (
      <CanEditKeyResultWrapper
        render={canEdit => (
          <KeyresultTodos
            submitStatus={this.state.submitStatus}
            submitted={this.state.submitted}
            todos={this.state.todos}
            canEdit={canEdit}
            className={this.props.className}
            keyresult={this.props.keyresult.keyresult}
            hideOverline={this.props.hideOverline}
            onStatusChange={this.onTodoStatusChange}
            onTextChange={this.onTodoTextChange}
            onTodoAdded={this.onTodoAdded}
            onTodoDeleted={this.onTodoDeleted}
            deleteIfEmpty={this.deleteIfEmpty}
          />
        )}
        keyresult={this.props.keyresult}
        actionlog={this.props.actionlog}
        dispatch={this.props.dispatch}
        hideOverline={this.props.hideOverline}
      />
    );
  }
}

KeyresultTodosContainer.propTypes = {
  keyresult: PropTypes.object,
  actionlog: PropTypes.object,
  dispatch: PropTypes.func,
  hideOverline: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  const krData = ownProps.keyresult;
  const objectiveData = objectivesSelectors.selectObjective(
    state.main.objectives,
    ownProps.objectiveID,
  );

  return {
    actionlog: state.main.objectives.actionlog,
    sub: state.auth.userID,
    keyresult: krData,
    objectiveData,
  };
};

export default connect(mapStateToProps)(KeyresultTodosContainer);
