import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import Collapse from '@mui/material/Collapse';
import Hidden from '@mui/material/Hidden';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import IconButton from '@mui/material/IconButton';
import ExpandIcon from '@mui/icons-material/OpenInNew';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import PersonasAvatar from 'Components/Common/PersonasAvatar';
import TgProgress from 'Components/Common/TgProgress';
import TeamChip from 'Components/Library/TeamChip';
import UserChip from 'Components/Library/UserChip';
import DataObjectChip from 'Components/Library/DataObjectChip';
import ObjectivesTable from '../ObjectivesTable';
import ObjectivesTableHeader from '../ObjectivesTableHeader';
import MultipleObjectiveChart from './MultipleObjectiveChart';

const styles = theme => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    pageBreakInside: 'avoid',
    ...theme.shape,
    backgroundColor: theme.palette.background.paper,
    minHeight: 32,
    padding: theme.spacing(),
  },
  interactive: {
    boxShadow: theme.shadows[1],
    '&:hover': {
      boxShadow: theme.shadows[5],
    },
  },
  tableContainer: {
    overflow: 'clip',
    borderTop: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('lg')]: {
      overflowX: 'auto',
      '-webkit-transform': 'translateZ(0)',
    },
  },
  minimizedStatusText: {
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.subtitle1,
    },
  },
  statusText: {
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.h4,
    },
  },
  header: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexShrink: 0,
    flexDirection: 'column',
  },
  domainRow: {
    maxWidth: `calc(100% - 32px)`,
    overflow: 'hidden',
    marginBottom: theme.spacing(),
  },
  collapser: {
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    flexWrap: 'wrap',
  },
  chartContainer: {
    flexGrow: 1,
    marginRight: theme.spacing(2),
  },
  chipContainer: {
    flexShrink: 0,
  },
  statusContainer: {
    flexGrow: 1,
    marginRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  chip: {
    height: 22,
    ...theme.shape,
    display: 'flex',
    flexShrink: 20,
    minWidth: '1px',
    overflow: 'hidden',
    alignItems: 'center',
    padding: '0px 10px',
    color: theme.palette.text.secondary,
    border: '1px solid',
    '& span:first-of-type': {
      width: 20,
      textAlign: 'center',
    },
    '& span:last-child': {
      marginLeft: 2.2,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    marginLeft: 4,
  },
  outdated: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '& .MuiTypography-root': {
      color: theme.palette.error.main,
    },
  },
  red: {
    color: theme.palette.confidence.red,
    borderColor: theme.palette.confidence.red,
    '& .MuiTypography-root': {
      color: theme.palette.confidence.red,
    },
  },
  green: {
    color: theme.palette.confidence.green,
    borderColor: theme.palette.confidence.green,
    '& .MuiTypography-root': {
      color: theme.palette.confidence.green,
    },
  },
  amber: {
    color: theme.palette.confidence.amber,
    borderColor: theme.palette.confidence.amber,
    '& .MuiTypography-root': {
      color: theme.palette.confidence.amber,
    },
  },
  greentext: {
    color: `${theme.palette.confidence.green} !important`,
  },
  redtext: {
    color: `${theme.palette.confidence.red} !important`,
  },
  xsTable: {
    flexGrow: 2,
  },
  modalRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 10, // Fill screen vertically
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
      minWidth: '100%',
      margin: 0,
      padding: 0,
      borderRadius: 0,
      top: 0,
      left: 0,
    },
  },
  flexGrow: {
    flexGrow: 10,
    flexShrink: 5,
  },
  scrollable: {
    overflowY: 'auto',
  },
});

class ObjectivesTableOverviewHeader extends React.Component {
  state = {
    tableVisible: false,
    isPrinting: false,
  };

  componentDidMount() {
    this.setState({ tableVisible: this.props.open });
    window.addEventListener('beforeprint', this.onPrintStart, false);
    window.addEventListener('afterprint', this.onPrintEnd, false);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeprint', this.onPrintStart, false);
    window.removeEventListener('afterprint', this.onPrintEnd, false);
  }

  onPrintStart = () => {
    this.setState({ isPrinting: true });
  };

  onPrintEnd = () => {
    this.setState({ isPrinting: false });
  };

  toggleTable = () => {
    this.setState({ tableVisible: !this.state.tableVisible || this.props.alwaysOpen });
  };

  render() {
    const {
      classes,
      domain,
      periodcfg,
      objectiveIDs,
      ownedObjectives,
      ownedKrs,
      outdatedKrs,
      goodKrs,
      redConfidenceKrs,
      amberConfidenceKrs,
      rootTeamSub,
      status,
      weeklyDelta,
      events,
      loading,
      refreshing,
      onClose,
      onExpand,
      t,
      period,
      alwaysOpen,
      showControls,
      showHeader,
    } = this.props;

    if (!periodcfg) {
      return null;
    }

    const { tableVisible, isPrinting } = this.state;

    let domainWidget;

    if (domain.t === 'team') {
      domainWidget = <TeamChip teamId={domain.d} variant="normal" color="transparent" />;
    } else if (domain.t === 'personal') {
      domainWidget = <UserChip sub={domain.d} variant="normal" color="transparent" />;
    } else if (domain.t === 'company') {
      domainWidget = (
        <DataObjectChip
          icon={<PersonasAvatar disablePopOver sub={rootTeamSub} type="team" size="xtiny" />}
          variant="normal"
          color="transparent"
          text={t('objectives.companyobjectives')}
        />
      );
    }

    const HeaderElement = alwaysOpen ? 'div' : ButtonBase;
    const headerProps = alwaysOpen ? {} : { component: 'div', onClick: this.toggleTable };

    return (
      <div
        className={clsx(
          classes.root,
          !alwaysOpen && classes.interactive,
          alwaysOpen ? classes.modalRoot : null,
        )}
        name={`okr-header-root-${domain.t}-${domain.d}`}
      >
        {showHeader && (
          <HeaderElement name="okr-header-interactable" className={classes.header} {...headerProps}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                justifyContent: 'flex-start',
                alignSelf: 'stretch',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  minHeight: 32,
                  boxSizing: 'border-box',
                  flexWrap: { xs: 'wrap', sm: 'nowrap' },
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexGrow: 100,
                    minWidth: { xs: '100%', sm: '200px' },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      minWidth: '1px',
                    }}
                  >
                    {loading && !refreshing && <TgProgress color="primary" size={32} />}
                    {domainWidget}
                  </Box>

                  {!tableVisible && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        mr: { xs: 1, lg: 2 },
                      }}
                    >
                      <Box
                        sx={{
                          height: 32,
                          display: { xs: 'none', lg: 'flex' },
                          alignItems: 'center',
                          flexGrow: 100,
                          minWidth: 120,
                          maxWidth: 220,
                        }}
                      >
                        <Box
                          sx={{
                            display: { xs: 'none', lg: 'block' },
                            width: '100%',
                          }}
                        >
                          <MultipleObjectiveChart
                            loading={loading}
                            events={events}
                            periodStart={periodcfg.periodStart}
                            periodEnd={periodcfg.periodEnd}
                            status={status}
                            amberConfidenceKrs={amberConfidenceKrs}
                            redConfidenceKrs={redConfidenceKrs}
                            variant="small"
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          flexShrink: 0,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          minWidth: {
                            xs: 'auto',
                            sm: '144px',
                          },
                        }}
                      >
                        <Typography
                          variant="h6"
                          component="span"
                          className={classes.minimizedStatusText}
                        >{`${status}%`}</Typography>
                        <Typography
                          variant="subtitle2"
                          component="span"
                          display="inline"
                          sx={{ mt: 0.5, ml: 0.5 }}
                          className={clsx(classes[weeklyDelta >= 0 ? 'greentext' : 'redtext'])}
                        >
                          {`${weeklyDelta >= 0 ? '+' : ''}${weeklyDelta}%`}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    minWidth: {
                      xs: '192px', // enough room for all chips with atleast emoji and number
                    },
                    width: { xs: 'auto', sm: '45%', lg: '45%', xl: 'auto' },
                    flexShrink: {
                      xs: 100,
                      sm: 0, // For larger screens, we want the chips to occupy the same amount of space
                    },
                  }}
                >
                  <div
                    name="okr-header-good-krs"
                    className={clsx(classes.chip, goodKrs !== 0 ? classes.green : classes.grey)}
                  >
                    <Typography variant="body2" component="span">
                      👌
                    </Typography>
                    <Typography variant="body2" component="span">
                      {goodKrs}
                    </Typography>
                    <Typography variant="body2" component="span">
                      {t('objectives.goodkrs')}
                    </Typography>
                  </div>
                  <div
                    name="okr-header-amber-krs"
                    className={clsx(
                      classes.chip,
                      amberConfidenceKrs !== 0 ? classes.amber : classes.grey,
                    )}
                  >
                    <Typography variant="body2" component="span">
                      🤞
                    </Typography>
                    <Typography variant="body2" component="span">
                      {amberConfidenceKrs}
                    </Typography>
                    <Typography variant="body2" component="span">
                      {t('objectives.amberkrs')}
                    </Typography>
                  </div>
                  <div
                    name="okr-header-red-krs"
                    className={clsx(
                      classes.chip,
                      redConfidenceKrs !== 0 ? classes.red : classes.grey,
                    )}
                  >
                    <Typography variant="body2" component="span">
                      👋
                    </Typography>
                    <Typography variant="body2" component="span">
                      {redConfidenceKrs}
                    </Typography>
                    <Typography variant="body2" component="span">
                      {t('objectives.redkrs')}
                    </Typography>
                  </div>
                  {moment().isBefore(moment(periodcfg.periodEnd)) && (
                    <div
                      name="okr-header-outdated-krs"
                      className={clsx(
                        classes.chip,
                        outdatedKrs !== 0 ? classes.outdated : classes.grey,
                      )}
                    >
                      <Typography variant="body2" component="span">
                        📅
                      </Typography>
                      <Typography variant="body2" component="span">
                        {outdatedKrs}
                      </Typography>
                      <Typography variant="body2" component="span">
                        {t('objectives.outdatedkrs')}
                      </Typography>
                    </div>
                  )}
                </Box>
                {!loading && !refreshing && showControls && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <IconButton
                      name="expand"
                      onClick={onExpand}
                      size="small"
                      aria-label={t('general.expand')}
                    >
                      <ExpandIcon />
                    </IconButton>
                    <IconButton
                      name="okr-header-close-button"
                      onClick={onClose}
                      size="small"
                      aria-label={t('general.close')}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </Box>
          </HeaderElement>
        )}
        {tableVisible && (
          <Box sx={{ p: 1 }}>
            <MultipleObjectiveChart
              loading={loading}
              events={events}
              period={periodcfg.periodStart}
              periodcfg={periodcfg}
              status={status}
              amberConfidenceKrs={amberConfidenceKrs}
              redConfidenceKrs={redConfidenceKrs}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              {!!loading ? (
                <Typography variant="h6">{t('general.loading')}</Typography>
              ) : (
                <div className={classes.statusContainer}>
                  <Typography
                    variant="h3"
                    className={classes.statusText}
                  >{`${status}%`}</Typography>
                  <Box sx={{ ml: 1 }}>
                    <Typography
                      variant="subtitle1"
                      component="span"
                      display="inline"
                      className={clsx(classes[weeklyDelta >= 0 ? 'greentext' : 'redtext'])}
                    >
                      {`${weeklyDelta >= 0 ? '+' : ''}${weeklyDelta}%`}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      component="span"
                      display="inline"
                      sx={{ display: { xs: 'none', sm: 'inline' } }}
                    >
                      <Trans i18nKey="objectives.tabledeltalastweek" />
                    </Typography>
                  </Box>
                </div>
              )}

              <Box>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography variant="subtitle1" component="span">
                    {t('objectives.genericDataObjectNameObjectives')}
                    &nbsp;<span name="okr-header-owned-objectives">{ownedObjectives}</span>
                    {`, ${t('objectives.genericDataObjectNameKeyresults')}`}
                    &nbsp;<span name="okr-header-owned-krs">{ownedKrs}</span>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        {alwaysOpen ? (
          <React.Fragment>
            <Hidden mdDown>
              <div className={clsx(classes.flexGrow, classes.scrollable)}>
                <div className={classes.tableContainer}>
                  <Table sx={{ tableLayout: 'fixed' }} size="small" padding="none" stickyHeader>
                    <ObjectivesTableHeader />
                    <ObjectivesTable
                      domain={domain}
                      objectiveIDs={objectiveIDs}
                      stperiod={period}
                      allowScroll
                    />
                  </Table>
                </div>
              </div>
            </Hidden>
            <Hidden mdUp>
              <div name="hsmUp" className={clsx(classes.tableContainer, classes.flexGrow)}>
                <Table sx={{ tableLayout: 'fixed' }} size="small" padding="none" stickyHeader>
                  <ObjectivesTableHeader />
                  <ObjectivesTable domain={domain} objectiveIDs={objectiveIDs} stperiod={period} />
                </Table>
              </div>
            </Hidden>
          </React.Fragment>
        ) : !isPrinting ? (
          <Collapse in={tableVisible} className={classes.collapser}>
            <div className={classes.tableContainer}>
              <Table sx={{ tableLayout: 'fixed' }} size="small" padding="none">
                <ObjectivesTableHeader />
                <ObjectivesTable domain={domain} objectiveIDs={objectiveIDs} stperiod={period} />
              </Table>
            </div>
          </Collapse>
        ) : (
          <div className={classes.tableContainer}>
            <Table sx={{ tableLayout: 'fixed' }} size="small" padding="none">
              <ObjectivesTableHeader />
              <ObjectivesTable domain={domain} objectiveIDs={objectiveIDs} stperiod={period} />
            </Table>
          </div>
        )}
      </div>
    );
  }
}

ObjectivesTableOverviewHeader.propTypes = {
  domain: PropTypes.object,
  open: PropTypes.bool,
  periodcfg: PropTypes.object,
  period: PropTypes.string,
  ownedObjectives: PropTypes.number,
  ownedKrs: PropTypes.number,
  outdatedKrs: PropTypes.number,
  goodKrs: PropTypes.number,
  redConfidenceKrs: PropTypes.number,
  amberConfidenceKrs: PropTypes.number,
  rootTeamSub: PropTypes.string,
  status: PropTypes.number,
  weeklyDelta: PropTypes.number,
  events: PropTypes.object,
  loading: PropTypes.bool,
  refreshing: PropTypes.bool,
  showControls: PropTypes.bool,
  showHeader: PropTypes.bool,
  alwaysOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onExpand: PropTypes.func,
  objectiveIDs: PropTypes.object,
  classes: PropTypes.exact({
    root: PropTypes.string,
    interactive: PropTypes.string,
    tableContainer: PropTypes.string,
    header: PropTypes.string,
    minimizedStatusText: PropTypes.string,
    collapser: PropTypes.string,
    domainRow: PropTypes.string,
    row: PropTypes.string,
    chartContainer: PropTypes.string,
    chipContainer: PropTypes.string,
    statusContainer: PropTypes.string,
    statusText: PropTypes.string,
    deltasContainer: PropTypes.string,
    chip: PropTypes.string,
    outdated: PropTypes.string,
    green: PropTypes.string,
    red: PropTypes.string,
    amber: PropTypes.string,
    grey: PropTypes.string,
    greentext: PropTypes.string,
    redtext: PropTypes.string,
    actionable: PropTypes.string,
    xsTable: PropTypes.string,
    modalRoot: PropTypes.string,
    flexGrow: PropTypes.string,
    scrollable: PropTypes.string,
  }),
  t: PropTypes.func,
};

ObjectivesTableOverviewHeader.defaultProps = {
  showControls: false,
  alwaysOpen: false,
  showHeader: true,
};

export default withTranslation()(withStyles(styles)(ObjectivesTableOverviewHeader));
