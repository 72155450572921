import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { interlocksActions } from 'state/ducks/interlocks/index';

import RichTextEditor from 'Components/Library/RichTextEditor';
import FormRichTextField from 'Components/Library/Forms/Elements/FormRichTextField';
import Form from 'Components/Library/Forms/';
import FormTextField from 'Components/Library/Forms/Elements/FormTextField';
import FormButton from 'Components/Library/Forms/Elements/FormButton';
import LinearIndicator from 'Components/Library/Forms/Elements/LinearIndicator';
import InlineEditInput from 'Components/Library/InlineEditInput';
import StandardDialog from 'Components/Library/StandardDialog';
import FormSelectorField from 'Components/Library/Forms/Elements/FormSelectorField';
import UserSelector from 'Components/Library/UserSelector/index';
import { NODE_TYPE_TEAM, NODE_TYPE_USER } from 'config/constants';
import TeamChip from 'Components/Library/TeamChip';
import { graphActions } from 'state/ducks/graph/index';
import schema from '../schema';

const styles = theme => ({
  content: {
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: theme.spacing(3),
    flex: 10,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
    paddingBottom: theme.spacing(),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  formContainer: {
    margin: theme.spacing(),
    marginBottom: theme.spacing(3),
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
});

class DialogAddInterlock extends Component {
  onInterlockCreated = result => {
    const interlockID = get(result, 'data.id');
    if (!!this.props.onInterlockCreated && interlockID) {
      if (this.props.relatedItemID) {
        this.props.dispatch(
          graphActions.editRelations({
            changeset: [
              {
                action: 'ADD',
                from: this.props.relatedItemID,
                to: interlockID,
                type: 'GENREL',
              },
            ],
          }),
        );
      }
      this.props.onInterlockCreated(interlockID);
    } else {
      this.props.onClose();
    }
  };

  render() {
    const { domain, classes, onClose, t, sub } = this.props;

    return (
      <Form
        name="add-interlock-form"
        schema={schema}
        initialValues={{
          owner: sub,
          name: '',
          description: null,
        }}
        stateSlice="main.interlocks"
        submitActionCreator={interlocksActions.addInterlock}
        onValueChange={this.onValueChange}
        onSubmitSuccess={this.onInterlockCreated}
        additionalProperties={{
          contributors: domain && domain.type ? [{ type: domain.type, id: domain.id }] : [],
        }}
      >
        <StandardDialog
          open
          onBackdropClick={onClose}
          preventBackDropCloseFunc={this.preventBackDropCloseFunc}
          size="small"
        >
          <LinearIndicator positioning="absolute" />
          <div className={classes.content} id="panel-add-interlock-content">
            <div className={classes.formContainer}>
              <FormTextField
                fieldName="name"
                render={fieldProps => (
                  <InlineEditInput
                    autoFocus
                    label={t('interlocks.nameInputLabel')}
                    fullWidth
                    noPadding
                    hideFocus
                    multiline
                    variant="h4"
                    {...fieldProps}
                    sx={{ mb: 2 }}
                  />
                )}
              />
              <FormRichTextField
                fieldName="description"
                render={fieldProps => (
                  <RichTextEditor
                    {...fieldProps}
                    label={t('interlocks.descriptionInputLabel')}
                    placeholder={t('interlocks.descriptionInputLabel')}
                    toolbarVariant="floating"
                    variant="inline"
                    schema="all"
                  />
                )}
              />
            </div>
            <Stack direction="row" spacing={1}>
              {!!domain && domain.type === NODE_TYPE_TEAM && <TeamChip teamId={domain.id} />}
              <FormSelectorField
                fieldName="owner"
                render={fieldProps => <UserSelector showRecent {...fieldProps} />}
              />
            </Stack>
          </div>
          <div className={classes.actionsContainer}>
            <Button onClick={onClose} id="add-interlock-form-cancel">
              {t('general.cancel')}
            </Button>
            <FormButton
              render={buttonProps => (
                <Button
                  id="add-interlock-form-submit"
                  variant="contained"
                  color="secondary"
                  {...buttonProps}
                >
                  {t('interlocks.createNewInterlockButtonLabel')}
                </Button>
              )}
            />
          </div>
        </StandardDialog>
      </Form>
    );
  }
}

DialogAddInterlock.propTypes = {
  classes: PropTypes.exact({
    content: PropTypes.string,
    formContainer: PropTypes.string,
    actionsContainer: PropTypes.string,
  }),
  t: PropTypes.func,
  sub: PropTypes.string,
  onInterlockCreated: PropTypes.func,
  onClose: PropTypes.func,
  domain: PropTypes.shape({
    type: PropTypes.oneOf([NODE_TYPE_USER, NODE_TYPE_TEAM]),
    id: PropTypes.string,
  }),
  relatedItemID: PropTypes.string,
  dispatch: PropTypes.func,
};

const mapStateToProps = state => ({
  sub: state.auth.userID,
});

export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(DialogAddInterlock)));
