import React from 'react';
import { useSelector } from 'react-redux';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { settingsSelectors } from 'state/ducks/usersettings';
import getTheme from './theme';

function MuiThemeProvider({ children }) {
  const darkmode = useSelector(state => settingsSelectors.selectDarkMode(state.main.usersettings));

  const theme = getTheme(darkmode);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Container
          maxWidth={false}
          disableGutters
          sx={{
            height: '100%',
            width: '100%',
          }}
        >
          {children}
        </Container>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default MuiThemeProvider;
