import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import { nanoid } from 'nanoid';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import AiQuestionWrapper from 'Components/Features/TgAI/AiQuestionWrapper';

function HelpDialog({ open, onClose }) {
  const { t } = useTranslation();
  const [questionsAsked, setQuestionsAsked] = useState([
    {
      id: nanoid(10),
      isAnswered: false,
      question: '',
      answer: '',
    },
  ]);
  const [isHistoryEnabled, setIsHistoryEnabled] = useState(false);
  const [sessionId, setSessionId] = useState(null);

  const closeDialog = () => {
    setQuestionsAsked([
      {
        id: nanoid(10),
        isAnswered: false,
        question: '',
        answer: '',
      },
    ]);
    onClose();
  };

  const onAnswerReceived = questionData => {
    const preparedQuestions = [...questionsAsked];
    const answeredQuestion = preparedQuestions.find(q => q.id === questionData.id);

    if (answeredQuestion) {
      answeredQuestion.isAnswered = true;
      answeredQuestion.answer = questionData.answer;
      answeredQuestion.question = questionData.question;
    }
    preparedQuestions.push({
      id: nanoid(10),
      isAnswered: false,
      question: '',
      answer: '',
    });
    setQuestionsAsked(preparedQuestions);
  };

  return (
    <Dialog onClose={closeDialog} open={open} maxWidth="sm">
      <Stack direction="column">
        <Box sx={{ p: 3, pt: 2, pb: 0 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            sx={{ width: '100%' }}
          >
            <Typography variant="subtitle1">{t('usersettingsmenu.helpAndSupport')}</Typography>
            <IconButton size="small" onClick={closeDialog}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </Box>
        <FormControlLabel
          label="Enable history"
          control={
            <Checkbox
              color="secondary"
              checked={isHistoryEnabled}
              size="small"
              onChange={() => setIsHistoryEnabled(!isHistoryEnabled)}
              label="Enable history"
              sx={{
                p: 2,
                ml: 3,
              }}
            />
          }
        />
        {questionsAsked.map(question => (
          <AiQuestionWrapper
            isHistoryEnabled={isHistoryEnabled}
            sessionId={isHistoryEnabled ? sessionId : ''}
            key={question.id}
            questionData={question}
            onAnswerReceived={onAnswerReceived}
            setSessionId={newSessionId => {
              setSessionId(newSessionId);
            }}
          />
        ))}
      </Stack>
    </Dialog>
  );
}

HelpDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
export default HelpDialog;
