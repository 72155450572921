import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PersonasAvatar from 'Components/Common/PersonasAvatar';

import { getLastModifiedStringFromTimestamp } from 'config/helpers';
import { peopleSelectors } from 'state/ducks/people';

function ActivityEntry(props) {
  const {
    sub,
    title,
    metaDataMessage,
    message,
    timestamp,
    className,
    collapseConfig,
    name = 'activity-entry',
  } = props;

  const [collapsed, toggleCollapsed] = useState(
    !!collapseConfig && collapseConfig.initialState === 'collapsed',
  );

  const userName = useSelector(state => peopleSelectors.selectFirstName(state.main.people, sub));

  return (
    <Box
      className={className}
      name={name}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'stretch',
        alignItems: 'flex-start',
      }}
    >
      {/* Left column, avatar and vertical line */}
      <Box
        sx={{
          alignSelf: 'stretch',
          flexDirection: 'column',
          justifyContent: 'stretch',
          alignItems: 'center',
          width: '32px',
          flexBasis: 32,
          flexGrow: 0,
          display: 'flex',
          paddingRight: 1,
        }}
      >
        <PersonasAvatar sub={sub} size="xtiny" />
        <Box
          sx={{
            width: '1px',
            mt: 1,
            backgroundColor: theme => theme.palette.grey[400],
            flexGrow: 100,
          }}
        />
        {/* End left column */}
      </Box>

      {/* Right column */}
      <Box
        sx={{
          flexGrow: 10,
          flexShrink: 1000,
          flexBasis: 'auto',
          minWidth: '1px', // !!important to ensure ellipsis works in children
        }}
      >
        {/* Title row */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            overflow: 'hidden',
            height: '18px',
            lineHeight: '18px',
            mb: 0.5,
          }}
        >
          <Typography
            variant="body2"
            component="span"
            color="text.strong"
            sx={{ flexShrink: 0, flexBasis: 'auto', flexGrow: 0 }}
          >
            {userName}
          </Typography>
          {title && (
            <Typography
              variant="body2"
              component="span"
              color="text.secondary"
              sx={{
                minWidth: '1px', // !!important to ensure ellipsis works in children
                whiteSpace: 'nowrap',
                display: 'inline-flex',
                flexShrink: 10,
                alignItems: 'center',
              }}
            >
              &nbsp;
              {title}
            </Typography>
          )}
          <Typography
            variant="tiny"
            color="text.primary"
            sx={{
              flexShrink: 0,
              flexBasis: 'auto',
              flexGrow: 0,
              minWidth: '1px',
              ml: 0.5,
            }}
          >
            {getLastModifiedStringFromTimestamp(timestamp)}
          </Typography>
          {!!collapseConfig && !!collapseConfig.allowChange && (
            <Button
              variant="text"
              onClick={() => toggleCollapsed(!collapsed)}
              sx={theme => ({
                ...theme.typography.tiny,
                color: theme.palette.text.primary,
                lineHeight: '18px',
                height: '18px',
                whiteSpace: 'nowrap',
                ml: 0.5,
                flexShrink: 0,
                minWidth: 0,
                padding: 0,
                pl: 0.5,
                pr: 0.5,
                display: 'inline',
                textTransform: 'lowercase',
              })}
            >
              {!!collapsed ? collapseConfig.expandCta : collapseConfig.collapseCta}
            </Button>
          )}
        </Box>
        {/* Content box */}
        {!collapsed && (
          <Box
            sx={{
              flexGrow: 10,
              flexShrink: 10,
              borderRadius: 1,
              flexBasis: 'auto',
              flexDirection: 'column',
            }}
          >
            {!!metaDataMessage && (
              <Box
                sx={{
                  flexGrow: 10,
                  flexShrink: 1000,
                  borderRadius: 1,
                  flexBasis: 'auto',
                  flexDirection: 'column',
                  mt: 1,
                  mb: 1,
                }}
              >
                {metaDataMessage}
              </Box>
            )}
            {!!message && (
              <Box
                sx={{
                  flexGrow: 10,
                  flexShrink: 1000,
                  borderRadius: 1,
                  flexBasis: 'auto',
                  backgroundColor: theme => theme.palette.background.box,
                  padding: 2,
                  flexDirection: 'column',
                }}
              >
                {message}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}

ActivityEntry.propTypes = {
  sub: PropTypes.string,
  title: PropTypes.node,
  message: PropTypes.node,
  metaDataMessage: PropTypes.node,
  timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  name: PropTypes.string,
  collapseConfig: PropTypes.exact({
    collapseCta: PropTypes.string,
    allowChange: PropTypes.bool,
    expandCta: PropTypes.string,
    initialState: PropTypes.string,
  }),
};

export default ActivityEntry;
