import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { peopleSelectors, peopleActions } from 'state/ducks/people';
import { TgMentionAtomPopupComponent } from './TgMentionAtomPopupComponent.tsx';

const styles = theme => ({
  popupWrapper: {
    ...theme.shape,
    backgroundColor: 'white',
    boxShadow: theme.shadows[4],
    padding: theme.spacing(),
  },
});

class TgMentionComponent extends React.Component {
  state = {
    suggestions: [],
    mentionState: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { people } = nextProps;
    const results = [];

    const prevQuery = get(prevState, 'mentionState.query.full', '');
    if (prevQuery.length > 1) {
      for (const sub in people.subs) {
        if (Object.hasOwnProperty.call(people.subs, sub)) {
          if (
            people.subs[sub].fullName &&
            people.subs[sub].fullName.toLowerCase().includes(prevQuery.toLowerCase())
          ) {
            const person = JSON.parse(JSON.stringify(people.subs[sub])); // Clone object
            results.push({
              sub: person.sub,
              name: person.fullName,
              id: person.sub,
              label: person.fullName,
            });
          }
        }
      }
    }
    return { suggestions: results };
  }

  componentDidUpdate(prevProps, prevState) {
    const prevQuery = get(prevState, 'mentionState.query.full', '');
    const curQuery = get(this.state, 'mentionState.query.full', '');
    if (prevQuery !== curQuery && curQuery.length >= 2) {
      this.getPeople(curQuery);
    }
  }

  setMentionState = mentionState => {
    this.setState({ mentionState });
  };

  getPeople = searchString => {
    if (searchString.length > 1) {
      this.props.dispatch(peopleActions.searchPeople({ searchstring: searchString }));
    }
  };

  render() {
    const { classes } = this.props;
    const { suggestions } = this.state;

    return (
      <TgMentionAtomPopupComponent
        onChange={this.setMentionState}
        items={suggestions}
        classes={classes}
      />
    );
  }
}

const mapStateToProps = state => ({
  people: state.main.people,
  recentPeople: peopleSelectors.selectRecentUsers(state.main.people),
  selectFullName: sub => peopleSelectors.selectFullName(state.main.people, sub),
});

TgMentionComponent.propTypes = {
  dispatch: PropTypes.func,
  classes: PropTypes.exact({
    popupWrapper: PropTypes.string,
  }),
  people: PropTypes.shape({
    subs: PropTypes.object,
  }),
};

export default withStyles(styles)(connect(mapStateToProps)(TgMentionComponent));
