import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import withStyles from '@mui/styles/withStyles';
import { withLocation, withNavigation } from 'withRouter';
import Fab from 'Components/Common/Fab';
import ContainedIconButton from 'Components/Library/ContainedIconButton';
import { openAddInterlockPanel } from 'config/ModalProvider/helpers';
import { NODE_TYPE_TEAM } from 'config/constants';

const styles = theme => ({
  fabIcon: {
    color: theme.palette.secondary.contrastText,
  },
});

function AddInterlockButton(props) {
  const { t } = useTranslation();
  const { variant, classes, navigate, location, teamId } = props;

  const ButtonElement = variant === 'fab' ? Fab : ContainedIconButton;

  const buttonProps = variant === 'contained' ? { size: 'small' } : {};
  return (
    <ButtonElement
      {...buttonProps}
      name="add-interlock-fab"
      color="secondary"
      onClick={() => openAddInterlockPanel(navigate, location, NODE_TYPE_TEAM, teamId)}
      aria-label={t('general.add')}
    >
      <AddIcon className={classes.fabIcon} />
    </ButtonElement>
  );
}

AddInterlockButton.propTypes = {
  classes: PropTypes.exact({
    fabIcon: PropTypes.string,
  }),
  location: PropTypes.object,
  navigate: PropTypes.func,
  teamId: PropTypes.string,
  variant: PropTypes.string,
};
AddInterlockButton.defaultProps = {
  variant: 'fab',
};

export default withLocation(
  withNavigation(withStyles(styles, { withTheme: true })(AddInterlockButton)),
);
