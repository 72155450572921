import { PURGE } from 'redux-persist';
import { get } from 'lodash';
import { validatePersistedState, copyState, enrich } from 'state/helpers';
import { CONTRIBUTOR_TYPES } from 'config/constants';
import {
  onSingleFetchSuccess,
  onFetchStarted,
  onListFetchSuccess,
  onFetchFailed,
  onDeleteSuccess,
  addApiErrorToState,
} from 'state/defaultLogic';
import * as types from './types';
import * as selectors from './selectors';
import * as constants from '../../constants/api';

export const initialState = {
  VERSION: 1.05,
  commitments: {},
  contributors: {},
  userCommitments: {},
  teamCommitments: {},
  userCompletedCommitments: {},
  teamCompletedCommitments: {},
  actionlog: {},
};

function addUpdatedCommitmentToState(state, payload) {
  const newState = copyState(state);
  newState.actionlog[payload.requestID] = { result: 'ok' };

  const { status, id } = payload;

  const oldStatus = newState.commitments[id]?.data?.status;

  newState.commitments[id] = enrich({
    fetchStatus: constants.OK,
    lastFetched: Date.now(),
    data: {
      ...payload,
      description: JSON.parse(payload.description),
    },
  });
  if (oldStatus !== status && (oldStatus === 'COMPLETED' || status === 'COMPLETED')) {
    // This commitment should be moved in the state objects from the "open" commitments
    // list to the "completed" commitments list for all contributors
    const contributors = selectors.selectContributors(newState, id);
    if (contributors.ok) {
      for (const node of contributors.data) {
        const keyPrefix = node.type === CONTRIBUTOR_TYPES.TEAM ? 'team' : 'user';
        let removeFrom;
        let addTo;
        if (oldStatus === 'COMPLETED') {
          removeFrom = get(newState, `${keyPrefix}CompletedCommitments.${node.id}`, {});
          addTo = get(newState, `${keyPrefix}Commitments.${node.id}`, {});
        } else {
          removeFrom = get(newState, `${keyPrefix}Commitments.${node.id}`, {});
          addTo = get(newState, `${keyPrefix}CompletedCommitments.${node.id}`, {});
        }
        if (removeFrom.ok) {
          removeFrom.data = removeFrom.data.filter(cid => cid !== id);
        }
        if (addTo.ok && !addTo.data.includes(id)) {
          addTo.data.unshift(id);
        }
      }
    }
  }

  return newState;
}

const contributorsQueryCompletedTransformFunc = (state, payload) => {
  // function run on the state after receiving the contributors for a commitment,
  // adds the commitment ID to the commitment lists of the received contributors

  const commitmentData = selectors.selectCommitment(state, payload.id);
  if (commitmentData.ok) {
    // Add this ID to the lists for received contributors
    const keySuffix =
      commitmentData.data.status === 'COMPLETED' ? 'CompletedCommitments' : 'Commitments';
    for (const node of payload.nodes) {
      const keyPrefix = node.type === CONTRIBUTOR_TYPES.TEAM ? 'team' : 'user';
      const key = keyPrefix + keySuffix;

      const domainData = get(state, `${key}.${node.id}`, {});
      if (domainData.ok && !domainData.data.includes(payload.id)) {
        state[key][node.id].data.unshift(payload.id);
        state[key][node.id] = enrich(state[key][node.id]);
      }
    }
  }
};

const addCommitmentTransformFunc = (state, payload) => {
  // function run on the state after adding a commitment,
  // adds to commitment ID to the lists for the creator
  // and owner if provided in the payload

  // it's somewhat questionable if this is required, opening
  // the freshly created commitment will trigger a fetch for
  // the contributors that will also handle the same.
  for (const userId of [payload.creator, payload.owner]) {
    if (userId) {
      if (!state.userCommitments[userId]?.data?.length) {
        state.userCommitments[userId] = enrich({
          // this makes sure that the logics know what to do when
          // they encounter this object
          fetchStatus: constants.PARTIAL,
          data: [],
        });
      }

      // adding fresh commitment id on top of already existing ones
      const userHasCommitment = state.userCommitments[userId]?.data?.includes(payload.id);
      if (!!state.userCommitments[userId]?.data && !userHasCommitment) {
        state.userCommitments[userId]?.data.unshift(payload.id);
        state.userCommitments[userId] = enrich(state.userCommitments[userId]);
      }
    }
  }
};

const queryCompletedTransformFunc = (state, payload) => {
  // function run on the state after a list of commitments,
  // adds all the commitments to the "commitments" objects,
  // the lists under the contributors contain just IDs

  payload.nodes.forEach(node =>
    onSingleFetchSuccess({
      state,
      payload: node,
      key: 'commitments',
      transformItemFn: data => ({
        ...data,
        ts: data.timestamp,
        description: JSON.parse(data.description),
      }),
      copyState: false,
      postProcessTransform: addCommitmentTransformFunc,
    }),
  );
};

// The params need to be in this order, that's what redux gives :)
// eslint-disable-next-line default-param-last
const reducer = (state = JSON.parse(JSON.stringify(initialState)), action) => {
  state = validatePersistedState(state, initialState);

  switch (action.type) {
    case types.ADDED_COMMITMENT:
      return onSingleFetchSuccess({
        state,
        payload: action.payload,
        key: 'commitments',
        transformItemFn: data => ({
          ...data,
          ts: data.timestamp,
          description: JSON.parse(data.description),
        }),
        postProcessTransform: (s, payload) => {
          addCommitmentTransformFunc(s, payload);

          if ('requestID' in payload) {
            s.actionlog[payload.requestID] = { result: 'ok', data: { ...payload } };
          }
        },
      });
    case types.FETCH_USER_COMMITMENTS:
      return onFetchStarted({ state, payload: action.payload, key: 'userCommitments' });
    case types.FETCH_COMPLETED_USER_COMMITMENTS:
      return onFetchStarted({ state, payload: action.payload, key: 'userCompletedCommitments' });
    case types.FETCH_TEAM_COMMITMENTS:
      return onFetchStarted({ state, payload: action.payload, key: 'teamCommitments' });
    case types.FETCH_COMPLETED_TEAM_COMMITMENTS:
      return onFetchStarted({ state, payload: action.payload, key: 'teamCompletedCommitments' });
    case types.FETCH_COMMITMENT:
      return onFetchStarted({ state, payload: action.payload, key: 'commitments' });
    case types.FETCH_CONTRIBUTORS:
      return onFetchStarted({ state, payload: action.payload, key: 'contributors' });
    case types.RECEIVED_USER_COMMITMENTS:
      return onListFetchSuccess({
        state,
        payload: action.payload,
        key: 'userCommitments',
        mapFn: node => node.id,
        postProcessTransform: queryCompletedTransformFunc,
      });
    case types.RECEIVED_COMPLETED_USER_COMMITMENTS:
      return onListFetchSuccess({
        state,
        payload: action.payload,
        key: 'userCompletedCommitments',
        mapFn: node => node.id,
        postProcessTransform: queryCompletedTransformFunc,
      });
    case types.RECEIVED_TEAM_COMMITMENTS:
      return onListFetchSuccess({
        state,
        payload: action.payload,
        key: 'teamCommitments',
        mapFn: node => node.id,
        postProcessTransform: queryCompletedTransformFunc,
      });
    case types.RECEIVED_COMPLETED_TEAM_COMMITMENTS:
      return onListFetchSuccess({
        state,
        payload: action.payload,
        key: 'teamCompletedCommitments',
        mapFn: node => node.id,
        postProcessTransform: queryCompletedTransformFunc,
      });
    case types.RECEIVED_COMMITMENT:
      return onSingleFetchSuccess({
        state,
        payload: action.payload,
        key: 'commitments',
        transformItemFn: data => ({
          ...data,
          ts: data.timestamp,
          description: JSON.parse(data.description),
        }),
        postProcessTransform: addCommitmentTransformFunc,
      });
    case types.RECEIVED_CONTRIBUTORS:
      return onListFetchSuccess({
        state,
        payload: action.payload,
        key: 'contributors',
        postProcessTransform: contributorsQueryCompletedTransformFunc,
      });
    case types.CONTRIBUTORS_UPDATED:
      return onListFetchSuccess({
        state,
        payload: action.payload,
        key: 'contributors',
        postProcessTransform: contributorsQueryCompletedTransformFunc,
      });
    case types.COMMITMENT_UPDATED:
      return addUpdatedCommitmentToState(state, action.payload);
    case types.COMMITMENT_DELETED:
      return onDeleteSuccess({ state, payload: action.payload, key: 'commitments' });
    case types.FAILED_USER_COMMITMENTS:
      return onFetchFailed({ state, payload: action.payload, key: 'userCommitments' });
    case types.FAILED_COMPLETED_USER_COMMITMENTS:
      return onFetchFailed({ state, payload: action.payload, key: 'userCompletedCommitments' });
    case types.FAILED_COMPLETED_TEAM_COMMITMENTS:
      return onFetchFailed({ state, payload: action.payload, key: 'teamCompletedCommitments' });
    case types.FAILED_CONTRIBUTORS:
      return onFetchFailed({ state, payload: action.payload, key: 'contributors' });
    case types.FAILED_TEAM_COMMITMENTS:
      return onFetchFailed({ state, payload: action.payload, key: 'teamCommitments' });
    case types.FAILED_COMMITMENT:
      return onFetchFailed({ state, payload: action.payload, key: 'commitments' });
    case types.ERROR_RECEIVED_FROM_API:
      return addApiErrorToState(state, action.payload);
    case PURGE:
      return JSON.parse(JSON.stringify(initialState));
    default:
      return state;
  }
};

export default reducer;
