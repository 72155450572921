import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import CollapseIcon from '@mui/icons-material/UnfoldLess';
import ExpandIcon from '@mui/icons-material/UnfoldMore';
import TeamChip from 'Components/Library/TeamChip';
import UserChip from 'Components/Library/UserChip';
import { peopleSelectors, peopleActions } from 'state/ducks/people';

function TeamWidget(props) {
  const { teamId, startCollapsed } = props;
  const [collapsed, toggleCollapsed] = useState(startCollapsed);

  const teamMembers = useSelector(
    state => peopleSelectors.selectTeamMembers(state.main.people, teamId),
    // { stabilityCheck: 'never' },
  );
  const teamManagerSub = useSelector(state =>
    peopleSelectors.selectTeamManager(state.main.people, teamId),
  );

  const memberList = [
    teamManagerSub,
    ...get(teamMembers, 'members', []).filter(sub => sub !== teamManagerSub),
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(peopleActions.getTeam({ teamId }));
  }, [dispatch]);

  const onKeyDown = e => {
    if (['Enter', 'ArrowDown', 'ArrowUp'].includes(e.key)) {
      toggleCollapsed(!collapsed);
      return true;
    }

    return false;
  };

  return (
    <Box
      sx={{
        backgroundColor: theme => theme.palette.background.box,
        borderRadius: 1,
        '&:hover': {
          cursor: 'pointer',
        },
        '&:hover .team-widget-toggle-icon': {
          backgroundColor: 'action.hover',
        },
        '&:focus .team-widget-toggle-icon': {
          backgroundColor: 'action.hover',
        },
      }}
      tabIndex={0}
      onClick={() => toggleCollapsed(!collapsed)}
      onKeyDown={onKeyDown}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <TeamChip color="transparent" teamId={teamId} />
        <Box
          sx={{
            mr: 1,
            borderRadius: 0.5,
            width: 24,
            height: 24,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          className="team-widget-toggle-icon"
        >
          {collapsed ? (
            <ExpandIcon fontSize="small" color="action" />
          ) : (
            <CollapseIcon fontSize="small" color="action" />
          )}
        </Box>
      </Box>
      {teamMembers.ok && memberList.length > 0 && !collapsed && (
        <Box
          sx={{
            p: 1,
            pb: 1,
            '& > div:not(:last-child)': {
              mb: 1,
            },
          }}
        >
          {memberList.map(sub => (
            <UserChip key={sub} sub={sub} color="white" variant="normal" />
          ))}
        </Box>
      )}
    </Box>
  );
}

TeamWidget.propTypes = {
  teamId: PropTypes.string,
  startCollapsed: PropTypes.bool,
};

export default TeamWidget;
