import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import { withNavigation, withLocation } from 'withRouter';
import { openAddCommitmentPanel } from 'config/ModalProvider/helpers';
import { NODE_TYPE_KR, NODE_TYPE_TEAM } from 'config/constants';

import TgProgress from 'Components/Common/TgProgress';
import StandardContentDialog from 'Components/Library/StandardContentDialog';
import TaskItemDialogContent from 'Components/Library/TaskItemDialogContent';
import DataObjectChip from 'Components/Library/DataObjectChip';
import DialogConfirmDeleteKr from 'Components/Features/Objectives/DialogConfirmDeleteKr';
import NodeRelations from 'Components/Library/NodeRelations';
import NodeRelationsNew from 'Components/Library/NodeRelations/new';

import { isEnabled } from 'config/flags';
import RelationshipIcon from 'Components/Library/NodeRelations/RelationshipIcon';
import { graphActions } from 'state/ducks/graph/index';
import CommitmentsIcon from 'Components/Features/Commitments/CommitmentsIcon';
import KeyresultHeadline from './KeyresultHeadline';
import KeyresultDetails from './KeyresultDetails';
import KeyresultEventList from './KeyresultEventList';
import KeyresultsIcon from '../KeyresultsIcon';
import KeyresultTodos from './KeyresultTodos';

class PanelViewKeyresult extends React.Component {
  state = {
    deleteDialogOpen: false,
  };

  toggleDeleteDialog = deleteOpen => {
    this.setState({ deleteDialogOpen: deleteOpen });
  };

  getCommitmentDomainsList = graphId => {
    const { objectiveData } = this.props;
    const domains = [];
    if (objectiveData?.data?.type === 'team') {
      domains.push({ type: NODE_TYPE_TEAM, id: objectiveData.data.teamID });
    }
    domains.push({ type: NODE_TYPE_KR, id: graphId });
    return domains;
  };

  renderContent() {
    const { t, objectiveData, keyresult, canEdit, navigate, location, dispatch } = this.props;
    if (!keyresult) {
      return <TgProgress color="secondary" size={80} center />;
    }
    const graphId = `${objectiveData.data.graph_id}-${keyresult.graph_id}`;
    return (
      <TaskItemDialogContent
        headlineComponent={
          <KeyresultHeadline
            objectiveData={objectiveData}
            keyresultData={keyresult}
            canEdit={canEdit}
          />
        }
        detailsComponent={
          <KeyresultDetails
            objectiveData={objectiveData}
            keyresultData={keyresult}
            canEdit={canEdit}
          />
        }
        relationsComponent={
          isEnabled('COMMITMENTS') ? (
            isEnabled('RELATED-ITEMS') ? (
              <NodeRelationsNew
                label={
                  <DataObjectChip
                    Icon={RelationshipIcon}
                    text={t('relations.relationships')}
                    color="transparent"
                  />
                }
                graphId={graphId}
                onSelect={data => {
                  dispatch(
                    graphActions.editRelations({
                      changeset: [
                        {
                          action: 'ADD',
                          from: keyresult.graph_id,
                          to: data.node_id,
                          type: 'GENREL',
                        },
                      ],
                    }),
                  );
                }}
                canEdit={canEdit}
              />
            ) : (
              <NodeRelations
                label={
                  <DataObjectChip
                    Icon={CommitmentsIcon}
                    text={t('commitments.pageTitle')}
                    color="transparent"
                  />
                }
                graphId={graphId}
                cta={
                  canEdit ? (
                    <Button
                      variant="text"
                      color="secondary"
                      sx={{ alignSelf: 'flex-start' }}
                      onClick={() =>
                        openAddCommitmentPanel(
                          navigate,
                          location,
                          this.getCommitmentDomainsList(graphId),
                        )
                      }
                    >
                      {`+ ${t('commitments.addCommitmentCta')}`}
                    </Button>
                  ) : undefined
                }
                canEdit={canEdit}
              />
            )
          ) : (
            <KeyresultTodos objectiveID={objectiveData.data.objectiveID} keyresult={keyresult} />
          )
        }
        logComponent={<KeyresultEventList canEdit={canEdit} keyresult={keyresult} />}
      />
    );
  }

  render() {
    const { onClose, open, t, canEdit, keyresult, zIndexModifier = 0 } = this.props;
    const { deleteDialogOpen } = this.state;
    const menuItems = canEdit
      ? [
          <MenuItem
            id="kr-actions-menu-delete-option"
            onClick={() => this.toggleDeleteDialog(true)}
          >
            {t('general.delete')}
          </MenuItem>,
        ]
      : null;

    return (
      <>
        <StandardContentDialog
          size="large"
          open={open}
          onBackdropClick={onClose}
          onCloseClick={onClose}
          menuButtonProps={{
            id: 'kr-actions-menu',
          }}
          closeButtonProps={{
            id: 'panel-view-kr-close-btn',
          }}
          menuItems={menuItems}
          title={
            <DataObjectChip
              Icon={KeyresultsIcon}
              text={t('objectives.genericDataObjectNameKeyresult')}
              variant="normal"
            />
          }
          zIndexModifier={zIndexModifier}
        >
          {this.renderContent()}
        </StandardContentDialog>
        {deleteDialogOpen && (
          <DialogConfirmDeleteKr
            keyresultData={keyresult}
            open
            onCancel={() => this.toggleDeleteDialog(false)}
            onConfirm={() => {}}
          />
        )}
      </>
    );
  }
}

PanelViewKeyresult.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  keyresult: PropTypes.object,
  objectiveData: PropTypes.object,
  canEdit: PropTypes.bool,
  t: PropTypes.func,
  navigate: PropTypes.func,
  location: PropTypes.object,
  zIndexModifier: PropTypes.number,
  dispatch: PropTypes.func,
};

export default withNavigation(withLocation(withTranslation()(connect()(PanelViewKeyresult))));
