import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import Paper from '@mui/material/Paper';
import { isEqual } from 'lodash';
import { withParams } from 'withRouter';

import ObjectivesTableOverviewHeader from 'Components/Features/Objectives/ObjectivesTableOverviewHeader';

import { objectivesSelectors, objectivesActions } from 'state/ducks/objectives';
import { peopleActions } from 'state/ducks/people';

const styles = theme => ({
  domainwidget: {
    backgroundColor: theme.palette.background.box,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    height: '100%',
    overflowY: 'auto',
  },
});

const fetchData = (props, force = false) => {
  /*
      Fetch the OKRs for the requested domains
      called with force = True on mount to force the fetching on page refresh
  */
  if (props.params && props.selectedPeriod) {
    const { teamID, sub } = props.params;
    if (!!teamID && !!props.selectedPeriod) {
      props.dispatch(
        objectivesActions.getTeamObjectives({
          teamId: teamID,
          stperiod: props.selectedPeriod,
          force,
        }),
      );
    } else if (!!sub) {
      props.dispatch(peopleActions.getPersons({ subs: [sub] }));
      props.dispatch(
        objectivesActions.getRelatedObjectives({
          sub,
          stperiod: props.selectedPeriod,
          force,
        }),
      );
    }
  }
};

class TabObjectivesFetcher extends Component {
  state = {
    objectives: null,
    hash: null,
  };

  componentDidMount() {
    fetchData(this.props, true);
    this.refreshtimer = setInterval(() => {
      fetchData(this.props);
    }, 1000 * 25);
  }

  componentDidUpdate() {
    fetchData(this.props);
  }

  componentWillUnmount() {
    clearInterval(this.refreshtimer);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let okrs;
    if (nextProps.params) {
      const { teamID, sub } = nextProps.params;
      if (!!teamID) {
        okrs = nextProps.selectTeamObjectives(teamID);
      } else if (!!sub) {
        okrs = nextProps.selectPersonObjectives(sub);
      } else {
        return null;
      }
      if (
        (!!okrs && !!okrs.ok) ||
        !prevState.objectives ||
        !isEqual(okrs.data, prevState.objectives.data)
      ) {
        return {
          objectives: okrs,
          hash: okrs.hash || 'nohash',
        };
      }
    }
    return null;
  }

  render() {
    const { selectedPeriod, classes } = this.props;
    const { objectives, hash } = this.state;
    const { teamID, sub } = this.props.params;
    let domain = {};

    if (!!teamID) {
      domain = { t: 'team', d: teamID };
    } else if (!!sub) {
      domain = { t: 'personal', d: sub };
    } else {
      return null;
    }

    if (!objectives.ok) {
      return null;
    }

    return (
      <Paper className={classes.domainwidget}>
        <ObjectivesTableOverviewHeader
          hash={hash}
          domain={domain}
          objectiveIDs={objectives}
          period={selectedPeriod}
          open
          showControls={false}
          alwaysOpen
        />
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  const selectedPeriod = objectivesSelectors.selectCurrentPeriod(state.main.objectives);

  return {
    selectedPeriod,
    selectTeamObjectives: teamID =>
      objectivesSelectors.selectTeamObjectives(state.main.objectives, teamID, selectedPeriod),
    selectPersonObjectives: sub =>
      objectivesSelectors.selectRelatedObjectives(state.main.objectives, sub, selectedPeriod),
  };
};

TabObjectivesFetcher.propTypes = {
  params: PropTypes.object,
  selectedPeriod: PropTypes.string,
  selectTeamObjectives: PropTypes.func,
  selectPersonObjectives: PropTypes.func,
  classes: PropTypes.exact({
    domainwidget: PropTypes.string,
  }),
};

export default withParams(withStyles(styles)(connect(mapStateToProps)(TabObjectivesFetcher)));
