import { validatePersistedState, copyState, apiError, enrich, fetching } from 'state/helpers';
import * as types from './types';

import * as constants from '../../constants/api';

export const initialState = {
  VERSION: 1.02,
};

function addFetchedAppStatusToState(state, payload) {
  const newState = copyState(state);

  newState.status = enrich({
    fetchStatus: constants.OK,
    lastFetched: Date.now(),
    maxAge: 1000 * 60 * 10, // 10 minutes
    data: { ...payload },
  });
  return newState;
}

function addFetchingAppStatusToState(state) {
  const newState = copyState(state);

  newState.status = fetching(newState.status);

  return newState;
}

function addFailedAppStatusToState(state) {
  const newState = copyState(state);
  newState.status = apiError(newState.status);
  return newState;
}

// eslint-disable-next-line default-param-last
export default (state = JSON.parse(JSON.stringify(initialState)), action) => {
  state = validatePersistedState(state, initialState);

  switch (action.type) {
    case types.GET_APP_STATUS:
      return addFetchingAppStatusToState(state, action.payload);
    case types.FETCHED_APP_STATUS:
      return addFetchedAppStatusToState(state, action.payload);
    case types.FAILED_APP_STATUS:
      return addFailedAppStatusToState(state, action.payload);
    default:
      return state;
  }
};
