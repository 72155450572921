const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    color: theme.palette.text.secondary,
  },
  content: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    '& > div:first-of-type': {
      marginTop: theme.spacing(-2),
      marginLeft: theme.spacing(-2),
      marginBottom: theme.spacing(2),
    },
  },
  leftColumn: {
    width: 100,
    flexShrink: 0,
    color: theme.palette.text.secondary,
  },
  rightColumn: {
    minWidth: 0, // Fix text ellipsis in flexbox
  },
  chipLabel: {
    paddingTop: 3,
    marginBottom: theme.spacing(1),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(),
    '&:last-child': {
      marginBottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  ownerRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
});

export default styles;
