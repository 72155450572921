import * as types from './types';

/* OKRS */
export const getCompanyObjectives = payload => ({
  type: types.GET_COMPANY_OBJECTIVES,
  payload,
});

export const companyObjectivesReceived = payload => ({
  type: types.RECEIVED_COMPANY_OBJECTIVES,
  payload,
});

export const companyObjectivesFailed = payload => ({
  type: types.FAILED_COMPANY_OBJECTIVES,
  payload,
});

export const createCompanyObjective = payload => ({
  type: types.CREATE_COMPANY_OBJECTIVE,
  payload,
});

export const objectiveCreated = payload => ({
  type: types.OBJECTIVE_CREATED,
  payload,
});

export const getTeamObjectives = payload => ({
  type: types.GET_TEAM_OBJECTIVES,
  payload,
});

export const teamObjectivesReceived = payload => ({
  type: types.RECEIVED_TEAM_OBJECTIVES,
  payload,
});

export const teamObjectivesFailed = payload => ({
  type: types.FAILED_TEAM_OBJECTIVES,
  payload,
});

export const createTeamObjective = payload => ({
  type: types.CREATE_TEAM_OBJECTIVE,
  payload,
});

export const getRelatedObjectives = payload => ({
  type: types.GET_RELATED_OBJECTIVES,
  payload,
});

export const relatedObjectivesReceived = payload => ({
  type: types.RECEIVED_RELATED_OBJECTIVES,
  payload,
});

export const relatedObjectivesFailed = payload => ({
  type: types.FAILED_RELATED_OBJECTIVES,
  payload,
});

export const createPersonObjective = payload => ({
  type: types.CREATE_PERSON_OBJECTIVE,
  payload,
});

export const deleteObjective = payload => ({
  type: types.DELETE_OBJECTIVE,
  payload,
});

export const objectiveDeleted = payload => ({
  type: types.OBJECTIVE_DELETED,
  payload,
});

export const getKeyresults = payload => ({
  type: types.GET_KEYRESULTS,
  payload,
});

export const keyresultsReceived = payload => ({
  type: types.RECEIVED_KEYRESULTS,
  payload,
});

export const createKeyresult = payload => ({
  type: types.CREATE_KEYRESULT,
  payload,
});

export const keyresultCreated = payload => ({
  type: types.KEYRESULT_CREATED,
  payload,
});

export const keyresultUpdated = payload => ({
  type: types.KEYRESULT_UPDATED,
  payload,
});

export const updateKeyresult = payload => ({
  type: types.UPDATE_KEYRESULT,
  payload,
});

export const updateKeyresultTodos = payload => ({
  type: types.UPDATE_KEYRESULT_TODOS,
  payload,
});

export const editKeyresult = payload => ({
  type: types.EDIT_KEYRESULT,
  payload,
});

export const deleteKeyresult = payload => ({
  type: types.DELETE_KEYRESULT,
  payload,
});

export const keyresultDeleted = payload => ({
  type: types.KEYRESULT_DELETED,
  payload,
});

export const getHierarchy = payload => ({
  type: types.GET_HIERARCHY,
  payload,
});

export const hierarchyReceived = payload => ({
  type: types.RECEIVED_HIERARCHY,
  payload,
});

export const hierarchyFetchFailed = payload => ({
  type: types.OBJECTIVE_HIERARCHY_FETCH_FAILED,
  payload,
});

export const getObjectiveLinks = payload => ({
  type: types.GET_OBJECTIVE_LINKS,
  payload,
});

export const objectiveLinksReceived = payload => ({
  type: types.RECEIVED_OBJECTIVE_LINKS,
  payload,
});

export const objectiveLinksFailed = payload => ({
  type: types.FETCH_LINKS_FAILED,
  payload,
});

export const updateObjective = payload => ({
  type: types.UPDATE_OBJECTIVE,
  payload,
});

export const objectiveUpdated = payload => ({
  type: types.OBJECTIVE_UPDATED,
  payload,
});

export const gradeObjective = payload => ({
  type: types.GRADE_OBJECTIVE,
  payload,
});

export const objectiveGraded = payload => ({
  type: types.GRADED_OBJECTIVE,
  payload,
});

export const getObjective = payload => ({
  type: types.GET_OBJECTIVE,
  payload,
});

export const objectiveReceived = payload => ({
  type: types.OBJECTIVE_FETCHED,
  payload,
});

export const objectiveFetchFailed = payload => ({
  type: types.OBJECTIVE_GET_FAILED,
  payload,
});

export const getPeriods = payload => ({
  type: types.GET_PERIODS,
  payload,
});

export const periodsReceived = payload => ({
  type: types.PERIODS_FETCHED,
  payload,
});

export const periodFetchFailed = payload => ({
  type: types.PERIODS_FETCH_FAILED,
  payload,
});

export const updateObjectiveParent = payload => ({
  type: types.UPDATE_OBJECTIVE_PARENT,
  payload,
});

export const objectiveParentUpdated = payload => ({
  type: types.OBJECTIVE_PARENT_UPDATED,
  payload,
});

export const copyObjectiveToPeriod = payload => ({
  type: types.COPY_TO_PERIOD,
  payload,
});

export const objectiveCopiedToPeriod = payload => ({
  type: types.COPIED_TO_PERIOD,
  payload,
});

export const errorTryAgainLater = payload => ({
  type: types.ERROR_RECEIVED_FROM_API,
  payload,
});
