import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { debounce } from 'config/helpers';
import { withNavigation } from 'withRouter';

import { getNodeModalPath } from 'Components/Library/GraphElementResolver/helpers';
import { peopleSelectors, peopleActions } from 'state/ducks/people';
import GlobalSearch from './GlobalSearch';
import {
  fetchActualSearchResults,
  generateTeamNavLink,
  getPeopleSuggestions,
  getRecentTeams,
  getTeamSuggestions,
} from './utils';

const debouncedSearch = debounce(fetchActualSearchResults, 200);

export const prepareResults = ({ nextProps, searchString, suggestions }) => {
  let results = suggestions?.filter(s => !isEmpty(s));
  let teamSuggestions = [];
  let peopleSuggestions = [];
  const { teamNames, recentTeams, searchFor } = nextProps;

  if (searchString.length === 0) {
    const recent = getRecentTeams(searchFor, recentTeams, teamNames);

    return {
      multiSection: true,
      suggestions: [
        {
          title: 'recent',
          suggestions: recent,
        },
      ],
    };
  }
  // Compute matches
  // This is done in getDerivedStateFromProps, as some of the data being searched
  // is always available (all teams) - while other parts of the data require fetching
  // and thus props to update

  // Search for teams:
  if (searchString.length > 1) {
    teamSuggestions = getTeamSuggestions(searchString, nextProps);

    const hasTeams = results.some(result => result.title === 'teams');
    if (hasTeams) {
      results = results.filter(result => result.title !== 'teams');
    }
    results.push({
      title: 'teams',
      suggestions: teamSuggestions,
    });

    peopleSuggestions = getPeopleSuggestions(searchString, nextProps);
    const hasPeople = results.some(result => result.title === 'people');
    if (hasPeople) {
      results = results.filter(result => result.title !== 'people');
    }
    results.push({
      title: 'people',
      suggestions: peopleSuggestions,
    });
  }

  // remove "recent" section if something found
  const indexOfRecentEntry = results.findIndex(resultItem => resultItem.title === 'recent');
  if (results.length > 1 && indexOfRecentEntry > -1) {
    results.splice(indexOfRecentEntry, 1);
  }

  return { suggestions: results, multiSection: true };
};

class GlobalSearchOverlay extends Component {
  state = {
    searchString: '',
    suggestions: [],
    isLoading: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return prepareResults({ nextProps, ...prevState });
  }

  search = (searchString = '') => {
    this.setState({ searchString });
    if (searchString.length > 1) {
      const { auth, searchFor } = this.props;

      // if not already fetching and input field is filled in
      if (!!searchString.length) {
        this.setState({ isLoading: true });

        if (
          searchFor.includes('ALL') &&
          !searchFor.includes('teams') &&
          !searchFor.includes('people')
        ) {
          // Dispatch queries for data
          this.props.dispatch(peopleActions.searchPeople({ searchstring: searchString }));
        }
        debouncedSearch({
          searchString,
          auth,
          setSuggestions: searchResults => {
            this.setState({ suggestions: searchResults, isLoading: false });
          },
        });
      }
    }
  };

  onSelect = suggestion => {
    const { onSelect, navigationPrevented, dispatch, navigate, auth } = this.props;

    if (onSelect) {
      onSelect(suggestion);
    }
    if (!navigationPrevented) {
      if (suggestion?.type === 'team') {
        dispatch(peopleActions.addRecentTeam({ teamId: suggestion.id }));
        navigate(generateTeamNavLink(window.location, suggestion.id), { replace: true });
      } else if (suggestion?.type === 'USER') {
        navigate(getNodeModalPath(`USER_${suggestion.id}`));
      } else if (suggestion?.document_id) {
        const preparedGraphId = suggestion?.document_id.replace(`${auth.tenantID}_`, '');
        navigate(getNodeModalPath(preparedGraphId));
      }
    }
  };

  render() {
    const { id, renderButton, name, label, auth, searchDialogOpened, navigationPrevented } =
      this.props;
    const { suggestions, isLoading } = this.state;

    if (!auth) {
      return null;
    }
    return (
      <GlobalSearch
        suggestions={suggestions}
        onSelect={this.onSelect}
        name={name}
        onFetchRequested={this.search}
        id={id}
        label={label}
        renderButton={renderButton}
        fetching={isLoading}
        searchDialogOpened={searchDialogOpened}
        navigationPrevented={navigationPrevented}
      />
    );
  }
}

GlobalSearchOverlay.propTypes = {
  // leaving .shape here instead of .exact as the number of properties
  // in auth is enormous
  auth: PropTypes.shape({
    cognitoSubDomain: PropTypes.string,
    idpName: PropTypes.string,
    loginType: PropTypes.string,
    clientId: PropTypes.string,
    tenantID: PropTypes.string,
    tokens: PropTypes.object,
    VERSION: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  renderButton: PropTypes.func,
  dispatch: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  recentTeams: PropTypes.array,
  // eslint-disable-next-line react/no-unused-prop-types
  managedTeams: PropTypes.array,
  // eslint-disable-next-line react/no-unused-prop-types
  teamNames: PropTypes.object,
  // eslint-disable-next-line react/no-unused-prop-types
  selectFullName: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  selectTeamsManagedBy: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  searchFor: PropTypes.array,
  navigate: PropTypes.func,
  searchDialogOpened: PropTypes.bool,
  onSelect: PropTypes.func,
  navigationPrevented: PropTypes.bool,
};

GlobalSearchOverlay.defaultProps = {
  searchFor: ['teams', 'teamsledby', 'people'],
  navigationPrevented: false,
};

const mapStateToProps = state => ({
  people: state.main.people,
  recentTeams: peopleSelectors.selectRecentTeams(state.main.people),
  teamNames: peopleSelectors.selectTeamNames(state.main.people),
  managedTeams: peopleSelectors.selectManagedTeams(
    state.main.people,
    state.auth.myTeams,
    state.auth.userID,
  ),
  selectTeamsManagedBy: userId => peopleSelectors.selectTeamsManagedBy(state.main.people, userId),
  selectFullName: sub => peopleSelectors.selectFullName(state.main.people, sub),
  auth: state.auth,
});

export default withNavigation(connect(mapStateToProps)(GlobalSearchOverlay));
