import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

import EmptyState from 'Components/Common/EmptyState';
import { objectivesSelectors } from 'state/ducks/objectives';
import EmphasizedSection from 'Components/Library/EmphasizedSection';
import ObjectivesTable from './ObjectivesTable';
import ObjectivesTableHeader from './ObjectivesTableHeader';
import ObjectivesEmptyStateIcon from './empty_state-icon-objectives.svg';
import PeriodNavigator from './PeriodNavigator';

const styles = theme => ({
  root: {
    position: 'relative',
    overflow: 'clip', // prevent table cell bg color on top of box shadow
  },
  tableContainer: {
    [theme.breakpoints.down('lg')]: {
      overflowX: 'auto',
    },
  },
  top: {
    marginBottom: theme.spacing(2),
  },
});

class ObjectivesTableNoHeader extends React.Component {
  render() {
    const {
      classes,
      domain,
      objectives,
      selectedPeriod,
      handlePeriodChange,
      prevShortTermPeriod,
      nextShortTermPeriod,
    } = this.props;
    let okrContent = <Skeleton variant="rounded" />;
    if (objectives && objectives.ok) {
      if (objectives.data.length > 0) {
        okrContent = (
          <EmphasizedSection>
            <Paper className={classes.root}>
              <div className={classes.tableContainer}>
                <Table sx={{ tableLayout: 'fixed' }}>
                  <ObjectivesTableHeader />
                  <ObjectivesTable
                    domain={domain}
                    objectiveIDs={objectives}
                    stperiod={selectedPeriod}
                  />
                </Table>
              </div>
            </Paper>
          </EmphasizedSection>
        );
      } else {
        okrContent = <EmptyState icon={ObjectivesEmptyStateIcon} translationgroup="objectives" />;
      }
    }
    return (
      <>
        <Grid container alignItems="center" className={classes.top}>
          <Grid item xs={12} sm={4}>
            <PeriodNavigator
              selectedPeriod={selectedPeriod}
              handlePeriodChange={handlePeriodChange}
              prevPeriod={prevShortTermPeriod}
              nextPeriod={nextShortTermPeriod}
            />
          </Grid>
        </Grid>
        {okrContent}
      </>
    );
  }
}

ObjectivesTableNoHeader.propTypes = {
  domain: PropTypes.object,
  classes: PropTypes.exact({
    root: PropTypes.string,
    tableContainer: PropTypes.string,
    top: PropTypes.string,
  }),
  selectedPeriod: PropTypes.string,
  ok: PropTypes.bool,
  handlePeriodChange: PropTypes.func,
  objectives: PropTypes.object,
  nextShortTermPeriod: PropTypes.string,
  prevShortTermPeriod: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => ({
  objectives: objectivesSelectors.selectTeamObjectives(
    state.main.objectives,
    ownProps.domain.d,
    ownProps.selectedPeriod,
  ),
  nextShortTermPeriod: objectivesSelectors.selectNextShortTermPeriod(
    state.main.objectives,
    ownProps.selectedPeriod,
  ),
  prevShortTermPeriod: objectivesSelectors.selectPrevShortTermPeriod(
    state.main.objectives,
    ownProps.selectedPeriod,
  ),
});

export default withStyles(styles)(connect(mapStateToProps)(ObjectivesTableNoHeader));
