import { createTheme } from '@mui/material';

function getContrastColor(darkmode, opacity, darkModeMultiplier = 1) {
  const value = darkmode ? '255' : '0';
  const multiplier = darkmode ? darkModeMultiplier : 1;
  return `rgba(${value}, ${value}, ${value}, ${parseFloat(opacity) * multiplier})`;
}

const getShadows = darkmode => {
  const hue1 = darkmode ? 'rgba(0, 11, 22, 0.06)' : 'rgba(0, 11, 22, 0.08)';
  const hue2 = darkmode ? 'rgba(0, 0, 0, 0.04)' : 'rgba(0, 11, 22, 0.06)';
  return [
    'none',
    `0px 2px 1px -1px ${hue1}, 0px 1px 1px ${hue2}, 0px 1px 3px rgba(0, 0, 0, 0.04)`,
    `0px 3px 1px -2px ${hue1}, 0px 2px 2px ${hue2}, 0px 1px 5px rgba(0, 0, 0, 0.04)`,
    `0px 3px 3px -2px ${hue1}, 0px 3px 4px ${hue2}, 0px 1px 8px rgba(0, 0, 0, 0.04)`,
    `0px 2px 4px -1px ${hue1}, 0px 4px 5px ${hue2}, 0px 1px 10px rgba(0, 0, 0, 0.04)`,
    `0px 3px 5px -1px ${hue1}, 0px 5px 8px ${hue2}, 0px 1px 14px rgba(0, 0, 0, 0.04)`, // 5
    `0px 3px 5px -1px ${hue1}, 0px 6px 10px ${hue2}, 0px 1px 18px rgba(0, 0, 0, 0.04)`,
    `0px 4px 5px -2px ${hue1}, 0px 7px 10px 1px ${hue2}, 0px 2px 16px 1px rgba(0, 0, 0, 0.04)`,
    `0px 5px 5px -3px ${hue1}, 0px 8px 10px 1px ${hue2}, 0px 3px 14px 2px rgba(0, 0, 0, 0.04)`,
    `0px 5px 6px -3px ${hue1}, 0px 9px 12px 1px ${hue2}, 0px 3px 16px 2px rgba(0, 0, 0, 0.04)`,
    `0px 6px 6px -3px ${hue1}, 0px 10px 14px 1px ${hue2}, 0px 4px 18px 3px rgba(0, 0, 0, 0.04)`, // 10
    `0px 6px 7px -4px ${hue1}, 0px 11px 15px 1px ${hue2}, 0px 4px 20px 3px rgba(0, 0, 0, 0.04)`,
    `0px 7px 8px -4px ${hue1}, 0px 12px 17px 2px ${hue2}, 0px 5px 22px 4px rgba(0, 0, 0, 0.04)`,
    `0px 7px 8px -4px ${hue1}, 0px 13px 19px 2px ${hue2}, 0px 5px 24px 4px rgba(0, 0, 0, 0.04)`,
    `0px 7px 9px -4px ${hue1}, 0px 14px 21px 2px ${hue2}, 0px 5px 26px 4px rgba(0, 0, 0, 0.04)`,
    `0px 8px 9px -5px ${hue1}, 0px 15px 22px 2px ${hue2}, 0px 6px 28px 5px rgba(0, 0, 0, 0.04)`, // 15
    `0px 8px 10px -5px ${hue1}, 0px 16px 24px 2px ${hue2}, 0px 6px 30px 5px rgba(0, 0, 0, 0.04)`,
    `0px 8px 11px -5px ${hue1}, 0px 17px 26px 2px ${hue2}, 0px 6px 32px 5px rgba(0, 0, 0, 0.04)`,
    `0px 9px 11px -5px ${hue1}, 0px 18px 28px 2px ${hue2}, 0px 7px 34px 6px rgba(0, 0, 0, 0.04)`,
    `0px 9px 12px -6px ${hue1}, 0px 19px 29px 2px ${hue2}, 0px 7px 36px 6px rgba(0, 0, 0, 0.04)`,
    `0px 10px 13px -6px ${hue1}, 0px 20px 31px 3px ${hue2}, 0px 8px 38px 7px rgba(0, 0, 0, 0.04)`, // 20
    `0px 10px 13px -6px ${hue1}, 0px 21px 33px 3px ${hue2}, 0px 8px 40px 7px rgba(0, 0, 0, 0.04)`,
    `0px 10px 14px -6px ${hue1}, 0px 22px 35px 3px ${hue2}, 0px 8px 42px 7px rgba(0, 0, 0, 0.04)`,
    `0px 11px 14px -7px ${hue1}, 0px 23px 36px 3px ${hue2}, 0px 9px 44px 8px rgba(0, 0, 0, 0.04)`,
    `0px 11px 15px -7px ${hue1}, 0px 24px 38px 3px ${hue2}, 0px 9px 46px 8px rgba(0, 0, 0, 0.04)`,
  ];
};

const darkModeBg = '#0F0F0F';
const darkModePaper = '#272727';

const getScrollbarStyles = darkmode => ({
  '& *::-webkit-scrollbar-thumb': {
    background: darkmode ? '#5E5E5E' : '#CCC',
    border: '1px solid transparent',
    borderRadius: '6px',
    backgroundClip: 'content-box',
  },
  '& *::-webkit-scrollbar': {
    width: '10px',
  },
  '& *::-webkit-scrollbar-track': {
    width: ' 10px',
  },
  '& *::-webkit-scrollbar-thumb:hover': {
    background: '#848484',
  },
  '& *:not(:hover):not(:active):not(:focus):not(:focus-within)::-webkit-scrollbar-thumb': {
    background: 'none',
  },
});

const getTheme = darkmode =>
  createTheme({
    ...{
      shape: {
        borderRadius: 10,
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960, //
          lg: 1200,
          xl: 1920,
        },
      },
      shadows: getShadows(darkmode),
      typography: {
        fontFamily: "'Inter', sans-serif",
        fontWeightLight: 400,
        fontWeightRegular: 500,
        fontWeightMedium: 600,
        useNextVariants: true,
        overline: {
          fontWeight: 500,
          letterSpacing: '0.078125rem',
          fontSize: '0.625rem',
        },
        h1: {
          fontWeight: 500,
          letterSpacing: '0.09375rem',
          fontSize: '6rem',
        },
        h2: {
          fontWeight: 600,
          letterSpacing: '0.03125rem',
          fontSize: '3.75rem',
        },
        h3: {
          fontWeight: 600,
          letterSpacing: 0,
          fontSize: '3rem',
        },
        h4: {
          fontWeight: 600,
          fontSize: '2.125rem',
          letterSpacing: '0.015625rem',
        },
        h5: {
          fontWeight: 600,
          fontSize: '1.5rem', // 24px
          letterSpacing: '0rem',
        },
        h6: {
          fontWeight: 600,
          fontSize: '1.25rem', // 20px
          letterSpacing: '0.009375rem', // .15px
        },
        subtitle1: {
          fontWeight: 600,
          fontSize: '1rem', // 16px
          letterSpacing: '0.009375rem', // .15px
        },
        subtitle2: {
          fontWeight: 550,
          fontSize: '0.8125rem', // 13px
          letterSpacing: '0.00625rem', // .1px
        },
        body1: {
          fontWeight: 400,
          fontSize: '0.875rem', // 14px
          letterSpacing: '0.009375rem', // .15px
        },
        body2: {
          fontWeight: 500,
          fontSize: '0.75rem', // 12px
          letterSpacing: '0.009375rem', // .15px
        },
        bodylarge: {
          fontWeight: 400,
          fontSize: '1.5rem', // 24px
          letterSpacing: '0rem',
        },
        caption: {
          fontWeight: 500,
          fontSize: '0.75rem', // 12px
          letterSpacing: '0.025rem', // .4px
        },
        tiny: {
          fontSize: '0.625rem',
          letterSpacing: '0',
        },
      },
      spacing: 8,
      palette: {
        mode: darkmode ? 'dark' : 'light',
        divider: getContrastColor(darkmode, '0.08'),
        secondary: {
          light: '#8BF351',
          main: '#0A9C07',
          dark: '#005718',
          contrastText: '#fff',
        },
        primary: {
          light: darkmode ? '#cccccc' : '#6D6D6D',
          main: darkmode ? '#f7f7f7' : '#424242',
          dark: '#1B1B1B',
          contrastText: '#ffffff',
          50: '#fcfcfc',
          100: '#f7f7f7',
          200: darkmode ? '#171717' : '#f2f2f2',
          300: '#ededed',
          400: '#cccccc',
          500: '#aeaeae',
          600: '#848484',
          700: '#6f6f6f',
          800: '#4f4f4f',
          900: '#2d2d2d',
          1000: '#212121',
          hover: darkmode ? '#303030' : '#373737',
        },
        confidence: {
          red: '#FF5A6E',
          amber: darkmode ? '#E99A00' : '#EE9D00',
          yellow: darkmode ? '#E99A00' : '#EE9D00', // some legacy features are using "yellow"
          green: darkmode ? '#0A9C07' : '#55BF16',
        },
        background: {
          box: darkmode ? '#1D1D1D' : '#eeeeee',
          app: darkmode ? darkModeBg : '#fff',
          default: darkmode ? '#1D1D1D' : '#fff',
          paper: darkmode ? darkModePaper : '#fff',
        },
        action: {
          border: getContrastColor(darkmode, '0.23'),
          borderActive: getContrastColor(darkmode, '0.42'),
          active: getContrastColor(darkmode, '0.54'),
          hover: getContrastColor(darkmode, '0.04'),
          selected: getContrastColor(darkmode, '0.08', 2),
          disabled: getContrastColor(darkmode, '0.26'),
          focus: getContrastColor(darkmode, '0.12'),
        },
        text: {
          strong: getContrastColor(darkmode, '1.0'),
          primary: getContrastColor(darkmode, '0.87'),
        },
        // features:
        featureOkr: {
          main: '#4090F7',
        },
        featureDfac: {
          main: '#FA8417',
        },
        featureCommitments: {
          main: '#F6C944',
        },
        featureInterlocks: {
          main: '#3F34BD',
        },
        featureViews: {
          main: '#DB00FF',
        },
        featureInsights: {
          main: '#9F2560',
        },
        featureUnderstanding: {
          main: '#9F2560',
        },
        featureContextSharing: {
          main: '#0A8F00',
        },
        featureDashboards: {
          main: '#16B67C',
        },
        visualizations: {
          pie: ['#072101', '#144610', '#226F1D', '#2E9B2A', '#38C937', '#3FFA42'],
          pieContrastColors: ['#FFF', '#FFF', '#ffffff', '#fff', '#2d2d2d', '#2d2d2d'],
          nps: [
            '#BF1212',
            '#BF1212',
            '#BF1212',
            '#BF1212',
            '#BF1212',
            '#BF1212',
            '#BF1212',
            '#F2C94C',
            '#F2C94C',
            '#55BF16',
            '#55BF16',
          ],
          csat: ['#BF1212', '#F2C94C', '#F2C94C', '#55BF16', '#55BF16'],
        },
        error: {
          main: darkmode ? '#C83C3C' : '#FF5A63',
          dark: '#AE1820',
          light: '#C83C3C',
        },
        warning: {
          main: darkmode ? '#C86400' : '#E99A00',
          dark: '#C86400',
          light: '#E99A00',
        },
        // Used by `getContrastText()` to maximize the contrast between the background and
        // the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
      },
    },
    ...{
      components: {
        MuiModal: {
          styleOverrides: {
            root: getScrollbarStyles(darkmode),
          },
        },
        MuiContainer: {
          styleOverrides: {
            root: getScrollbarStyles(darkmode),
          },
        },
        MuiBackdrop: {
          styleOverrides: {
            root: {
              '&:not(.MuiBackdrop-invisible)': {
                backgroundColor: darkmode ? 'rgba(24,24,26,0.8)' : 'rgba(0,0,0,0.5)',
              },
            },
          },
        },
        MuiDialog: {
          styleOverrides: {
            paper: {
              backgroundColor: darkmode ? darkModeBg : '#fff',
              backgroundImage: 'none',
            },
          },
        },
        MuiPopover: {
          styleOverrides: {
            paper: {
              backgroundColor: darkmode ? darkModePaper : '#fff',
              backgroundImage: 'none',
            },
          },
        },
        MuiSelect: {
          styleOverrides: {
            root: {
              backgroundColor: darkmode ? darkModePaper : '#fff',
            },
          },
        },
        MuiFab: {
          defaultProps: {
            size: 'medium',
          },
          styleOverrides: {
            root: {
              width: 40,
              height: 40,
            },
          },
        },
        MuiIconButton: {
          styleOverrides: {
            sizeSmall: {
              height: 24,
              width: 24,
              borderRadius: 6,
              '& .MuiSvgIcon-root': {
                fontSize: '1rem',
              },
            },
          },
        },
        MuiSvgIcon: {
          styleOverrides: {
            fontSizeSmall: {
              fontSize: '18px',
            },
          },
        },
        MuiTypography: {
          defaultProps: {
            color: 'text.primary',
          },
        },
        MuiButton: {
          variants: [
            {
              props: { variant: 'text' },
              style: {},
            },
          ],
          defaultProps: {
            disableElevation: true,
            size: 'large',
          },
          styleOverrides: {
            root: {
              textTransform: 'none',
              fontWeight: 400,
            },
            text: {
              textTransform: 'none',
              fontWeight: 400,
            },
            sizeLarge: {
              // height: 32,
              borderRadius: 10,
              fontWeight: 550,
              fontSize: '0.8125rem', // 13px
              letterSpacing: '0.03125', // .5px
              paddingLeft: 14,
              paddingRight: 14,
              paddingTop: 3,
              paddingBottom: 3,
            },
            sizeMedium: {
              // height: 24,
              borderRadius: 8,
              fontWeight: 500,
              fontSize: '0.75rem', // 12px
              letterSpacing: '0.009375rem', // .15px
              paddingTop: 1,
              paddingBottom: 1,
              '& .MuiChip-label': {
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            sizeSmall: {
              // height: 20,
              borderRadius: 6,
              fontWeight: 550,
              fontSize: '0.6875', // 11px
              letterSpacing: '0.03125', // .5px
              paddingLeft: 10,
              paddingRight: 10,
            },
          },
        },
        MuiDialogActions: {
          styleOverrides: {
            root: {
              padding: 16,
            },
          },
        },
        MuiTableHead: {
          styleOverrides: {
            root: {
              '& .MuiTableCell-root': {
                // subtitle2
                fontWeight: 550,
                fontSize: '0.8125rem', // 13px
                letterSpacing: '0.00625rem', // .1px
              },
            },
          },
        },
        MuiLink: {
          defaultProps: {
            underline: 'none',
            color: 'secondary',
          },
        },
        MuiChip: {
          variants: [
            {
              props: { size: 'large' },
              style: {
                height: 32,
                borderRadius: 10,
                fontWeight: 550,
                fontSize: '0.8125rem', // 13px
                letterSpacing: '0.03125', // .5px
                paddingLeft: 14,
                paddingRight: 14,
              },
            },
          ],
          styleOverrides: {
            root: {
              textTransform: 'none',
              fontWeight: 400,
            },
            iconSmall: {
              fontSize: '12px',
              marginLeft: 6,
            },
            iconMedium: {
              fontSize: '16px',
              marginLeft: 8,
            },
            text: {
              textTransform: 'none',
              fontWeight: 400,
            },
            sizeLarge: {
              height: 32,
              borderRadius: 10,
              fontWeight: 550,
              fontSize: '0.8125rem', // 13px
              letterSpacing: '0.03125', // .5px
            },
            sizeMedium: {
              height: 24,
              borderRadius: 8,
              fontWeight: 550,
              fontSize: '0.8125rem', // 13px
              letterSpacing: '0.00625rem', // .1px
            },
            sizeSmall: {
              height: 20,
              borderRadius: 6,
              fontWeight: 550,
              fontSize: '0.6875', // 11px
              letterSpacing: '0.03125', // .5px
            },
          },
        },
        MuiButtonBase: {
          styleOverrides: {
            root: {
              color: getContrastColor(darkmode, '0.87'),
              '&.Mui-selected': {
                color: getContrastColor(darkmode, '0.87'),
              },
              '&.MuiButton-colorInherit': {
                backgroundColor: darkmode ? '#424242' : '#e0e0e0',
              },
            },
          },
        },
        MuiTab: {
          styleOverrides: {
            root: {
              fontSize: '0.6875rem',
              letterSpacing: '0.0rem',
              fontWeight: 500,
              marginLeft: 8,
              borderRadius: 5,
              textTransform: 'none',
              paddingTop: 5,
              paddingBottom: 5,
              height: 20,
              minHeight: 20,
              flexDirection: 'row',
              paddingRight: 8,
              paddingLeft: 8,
              minWidth: 60,
              '&:first-of-type': {
                marginLeft: 0,
              },
              '&:hover': {
                backgroundColor: darkmode ? '#424242' : 'rgba(0, 0, 0, 0.04)', // 'action.hover',
              },
              '&>.MuiTab-iconWrapper': {
                marginBottom: 0,
                marginRight: 4,
                marginLeft: -2,
                fontSize: '16px',
                transform: 'translateY(-1px)',
              },
              '&.Mui-selected': {
                backgroundColor: getContrastColor(darkmode, '0.08', 2),
              },
            },
          },
        },
        MuiFilledInput: {
          styleOverrides: {
            root: {
              borderRadius: 10,
            },
          },
        },
        MuiTabs: {
          styleOverrides: {
            root: {
              minHeight: 20,
            },
            indicator: {
              display: 'none',
            },
          },
        },
      },
    },
  });
export default getTheme;
