import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Checkbox from '@mui/material/Checkbox';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';

import TodosIcon from 'Components/Features/Objectives/TodosIcon';
import DataObjectChip from 'Components/Library/DataObjectChip';
import InlineEditInput from 'Components/Library/InlineEditInput';

import SavingIndicator from 'Components/Common/SavingIndicatorv2';
import LinkifiedTypography from 'Components/Common/LinkifiedTypography';

const styles = theme => ({
  root: {
    ...theme.shape,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.box,
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    boxSizing: 'border-box',
    '& > div:first-of-type': {
      marginTop: theme.spacing(-2),
      marginLeft: theme.spacing(-2),
      marginBottom: theme.spacing(2),
    },
    '& > button:not(:last-child)': {
      marginBottom: theme.spacing(),
    },
  },
  actions: {
    marginTop: -6,
    visibility: 'hidden',
    flexGrow: 0,
    flexShrink: 0,
    [theme.breakpoints.down('md')]: {
      visibility: 'visible',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 4,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(),
    '& > $row': {
      // No margin in embedded rows
      marginBottom: 0,
    },
    '&:hover $actions': {
      visibility: 'visible',
    },
    '&:focus-within $actions': {
      visibility: 'visible',
    },
  },
  inputContainer: {
    flexGrow: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  iconbutton: {
    [theme.breakpoints.down('md')]: {
      padding: 8,
    },
  },
  active: {
    marginTop: 8,
    marginBottom: 8,
  },
  rowButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  todoText: {
    overflow: 'hidden',
    '& a': {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
    },
    '& a:visited': {
      color: theme.palette.secondary.dark,
    },
    '& a:hover': {
      color: theme.palette.secondary.light,
      textDecoration: 'underline',
    },
  },
  addTodoButton: {
    color: theme.palette.secondary.main,
    padding: 8, // padding instead of margin to increase touch area
    paddingLeft: 0,
    display: 'block',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  savingIndicator: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    width: 'auto',
  },
  noTodosMessage: {
    display: 'block',
  },
});

class KeyresultTodos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: -1,
      value: '',
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.editing < 0 && nextProps.todos.length > this.props.todos.length) {
      this.setState({
        editing: nextProps.todos.length - 1,
        value: nextProps.todos[nextProps.todos.length - 1].todo,
      });
    }
  }

  onTodoStatusChange(i, event) {
    this.props.onStatusChange(i, event.target.checked);
  }

  onTodoTextChange = event => {
    this.setState({ value: event.target.value });
  };

  toggleEdit = index => {
    this.setState({ editing: index, value: this.props.todos[index].todo });
  };

  onSave = index => {
    this.props.onTextChange(index, this.state.value);
    this.setState({ editing: -1 });
  };

  onCancel = () => {
    this.props.deleteIfEmpty(this.state.editing);
    this.setState({ editing: -1 });
  };

  render() {
    const {
      t,
      classes,
      canEdit,
      className,
      todos,
      submitted,
      submitStatus,
      hideOverline = false,
    } = this.props;

    return (
      <div className={clsx(classes.root, className)}>
        {!hideOverline && (
          <DataObjectChip
            Icon={TodosIcon}
            text={t('objectives.krtodosoverline')}
            color="transparent"
          />
        )}
        <SavingIndicator
          submitStatus={submitStatus}
          submitted={submitted}
          className={classes.savingIndicator}
        />
        {todos &&
          todos.length > 0 &&
          todos.map((todo, i) => (
            <div
              key={`todos-${i}`}
              className={clsx(
                classes.row,
                'kr-todos-panel-todo-row',
                this.state.editing === i ? classes.active : null,
              )}
            >
              <Checkbox
                id={`kr-todos-panel-checkbox-${i}`}
                color="secondary"
                disabled={!canEdit || !('id' in todo) || this.state.editing > -1}
                checked={todo.status}
                className="kr-todos-panel-todo-checkbox"
                size="small"
                onChange={evt => this.onTodoStatusChange(i, evt)}
              />
              <div className={classes.row}>
                {canEdit && this.state.editing === i ? (
                  <div className={classes.inputContainer}>
                    <InlineEditInput
                      id={`kr-todos-panel-edit-field-${i}`}
                      autoFocus
                      variant="caption"
                      inputProps={{ maxLength: '512' }}
                      dense
                      noExtend
                      multiline
                      value={this.state.value}
                      onChange={evt => this.onTodoTextChange(evt)}
                    />
                    <div className={classes.rowButtonsContainer}>
                      <Button
                        id={`kr-todos-panel-edit-cancel-btn-${i}`}
                        onClick={this.onCancel}
                        color="primary"
                      >
                        {t('general.cancel')}
                      </Button>
                      <Button
                        id={`kr-todos-panel-edit-submit-btn-${i}`}
                        onClick={() => this.onSave(i)}
                        color="secondary"
                        disabled={this.state.value.length === 0}
                      >
                        {t('general.save')}
                      </Button>
                    </div>
                  </div>
                ) : (
                  <LinkifiedTypography
                    id={`kr-todos-panel-text-field-${i}`}
                    variant="caption"
                    className={classes.todoText}
                  >
                    {todo.todo}
                  </LinkifiedTypography>
                )}
                {canEdit && this.state.editing < 0 && (
                  <div className={classes.actions}>
                    <IconButton
                      id={`kr-todos-panel-toggle-edit-button-${i}`}
                      size="small"
                      className={classes.iconbutton}
                      onClick={() => this.toggleEdit(i)}
                      aria-label={t('general.edit')}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      id={`kr-todos-panel-delete-button-${i}`}
                      size="small"
                      className={classes.iconbutton}
                      onClick={() => this.props.onTodoDeleted(i)}
                      aria-label={t('general.delete')}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                )}
              </div>
            </div>
          ))}
        {!canEdit && todos.length === 0 && (
          <Typography variant="caption" className={classes.noTodosMessage}>
            {t('objectives.krtodosnotodoshint')}
          </Typography>
        )}
        {canEdit && todos && todos.length < 20 && this.state.editing < 0 && !submitted && (
          <Typography
            id="kr-todos-panel-add-one"
            variant="caption"
            className={classes.addTodoButton}
            onClick={this.props.onTodoAdded}
          >
            {t('objectives.krtodosaddtodocta')}
          </Typography>
        )}
      </div>
    );
  }
}

KeyresultTodos.propTypes = {
  classes: PropTypes.exact({
    root: PropTypes.string,
    actions: PropTypes.string,
    row: PropTypes.string,
    inputContainer: PropTypes.string,
    iconbutton: PropTypes.string,
    active: PropTypes.string,
    paneltitle: PropTypes.string,
    rowButtonsContainer: PropTypes.string,
    todoTextField: PropTypes.string,
    todoText: PropTypes.string,
    addTodoButton: PropTypes.string,
    input: PropTypes.string,
    savingIndicator: PropTypes.string,
    noTodosMessage: PropTypes.string,
  }),
  t: PropTypes.func,
  todos: PropTypes.array,
  canEdit: PropTypes.bool,
  onTextChange: PropTypes.func,
  deleteIfEmpty: PropTypes.func,
  submitStatus: PropTypes.number,
  submitted: PropTypes.bool,
  hideOverline: PropTypes.bool,
  onTodoAdded: PropTypes.func,
  onTodoDeleted: PropTypes.func,
  onStatusChange: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(KeyresultTodos));
