import { uniq } from 'lodash';
import { instance as axios } from 'config/axios/index';
import { API_GLOBAL_SEARCH_URL } from 'state/constants/api';
import { NODE_TYPE_USER } from 'config/constants';

export const isMatch = (searchString, compVal) => {
  /* Check if the value is a match for the given searchString
    Returns the "priority of the match":
      2: the value begins with the search string
      1: the value contains the search string
      0: no match
  */
  if (typeof searchString === 'string' && typeof compVal === 'string') {
    if (compVal.toLowerCase().startsWith(searchString.toLowerCase())) {
      return 2;
    }
    if (compVal.toLowerCase().includes(searchString.toLowerCase())) {
      return 1;
    }
  }
  return 0;
};

export const getTeamSuggestions = (
  searchString = '',
  searchProps = {
    people: {},
    teamNames: [],
    selectTeamsManagedBy: null,
    searchFor: [],
  },
) => {
  const { people, teamNames, selectTeamsManagedBy, searchFor } = searchProps;
  const highPrioTeamMatches = [];
  const lowPrioTeamMatches = [];
  const matchedIds = [];
  if (searchFor.includes('teams')) {
    for (const [searchedId, teamName] of Object.entries(teamNames)) {
      const matchLevel = isMatch(searchString, teamName);

      if (matchLevel === 2) {
        matchedIds.push(searchedId);
        highPrioTeamMatches.push({ id: searchedId, text: teamName, type: 'team' });
      } else if (matchLevel === 1) {
        lowPrioTeamMatches.push({ id: searchedId, text: teamName, type: 'team' });
        matchedIds.push(searchedId);
      }
    }
  }
  if (searchFor.includes('teamsledby')) {
    for (const sub in people.subs) {
      if (Object.hasOwnProperty.call(people.subs, sub) && people.subs[sub].ok) {
        const { fullName } = people.subs[sub];
        const matchLevel = isMatch(searchString, fullName);
        if (matchLevel > 0) {
          const managedTeams = selectTeamsManagedBy(sub);
          for (const teamId of managedTeams) {
            if (!matchedIds.includes(teamId) && teamId in teamNames) {
              lowPrioTeamMatches.push({ id: teamId, text: teamNames[teamId], type: 'team' });
              matchedIds.push(teamId);
            }
          }
        }
      }
    }
  }

  return highPrioTeamMatches.concat(lowPrioTeamMatches);
};

export const getRecentTeams = (searchFor = [], recentTeams = [], teamNames = []) => {
  const recent = [];
  if (searchFor.includes('teams')) {
    for (const recentTeamId of recentTeams) {
      if (!!recentTeamId && !!teamNames[recentTeamId]) {
        recent.push({
          type: 'team',
          id: recentTeamId,
          text: teamNames[recentTeamId],
        });
      }
    }
  }
  return recent;
};

export const fetchActualSearchResults = (
  fetchingOptions = {
    searchString: '',
    auth: {},
    setSuggestions: () => null,
  },
) => {
  const { searchString, auth, setSuggestions } = fetchingOptions;
  axios
    .get(
      `${API_GLOBAL_SEARCH_URL}/${auth.tenantID}/queryindex?search_text=${searchString}&type=ALL`,
      {
        headers: { Authorization: `Bearer ${auth.tokens.access_token}` },
      },
    )
    .then(({ data }) => {
      const fetchedSuggestions = data.query_response;
      if (fetchedSuggestions.length > 0) {
        const availableTypes = uniq(fetchedSuggestions.map(s => s.type));
        // forming the needed format for suggestions
        const preparedSuggestions = availableTypes.map(typeName => ({
          title: typeName,
          suggestions: fetchedSuggestions.filter(s => s.type === typeName),
        }));

        // merging the result from team search to what we've just fetched
        setSuggestions(preparedSuggestions);
      } else {
        setSuggestions([]);
      }
    })
    .catch(e => {
      // eslint-disable-next-line no-console
      console.log('some error occured: ', e);
      setSuggestions([]);
    });
};

export const generateTeamNavLink = (location, teamID) => {
  if (location.pathname.includes('/home/teams/')) {
    // replace the teamID
    const pathParts = location.pathname.split('/');
    pathParts[pathParts.indexOf('teams') + 1] = teamID;
    return pathParts.join('/');
  }
  return `/home/teams/${teamID}`;
};

export const getPeopleSuggestions = (
  searchString,
  searchProps = {
    people: {},
    searchFor: [],
  },
) => {
  const { people, searchFor } = searchProps;
  const lowPrioUserMatches = [];
  const highPrioUserMatches = [];
  if (searchFor.includes('people')) {
    for (const sub in people.subs) {
      if (Object.hasOwnProperty.call(people.subs, sub) && people.subs[sub].ok) {
        const { fullName } = people.subs[sub];
        const matchLevel = isMatch(searchString, fullName);
        if (matchLevel > 0) {
          if (matchLevel === 2) {
            highPrioUserMatches.push({ id: sub, text: fullName, type: NODE_TYPE_USER });
          } else {
            lowPrioUserMatches.push({ id: sub, text: fullName, type: NODE_TYPE_USER });
          }
        }
      }
    }
    return highPrioUserMatches.concat(lowPrioUserMatches);
  }
  return [];
};
