import axios from 'axios';
import { refreshTokens } from 'state/ducks/auth/actions';

// TODO: handle injecting the authorization information in a request interceptor for DRYness
const instance = axios.create();

const setupAxios = store => {
  instance.interceptors.response.use(
    response => response,
    error => {
      if (!!error.response && error.response.status === 401) {
        // eslint-disable-next-line no-console
        console.log('Forcing token refresh');
        store.dispatch(refreshTokens({ force: true }));
      }
      return Promise.reject(error);
    },
  );
};

export { instance, setupAxios };
