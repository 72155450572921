import React, { Component } from 'react';
import clsx from 'clsx';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import MediaQueryWrapper from 'Components/Common/MediaQueryWrapper';
import CanEditKeyResultWrapper from 'Components/Features/Objectives/CanEditKeyResultWrapper';
import ObjectiveChip from 'Components/Features/Objectives/ObjectiveChip';
import { isObjectiveOwned } from 'state/ducks/objectives/helpers';
import KeyresultCells from './KeyresultCells';

const styles = theme => ({
  cell: {
    overflow: 'hidden',
    border: 'none',
    minWidth: 20,
    '&:last-child': {
      paddingRight: theme.spacing(2),
    },
  },
  domain: {
    opacity: 0.4,
  },
  topAlignedCell: {
    verticalAlign: 'top',
    overflow: 'hidden',
  },
  objectiveCell: {
    paddingTop: theme.spacing(),
    borderBottom: 'none',
    '& > button': {
      width: '100%',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%',
  },
  objectiveRow: {
    borderTop: `1px solid ${theme.palette.divider}`,
    '& td': {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '& $objectiveCell': {
      [theme.breakpoints.down('sm')]: {
        display: 'table-cell',
      },
    },
  },
  krRow: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  progress: {
    willChange: 'unset !important',
    borderRadius: 4,
  },
  progressRoot: {
    borderRadius: 4,
    backgroundColor: '#DADADA',
  },
  progressContainer: {
    height: 4,
    marginTop: theme.spacing(),
  },
  objectiveDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  xsAvatar: {
    marginRight: theme.spacing(),
  },
});

class ObjectiveRow extends Component {
  render() {
    const { xs, classes, domain, objectiveData, openAddKeyResult, stperiod } = this.props;
    if (!objectiveData.ok) {
      return null;
    }

    let isPrinting = false;
    if (window.matchMedia) {
      const mediaQueryList = window.matchMedia('print');
      if (mediaQueryList.matches) {
        isPrinting = true;
      }
    }
    const rowSpan = !isPrinting && xs ? 1 : Math.max(1, objectiveData.data.keyresults.length + 1);

    const numKrs = get(objectiveData, 'data.keyresults', []).length;

    return (
      <React.Fragment>
        <TableRow
          id={`okr-table-row-${domain.t}-${domain.d}-${objectiveData.data.objectiveID}`}
          name="okr-table-row"
          className={clsx(!isPrinting && classes.objectiveRow)}
        >
          <TableCell
            name="okr-table-objective-cell"
            className={clsx(classes.topAlignedCell, classes.objectiveCell)}
            rowSpan={rowSpan}
            sx={{ width: '33%' }}
          >
            <ObjectiveChip
              objectiveData={objectiveData}
              objectiveID={objectiveData.data.objectiveID}
              overline={{ period: true, domain: domain.t === 'personal' }}
              color="white"
              stperiod={stperiod}
              allowNavigate
              ContextIcon={null}
              wrapText
              sx={{
                opacity: isObjectiveOwned(domain, objectiveData.data) ? 1 : 0.5,
              }}
            />
          </TableCell>
          <CanEditKeyResultWrapper
            objectiveData={objectiveData}
            keyresult={objectiveData.data.keyresults[0]}
            render={canEdit => (
              <KeyresultCells
                domain={domain}
                objectiveData={objectiveData.data}
                keyresult={objectiveData.data.keyresults[0]}
                openAddKeyResult={openAddKeyResult}
                canEdit={canEdit}
                stperiod={stperiod}
                isFirst
              />
            )}
          />
        </TableRow>
        {objectiveData.data.keyresults.map((kr, i) => {
          if (i > 0) {
            const keyresult = objectiveData.data.keyresults[i];
            return (
              <TableRow
                key={`keyresultrow-${domain.t}-${domain.d}-${keyresult.keyresultID}`}
                className={clsx(isPrinting ? null : classes.krRow)}
              >
                <CanEditKeyResultWrapper
                  objectiveData={objectiveData}
                  keyresult={keyresult}
                  render={canEdit => (
                    <KeyresultCells
                      domain={domain}
                      objectiveData={objectiveData.data}
                      keyresult={keyresult}
                      openAddKeyResult={openAddKeyResult}
                      canEdit={canEdit}
                      stperiod={stperiod}
                    />
                  )}
                />
              </TableRow>
            );
          }
          return null;
        })}
        {numKrs > 0 && (
          <TableRow className={clsx(isPrinting ? null : classes.krRow)}>
            <CanEditKeyResultWrapper
              objectiveData={objectiveData}
              keyresult={null}
              render={canEdit => (
                <KeyresultCells
                  domain={domain}
                  objectiveData={objectiveData.data}
                  keyresult={null}
                  openAddKeyResult={openAddKeyResult}
                  canEdit={canEdit}
                  stperiod={stperiod}
                  isLast
                />
              )}
            />
          </TableRow>
        )}
      </React.Fragment>
    );
  }
}

ObjectiveRow.propTypes = {
  xs: PropTypes.bool,
  domain: PropTypes.object,
  openAddKeyResult: PropTypes.func,
  classes: PropTypes.exact({
    cell: PropTypes.string,
    domain: PropTypes.string,
    topAlignedCell: PropTypes.string,
    objectiveCell: PropTypes.string,
    row: PropTypes.string,
    objectiveRow: PropTypes.string,
    krRow: PropTypes.string,
    progress: PropTypes.string,
    progressRoot: PropTypes.string,
    progressContainer: PropTypes.string,
    objectiveDetails: PropTypes.string,
    xsAvatar: PropTypes.string,

    chip: PropTypes.string,
  }),
  objectiveData: PropTypes.object,
  stperiod: PropTypes.string,
  t: PropTypes.func,
};

export default withStyles(styles)(MediaQueryWrapper()(ObjectiveRow));
