import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

import PersonasAvatar from 'Components/Common/PersonasAvatar';
import { peopleSelectors } from 'state/ducks/people';

const styles = theme => ({
  avatar: {
    position: 'absolute',
    top: '50%',
    left: 0,
    transform: 'translateY(-50%) translateY(1px)',
  },
  avatarContainer: {
    height: theme.typography.body2.fontSize,
    width: 22,
    display: 'inline-block',
    position: 'relative',
  },
  root: {
    color: theme.palette.secondary.dark,
  },
});

function UserSpan(props) {
  const { classes, node } = props;

  const sub = get(node, 'attrs.id');
  const userName = useSelector(state => peopleSelectors.selectFullName(state.main.people, sub));

  return (
    <span
      // the classnames and data attributes are required for copy pasting of rendered mentions
      // to be parsed correctly as mentions
      className={clsx(classes.root, 'tg-mention', `tg-mention-${sub}`)}
      data-mention-atom-id={sub}
      data-mention-atom-name={`tg-mention-${sub}`}
    >
      <span className={classes.avatarContainer}>
        <PersonasAvatar sub={sub} size="xtiny" className={classes.avatar} disablePopOver />
      </span>
      {userName}
    </span>
  );
}

UserSpan.propTypes = {
  node: PropTypes.object,
  classes: PropTypes.exact({
    avatarContainer: PropTypes.string,
    avatar: PropTypes.string,
    root: PropTypes.string,
  }),
};

export default withStyles(styles)(UserSpan);
