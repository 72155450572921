import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import FormContext from 'Components/Library/Forms/FormContext';

const FormSliderEditorConnector = React.memo(props => {
  const {
    render,
    fieldName,
    values,
    formName,
    onFieldChange,
    onEntryDeleted,
    onEntryAdded,
    error,
    helperText,
    entryErrors,
    minLength,
    maxLength,
    isRequired,
    canAdd,
  } = props;

  const inputProps = {};
  if (!!minLength) {
    inputProps.minLength = minLength;
  }
  if (!!maxLength) {
    inputProps.maxLength = maxLength;
  }
  const entryProps = [];
  values.forEach((value, index) => {
    const questionError = get(entryErrors, `[${index}].question`, '');
    const minLabelError = get(entryErrors, `[${index}].minLabel`, '');
    const maxLabelError = get(entryErrors, `[${index}].maxLabel`, '');
    entryProps[index] = {
      question: {
        value: value.question,
        onChange: e => onFieldChange(index, 'question', e.target.value),
        error: Boolean(questionError),
        helperText: questionError,
      },
      minLabel: {
        value: value.minLabel,
        onChange: e => onFieldChange(index, 'minLabel', e.target.value),
        error: Boolean(minLabelError),
        helperText: minLabelError,
      },
      maxLabel: {
        value: value.maxLabel,
        onChange: e => onFieldChange(index, 'maxLabel', e.target.value),
        error: Boolean(maxLabelError),
        helperText: maxLabelError,
      },
      onDelete: () => onEntryDeleted(index),
      inputProps,
      name: `${formName}-${fieldName}-${index}`,
    };
  });
  const fieldProps = {
    values,
    name: `${formName}-${fieldName}`,
    error,
    helperText,
    required: isRequired,
    canAdd,
    onAdd: i => onEntryAdded(i),
    onTypeChange: (i, v) => onFieldChange(i, 'type', v),
    entryProps,
  };
  return render(fieldProps);
});

function OuterFormSliderEditorConnector(props) {
  /* Outer context -connector, passes the context as destructured props
     To the inner connector FormSliderEditorConnector that uses React.memo()

     This way, the input component will not re-render unless the props
     relevant to it change.
  */
  const { render, fieldName } = props;

  const { onFieldChange, values, formName, fieldErrors, showErrors, schema } =
    React.useContext(FormContext);

  const minLength = get(schema, `properties.${fieldName}.items.minLength`);
  const maxLength = get(schema, `properties.${fieldName}.items.maxLength`);
  const maxItems = get(schema, `properties.${fieldName}.maxItems`, 100);
  const isRequired = get(schema, 'required', []).includes(fieldName);

  const onChange = (index, field, value) => {
    const newValues = [...values[fieldName]];
    newValues[index][field] = value;
    onFieldChange(fieldName, newValues);
  };

  const onEntryAdded = index => {
    const newValues = [...values[fieldName]];
    newValues.splice(index + 1, 0, { question: '', minLabel: '', maxLabel: '', type: 'basic' });
    onFieldChange(fieldName, newValues);
  };

  const onEntryDeleted = index => {
    const newValues = [...values[fieldName]];
    newValues.splice(index, 1);
    onFieldChange(fieldName, newValues);
  };

  // Parse errors
  let overallError = null;
  const entryErrors = {};
  if (!!showErrors && !!fieldErrors && fieldName in fieldErrors) {
    fieldErrors[fieldName].forEach(errorEntry => {
      if (Array.isArray(errorEntry)) {
        const pathParts = errorEntry[0].split('/');
        const index = parseInt(pathParts[0], 10);
        const subField = pathParts[1];
        if (!(index in entryErrors)) {
          entryErrors[index] = {};
        }
        if (!(subField in entryErrors[index])) {
          entryErrors[index][subField] = [];
        }
        entryErrors[index][subField].push(errorEntry[1]);
      } else {
        overallError = errorEntry;
      }
    });
  }

  return (
    <FormSliderEditorConnector
      fieldName={fieldName}
      render={render}
      onFieldChange={onChange}
      onEntryAdded={onEntryAdded}
      onEntryDeleted={onEntryDeleted}
      values={values[fieldName]}
      formName={formName}
      maxLength={maxLength}
      minLength={minLength}
      isRequired={isRequired}
      error={Boolean(overallError)}
      entryErrors={entryErrors}
      helperText={!!overallError ? overallError : ''}
      canAdd={values[fieldName].length < maxItems}
    />
  );
}

OuterFormSliderEditorConnector.propTypes = {
  render: PropTypes.func,
  fieldName: PropTypes.string,
};

export default OuterFormSliderEditorConnector;
