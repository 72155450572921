import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation, Trans } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';

import Typography from '@mui/material/Typography';
import ActivityEntry from 'Components/Library/ActivityLog/ActivityEntry';
import KeyresultChip from 'Components/Features/Objectives/KeyresultChip';

import { peopleSelectors } from 'state/ducks/people';

const styles = theme => ({
  green: {
    color: theme.palette.confidence.green,
  },
  amber: {
    color: theme.palette.confidence.amber,
  },
  red: {
    color: theme.palette.confidence.red,
  },
});

const attrToStr = attr => {
  if (attr === null || attr === '') {
    return "' '";
  }
  return attr;
};

export class UnwrappedKeyresultEvent extends React.Component {
  getStringForLegacyEvent() {
    let actions = [];
    const updateActions = this.getUpdatedactions();
    const editedActions = this.getEditedactions();
    actions.push(updateActions);
    actions.push(editedActions);
    if (actions[0].length === 0 && actions[1].length === 0) {
      actions.push('');
    } else {
      actions = [...actions[0], ...actions[1]];
    }
    return [actions, updateActions.length > 0, editedActions.length > 0];
  }

  getUpdatedactions() {
    // Events are unreliable, helper function to abstract this mess
    const { t, classes, event } = this.props;

    const actions = [];
    if (!!event.changes) {
      if ('status' in event.changes) {
        actions.push(
          <span key="krEventStatus" style={{ display: 'block', whiteSpace: 'pre-wrap' }}>
            <Trans
              i18nKey="activitylogs.valueModification"
              values={{
                name: t('activitylogs.attrNames.KEYRESULT.status'),
                from: event.changes.status,
                to: event.status,
              }}
              components={[<b>placeholder</b>, <b>placeholder</b>]}
            />
          </span>,
        );
      }
      if ('confidenceStr' in event.changes) {
        actions.push(
          <span key="krEventConfidence" style={{ display: 'block', whiteSpace: 'pre-wrap' }}>
            <Trans
              i18nKey="activitylogs.valueModification"
              values={{
                name: t('activitylogs.attrNames.KEYRESULT.confidence'),
                from: t(`objectives.${event.changes.confidenceStr}.name`),
                to: t(`objectives.${event.confidenceStr}.name`),
              }}
              components={[
                <b className={classes[event.changes.confidenceStr]}>placeholder</b>,
                <b className={classes[event.confidenceStr]}>placeholder</b>,
              ]}
            />
          </span>,
        );
      }
    }

    return actions;
  }

  getEditedactions() {
    // Events are unreliable, helper function to abstract this mess
    const { t, event, selectFullName } = this.props;
    const ATTRS = ['keyresult', 'description', 'target', 'baseline', 'weight', 'unit'];
    const BOOL_ATTRS = ['committed'];
    const actions = [];

    if (!!event.changes) {
      for (const attr of ATTRS) {
        if (
          Object.prototype.hasOwnProperty.call(event, attr) &&
          Object.prototype.hasOwnProperty.call(event.changes, attr)
        ) {
          actions.push(
            <span key={attr} style={{ display: 'block', whiteSpace: 'pre-wrap' }}>
              <Trans
                i18nKey="activitylogs.valueModification"
                values={{
                  name: t(`activitylogs.attrNames.KEYRESULT.${attr}`),
                  from: attrToStr(event.changes[attr]),
                  to: attrToStr(event[attr]),
                }}
                components={[<b>placeholder</b>, <b>placeholder</b>]}
              />
            </span>,
          );
        }
      }
      for (const attr of BOOL_ATTRS) {
        if (
          Object.prototype.hasOwnProperty.call(event, attr) &&
          Object.prototype.hasOwnProperty.call(event.changes, attr)
        ) {
          actions.push(
            <span key={attr} style={{ display: 'block', whiteSpace: 'pre-wrap' }}>
              <Trans
                i18nKey="activitylogs.boolValueModification"
                values={{
                  from: t(
                    `activitylogs.attrNames.KEYRESULT.${attr}_${
                      !!event.changes[attr] ? 'true' : 'false'
                    }`,
                  ),
                  to: t(
                    `activitylogs.attrNames.KEYRESULT.${attr}_${!!event[attr] ? 'true' : 'false'}`,
                  ),
                }}
                components={[<b>placeholder</b>, <b>placeholder</b>]}
              />
            </span>,
          );
        }
      }
      if (
        Object.prototype.hasOwnProperty.call(event, 'krowner') &&
        Object.prototype.hasOwnProperty.call(event.changes, 'krowner')
      ) {
        actions.push(
          <span key="krowner" style={{ display: 'block', whiteSpace: 'pre-wrap' }}>
            <Trans
              i18nKey="activitylogs.valueModification"
              values={{
                name: 'the owner',
                from: selectFullName(event.changes.krowner),
                to: selectFullName(event.krowner),
              }}
              components={[<b>placeholder</b>, <b>placeholder</b>]}
            />
          </span>,
        );
      }
    }
    return actions;
  }

  render() {
    const { t, event, showNodeName } = this.props;
    const { owner, timestamp } = event;
    let eventContent;
    let isCreate;
    let isUpdate;
    let isEdit;

    if (event.isCreate) {
      isCreate = true;
      isUpdate = false;
      isEdit = false;
    } else {
      [eventContent, isUpdate, isEdit] = this.getStringForLegacyEvent();
      isCreate = false;
    }

    const verb = isCreate
      ? t('activitylogs.createdVerb')
      : isEdit
      ? t('activitylogs.editedVerb')
      : isUpdate
      ? t('activitylogs.updatedVerb')
      : t('activitylogs.checkedInOn');
    const title = (
      <>
        {`${verb} `}
        {showNodeName ? (
          <KeyresultChip
            keyresultID={event.keyresultID}
            color="transparent"
            actionable={false}
            size="small"
            hideOwner
            hideStatus
          />
        ) : (
          t('activitylogs.nodeTypes.KEYRESULT')
        )}
      </>
    );
    const initialCollapseState = isUpdate || !!event.comment ? 'expanded' : 'collapsed';
    return (
      <ActivityEntry
        name="KR-UPDATE-EVT"
        sub={owner}
        title={title}
        timestamp={timestamp}
        collapseConfig={{
          collapseCta: `(${t('general.hide')})`,
          expandCta: `(${t('general.show')})`,
          initialState: initialCollapseState,
          allowChange: !!isEdit,
        }}
        message={
          !!event.comment && (
            <Typography variant="body2" component="span" display="block" gutterBottom>
              {event.comment}
            </Typography>
          )
        }
        metaDataMessage={
          <Typography
            variant="body2"
            component="span"
            display="block"
            color="text.secondary"
            gutterBottom
          >
            {eventContent}
          </Typography>
        }
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  selectFullName: sub => peopleSelectors.selectFullName(state.main.people, sub),
  // looking at the KR event in the context of an Objective
  showNodeName: !ownProps.viewingEmbeddedNodeId,
});

UnwrappedKeyresultEvent.propTypes = {
  classes: PropTypes.exact({
    root: PropTypes.string,
    paneltitle: PropTypes.string,
    krGridItem: PropTypes.string,
    eventPaper: PropTypes.string,
    green: PropTypes.string,
    amber: PropTypes.string,
    red: PropTypes.string,
  }),
  event: PropTypes.object,
  selectFullName: PropTypes.func,
  showNodeName: PropTypes.bool,
  t: PropTypes.func,
};

export default withTranslation()(
  withStyles(styles)(connect(mapStateToProps)(UnwrappedKeyresultEvent)),
);
