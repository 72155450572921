import * as types from './types';

export const fetchGraphRelations = payload => ({
  type: types.FETCH_RELATIONS,
  payload,
});

export const editRelations = payload => ({
  type: types.EDIT_RELATIONS,
  payload,
});

export const editedRelations = payload => ({
  type: types.EDITED_RELATIONS,
  payload,
});
