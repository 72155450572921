import React from 'react';
import { get } from 'lodash';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';

const createVariants = theme => {
  const variants = {};
  Object.keys(theme.typography).forEach(key => {
    variants[key] = {
      ...theme.typography[key],
      letterSpacing: `${theme.typography[key].letterSpacing} !important`,
      '& label:not(.Mui-focused):not(.MuiInputLabel-shrink)': {
        ...theme.typography[key],
        letterSpacing: `${theme.typography[key].letterSpacing} !important`,
      },
    };
  });
  return variants;
};

const styles = theme => ({
  ...{
    root: {
      transition: 'all 0.25s ease',
      '& .Mui-error': {
        color: `${theme.palette.error.main}`,
        '-webkit-text-fill-color': `${theme.palette.error.main}`,
      },
      '& :hover textarea': {
        '-webkit-text-fill-color': `${theme.palette.text.primary}`,
      },
      '& .Mui-disabled': {
        color: `${theme.palette.text.primary} !important`,
        '-webkit-text-fill-color': `${theme.palette.text.primary} !important`,
      },
      '& .MuiFormHelperText-root': {
        alignSelf: 'flex-start',
        fontSize: '1rem',
        letterSpacing: 0,
        transform: 'scale(0.75)',
        transformOrigin: '0px 0px',
      },
    },
    focused: {
      backgroundColor: 'rgba(0,0,0,0.08)',
      '& fieldset': {
        borderWidth: '0px !important',
      },
    },
    noFocus: {
      // empty class is needed
    },
    inputComponent: {
      transition: 'background-color 0.25s ease',
      '&:hover:not(:focus):not(.Mui-focused):not(.Mui-disabled):not($noFocus)': {
        backgroundColor: 'rgba(0,0,0,0.06)',
      },
      '& fieldset': {
        transition: 'border 0.25s ease',
        border: 0,
        '& legend': {
          visibility: 'hidden',
          width: 0,
        },
        '& span': {
          visibility: 'hidden',
        },
      },
    },
  },
  dense: {
    '& $inputComponent': {
      paddingTop: 7,
      paddingBottom: 7,
    },
    '& $inputComponent input': {
      paddingTop: 7,
      paddingBottom: 7,
    },
  },
  noPadding: {
    padding: 0,
    '& $inputComponent': {
      padding: 0,
    },
    '& $inputComponent input': {
      padding: 0,
    },
  },
  /* Dynamically create the classes for the different typography variants */
  ...createVariants(theme),
});

function InlineEditInput(props) {
  const { classes, variant, dense, noPadding, hideFocus, label, inputProps, ...rest } = props;

  // Fix weird MUI bug where the label is shown on top of the text
  // If the value is updated via props without user interaction
  const inputLabelProps = !!rest.value ? { shrink: true } : {};

  return (
    <TextField
      placeholder={label}
      {...rest}
      className={clsx(
        classes.root,
        classes[variant],
        !!dense && classes.dense,
        !!noPadding && classes.noPadding,
      )}
      inputProps={{
        'aria-label': label,
        ...inputProps,
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        ...rest.InputProps,
        classes: {
          ...get(rest, 'InputProps.classes', {}),
          focused: clsx(!hideFocus && classes.focused),
          root: clsx(classes.inputComponent, classes[variant], !!hideFocus && classes.noFocus),
        },
      }}
      InputLabelProps={inputLabelProps}
      variant="outlined"
    />
  );
}

InlineEditInput.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    inputComponent: PropTypes.string,
    focused: PropTypes.string,
    dense: PropTypes.string,
    noPadding: PropTypes.string,
    noFocus: PropTypes.string,
  }),
  label: PropTypes.string,
  inputProps: PropTypes.object,
  variant: PropTypes.string,
  noPadding: PropTypes.bool,
  hideFocus: PropTypes.bool,
  dense: PropTypes.bool,
};

InlineEditInput.defaultProps = {
  inputProps: {},
  hideFocus: false,
};

export default withStyles(styles)(InlineEditInput);
