import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import ThumbIcon from '@mui/icons-material/ThumbUp';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import HelperText from 'Components/Common/HelperText';

const styles = theme => ({
  question: {
    marginBottom: theme.spacing(4),
    backgroundColor: theme.palette.background.box,
    ...theme.shape,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  inputItem: {
    wordWrap: 'break-word',
    wordBreak: 'normal',
    overflow: 'hidden',
    ...theme.shape,
    backgroundColor: theme.palette.background.paper,
    justifySelf: 'stretch',
    flexGrow: 5,
    lineHeight: '1.25rem',
    padding: 12,
    paddingTop: 8,
    paddingBottom: 8,
  },
});

class FormVote extends Component {
  render() {
    const { classes, facilitationData, onVoteToggle, inputs, ownvotes, t } = this.props;
    let questions = [];
    if (facilitationData.ok) {
      // eslint-disable-next-line prefer-destructuring
      questions = facilitationData.questions;
    }
    if (!questions || !inputs || !inputs.ok) {
      return null;
    }
    return (
      <div name="live-dfac-panel-vote-form">
        {questions &&
          questions.map((question, i) => (
            <div className={classes.question} key={`live-dfac-panel-question-${i}`}>
              <Typography
                variant="h6"
                color="text.strong"
                component="span"
                display="block"
                sx={{ mb: 2 }}
              >
                {`${question}`}
              </Typography>
              {inputs.inputs[i].length === 0 && (
                <HelperText name="no-answers-yet">{t('dfac.sessionnoanswerstovoteon')}</HelperText>
              )}
              {inputs.inputs[i].map((inputItem, index) => (
                <FormControlLabel
                  key={`live-dfac-panel-question-input-${i}-${index}`}
                  id={`live-dfac-panel-question-input-${i}-${index}`}
                  sx={{
                    alignSelf: 'stretch',
                    mb: 1,
                    mr: 0,
                  }}
                  control={
                    <Checkbox
                      icon={<ThumbIcon color="active" size="small" />}
                      checkedIcon={<ThumbIcon color="secondary" size="small" name="thumbsupicon" />}
                      checked={ownvotes.includes(inputItem.input_id)}
                      onChange={() => onVoteToggle(inputItem.input_id)}
                      sx={{
                        color: 'action.active',
                        mr: 0.5,
                      }}
                    />
                  }
                  label={
                    <Typography
                      className={classes.inputItem}
                      variant="body1"
                      sx={{
                        color: ownvotes.includes(inputItem.input_id)
                          ? 'secondary.main'
                          : 'text.primary',
                      }}
                    >
                      {`${inputItem.input}`}
                    </Typography>
                  }
                />
              ))}
            </div>
          ))}
      </div>
    );
  }
}

FormVote.propTypes = {
  facilitationData: PropTypes.object,
  inputs: PropTypes.object,
  classes: PropTypes.exact({
    question: PropTypes.string,
    inputItem: PropTypes.string,
  }),
  ownvotes: PropTypes.arrayOf(PropTypes.string),
  onVoteToggle: PropTypes.func,
  t: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(FormVote));
