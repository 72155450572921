/* eslint-disable no-console */
import { get } from 'lodash';
import { getRegistration as originalGetRegistration } from 'serviceWorkerRegistration';

// Read the version number of the currently running service worker
export const getCurrentRunningVersion = () => {
  console.log(
    `ServiceWorkerUpdateHandler - retrieving running version: ${process.env.REACT_APP_BUILD_VERSION}`,
  );
  return process.env.REACT_APP_BUILD_VERSION;
};

// Read the version number of the last installed service worker - note that this is not
// always the same as the running version - a service worker will not be taken into use
// automatically after it has been installed - normally it requires all running tabs
// with the app to be closed first
export const getLastInstalledVersion = () => {
  console.log(
    `ServiceWorkerUpdateHandler - retrieving last installed version: ${localStorage.getItem(
      'last_installed_sw_ver',
    )}`,
  );
  return localStorage.getItem('last_installed_sw_ver');
};

export const compareSwVersion = (incoming, prev = getCurrentRunningVersion()) => {
  console.log('Debug: ', incoming, prev);
  const newDate = incoming.substr(0, 10).trim();
  const curDate = prev.substr(0, 10).trim();
  console.log('Debug: ', newDate, curDate);
  if (newDate >= curDate) {
    console.log('Debug 1: ', parseInt(incoming.substr(11), 10) > parseInt(prev.substr(11), 10));
    return parseInt(incoming.substr(11), 10) > parseInt(prev.substr(11), 10);
  }
  console.log('Debug 2: false');
  return false;
};

export const checkAvailableSwVersion = () => localStorage.getItem('last_offered_sw_version');

export const versionIsOutdated = props => {
  const { appstatus } = props;
  const minVersion = get(appstatus, 'data.min_app_version');
  if (!!minVersion) {
    const currentVersion = getCurrentRunningVersion();
    console.log(
      `ServiceWorkerUpdateHandler - comparing current version: ${currentVersion} to minimum supported: ${minVersion}`,
    );
    const forceUpdateCheck = compareSwVersion(minVersion, currentVersion);
    if (forceUpdateCheck) {
      return true;
    }
  }
  return false;
};

// This is a separate method to make testing easier
// without having to mock the whole serviceWorkerRegistration library
// eslint-disable-next-line class-methods-use-this
export const forceSwDownload = registration => {
  registration.update();
};

export const serviceWorkerIsRunning = () => 'serviceWorker' in navigator;

export const getRegistration = () => originalGetRegistration();

export const hardRefresh = () => {
  // Fix to ENG-1728
  // perform an XHR request to the current url with Pragma: no-cache
  // to make sure the app is not loaded from cache when reloading later,
  // only reload after that request is successful
  const xmlRequest = new XMLHttpRequest();
  xmlRequest.open('get', window.location.pathname);
  xmlRequest.setRequestHeader('Pragma', 'no-cache');
  xmlRequest.send();
  xmlRequest.onreadystatechange = () => {
    if (xmlRequest.readyState === XMLHttpRequest.DONE) {
      window.location.assign(window.location.pathname);
    }
  };
};
