import { debounce as lodashDebounce } from 'lodash';
import moment from 'moment';
import { BroadcastChannel } from 'broadcast-channel';
import i18n from 'config/i18n';
import * as constants from 'state/constants/api';
import { deleteToken } from 'firebase-cfg';

export function roundPercent(percent) {
  return Math.round(1000 * percent) / 10;
}

export function round(number) {
  return Math.round(10 * number) / 10;
}

export const validateText = function validateText(text, minLength, maxLength) {
  if (!text || text.replace(/\s/g, '') === '') {
    return false;
  }
  if (!!minLength && text.length < minLength) {
    return false;
  }
  if (!!maxLength && text.length > maxLength) {
    return false;
  }
  return true;
};

export const validateToken = function validateToken(text) {
  if (!text || text.replace(/\s/g, '') === '' || text.replace(/\s/g, '').length !== 7) {
    return false;
  }
  return true;
};

export const validateEmail = function validateEmail(email) {
  return /^((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|x09)*(x0dx0a))?(\x20|x09)+)?(([x01-x08x0bx0cx0e-x1fx7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([x01-x09x0bx0cx0d-x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|x09)*(x0dx0a))?(\x20|x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(
    email,
  );
};

export const parseDomainFromEmail = function parseDomainFromEmail(email) {
  const stringArray = email.split('@');
  return stringArray[stringArray.length - 1];
};

export function debounce(fn, delay) {
  return lodashDebounce(fn, delay);
}

export function getKeyResultCompletionPct(keyresult) {
  if (!keyresult || !keyresult.status) {
    return 0;
  }
  const value =
    100 *
      ((parseFloat(keyresult.status) - parseFloat(keyresult.baseline)) /
        (parseFloat(keyresult.target) - parseFloat(keyresult.baseline))) || 0;
  return Math.max(Math.min(round(value), 100), 0);
}

export function getObjectiveCompletionPct(objective) {
  let totalValue = 0;
  let sumWeight = 0;
  if (objective && objective.keyresults) {
    if (objective.keyresults.length > 0) {
      for (const kr in objective.keyresults) {
        if (kr in objective.keyresults) {
          const keyresult = objective.keyresults[kr];
          const value = getKeyResultCompletionPct(keyresult);
          totalValue += parseFloat(keyresult.weight) * value;
          sumWeight += parseFloat(keyresult.weight);
        }
      }
      totalValue /= sumWeight;
      if (sumWeight === 0) {
        totalValue = 0;
      }
    }
  }
  return totalValue;
}

export function getCardType(objectiveID) {
  let type;
  if (objectiveID) {
    type = objectiveID.substring(0, 4);
  }
  return type;
}

export function getLastModifiedString(days, skipAgo = false) {
  if (days > 1) {
    if (skipAgo) {
      return i18n.t(`general.ndays`, { days: Math.ceil(days) });
    }
    return i18n.t(`general.ndaysago`, { days: Math.ceil(days) });
  }
  if (days > 0) {
    return i18n.t('general.yesterday');
  }
  return i18n.t('general.today');
}

export async function logout(auth) {
  const logoutUrl = `https://${auth.cognitoSubDomain}.auth.eu-west-1.amazoncognito.com/logout?logout_uri=${constants.LOGOUT_URL}&response_type=code&client_id=${auth.clientId}`;
  await deleteToken();
  window.location.assign(logoutUrl);
}

export const channel = new BroadcastChannel('cross-tab-bus');

export function purgeState(persistor) {
  persistor.pause();
  persistor.flush().then(() => {
    persistor.purge();
    Object.keys(localStorage)
      .filter(
        key => !['last_installed_sw_ver', 'i18nextLng', 'last_offered_sw_version'].includes(key),
      )
      .forEach(key => localStorage.removeItem(key));
    persistor.persist();
  });
}

export function pausePersistorAndReload(persistor) {
  persistor.pause();
  // to prevent a logout-loop if several tabs are open do not reload /logout
  if (window.location.toString().includes('/logout')) {
    const url = window.location.toString().split('/logout')[0];
    persistor.purge().then(() => window.location.assign(url));
  } else {
    persistor.purge().then(() => window.location.reload());
  }
}

export function purgeStateFromAllTabs(persistor) {
  // eslint-disable-next-line no-console
  console.log('Logging out: Posting PURGE_STATE command to all active tabs');
  purgeState(persistor);
  channel.postMessage({ title: 'PURGE_STATE' });
}

export function getLastModifiedStringFromTimestamp(ts) {
  let deltaMinutes;
  // Number.isNaN and isNaN are different!
  // See: https://ponyfoo.com/articles/es6-number-improvements-in-depth
  // eslint-disable-next-line no-restricted-globals
  if (!isNaN(ts)) {
    // TODO: better way to check if ts looks like a unix timestamp, use moment.unix(ts).utc() if yes.
    deltaMinutes = moment().utc().diff(moment.unix(ts), 'minutes');
  } else {
    deltaMinutes = moment().utc().diff(moment.utc(ts, 'YYYY-MM-DD HH:mm.SSS'), 'minutes');
  }
  const deltaHours = Math.round(deltaMinutes / 60);

  if (deltaHours > 8) {
    return getLastModifiedString(Math.floor(deltaHours / 24));
  }
  if (deltaMinutes > 120) {
    return i18n.t(`general.nhoursago`, { hours: deltaHours });
  }
  if (deltaMinutes > 60) {
    return i18n.t('general.onehourago');
  }
  if (deltaMinutes > 5) {
    return i18n.t(`general.nminutesago`, { minutes: deltaMinutes });
  }
  return i18n.t('general.justnow');
}

export function truncateString(str, n) {
  return str.length > n ? `${str.substr(0, n - 1)} ...` : str;
}
