import React from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import DarkModeToggle from 'Components/Common/DialogUserSettings2/DarkModeToggle';

function ThemeTab() {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {t('usersettings.theme.tabTitle')}
      </Typography>
      <br />
      <p>{t('usersettings.theme.content')}</p>
      <DarkModeToggle />
    </>
  );
}

export default ThemeTab;
