import { createLogic, createDefaultListQueryLogic } from 'state/defaultLogic';
import { instance as axios } from 'config/axios';
import { APIGW_URL } from '../../constants/api';
import * as types from './types';
import * as selectors from './selectors';

const SLICE_NAME = 'graph';

export const getGraphRelations = createDefaultListQueryLogic({
  endpoint: { api: `${APIGW_URL}/graph`, method: 'queryrelations' },
  actionTypes: {
    fetch: types.FETCH_RELATIONS,
    success: types.RECEIVED_RELATIONS,
    fail: types.FAILED_RELATIONS,
  },
  selector: selectors.selectRelations,
  sliceName: SLICE_NAME,
});

export const editGraphRelationsLogic = createLogic({
  type: types.EDIT_RELATIONS,
  process: async ({ getState, action }, dispatch, done) => {
    const { payload } = action;

    axios
      .post(`${APIGW_URL}/graph/${getState().auth.tenantID}/editrelations`, payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        result.requestID = payload.requestID;
        dispatch({ type: types.EDITED_RELATIONS, payload: result });
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        errorPayload.requestID = payload.requestID;
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch({ type: types.ERROR_RECEIVED_FROM_API, payload });
      })
      .then(() => done());
  },
});
