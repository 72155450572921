import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import TextField from '@mui/material/TextField';
import withStyles from '@mui/styles/withStyles';
import SectionLabel from './SectionLabel';

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  suggestionContainerFocused: {
    // Empty class, needs to exist for scrollbar magic
  },
  suggestionsContainerOpen: {
    ...theme.shape,
    backgroundColor: theme.palette.main,
    marginTop: 0,
    position: 'static',
    maxHeight: 244,
    overflowY: 'scroll',
    // Scrollbar magic:
    '&::-webkit-scrollbar': {
      width: 10,
    },
    '&::-webkit-scrollbar-track': {
      width: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 6,
      border: '1px solid transparent',
      backgroundClip: 'content-box',
    },
    '&:not(:hover):not(:active):not(:focus):not(:focus-within):not($suggestionContainerFocused)::-webkit-scrollbar-thumb':
      {
        background: 'none',
      },
  },
  suggestion: {
    display: 'block',
    margin: 8,
    marginTop: 4,
    marginBottom: 4,
    '&:last-child': {
      marginBottom: 8,
    },
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  input: {
    border: '0px !important',
    borderRadius: '0px !important',
    transition: 'all 0.125s ease',
    '& input': {
      fontSize: '0.8125rem',
      fontWeight: 500,
      letterSpacing: '0px !important',
      padding: `9px ${theme.spacing()} 9px ${theme.spacing(2)} !important`,
      height: 21,
    },
    '& fieldset': {
      border: '0px !important',
      borderRadius: '0px !important',
    },
    '&:hover:not(:focus):not(.Mui-focused)': {
      backgroundColor: theme.palette.background.box,
    },
  },
});

function isShallowEqual(v, o) {
  for (const key in v) if (!(key in o) || v[key] !== o[key]) return false;

  for (const key in o) if (!(key in v) || v[key] !== o[key]) return false;

  return true;
}

function renderSuggestionsContainer(options) {
  const { containerProps, children } = options;
  return (
    <div
      style={containerProps.style}
      role={containerProps.role}
      key={containerProps.key}
      className={containerProps.className}
      id={containerProps.id}
      ref={containerProps.ref}
    >
      {children}
    </div>
  );
}

const renderInput = inputProps => {
  const { classes, ref, variant, id, name, ...other } = inputProps;
  return (
    <TextField
      fullWidth
      name={name}
      id={id}
      variant="outlined"
      autoFocus
      InputProps={{
        inputRef: ref,
        classes,
        ...other,
      }}
      sx={{ borderBottom: '1px solid', borderColor: 'divider' }}
    />
  );
};

const getSectionSuggestions = section => {
  if (section) {
    return section.suggestions;
  }
  return [];
};

class ChipAutocomplete extends React.Component {
  state = {
    searchString: '',
    highlightedSuggestion: null,
  };

  setSearchString = searchString => {
    if (searchString !== this.state.searchString) {
      this.setState({ searchString });
    }
  };

  onHighlight = event => {
    if (
      !!event.suggestion !== !!this.state.highlightedSuggestion ||
      (event.suggestion === null && this.state.highlightedSuggestion !== null) ||
      (event.suggestion !== null && this.state.highlightedSuggestion === null) ||
      !isShallowEqual(this.state.highlightedSuggestion, event.suggestion)
    ) {
      this.setState({ highlightedSuggestion: event.suggestion });
    }
  };

  render() {
    /* Shared display -component used by all the different searchable/autocomplete chips */
    const {
      classes,
      placeholder,
      suggestions,
      onFetchRequested,
      onClearRequested,
      onSelect,
      renderSuggestion,
      multiSection = false,
      alwaysRenderSuggestions = false,
      name,
    } = this.props;

    const { searchString, highlightedSuggestion } = this.state;

    return (
      <Autosuggest
        theme={{
          container: classes.container,
          input: classes.input,
          suggestionsContainerOpen: clsx(
            classes.suggestionsContainerOpen,
            Boolean(highlightedSuggestion) && classes.suggestionContainerFocused,
          ),
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderSuggestionsContainer={renderSuggestionsContainer}
        renderSuggestion={renderSuggestion}
        renderInputComponent={renderInput}
        alwaysRenderSuggestions={alwaysRenderSuggestions}
        multiSection={multiSection}
        suggestions={suggestions}
        onSuggestionHighlighted={this.onHighlight}
        onSuggestionsFetchRequested={e => onFetchRequested(e.value, e.reason)}
        onSuggestionsClearRequested={onClearRequested}
        onSuggestionSelected={(event, { suggestion }) => onSelect(suggestion)}
        getSuggestionValue={suggestion => suggestion.text}
        renderSectionTitle={section => (
          <SectionLabel
            section={{
              title: section.title,
              suggestions: section.suggestions,
            }}
          />
        )}
        getSectionItems={section => section.suggestions}
        getSectionSuggestions={getSectionSuggestions}
        id={name}
        inputProps={{
          value: searchString,
          name: `${name ? `${name}-` : ''}autocomplete-input`,
          placeholder,
          classes: { input: classes.input },
          onChange: (event, { newValue }) => this.setSearchString(newValue),
        }}
      />
    );
  }
}

ChipAutocomplete.propTypes = {
  classes: PropTypes.exact({
    container: PropTypes.string,
    input: PropTypes.string,
    suggestion: PropTypes.string,
    suggestionsList: PropTypes.string,
    suggestionContainerFocused: PropTypes.string,
    suggestionsContainerOpen: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  renderSuggestion: PropTypes.func,
  onFetchRequested: PropTypes.func,
  onClearRequested: PropTypes.func,
  onSelect: PropTypes.func,
  suggestions: PropTypes.array,
  multiSection: PropTypes.bool,
  alwaysRenderSuggestions: PropTypes.bool,
  name: PropTypes.string,
};

export { SectionLabel };
export default withStyles(styles)(ChipAutocomplete);
