import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { useTheme, useMediaQuery } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import clsx from 'clsx';

import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import OfflineIcon from '@mui/icons-material/CloudOff';

import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import MainNav from 'Components/Library/MainNav';
import NotificationsHandler from 'Components/Common/NotificationsHandler';
import NotificationsIconButton from 'Components/Common/NotificationsIconButton';
import AddToHomeScreenHandler from 'Components/Common/AddToHomeScreenHandler';
import ConnectionHandler from 'Components/Common/ConnectionHandler';
import ServiceWorkerUpdateHandler from 'Components/Common/ServiceWorkerUpdateHandler';
import ServiceWorkerTokenHandler from 'Components/Common/ServiceWorkerTokenHandler';

import { peopleActions } from 'state/ducks/people';
import { appstatusActions } from 'state/ducks/appstatus';
import { objectivesActions, objectivesSelectors } from 'state/ducks/objectives';
import { programsSelectors } from 'state/ducks/programs';
import { inboxActions } from 'state/ducks/inbox';

import ModalContainer from 'config/ModalProvider/ModalContainer';
import { connectionSelectors } from 'state/ducks/connection';
import { withLocation } from 'withRouter';
import NotFound from './NotFound';

const ChangePrograms = lazy(() => import('./ChangePrograms'));
const Teams = lazy(() => import('./Teams'));
const Strategy = lazy(() => import('./Strategy'));
const Objectives = lazy(() => import('screens/Features/Objectives/Objectives'));
const Views = lazy(() => import('screens/Features/Views'));
const Commitments = lazy(() => import('screens/Features/Commitments'));
const Admin = lazy(() => import('screens/Features/Admin'));
const Interlocks = lazy(() => import('screens/Features/Interlocks'));
const Dashboards = lazy(() => import('screens/Features/Dashboards'));
const drawerWidth = 240;
const anchor = 'left';

function mediaQueryWrapper(Component) {
  return function WrappedComponent(props) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return <Component {...props} isMobile={isMobile} />;
  };
}

const styles = theme => ({
  appFrame: {
    height: '100%',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    backgroundColor: theme.palette.background.app,
  },
  appBar: {
    width: `100%`,
    backgroundColor: theme.palette.primary.dark,
    position: 'relative',
    flexGrow: 0,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    backgroundColor: theme.palette.background.app,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    boxShadow: 0,
    borderRightWidth: 1, // overriding MUI weirdness
    borderRight: `1px solid ${theme.palette.divider}`,
    overflowY: 'hidden',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    paddingTop: 0,
    width: 240,
  },
  drawerMobilePaper: {
    position: 'relative',
    width: drawerWidth,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: theme.spacing(),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    minWidth: 240,
    maxWidth: '80%',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  drawerMobileBackdrop: {
    position: 'absolute',
  },
  pageTitle: {
    width: '100%',
    margin: 0,
    display: 'flex',
    '& h1': {
      color: '#fff',
      fontSize: '1.75rem',
      minHeight: theme.spacing(10),
      lineHeight: theme.spacing(10),
      [theme.breakpoints.down('md')]: {
        fontSize: '1.25rem',
        fontWeight: 500,
        minHeight: theme.spacing(8),
        lineHeight: theme.spacing(8),
      },
    },
  },
  toolBar: {
    margin: 0,
    padding: 0,
    minHeight: '90px',
    [theme.breakpoints.down('md')]: {
      minHeight: '64px',
    },
  },
  content: {
    flexGrow: 20,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  contentRoot: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  menuButton: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  mobileOfflineContainer: {
    display: 'none',
    opacity: 0,
    color: '#fff',
    height: 64,
    width: 64,
    flexShrink: 0,
  },
  offline: {
    display: 'flex',
    opacity: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  mobileNotificationsIconContainer: {
    marginRight: theme.spacing(3),
  },
  progress: {
    margin: 'auto',
  },
});

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.inboxtimer = null;
  }

  state = {
    mobileOpen: false,
  };

  componentDidMount() {
    this.props.dispatch(inboxActions.getInbox());
    this.props.dispatch(peopleActions.getMatrices({ force: true }));
    this.props.dispatch(peopleActions.getFollowedTeams());
    this.props.dispatch(objectivesActions.getPeriods({ force: true }));

    this.props.dispatch(appstatusActions.getAppStatus());

    this.inboxtimer = setInterval(() => {
      this.props.dispatch(inboxActions.getInbox());
      this.props.dispatch(appstatusActions.getAppStatus());
    }, 1000 * 120);
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(newProps) {
    newProps.dispatch(objectivesActions.getPeriods());
    if (!!this.state.mobileOpen && newProps.location.pathname !== this.props.location.pathname) {
      this.setState({ mobileOpen: false });
    }
    newProps.dispatch(peopleActions.getHierarchy());
    newProps.dispatch(peopleActions.getMatrices());
  }

  componentWillUnmount() {
    clearTimeout(this.inboxtimer);
  }

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  render() {
    const {
      classes,
      tenantID,
      pwaInstallPromptEvent,
      isOnline,
      strategyProgram,
      hierarchy,
      isMobile,
      t,
    } = this.props;

    if (!tenantID) {
      return <Navigate to="/missinguserinfo" />;
    }
    return (
      <Suspense fallback={null}>
        <div className={classes.appFrame}>
          <AddToHomeScreenHandler deferredEvent={pwaInstallPromptEvent} />
          {/* Desktop nav drawer */}
          {!isMobile && (
            <Drawer
              variant="permanent"
              classes={{
                paper: classes.drawerPaper,
              }}
              anchor={anchor}
              id="desktop-drawer"
            >
              <MainNav isOnline={isOnline} />
            </Drawer>
          )}
          <div className={classes.contentRoot} id="contentRoot">
            {/* mobile title bar and drawer */}
            {isMobile && (
              <>
                <AppBar className={classes.appBar} position="static">
                  <Toolbar className={classes.toolBar}>
                    <IconButton
                      color="inherit"
                      aria-label={t('general.open')}
                      id="home-mobile-menu-toggle"
                      className={classes.menuButton}
                      onClick={this.handleDrawerToggle}
                      size="large"
                    >
                      <MenuIcon />
                    </IconButton>
                    <div className={classes.pageTitle} />
                    <NotificationsIconButton
                      className={classes.mobileNotificationsIconContainer}
                      color="white"
                    />
                    <div
                      className={clsx([
                        classes.mobileOfflineContainer,
                        !isOnline ? classes.offline : null,
                      ])}
                    >
                      <OfflineIcon />
                    </div>
                  </Toolbar>
                </AppBar>
                <Drawer
                  classes={{
                    paper: classes.drawerMobilePaper,
                  }}
                  variant="temporary"
                  ModalProps={{
                    keepMounted: true,
                    disablePortal: true,
                    BackdropProps: {
                      classes: {
                        root: classes.drawerMobileBackdrop,
                      },
                    },
                  }}
                  anchor={anchor}
                  open={this.state.mobileOpen}
                  onClose={this.handleDrawerToggle}
                  id="mobile-drawer"
                >
                  <MainNav isOnline={isOnline} />
                </Drawer>
              </>
            )}

            <div className={classes.content} id="content">
              {strategyProgram && !!hierarchy && (
                <Suspense fallback={null}>
                  <Routes>
                    <Route path="/" element={<Strategy />} />
                    <Route path="/admin/*" element={<Admin />}>
                      <Route path=":page" element={<Admin />}>
                        <Route path=":extra" element={<Admin />} />
                      </Route>
                    </Route>
                    <Route path="/strategy/*" element={<Strategy />}>
                      <Route path=":page" element={<Strategy />}>
                        <Route path=":extra" element={<Strategy />} />
                      </Route>
                    </Route>
                    <Route path="/teams/*" element={<Teams />}>
                      <Route path=":teamID" element={<Teams />}>
                        <Route path=":page" element={<Teams />}>
                          <Route path=":subpage" element={<Teams />} />
                        </Route>
                      </Route>
                    </Route>
                    <Route path="/programs/*" element={<ChangePrograms />}>
                      <Route path=":program" element={<ChangePrograms />}>
                        <Route path=":page" element={<ChangePrograms />}>
                          <Route path=":extra" element={<ChangePrograms />} />
                        </Route>
                      </Route>
                    </Route>
                    <Route path="/objectives/*" element={<Objectives />}>
                      <Route path=":cadence" element={<Objectives />}>
                        <Route path=":page" element={<Objectives />}>
                          <Route path=":extra" element={<Objectives />} />
                        </Route>
                      </Route>
                    </Route>
                    <Route path="/views/*" element={<Views />}>
                      n
                      <Route path=":selectedTab" element={<Views />}>
                        <Route path=":page" element={<Views />} />
                      </Route>
                    </Route>
                    <Route path="/commitments/*" element={<Commitments />}>
                      <Route path=":commitment" element={<Commitments />} />
                    </Route>
                    <Route path="/interlocks/*" element={<Interlocks />}>
                      <Route path=":interlock" element={<Interlocks />} />
                    </Route>
                    <Route path="/dashboards/*" element={<Dashboards />}>
                      <Route path=":dashboards" element={<Dashboards />} />
                    </Route>
                    <Route path="/people/*" element={<Dashboards />}>
                      <Route path=":userId" element={<Dashboards />}>
                        <Route path=":page" element={<Dashboards />}>
                          <Route path=":subpage" element={<Dashboards />} />
                        </Route>
                      </Route>
                    </Route>
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </Suspense>
              )}
            </div>
          </div>
          <ConnectionHandler />
          <NotificationsHandler />
          <ModalContainer />
          <ServiceWorkerUpdateHandler />
          <ServiceWorkerTokenHandler />
        </div>
      </Suspense>
    );
  }
}

const mapStateToProps = state => ({
  actionlog: state.main.programs.actionlog,
  objectivePeriodConfig: objectivesSelectors.selectPeriodConfig(state.main.objectives),
  isChangeManager: state.auth.isChangeManager,
  isLineManager: state.auth.isLineManager,
  strategyProgram: programsSelectors.selectStrategy(state.main.programs),
  hierarchy: state.main.people.hierarchy,
  // Injecting the whole auth state object to force a re-render when tokens are refreshed:
  auth: state.auth,
  sub: state.auth.userID,
  myTeam: state.auth.teamID,
  tenantID: state.auth.tenantID,
  isOnline: connectionSelectors.selectOnlineStatus(state.main.connection),
});

Home.propTypes = {
  classes: PropTypes.exact({
    appFrame: PropTypes.string,
    appBar: PropTypes.string,
    drawerPaper: PropTypes.string,
    drawerMobilePaper: PropTypes.string,
    drawerMobileBackdrop: PropTypes.string,
    pageTitle: PropTypes.string,
    toolBar: PropTypes.string,
    content: PropTypes.string,
    contentRoot: PropTypes.string,
    menuButton: PropTypes.string,
    mobileOfflineContainer: PropTypes.string,
    offline: PropTypes.string,
    mobileNotificationsIconContainer: PropTypes.string,
    progress: PropTypes.string,
  }),
  dispatch: PropTypes.func,
  tenantID: PropTypes.string,
  pwaInstallPromptEvent: PropTypes.object,
  isOnline: PropTypes.bool,
  strategyProgram: PropTypes.object,
  hierarchy: PropTypes.object,
  isMobile: PropTypes.bool,
  location: PropTypes.object,
  // eslint doing stupid again, this prop is very much used
  // eslint-disable-next-line react/no-unused-prop-types
  auth: PropTypes.object,
  t: PropTypes.func,
};

export default withTranslation()(
  mediaQueryWrapper(
    connect(mapStateToProps)(withLocation(withStyles(styles, { withTheme: true })(Home))),
  ),
);
