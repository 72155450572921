import { PURGE } from 'redux-persist';
import { copyState, validatePersistedState, enrich, apiError, fetching } from 'state/helpers';
import * as types from './types';
import * as constants from '../../constants/api';

export const initialState = {
  VERSION: 1.01,
  context: {
    program: {},
    team: {},
  },
  actionlog: {},
};

function addFetchingContextToState(state, action) {
  const { contextID, contextType } = action.payload;
  const newState = copyState(state);
  newState.context[contextType][contextID] = fetching(newState.context[contextType][contextID]);
  return newState;
}

function addFailedContextToState(state, action) {
  const { request } = action.payload;
  const { contextID, contextType } = request;
  const newState = copyState(state);
  newState.context[contextType][contextID] = apiError(newState.context[contextType][contextID]);
  return newState;
}

function addReceivedContextToState(state, action) {
  const { contextID, contextType, context } = action.payload;
  const newState = copyState(state);
  newState.context[contextType][contextID] = enrich({
    fetchStatus: constants.OK,
    data: context,
    lastFetched: Date.now(),
    maxAge: 1000 * 600,
  });
  return newState;
}

function editContextCardOrder(state, action) {
  const newState = copyState(state);
  const { contextID, contextType, id, column, index } = action.payload;

  // find the card Object
  let prevColumn = null;
  let prevIndex = null;
  newState.context[contextType][contextID].data.forEach((statecolumn, columnIndex) => {
    for (const i of statecolumn.keys()) {
      const card = statecolumn[i];
      if (card.id === id) {
        prevColumn = columnIndex;
        prevIndex = i;
        break;
      }
    }
  });
  if (prevColumn !== null && prevIndex !== null) {
    const card = newState.context[contextType][contextID].data[prevColumn].splice(prevIndex, 1)[0];
    newState.context[contextType][contextID].data[column].splice(index, 0, card);
  }
  newState.actionlog[action.payload.requestID] = { result: 'ok' };
  return newState;
}

function editContextCard(state, action) {
  const newState = copyState(state);
  const { contextID, contextType, card } = action.payload;

  // TODO: clean this up once the migration to ENG-976 is complete
  for (const element of card.elements) {
    if (element.type === 'image') {
      delete element.src;
      delete element.b64str;
    }
  }

  // find the card Object
  let column = null;
  let index = null;
  newState.context[contextType][contextID].data.forEach((statecolumn, columnIndex) => {
    for (const i of statecolumn.keys()) {
      const statecard = statecolumn[i];
      if (statecard.id === card.id) {
        column = columnIndex;
        index = i;
        break;
      }
    }
  });

  if (column !== null && index !== null) {
    newState.context[contextType][contextID].data[column][index] = card;
  }
  newState.actionlog[action.payload.requestID] = { result: 'ok' };
  return newState;
}

function createContextCard(state, action) {
  const newState = copyState(state);
  const { contextID, contextType, card } = action.payload;

  newState.context[contextType][contextID].data[0].splice(0, 0, card);
  newState.context[contextType][contextID] = enrich(newState.context[contextType][contextID]);
  newState.actionlog[action.payload.requestID] = { result: 'ok' };
  return newState;
}

function deleteContextCard(state, action) {
  const newState = copyState(state);
  const { contextID, contextType, id } = action.payload;

  newState.context[contextType][contextID] = { ...newState.context[contextType][contextID] };
  for (let i = 0; i < 3; i++) {
    newState.context[contextType][contextID].data[i] = newState.context[contextType][
      contextID
    ].data[i].filter(card => card.id !== id);
  }
  newState.actionlog[action.payload.requestID] = { result: 'ok' };
  return newState;
}

function addApiErrorToState(state, action) {
  const newState = copyState(state);
  newState.actionlog[action.payload.requestID] = {
    result: 'error',
    message: action.payload.errormsg,
  };
  return newState;
}

// eslint-disable-next-line default-param-last
export default (state = JSON.parse(JSON.stringify(initialState)), action) => {
  state = validatePersistedState(state, initialState);
  switch (action.type) {
    case types.GET_CONTEXT:
      return addFetchingContextToState(state, action);
    case types.RECEIVED_CONTEXT:
      return addReceivedContextToState(state, action);
    case types.FAILED_CONTEXT:
      return addFailedContextToState(state, action);
    case types.MOVE_CONTEXT_CARD: // optimistic rendering
      // TODO: revert back to previous order on error and inform user
      return editContextCardOrder(state, action);
    case types.MOVED_CONTEXT_CARD:
      return editContextCardOrder(state, action);
    case types.EDITED_CONTEXT_CARD:
      return editContextCard(state, action);
    case types.CREATED_CONTEXT_CARD:
      return createContextCard(state, action);
    case types.DELETED_CONTEXT_CARD:
      return deleteContextCard(state, action);
    case types.ERROR_RECEIVED_FROM_API:
      return addApiErrorToState(state, action);

    case PURGE:
      return JSON.parse(JSON.stringify(initialState));
    default:
      return state;
  }
};
