import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import clsx from 'clsx';
import { useActive, useChainedCommands, useCommands, useHelpers } from '@remirror/react';

const CMD_NAME = 'toggleHeading';

const TRANSLATION_NAMESPACE = 'general.richText.textHeadingLevel';

export default function HeadingsMenu({ fontVariantMap }) {
  const { getCommandOptions } = useHelpers();
  const [menuAnchorEl, setMenuAnchorEl] = useState();
  const { t } = useTranslation();
  const menuOpen = Boolean(menuAnchorEl);

  const commands = useCommands();
  const chain = useChainedCommands();
  const active = useActive();
  const options = getCommandOptions(CMD_NAME);

  const isListActive = (active.bulletList() || active.orderedList() || active.taskList()) ?? false;

  let activeHeadingLevel = 0;
  for (let i = 1; i < 4; i++) {
    if (active[options.name]({ level: i })) {
      activeHeadingLevel = i;
    }
  }

  const toggleStyle = level => {
    if (options.disableChaining) {
      commands[CMD_NAME]({ level });
      return;
    }

    let command = chain.toggleHeading({ level });
    if (level === 0) {
      command = chain.convertParagraph();
    }
    command.focus();

    command.run();
    setMenuAnchorEl(null);
  };

  return (
    <>
      <Button
        className={clsx(['TG-texteditor-button'])}
        onClick={event => setMenuAnchorEl(event.currentTarget)}
        disabled={isListActive}
        aria-label="toggle text size"
        sx={{
          '&.MuiButtonGroup-grouped': {
            minWidth: '104px',
            justifyContent: 'space-between',
            paddingRight: '7px', // default 15, reduce by 1 spacing
          },
        }}
      >
        {t(`${TRANSLATION_NAMESPACE}.${activeHeadingLevel}`)}{' '}
        <ExpandMoreIcon sx={{ ml: 0.5 }} fontSize="13px" color="action" />
      </Button>

      <Menu anchorEl={menuAnchorEl} onClose={() => setMenuAnchorEl(null)} open={menuOpen}>
        <MenuItem onClick={() => toggleStyle(1)} selected={activeHeadingLevel === 1}>
          <Typography variant={fontVariantMap.h1}>{t(`${TRANSLATION_NAMESPACE}.1`)}</Typography>
        </MenuItem>
        <MenuItem onClick={() => toggleStyle(2)} selected={activeHeadingLevel === 2}>
          <Typography variant={fontVariantMap.h2}>{t(`${TRANSLATION_NAMESPACE}.2`)}</Typography>
        </MenuItem>
        <MenuItem onClick={() => toggleStyle(3)} selected={activeHeadingLevel === 3}>
          <Typography variant={fontVariantMap.h3}>{t(`${TRANSLATION_NAMESPACE}.3`)}</Typography>
        </MenuItem>
        <MenuItem onClick={() => toggleStyle(0)} selected={activeHeadingLevel === 0}>
          {t(`${TRANSLATION_NAMESPACE}.0`)}
        </MenuItem>
      </Menu>
    </>
  );
}

HeadingsMenu.propTypes = {
  fontVariantMap: PropTypes.object,
};
