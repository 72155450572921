import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import { settingsActions, settingsSelectors } from 'state/ducks/usersettings/index';
import FormContext from 'Components/Library/Forms/FormContext';
import FormRadioGroup from 'Components/Library/Forms/Elements/FormRadioGroup';
import FeatureFlag from 'Components/Library/FeatureFlag';
import Form from 'Components/Library/Forms/index';
import LightModeBg from './light_mode.png';
import DarkModeBg from './dark_mode.png';

const styles = () => ({
  lightMode: {
    backgroundImage: `url(${LightModeBg})`,
    backgroundSize: '100%',
    width: 174,
    height: 187,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  darkMode: {
    backgroundImage: `url(${DarkModeBg})`,
    backgroundSize: '100%',
    width: 174,
    height: 187,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  radioLabel: {
    marginTop: '-31px',
    marginLeft: '-50px',
  },
});

const schema = {
  type: 'object',
  properties: {
    darkmode: { type: 'boolean' },
  },
  required: ['darkmode'],
};

function DarkModeToggle({ classes }) {
  const darkmode = useSelector(state => settingsSelectors.selectDarkMode(state.main.usersettings));
  // , {
  // stabilityCheck: 'never',
  // }
  const { t } = useTranslation();

  return (
    <FeatureFlag flag="DARKMODE">
      <Form
        name="toggle-dark-mode-form"
        initialValues={{
          darkmode: !!darkmode,
        }}
        submitActionCreator={settingsActions.setDarkMode}
        debouncedAutoSubmit={1}
        schema={schema}
        preventSaveIncomplete
      >
        <FormContext.Consumer>
          {formContext => (
            <FormRadioGroup
              fieldName="darkmode"
              valueCastFunc={() => !formContext.values.darkmode}
              render={fieldProps => (
                <RadioGroup {...fieldProps} row>
                  <FormControlLabel
                    key="isLightModeSelected"
                    value="light"
                    control={
                      <div>
                        <div className={classes.lightMode} />
                        <Radio color="primary" checked={!formContext.values.darkmode} />
                      </div>
                    }
                    disableTypography
                    label={
                      <span className={classes.radioLabel}>{t(`usersettings.theme.light`)}</span>
                    }
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  />
                  <FormControlLabel
                    key="isDarkModeSelected"
                    value="dark"
                    control={
                      <div>
                        <div className={classes.darkMode} />
                        <Radio color="primary" checked={formContext.values.darkmode} />
                      </div>
                    }
                    disableTypography
                    label={
                      <span className={classes.radioLabel}>{t(`usersettings.theme.dark`)}</span>
                    }
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '20px',
                    }}
                  />
                </RadioGroup>
              )}
            />
          )}
        </FormContext.Consumer>
      </Form>
    </FeatureFlag>
  );
}

DarkModeToggle.propTypes = {
  classes: PropTypes.exact({
    lightMode: PropTypes.string,
    darkMode: PropTypes.string,
    radioLabel: PropTypes.string,
  }),
  t: PropTypes.func,
};

export default withStyles(styles)(DarkModeToggle);
