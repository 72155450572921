import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import List from '@mui/material/List';
import OkrIcon from '@mui/icons-material/TrackChanges';
import CommitmentsIcon from '@mui/icons-material/Task';
import ViewsIcon from '@mui/icons-material/AccountTree';
import AdminIcon from '@mui/icons-material/SupervisorAccount';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import GlobalSearchOverlay from 'Components/Library/GlobalSearch/index';
import InterlockIcon from 'Components/Features/Interlocks/InterlockIcon';
import Notifications from './Notifications';
import LinkItem from './LinkItem';
import FeatureFlag from '../FeatureFlag';

class ToolSelector extends Component {
  render() {
    const { classes, t, isAdmin } = this.props;
    return (
      <div className={classes.selectorRoot}>
        <List dense component="div">
          <GlobalSearchOverlay />
          <Notifications />
          <FeatureFlag flag="DASHBOARDS">
            <LinkItem
              to="dashboards"
              linkIcon={<AssignmentIndIcon color="featureDashboards" />}
              linkText={t('dashboards.navMenu')}
              variant="large"
            />
          </FeatureFlag>
          {isAdmin && (
            <LinkItem
              to="admin"
              linkIcon={<AdminIcon fontSize="small" />}
              linkText={t('admin.pageTitle')}
              variant="large"
            />
          )}
          <LinkItem
            to="objectives"
            linkIcon={<OkrIcon fontSize="small" color="featureOkr" />}
            linkText={t('objectives.pageTitle')}
            variant="large"
          />
          <FeatureFlag flag="COMMITMENTS">
            <LinkItem
              to="commitments"
              linkIcon={<CommitmentsIcon fontSize="small" color="featureCommitments" />}
              linkText={t('commitments.pageTitle')}
              variant="large"
            />
          </FeatureFlag>
          <FeatureFlag flag="INTERLOCKS">
            <LinkItem
              to="interlocks"
              linkIcon={<InterlockIcon viewBox="0 -3 18 18" />}
              linkText={t('interlocks.pageTitle')}
              variant="large"
            />
          </FeatureFlag>
          <LinkItem
            to="views"
            linkIcon={<ViewsIcon fontSize="small" color="featureViews" />}
            linkText={t('views.navTitle')}
            variant="large"
          />
        </List>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAdmin: state.auth.isAdmin,
});

ToolSelector.propTypes = {
  t: PropTypes.func,
  isAdmin: PropTypes.bool,
  classes: PropTypes.shape({
    selectorRoot: PropTypes.string,
  }),
};

export default connect(mapStateToProps)(withTranslation()(ToolSelector));
