import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@mui/styles';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ActivityEntry from 'Components/Library/ActivityLog/ActivityEntry';

import { peopleSelectors } from 'state/ducks/people';
import { NODE_TYPE_INTERLOCK } from 'config/constants';
import { getStatusColor } from 'Components/Library/Contributors/helpers';

const REL_TYPE_TRANSLATION_KEYS = {
  // Map of edge type constants to their translation keys
  CONTRIBUTOR: {
    TEAM: 'activitylogs.attrNames.generic.contributor_TEAM',
    USER: 'activitylogs.attrNames.generic.contributor_USER',
    OWNER: 'activitylogs.attrNames.generic.contributor_USER',
  },
};

const REL_TYPE_TRANSLATION_KEYS_INTELOCK = {
  // Map of edge type constants to their translation keys
  CONTRIBUTOR: {
    TEAM: 'activitylogs.attrNames.generic.participant_TEAM',
    USER: 'activitylogs.attrNames.generic.participant_USER',
    OWNER: 'activitylogs.attrNames.generic.participant_USER',
  },
};

const EDIT_VERBS = {
  ADD: 'activitylogs.addedRelationVerb',
  UPDATE: 'activitylogs.updatedRelationVerb',
  DELETE: 'activitylogs.removedRelationVerb',
};

const NODETYPE_TRANSLATION_NAMESPACE = {
  [NODE_TYPE_INTERLOCK]: 'interlocks',
};

class RelationEditEvent extends React.Component {
  render() {
    const { t, theme, event, nodeType, selectFullName, selectTeamName } = this.props;
    const { owner, timestamp, ignore } = event;
    const eventGraphId = event.graph_id;

    const translationNameSpace = get(NODETYPE_TRANSLATION_NAMESPACE, nodeType, '');

    if (ignore) {
      return null;
    }

    return (
      <ActivityEntry
        name="KR-UPDATE-EVT"
        sub={owner}
        title=""
        timestamp={timestamp}
        collapseConfig={{
          collapseCta: `(${t('general.hide')})`,
          expandCta: `(${t('general.show')})`,
          initialState: 'expanded',
          allowChange: false,
        }}
        message={
          !!event.comment && (
            <Typography variant="body2" component="span" display="block" gutterBottom>
              {event.comment}
            </Typography>
          )
        }
        metaDataMessage={
          <Typography
            variant="body2"
            component="span"
            display="block"
            color="text.secondary"
            gutterBottom
          >
            {get(event, 'changes', []).map((change, i) => {
              const verb = t(get(EDIT_VERBS, change.action, ''));

              // In the future when this component will support other relations
              // than contributors, we're most likely going some better logic here.
              // For now, this works:
              const noun =
                nodeType === 'INTERLOCK'
                  ? t(
                      REL_TYPE_TRANSLATION_KEYS_INTELOCK[change.type][
                        change.extra.contributor_type
                      ],
                    )
                  : t(REL_TYPE_TRANSLATION_KEYS[change.type][change.extra.contributor_type]);

              // In the future, there's probably a need for some "GraphItemString" or similar
              // component that identifies the element type based on ID and is able to then
              // render it's name.

              // For now, we will only have teams and users so let's handle it here:
              const otherNodeId = change.from === event.nodeId ? change.to : change.from;
              const [otherNodeType, unprefixedOtherNodeId] = otherNodeId.split('_');
              let subject;
              switch (otherNodeType) {
                case 'USR':
                  subject = selectFullName(unprefixedOtherNodeId);
                  break;
                case 'TEAM':
                  subject = selectTeamName(unprefixedOtherNodeId);
                  break;
                default:
                  break;
              }
              const complement =
                change.action === 'UPDATE' ? (
                  <>
                    ⟶ &nbsp;
                    <Box component="b" sx={{ color: getStatusColor(theme, change.extra.status) }}>
                      {t(
                        `${translationNameSpace}.contributorStatusValueText.${change.extra.status}`,
                      )}
                    </Box>
                  </>
                ) : (
                  ''
                );
              return (
                <React.Fragment key={`${eventGraphId}-change-${i}`}>
                  {` ${verb} ${noun}`} <strong>{subject}</strong> {complement}
                </React.Fragment>
              );
            })}
          </Typography>
        }
      />
    );
  }
}

const mapStateToProps = state => ({
  selectFullName: sub => peopleSelectors.selectFullName(state.main.people, sub),
  selectTeamName: id => peopleSelectors.selectTeamName(state.main.people, id),
});

RelationEditEvent.propTypes = {
  classes: PropTypes.exact({
    green: PropTypes.string,
    amber: PropTypes.string,
    red: PropTypes.string,
    grey: PropTypes.string,
  }),
  event: PropTypes.object,
  theme: PropTypes.object,
  selectFullName: PropTypes.func,
  selectTeamName: PropTypes.func,
  nodeType: PropTypes.string,
  t: PropTypes.func,
};

export default withTranslation()(withTheme(connect(mapStateToProps)(RelationEditEvent)));
