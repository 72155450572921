export const APIGW_URL = process.env.REACT_APP_APIGW_URL;
export const COGNITO_ENDPOINT_URL = '.auth.eu-west-1.amazoncognito.com';
export const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;
export const LOGOUT_URL = process.env.REACT_APP_LOGOUT_URL;

export const OK = '1';
export const ERROR = '-1';
export const RETRY = '-10';
export const PARTIAL = '-12';
export const FETCHING = '0';
export const REFRESHING = '-13';
export const NOT_TRIED = '10';
export const DELETED = '-100';
export const DOES_NOT_EXIST = '-404';

export const AVATARS_URL = `${APIGW_URL}/avatars`;
export const API_BASE_URL = `${APIGW_URL}/mystrategy`;
export const API_OBJECTIVES_URL = `${APIGW_URL}/objectives`;
export const API_FACILITATION_URL = `${APIGW_URL}/facilitation`;
export const TENANT_API_BASE_URL = `${APIGW_URL}/discovertenant`;
export const ORG_API_BASE_URL = `${APIGW_URL}/myorg`;
export const INTEGRATIONS_API_BASE_URL = `${APIGW_URL}/integrations`;
export const API_GLOBAL_SEARCH_URL = `${APIGW_URL}/textsearch`;
