import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { objectivesActions, objectivesSelectors, objectivesHelpers } from 'state/ducks/objectives';
import KeyresultChip from './KeyresultChip';

class KeyresultChipWrapper extends React.Component {
  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate() {
    this.fetchData();
  }

  fetchData = () => {
    if (!!this.props.objectiveData && !!!this.props.objectiveData.ok && !!this.props.objectiveID) {
      this.props.dispatch(
        objectivesActions.getObjective({ objectiveIDs: [this.props.objectiveID] }),
      );
    }
  };

  render() {
    return (
      <KeyresultChip
        keyresult={this.props.keyresultData}
        objectiveData={this.props.objectiveData}
        location={this.props.location}
        wrapText={this.props.wrapText}
        actionable={this.props.actionable}
        color={this.props.color}
        hideStatus={this.props.hideStatus}
        hideOwner={this.props.hideOwner}
        ContextIcon={this.props.ContextIcon}
        size={this.props.size}
        sx={this.props.sx}
        onClick={this.props.onClick}
        placeholder={this.props.placeholder}
        allowNavigate={this.props.allowNavigate}
        contextIconProps={this.props.contextIconProps}
      />
    );
  }
}

KeyresultChipWrapper.propTypes = {
  keyresultData: PropTypes.object,
  objectiveData: PropTypes.object,
  // Used in mapStateToProps:
  // eslint-disable-next-line react/no-unused-prop-types
  keyresult: PropTypes.object,
  // eslint-disable-next-line react/no-unused-prop-types
  keyresultID: PropTypes.string,
  objectiveID: PropTypes.string,
  dispatch: PropTypes.func,
  wrapText: PropTypes.bool,
  actionable: PropTypes.bool,
  allowNavigate: PropTypes.bool,
  color: PropTypes.oneOf(['white', 'grey', 'transparent', 'darkgrey']),
  ContextIcon: PropTypes.elementType,
  hideStatus: PropTypes.bool,
  hideOwner: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'normal', 'large']),
  sx: PropTypes.object,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  contextIconProps: PropTypes.object,
};

KeyresultChipWrapper.defaultProps = {
  allowNavigate: true,
};

const mapStateToProps = (state, ownProps) => {
  let keyresultData;
  let objectiveData;
  let objectiveID;
  if (!!ownProps.keyresult) {
    keyresultData = ownProps.keyresult;
  } else if (!!ownProps.keyresultID) {
    objectiveID = objectivesHelpers.parseObjectiveIDfromKeyresultID(ownProps.keyresultID);
    objectiveData = objectivesSelectors.selectObjective(state.main.objectives, objectiveID);
    keyresultData = objectivesSelectors.selectKeyresult(
      state.main.objectives,
      ownProps.keyresultID,
    );
  }
  return {
    keyresultData,
    objectiveData,
    objectiveID,
  };
};

export default connect(mapStateToProps)(KeyresultChipWrapper);
