import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import CommitmentsIcon from '@mui/icons-material/Task';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import UserChip from 'Components/Library/UserChip';
import DataObjectChip from 'Components/Library/DataObjectChip';
import StatusSelector from 'Components/Library/StatusSelector';
import Form from 'Components/Library/Forms/';
import LinearIndicator from 'Components/Library/Forms/Elements/LinearIndicator';
import EmphasizedSection from 'Components/Library/EmphasizedSection';
import FormSelectorField from 'Components/Library/Forms/Elements/FormSelectorField';
import UserSelector from 'Components/Library/UserSelector';
import { COM_STATUS_COLORS } from 'config/constants';
import Contributors from 'Components/Library/Contributors/index';

import { commitmentsActions, commitmentsSelectors } from 'state/ducks/commitments/index';
import { editSchema } from '../../schema';
import { calendarValue, isPastDate, createReduxAction } from './helpers';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  content: {
    marginBottom: theme.spacing(2),
    '& > div:first-of-type': {
      marginTop: theme.spacing(-2),
      marginLeft: theme.spacing(-2),
      marginBottom: theme.spacing(2),
    },
  },
  leftColumn: {
    width: 100,
    flexShrink: 0,
    color: theme.palette.text.secondary,
  },
  rightColumn: {
    minWidth: 0, // Fix text ellipsis in flexbox
  },
  chipLabel: {
    paddingTop: 3,
    marginBottom: theme.spacing(1),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(),
    '&:last-child': {
      marginBottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
});

class CommitmentDetails extends React.Component {
  renderDate = (params, fieldProps) => {
    const { commitmentData, t, theme, canEdit } = this.props;
    const isPast = isPastDate();
    if (commitmentData?.data?.due_date) {
      return (
        <Chip
          sx={{
            backgroundColor: 'background.paper',
            color: isPast ? theme.palette.warning.main : '',
            height: 32,
            pl: 0.75,
            pr: 0.75,
            borderRadius: '10px',
          }}
          label={params.inputProps.value}
          onClick={params.onClick}
          id="commitments-date-selector"
          onDelete={canEdit ? () => fieldProps.onSelect(null) : undefined}
          deleteIcon={
            canEdit ? (
              <HighlightOffIcon
                id="commitments-date-delete"
                sx={{
                  color: theme.palette.primary[500],
                  width: 18,
                  height: 18,
                }}
              />
            ) : undefined
          }
          icon={
            <CalendarTodayIcon
              sx={{
                height: 14.67,
                '&.MuiChip-icon': {
                  marginLeft: '0px !important',
                  marginRight: '-10px !important',
                },
              }}
            />
          }
        />
      );
    }
    if (canEdit) {
      return (
        <Button
          variant="text"
          color="secondary"
          onClick={params.onClick}
          id="commitments-date-selector"
        >
          {t('commitments.dateInputLabel')}
        </Button>
      );
    }

    return (
      <DataObjectChip
        name="commitments-noDate"
        text={
          <Typography align="center" color="text.disabled">
            {t('commitments.propertyLabels.noDate')}
          </Typography>
        }
        color="darkgrey"
      />
    );
  };

  render() {
    const { classes, commitmentData, t, canEdit, onSubmitSuccess, canDelete } = this.props;

    let initialValues = {};
    if (commitmentData?.ok) {
      initialValues = {
        status: commitmentData.data.status,
        due_date: commitmentData.data.due_date,
        owner: commitmentData.data.owner,
      };
    }

    return (
      <Form
        name="panel-view-commitments-details"
        debouncedAutoSubmit={5}
        schema={editSchema}
        initialValues={initialValues}
        stateSlice="main.commitments"
        allowRefreshData
        submitActionCreator={params => createReduxAction(params, commitmentData)}
        additionalProperties={{
          id: commitmentData?.data?.id,
        }}
        onSubmitSuccess={() => {
          if (!!onSubmitSuccess) {
            onSubmitSuccess();
          }
        }}
      >
        <LinearIndicator positioning="absolute" />
        <div id="commitment-details-container" className={classes.root}>
          <EmphasizedSection className={classes.content}>
            <DataObjectChip
              Icon={CommitmentsIcon}
              text={t('commitments.pageTitleSingle')}
              variant="normal"
              contextIconProps={{
                sx: {
                  color: '#F6C944',
                },
              }}
            />
            <div className={classes.row}>
              <div className={clsx(classes.leftColumn, classes.chipLabel)}>
                <Typography variant="subtitle2" color="text.secondary">
                  {t('commitments.propertyLabels.due_date')}
                </Typography>
              </div>
              <div className={classes.rightColumn}>
                <FormSelectorField
                  fieldName="due_date"
                  render={fieldProps => (
                    <MobileDatePicker
                      label={t('commitments.propertyLabels.due_date')}
                      value={calendarValue(commitmentData)}
                      inputFormat="DD.MM.YYYY"
                      // note that here we need to use the endOf('day') to make more certain that
                      // the ts is still in the future as expected by the backend in UTC
                      onChange={selectedMoment =>
                        fieldProps.onSelect(selectedMoment.endOf('day').unix())
                      }
                      closeOnSelect
                      disablePast
                      renderInput={params => this.renderDate(params, fieldProps)}
                      disabled={!canEdit}
                      sx={{
                        '& .MuiPickersToolbar-penIconButton': {
                          display: 'none',
                        },
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className={classes.row}>
              <div className={clsx(classes.leftColumn, classes.chipLabel)}>
                <Typography variant="subtitle2" color="text.secondary">
                  {t('commitments.propertyLabels.status')}
                </Typography>
              </div>
              <div className={classes.rightColumn}>
                <FormSelectorField
                  fieldName="status"
                  render={fieldProps => (
                    <StatusSelector
                      {...fieldProps}
                      disabled={!canEdit}
                      options={Object.keys(COM_STATUS_COLORS).map(status => ({
                        text: t(`commitments.statusValueText.${status}`),
                        option: status,
                        color: COM_STATUS_COLORS[status],
                      }))}
                    />
                  )}
                />
              </div>
            </div>
            <Divider sx={{ mb: 1.5, mt: 0.5 }} />
            <div className={classes.row}>
              <div className={clsx(classes.leftColumn, classes.chipLabel)}>
                <Typography variant="subtitle2" color="text.secondary">
                  {t('commitments.contributorValueText.CREATOR')}
                </Typography>
              </div>
              <div className={classes.rightColumn}>
                <UserChip color="grey" sub={commitmentData?.data?.creator} />
              </div>
            </div>
            <Divider sx={{ mb: 1.5, mt: 0.5 }} />
            <div className={classes.row}>
              <div className={clsx(classes.leftColumn, classes.chipLabel)}>
                <Typography variant="subtitle2" color="text.secondary">
                  {t('commitments.contributorValueText.OWNER')}
                </Typography>
              </div>
              <div className={classes.rightColumn}>
                <FormSelectorField
                  fieldName="owner"
                  render={fieldProps => (
                    <UserSelector showRecent {...fieldProps} color="white" disabled={!canEdit} />
                  )}
                />
              </div>
            </div>
            <Divider sx={{ mb: 1.5, mt: 0.5 }} />
            <div className={classes.row}>
              <div className={clsx(classes.leftColumn, classes.chipLabel)}>
                <Typography variant="subtitle2" color="text.secondary">
                  {t('commitments.propertyLabels.contributors')}
                </Typography>
              </div>
              <div className={classes.rightColumn}>
                <Contributors
                  instanceName="commitments"
                  selectContributors={commitmentsSelectors.selectContributors}
                  actions={commitmentsActions}
                  instanceData={commitmentData}
                  canEdit={canEdit}
                  canDelete={canDelete}
                  onSubmitSuccess={onSubmitSuccess}
                />
              </div>
            </div>
          </EmphasizedSection>
        </div>
      </Form>
    );
  }
}

CommitmentDetails.propTypes = {
  classes: PropTypes.exact({
    root: PropTypes.string,
    content: PropTypes.string,
    row: PropTypes.string,
    chipLabel: PropTypes.string,
    rightColumn: PropTypes.string,
    leftColumn: PropTypes.string,
  }),
  commitmentData: PropTypes.object,
  t: PropTypes.func,
  theme: PropTypes.object,
  dispatch: PropTypes.func,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
  onSubmitSuccess: PropTypes.func,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(CommitmentDetails));
