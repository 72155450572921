import { idRegex } from 'state/ducks/objectives/helpers';

// eslint-disable-next-line import/prefer-default-export
export const getNodeModalPath = graphId => {
  /* Return path to navigate to a node based on it's graph ID */
  const nodeTypePrefix = graphId.split('_')[0];

  switch (nodeTypePrefix) {
    case 'OBJ':
      if (idRegex.test(graphId)) {
        return `#modal/objectives/viewkr/-/${graphId}`;
      }
      return `#modal/objectives/view/-/${graphId}`;
    case 'INTERLOCK':
      return `#modal/interlocks/view/${graphId}`;
    case 'COM':
      return `#modal/commitments/view/${graphId}`;
    case 'USER':
      return `/home/people/${graphId.split('_')[1]}`;
    default:
      return null;
  }
};
