import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import Profile from './ProfileComponent';
import OfflineText from './OfflineText';
import ToolSelector from './ToolSelector';
import TeamSelector from './TeamSelector';
import TeamsLedSelector from './TeamsLedSelector';
import FollowedTeamsSelector from './FollowedTeamsSelector';
import ProgramSelector from './ProgramSelector';

const styles = theme => ({
  profileContainer: {
    height: '40px',
    width: '100%',
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxSizing: 'border-box',
  },
  expandLink: {
    ...theme.typography.subtitle2,
    fontWeight: 200,
    color: theme.palette.secondary.main,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    cursor: 'pointer',
  },
  addProgramLink: {
    ...theme.typography.subtitle2,
    fontWeight: 200,
    color: theme.palette.secondary.main,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    cursor: 'pointer',
  },
  scrollable: {
    overflowY: 'auto',
    width: 'calc(100% - 10px)',
    paddingLeft: 10,
    scrollbarGutter: 'stable',
    paddingTop: theme.spacing(2),
  },
  selectorRoot: {
    marginBottom: theme.spacing(3),
    '& button svg': {
      /* override styles for the section titles */
      display: 'block',
      // color: theme.palette.primary[600],
    },
  },
  root: {
    width: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    overflowY: 'hidden',
    '& .MuiList-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
});

function MainNav({ classes, isOnline }) {
  return (
    <div className={classes.root}>
      <div className={classes.profileContainer}>
        <Profile />
      </div>
      <div className={classes.scrollable}>
        <OfflineText isOnline={isOnline} />
        <ToolSelector classes={classes} />
        <TeamsLedSelector classes={classes} />
        <TeamSelector classes={classes} />
        <FollowedTeamsSelector classes={classes} />
        <ProgramSelector classes={classes} />
      </div>
    </div>
  );
}

MainNav.propTypes = {
  classes: PropTypes.exact({
    profileContainer: PropTypes.string,
    expandLink: PropTypes.string,
    addProgramLink: PropTypes.string,
    selectorRoot: PropTypes.string,
    root: PropTypes.string,
    scrollable: PropTypes.string,
  }),
  isOnline: PropTypes.bool,
};

export default withStyles(styles)(MainNav);
