import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import DataObjectChip from 'Components/Library/DataObjectChip';
import KeyresultsIcon from 'Components/Features/Objectives/KeyresultsIcon';
import KeyresultChip from 'Components/Features/Objectives/KeyresultChip';
import { withLocation, withNavigation } from 'withRouter';

import { openAddKeyResultPanel } from 'config/ModalProvider/helpers';

const styles = theme => ({
  root: {
    ...theme.shape,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.box,
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    boxSizing: 'border-box',
  },
});

class KeyresultsList extends React.Component {
  openAddKrPanel = () => {
    openAddKeyResultPanel(this.props.objectiveID, this.props.navigate, this.props.location);
  };

  render() {
    const { t, classes, keyresults, canEdit } = this.props;
    return (
      <div className={classes.root} name="keyresult-card-list">
        <DataObjectChip
          Icon={KeyresultsIcon}
          text={t('objectives.genericDataObjectNameKeyresults')}
          color="transparent"
          sx={{ mt: -1, ml: -1 }}
        />
        {keyresults.length > 0 && (
          <Box
            sx={{
              backgroundColor: 'background.paper',
              borderRadius: 1,
              p: 1,
              m: 1,
              '& button:last-child': {
                mb: 0,
              },
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {keyresults.map((kr, i) => (
              <KeyresultChip
                key={`kr-cardlist-card-${i}`}
                keyresult={kr}
                wrapText
                color="white"
                canEditObjective={canEdit}
                sx={{ marginBottom: 1 }}
                ContextIcon={null}
              />
            ))}
          </Box>
        )}

        {canEdit && (
          <Button
            color="secondary"
            variant="text"
            style={{ alignSelf: 'flex-start' }}
            sx={{ mt: 0, mb: -1, ml: -1 }}
            onClick={this.openAddKrPanel}
            name="add-keyresult-fab"
          >
            {`+ ${t('objectives.addkeyresultcta')}`}
          </Button>
        )}

        {keyresults.length === 0 && !canEdit && (
          <Typography variant="tiny">{t('objectives.nokeyresultsinfotext')}</Typography>
        )}
      </div>
    );
  }
}

KeyresultsList.propTypes = {
  keyresults: PropTypes.array,
  classes: PropTypes.exact({
    root: PropTypes.string,
  }),
  canEdit: PropTypes.bool,
  objectiveID: PropTypes.string,
  location: PropTypes.object,
  navigate: PropTypes.func,
  t: PropTypes.func,
};

export default withLocation(withNavigation(withTranslation()(withStyles(styles)(KeyresultsList))));
