import { PURGE } from 'redux-persist';
import { validatePersistedState, enrich, copyState, fetching } from 'state/helpers';
import * as types from './types';
import * as constants from '../../constants/api';

export const initialState = {
  VERSION: 1.01,
  actionlog: {},
  sessions: {},
  teamSessions: {},
  heartbeats: {},
  inputs: {},
  votes: {},
  summaries: {},
};

function addStartedSessionToState(state, action) {
  const newState = copyState(state);
  newState.actionlog[action.payload.requestID] = {
    result: 'ok',
    data: { session_id: action.payload.sessionID },
  };
  newState.sessions[action.payload.sessionID] = enrich({
    fetchStatus: constants.OK,
    id: action.payload.sessionID,
    questions: action.payload.questions,
    topic: action.payload.topic,
    description: action.payload.description,
    teamID: action.payload.teamID,
    lastFetched: Date.now(),
    maxAge: 1000 * 60 * 60,
    status: 0,
    owner: action.payload.owner,
  });
  if (!newState.teamSessions[action.payload.teamID]) {
    newState.teamSessions[action.payload.teamID] = enrich({
      fetchStatus: constants.PARTIAL,
      data: [],
    });
  } else {
    newState.teamSessions[action.payload.teamID].data = newState.teamSessions[
      action.payload.teamID
    ].data.filter(sessionID => sessionID !== action.payload.sessionID);
  }
  newState.teamSessions[action.payload.teamID].data.push(action.payload.sessionID);
  newState.teamSessions[action.payload.teamID].data.sort();
  newState.teamSessions[action.payload.teamID].data.reverse();
  return newState;
}

function addFetchedSessionToState(state, action) {
  const newState = copyState(state);
  newState.sessions[action.payload.id] = enrich({
    status: action.payload.state,
    id: action.payload.id,
    fetchStatus: constants.OK,
    questions: action.payload.questions,
    topic: action.payload.topic,
    description: action.payload.description,
    owner: action.payload.owner,
    teamID: action.payload.teamID,
    lastFetched: Date.now(),
    maxAge: 1000 * 60 * 60,
  });
  if (Array.isArray(newState.teamSessions[action.payload.teamID])) {
    if (!newState.teamSessions[action.payload.teamID]) {
      newState.teamSessions[action.payload.teamID] = {};
    } else {
      newState.teamSessions[action.payload.teamID] = newState.teamSessions[
        action.payload.teamID
      ].filter(sessionID => sessionID !== action.payload.sessionID);
    }
    newState.teamSessions[action.payload.teamID].push(action.payload.sessionID);
    newState.teamSessions[action.payload.teamID].sort();
    newState.teamSessions[action.payload.teamID].reverse();
  }
  return newState;
}

function addFetchingSessionToState(state, action) {
  const newState = copyState(state);
  newState.sessions[action.payload.sessionID] = fetching(
    newState.sessions[action.payload.sessionID],
  );
  return newState;
}

function addFetchedInputsToState(state, action) {
  const newState = copyState(state);
  newState.inputs[action.payload.sessionID] = enrich({
    fetchStatus: constants.OK,
    lastFetched: Date.now(),
    maxAge: 1000 * 60 * 60,
    ...action.payload,
  });
  return newState;
}

function addFetchingInputsToState(state, action) {
  const newState = copyState(state);
  newState.inputs[action.payload.sessionID] = fetching(newState.inputs[action.payload.sessionID]);
  return newState;
}

function addFetchedVotesToState(state, action) {
  const newState = copyState(state);
  newState.votes[action.payload.sessionID] = enrich({
    fetchStatus: constants.OK,
    lastFetched: Date.now(),
    maxAge: 1000 * 60 * 60,
    ...action.payload,
  });
  return newState;
}

function addFetchingVotesToState(state, action) {
  const newState = copyState(state);
  newState.votes[action.payload.sessionID] = fetching(newState.votes[action.payload.sessionID]);
  return newState;
}

function addFetchedSummaryToState(state, action) {
  const newState = copyState(state);
  newState.summaries[action.payload.sessionID] = enrich({
    fetchStatus: constants.OK,
    lastFetched: Date.now(),
    maxAge: 1000 * 60 * 60,
    summary: action.payload.summary,
  });
  return newState;
}

function addFetchingSummaryToState(state, action) {
  const newState = copyState(state);
  newState.summaries[action.payload.sessionID] = fetching(
    newState.summaries[action.payload.sessionID],
  );
  return newState;
}

function addSubmittedInputToState(state, action) {
  const newState = copyState(state);
  newState.actionlog[action.payload.requestID] = {
    result: 'ok',
  };
  if (newState.heartbeats[action.payload.sessionID]) {
    newState.heartbeats[action.payload.sessionID].users.forEach(user => {
      if (user.user_id === action.payload.sub) {
        user.state = 1;
      }
    });
  }
  return newState;
}

function addSubmittedVotesToState(state, action) {
  const newState = copyState(state);
  newState.actionlog[action.payload.requestID] = {
    result: 'ok',
  };
  if (newState.heartbeats[action.payload.sessionID]) {
    newState.heartbeats[action.payload.sessionID].users.forEach(user => {
      if (user.user_id === action.payload.sub) {
        user.state = 3;
      }
    });
  }
  return newState;
}

function addProgressedStateToState(state, action) {
  const newState = copyState(state);
  newState.actionlog[action.payload.requestID] = {
    result: 'ok',
  };
  if (newState.heartbeats[action.payload.sessionID]) {
    newState.heartbeats[action.payload.sessionID].session_state = action.payload.toState;
    newState.heartbeats[action.payload.sessionID].users.forEach(user => {
      if (user.user_id === action.payload.sub) {
        user.state = action.payload.toState;
      }
    });
  }
  if (
    newState.sessions[action.payload.sessionID] &&
    newState.sessions[action.payload.sessionID].ok
  ) {
    newState.sessions[action.payload.sessionID] = {
      ...newState.sessions[action.payload.sessionID],
      status: action.payload.toState,
    };
  }
  return newState;
}

function addSubmittedSummaryToState(state, action) {
  const newState = copyState(state);
  newState.actionlog[action.payload.requestID] = {
    result: 'ok',
  };
  if (newState.heartbeats[action.payload.sessionID]) {
    newState.heartbeats[action.payload.sessionID].session_state = 5;
    newState.heartbeats[action.payload.sessionID].users.forEach(user => {
      if (user.user_id === action.payload.sub) {
        user.state = 5;
      }
    });
  }
  if (
    newState.sessions[action.payload.sessionID] &&
    newState.sessions[action.payload.sessionID].ok
  ) {
    newState.sessions[action.payload.sessionID] = {
      ...newState.sessions[action.payload.sessionID],
      status: 5,
    };
  }
  newState.summaries[action.payload.sessionID] = enrich({ summary: action.payload.summary });
  return newState;
}

function addHeartbeatToState(state, action) {
  const newState = copyState(state);
  newState.actionlog[action.payload.requestID] = {
    result: 'ok',
    data: { session_id: action.payload.sessionID },
  };
  newState.heartbeats[action.payload.sessionID] = action.payload;
  if (
    newState.sessions[action.payload.sessionID] &&
    newState.sessions[action.payload.sessionID].ok
  ) {
    newState.sessions[action.payload.sessionID].status = action.payload.session_state;
  }
  return newState;
}

function addFetchingSessionListToState(state, action) {
  const newState = copyState(state);
  newState.teamSessions[action.payload.teamID] = fetching(
    newState.teamSessions[action.payload.teamID],
  );
  return newState;
}

function addSessionListToState(state, action) {
  const newState = copyState(state);
  newState.teamSessions[action.payload.teamID] = enrich({
    fetchStatus: constants.OK,
    data: !!action.payload.startkey ? newState.teamSessions[action.payload.teamID].data : [],
    lastEvaluated: action.payload.lastevaluatedkey,
    lastFetched: Date.now(),
    maxAge: 1000 * 60 * 2,
  });
  action.payload.sessions.forEach(session => {
    newState.sessions[session.id] = enrich({
      status: session.state,
      id: session.id,
      fetchStatus: constants.OK,
      questions: session.questions,
      topic: session.topic,
      description: session.description,
      owner: session.owner,
      teamID: session.teamID,
      lastFetched: Date.now(),
      maxAge: 1000 * 60 * 60,
    });
    if (!newState.teamSessions[action.payload.teamID].data.includes(session.id)) {
      newState.teamSessions[action.payload.teamID].data.push(session.id);
    }
  });
  newState.teamSessions[action.payload.teamID].data.sort();
  newState.teamSessions[action.payload.teamID].data.reverse();
  return newState;
}

function deleteSessionFromState(state, action) {
  const newState = copyState(state);
  const { sessionID, teamID } = action.payload;

  delete newState.sessions[sessionID];
  newState.teamSessions[teamID].data = newState.teamSessions[teamID].data.filter(
    item => item !== sessionID,
  );
  newState.actionlog[action.payload.requestID] = {
    result: 'ok',
  };

  return newState;
}

function addGenericApiErrorToState(state, action) {
  const newState = copyState(state);
  newState.actionlog[action.payload.requestID] = {
    result: 'error',
    message: action.payload.errormsg,
  };
  return newState;
}

function addFailedToFetchErrorToState(state, action) {
  const newState = copyState(state);
  newState.actionlog[action.payload.requestID] = {
    result: 'error',
    message: action.payload.errormsg,
  };
  newState.sessions[action.payload.sessionID] = enrich({
    fetchStatus: constants.ERROR,
    lastFetched: Date.now(),
    maxAge: 1000 * 60 * 60,
    id: action.payload.sessionID,
  });
  return newState;
}

// eslint-disable-next-line default-param-last
export default (state = JSON.parse(JSON.stringify(initialState)), action) => {
  state = validatePersistedState(state, initialState);
  switch (action.type) {
    case types.STARTED_FACILITATION_SESSION:
      return addStartedSessionToState(state, action);
    case types.FACILITATION_SESSION_DELETED:
      return deleteSessionFromState(state, action);
    case types.FETCHED_FACILITATION_SESSION:
      return addFetchedSessionToState(state, action);
    case types.FETCH_FACILITATION_SESSION:
      return addFetchingSessionToState(state, action);
    case types.FETCHED_FACILITATION_INPUTS:
      return addFetchedInputsToState(state, action);
    case types.FETCH_FACILITATION_INPUTS:
      return addFetchingInputsToState(state, action);
    case types.FETCHED_FACILITATION_VOTES:
      return addFetchedVotesToState(state, action);
    case types.FETCH_FACILITATION_VOTES:
      return addFetchingVotesToState(state, action);
    case types.FETCHED_FACILITATION_SUMMARY:
      return addFetchedSummaryToState(state, action);
    case types.FETCH_FACILITATION_SUMMARY:
      return addFetchingSummaryToState(state, action);
    case types.LIST_FACILITATION_SESSIONS:
      return addFetchingSessionListToState(state, action);
    case types.FETCHED_FACILITATION_SESSION_LIST:
      return addSessionListToState(state, action);
    case types.SUBMITTED_INPUT:
      return addSubmittedInputToState(state, action);
    case types.SUBMITTED_SUMMARY:
      return addSubmittedSummaryToState(state, action);
    case types.SUBMITTED_VOTES:
      return addSubmittedVotesToState(state, action);
    case types.RECEIVED_HEARTBEAT:
      return addHeartbeatToState(state, action);
    case types.STATE_PROGRESSED:
      return addProgressedStateToState(state, action);
    case types.ERROR_RECEIVED_FROM_API:
      return addGenericApiErrorToState(state, action);
    case types.ERROR_FAILED_TO_FETCH_SESSION:
      return addFailedToFetchErrorToState(state, action);
    case PURGE:
      return JSON.parse(JSON.stringify(initialState));
    default:
      return state;
  }
};
