/* eslint-disable camelcase */
/* eslint-disable no-console */
import { createLogic as defaultCreateLogic } from 'redux-logic';
import { uniq } from 'lodash';
import { instance as axios } from 'config/axios';
import { isAuthValid, VALID, OFFLINE } from './ducks/auth/selectors';
import { shouldFetch, copyState, enrich, apiError, fetching } from './helpers';
import * as constants from './constants/api';

function createLogic(params) {
  if ('validate' in params) {
    const originalValidateFunc = params.validate;
    params.validate = ({ getState, action }, allow, reject) => {
      const authStatus = isAuthValid(getState());
      if (authStatus !== VALID && authStatus !== OFFLINE) {
        reject();
      } else {
        originalValidateFunc({ getState, action }, allow, reject);
      }
    };
  } else {
    params.validate = ({ getState, action }, allow, reject) => {
      const authStatus = isAuthValid(getState());
      if (authStatus !== VALID && authStatus !== OFFLINE) {
        reject();
      }
      allow(action);
    };
  }

  if ('process' in params) {
    // Augment the process, prevent sending API methods
    // if the action is a crosstab -sync
    const originalProcess = params.process;
    params.process = async ({ getState, action }, dispatch, done) => {
      if (action.$isSync === true) {
        done();
      } else {
        originalProcess({ getState, action }, dispatch, done);
      }
    };
  }
  return defaultCreateLogic(params);
}

const createDefaultListQueryLogic = args => {
  const { endpoint, actionTypes, selector, sliceName, domainIdKey = 'id' } = args;
  const { api, method, additionalProperties } = endpoint;
  const { fetch, success, fail } = actionTypes;

  return createLogic({
    type: fetch,

    validate({ getState, action }, allow, reject) {
      const state = getState();
      if (
        shouldFetch(
          selector(state.main[sliceName], action.payload[domainIdKey]),
          state.main.connection,
          action?.payload?.force,
          action?.payload?.next_token,
        )
      ) {
        allow(action);
      } else {
        reject();
      }
    },

    process: async ({ getState, action }, dispatch, done) => {
      // eslint-disable-next-line camelcase
      const { next_token } = action.payload;
      const id = action.payload[domainIdKey];

      axios
        .get(`${api}/${getState().auth.tenantID}/${method}`, {
          // eslint-disable-next-line camelcase
          params: { [domainIdKey]: id, next_token, ...additionalProperties },
          headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
        })
        .then(res => {
          dispatch({
            type: success,
            payload: {
              ...res.data.result,
              [domainIdKey]: id,
              // eslint-disable-next-line camelcase
              fromToken: next_token,
            },
          });
        })
        .catch(() => {
          dispatch({ type: fail, payload: { ...action.payload, id } });
        })
        .then(() => done());
    },
  });
};

const onFetchStarted = args => {
  /* Standard reducer to be used when fetching something is started */
  const { state, payload, key, payloadIdKey = 'id' } = args;
  const newState = copyState(state);
  newState[key][payload[payloadIdKey]] = fetching({ ...newState[key][payload[payloadIdKey]] });

  return newState;
};

function defaultListItemMapper(node) {
  return node;
}

const onListFetchSuccess = args => {
  /* Standard reducer to be used when querying a list of things was successfull */
  const {
    state,
    payload,
    key,
    // eslint-disable-next-line default-param-last
    mapFn = defaultListItemMapper,
    postProcessTransform,
    payloadDataKey = 'nodes',
    payloadIdKey = 'id',
  } = args;
  const newState = copyState(state);
  const previousObj = newState[key][payload[payloadIdKey]];
  const previousData = previousObj?.data || [];
  const { next_token, fromToken } = payload;
  const isNextPage = !!fromToken;

  if ('requestID' in payload) {
    newState.actionlog[payload.requestID] = { result: 'ok' };
  }

  newState[key][payload[payloadIdKey]] = enrich({
    data: isNextPage
      ? uniq(previousData.concat(payload[payloadDataKey].map(node => mapFn(node))))
      : payload[payloadDataKey].map(node => mapFn(node)),
    lastFetched: Date.now(),
    fetchStatus: constants.OK,
    ok: true,
    next_token,
  });

  // Perform any "custom" transformations on the state before returning
  if (postProcessTransform) {
    postProcessTransform(newState, payload);
  }

  return newState;
};

const onFetchFailed = args => {
  /* Standard reducer to be used when fetching something failed */
  const { state, payload, key, payloadIdKey = 'id' } = args;
  const newState = copyState(state);
  newState[key][payload[payloadIdKey]] = apiError(newState[key][payload[payloadIdKey]]);
  return newState;
};

const onDeleteSuccess = args => {
  /* Standard reducer to be used when deleting something was successful */
  const { state, payload, key } = args;
  const newState = copyState(state);
  const id = payload.id || payload.id;

  newState[key][id] = enrich({ fetchStatus: constants.DELETED });
  newState.actionlog[payload.requestID] = { result: 'ok' };

  return newState;
};

const onSingleFetchSuccess = args => {
  /* Standard reducer to be used when fetching a single thing was successful */
  const {
    state,
    payload,
    key,
    transformItemFn = data => data,
    doCopyState = true,
    postProcessTransform = null,
  } = args;
  let newState;
  if (doCopyState === true) {
    newState = copyState(state);
  } else {
    newState = state;
  }
  newState[key][payload.id] = enrich({
    fetchStatus: constants.OK,
    data: transformItemFn(payload),
  });

  if (postProcessTransform) {
    postProcessTransform(newState, payload);
  }
  return newState;
};

const addApiErrorToState = (state, payload) => {
  const newState = copyState(state);
  newState.actionlog[payload.requestID] = { result: 'error' };
  newState.actionlog[payload.requestID].response = payload.error?.response;
  return newState;
};

export {
  createLogic,
  createDefaultListQueryLogic,
  onFetchStarted,
  onFetchFailed,
  onListFetchSuccess,
  onSingleFetchSuccess,
  onDeleteSuccess,
  addApiErrorToState,
};
