import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Skeleton, Box, Stack, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PeriodNavigator from 'Components/Features/Objectives/PeriodNavigator';
import ObjectiveChip from 'Components/Features/Objectives/ObjectiveChip';
import FeatureIcon from 'Components/Features/Objectives/FeatureIcon';
import AddObjectiveButton from 'Components/Features/Objectives/AddObjectiveButton';
import ContainedIconButton from 'Components/Library/ContainedIconButton';
import { objectivesActions, objectivesSelectors } from 'state/ducks/objectives';

function Card({
  dragHandleProps,
  canEdit,
  requestRemove,
  appliedPeriod,
  nextShortTermPeriod,
  prevShortTermPeriod,
  teamId,
  selectPeriod,
  name,
}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  useEffect(() => {
    if (!!appliedPeriod) {
      dispatch(objectivesActions.getTeamObjectives({ teamId, stperiod: appliedPeriod }));
    }
  }, [appliedPeriod, teamId]);

  const objectiveData = useSelector(
    state => objectivesSelectors.selectTeamObjectives(state.main.objectives, teamId, appliedPeriod),
    // { stabilityCheck: 'never' },
  );

  return (
    <>
      <Box
        sx={{
          p: 2,
          pb: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'stretch',
        }}
        {...dragHandleProps}
        name={name}
      >
        <Stack
          direction="column"
          alignItems="stretch"
          spacing={2}
          sx={{ flexBasis: '100%', overflowX: 'hidden' }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center">
              <FeatureIcon sx={{ mr: 1 }} />
              <Typography variant="subtitle2">{t('objectives.pageTitle')}</Typography>
            </Stack>
            {canEdit && (
              <Stack
                direction="row"
                spacing={1}
                className="gp-widget-actions"
                onClick={e => e.stopPropagation()}
              >
                <ContainedIconButton onClick={requestRemove} name="gameplans-remove-widget-button">
                  <DeleteIcon />
                </ContainedIconButton>
                <AddObjectiveButton stperiod={appliedPeriod} teamId={teamId} variant="contained" />
              </Stack>
            )}
          </Stack>
          <PeriodNavigator
            color="white"
            selectedPeriod={appliedPeriod}
            handlePeriodChange={p => selectPeriod(p.stPeriodId)}
            prevPeriod={prevShortTermPeriod}
            nextPeriod={nextShortTermPeriod}
            fullWidth
          />
        </Stack>
      </Box>
      <Box sx={{ pl: 2, pr: 2 }}>
        {objectiveData.ok &&
          objectiveData.data.map(objectiveID => (
            <Box
              key={`objectives-widget-${objectiveID}`}
              sx={{
                mt: 1,
                backgroundColor: 'background.paper',
                borderRadius: 1,
                p: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
              }}
              className="widget-content"
            >
              <ObjectiveChip
                objectiveID={objectiveID}
                color="transparent"
                variant="xl"
                ContextIcon={null}
                allowNavigate
              />
            </Box>
          ))}
        {!objectiveData.ok && objectiveData.loading && (
          <Skeleton height={80} variant="rounded" sx={{ mb: 2, bgcolor: 'background.paper' }} />
        )}
      </Box>
      <Box sx={{ height: 16 }} {...dragHandleProps} />
    </>
  );
}

Card.propTypes = {
  teamId: PropTypes.string,
  dragHandleProps: PropTypes.object,
  requestRemove: PropTypes.func,
  variant: PropTypes.oneOf(['card', 'dialog']),
  canEdit: PropTypes.bool,
  selectPeriod: PropTypes.func,
  prevShortTermPeriod: PropTypes.string,
  nextShortTermPeriod: PropTypes.string,
  appliedPeriod: PropTypes.string,
  name: PropTypes.string,
};

export default Card;
