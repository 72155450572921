import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import Form from 'Components/Library/Forms/Form';
import FormMultiChoiceReply from '../PanelReplyTopic/FormMultiChoiceReply';
import FormCsatReply from '../PanelReplyTopic/FormCsatReply';
import FormQuadReply from '../PanelReplyTopic/FormQuadReply';
import FormNpsReply from '../PanelReplyTopic/FormNpsReply';
import FormSliderReply from '../PanelReplyTopic/FormSliderReply';
import FormFreeText from '../PanelReplyTopic/FormFreeText';
import StyledPanelReplyTopicHeader from '../PanelReplyTopic/StyledPanelReplyTopicHeader';

function dummyFunction() {}

function SurveyPreviewScreen(props) {
  const { sub, audienceType, values } = props;
  const { ttl } = values;
  const { t } = useTranslation();

  return (
    <div>
      <Typography variant="h5" paragraph>
        {t('insights.createtopicwhathappensnextsubtitle')}
      </Typography>
      <Typography variant="subtitle1" paragraph color="textSecondary">
        {t('insights.createtopicwhathappensnextbody')}
        <br />
        <br />
        {t('insights.createtopicsurveyendsdatestring')}
        {moment().add(ttl, 'days').format('dddd MMM. D, YYYY [at] HH:mm')}

        <br />
        <br />
        {t('insights.createtopicsurveyendsinfostring')}
      </Typography>
      <Typography variant="h5" paragraph>
        {t('insights.createtopicpreviewsurveysubtitle')}
      </Typography>
      <Box sx={{ borderRadius: 1, border: 1, borderColor: 'divider', p: 4, mb: 4 }}>
        {/* FIXME: use the same component for all the content as in the reply dialog,
      it's a preview and thus it's imperative to make sure we use the same code to
      ensure the views do not start to deviate */}
        <StyledPanelReplyTopicHeader
          topicText={values.topicText}
          description={values.description}
          owner={sub}
          submitActionCreator={() => {}}
          preview
        />
        {/* The *Reply components used in the preview require a Form instance as their parent
            To provide the necessary context - just a dummy Form here, preventing value
            modifications
        */}
        <Form
          name="insights-survey-preview-dummy-form"
          schema={{
            type: 'object',
            properties: {
              text: { type: 'string', maxLength: 0 },
              score: { type: 'number' },
              scorex: { type: 'number', minimum: 0, maximum: 100 },
              scorey: { type: 'number', minimum: 0, maximum: 100 },
              sliders: { type: 'object', propertyNames: { pattern: '^[0-9]+$' } },
            },
            required: [],
            additionalProperties: true,
          }}
          initialValues={{}}
          submitActionCreator={dummyFunction}
        >
          {values.topicType === 'multiplechoice' && <FormMultiChoiceReply values={values} />}
          {values.topicType === 'quad' && <FormQuadReply values={values} />}
          {values.topicType === 'csat' && <FormCsatReply values={values} />}
          {values.topicType === 'nps' && <FormNpsReply values={values} />}
          {values.topicType === 'opentext' && <FormFreeText audienceType={audienceType} />}
          {values.topicType === 'sliders' && <FormSliderReply values={values} />}
        </Form>
      </Box>
    </div>
  );
}

SurveyPreviewScreen.propTypes = {
  values: PropTypes.object,
  sub: PropTypes.string,
  audienceType: PropTypes.string,
  ttl: PropTypes.number,
};

export default SurveyPreviewScreen;
