import { get, orderBy } from 'lodash';
import { PURGE } from 'redux-persist';
import { validatePersistedState, copyState, enrich, fetching, apiError } from 'state/helpers';
import { EVENT_TYPE_COMMENT } from 'config/constants';
import * as types from './types';
import * as constants from '../../constants/api';

export const initialState = {
  VERSION: 1.02,
  commentedNodes: {},
  actionlog: {},
};

function addCommentToState(state, payload) {
  const newState = copyState(state);

  const fetchStatus = get(
    newState.commentedNodes,
    `${payload.target_node_id}.fetchStatus`,
    constants.PARTIAL,
  );

  newState.commentedNodes[payload.target_node_id] = enrich({
    fetchStatus,
    data: orderBy(
      get(newState.commentedNodes, `${payload.target_node_id}.data`, []).concat({
        ...payload,
        richTextContent: JSON.parse(payload.content),
        ts: payload.timestamp,
        type: EVENT_TYPE_COMMENT,
      }),
      ['timestamp'],
      ['desc'],
    ),
  });

  newState.actionlog[payload.requestID] = { result: 'ok' };

  return newState;
}

function addFetchedNodeCommentsToState(state, payload) {
  const newState = copyState(state);
  newState.commentedNodes[payload.nodeId] = enrich({
    fetchStatus: constants.OK,
    lastFetched: Date.now(),
    maxAge: 1000 * 60 * 2, // 2 minutes
    data: orderBy(
      payload.comments.map(comment => ({
        ...comment,
        ts: comment.timestamp,
        richTextContent: JSON.parse(comment.content),
        type: EVENT_TYPE_COMMENT,
      })),
      ['timestamp'],
      ['desc'],
    ),
  });
  return newState;
}

function addFetchingNodeCommentsToState(state, payload) {
  const newState = copyState(state);
  const { nodeId } = payload;

  newState.commentedNodes[nodeId] = fetching(newState.commentedNodes[nodeId]);

  return newState;
}

function addFailedNodeCommentsToState(state, payload) {
  const newState = copyState(state);
  const { nodeId } = payload;
  newState.commentedNodes[nodeId] = apiError(newState.commentedNodes[nodeId]);
  return newState;
}

function addApiErrorToState(state, action) {
  const newState = copyState(state);
  newState.actionlog[action.payload.requestID] = { result: 'error' };
  if (!!action.payload.error && !!action.payload.error.error) {
    newState.actionlog[action.payload.requestID].message = action.payload.error.error;
  }
  return newState;
}

// The params need to be in this order, that's what redux gives :)
// eslint-disable-next-line default-param-last
const reducer = (state = JSON.parse(JSON.stringify(initialState)), action) => {
  state = validatePersistedState(state, initialState);
  switch (action.type) {
    case types.ADDED_COMMENT:
      return addCommentToState(state, action.payload);
    case types.FETCH_COMMENTS:
      return addFetchingNodeCommentsToState(state, action.payload);
    case types.RECEIVED_COMMENTS:
      return addFetchedNodeCommentsToState(state, action.payload);
    case types.FAILED_COMMENTS:
      return addFailedNodeCommentsToState(state, action.payload);
    case types.ERROR_RECEIVED_FROM_API:
      return addApiErrorToState(state, action);
    case PURGE:
      return JSON.parse(JSON.stringify(initialState));
    default:
      return state;
  }
};

export default reducer;
