import moment from 'moment';
import { PURGE } from 'redux-persist';
import { validatePersistedState } from 'state/helpers';
import * as types from './types';

export const initialState = {
  VERSION: 1.03,
  inbox: [],
  actionlog: {},
};

function filterDuplicates(inbox) {
  const seen = [];
  const result = [];
  for (const msg of inbox) {
    if (!seen.includes(msg.messageID)) {
      result.push(msg);
    }
    seen.push(msg.messageID);
  }
  return result;
}

function addInboxToState(state, action) {
  const newState = JSON.parse(JSON.stringify(state));

  newState.inbox = newState.inbox.filter(msg => msg.type === 'local');
  for (let i = 0; i < action.payload.length; i++) {
    const msg = action.payload[i];
    let hideEvent = true;

    if (msg.messageID.startsWith('INSSRV')) {
      msg.itemtype = 'survey';
      if (!msg.replyID) {
        hideEvent = false;
      }
    }
    if (msg.messageID.startsWith('OKR_UPDATE_REMINDER')) {
      msg.itemtype = 'outdatedOkrReminder';
      hideEvent = false;
    }
    if (msg.messageID.startsWith('CSI')) {
      msg.itemtype = 'understanding';
      hideEvent = false;
      const parts = msg.messageID.split('_');
      msg.csiID = parts.slice(0, parts.length - 1).join('_');
      msg.teamID = parts[parts.length - 1];
      msg.cpID = parts[1];
    }
    if (msg.messageID.startsWith('NEW-TEAM-JOIN-REQ')) {
      msg.itemtype = 'newTeamJoinRequest';
      hideEvent = false;
      const parts = msg.messageID.split('_');
      msg.teamID = parts.slice(1, parts.length - 1).join('_');
      msg.requestor = parts[parts.length - 1];
    }
    if (msg.messageID.startsWith('APPROVED-TEAM-JOIN-REQ')) {
      msg.itemtype = 'teamJoinRequestApproved';
      hideEvent = false;
      const parts = msg.messageID.split('_');
      msg.teamID = parts.slice(1, parts.length - 1).join('_');
      msg.approver = parts[parts.length - 1];
    }
    if (msg.messageID.startsWith('DECLINED-TEAM-JOIN-REQ')) {
      msg.itemtype = 'teamJoinRequestDeclined';
      hideEvent = false;
      const parts = msg.messageID.split('_');
      msg.teamID = parts.slice(1, parts.length - 1).join('_');
      msg.approver = parts[parts.length - 1];
    }
    msg.age = moment().diff(moment(msg.timestamp), 'days');
    msg.type = 'remote';
    /* Only add notifications that we know how to handle */
    if (!hideEvent) {
      newState.inbox.push(msg);
    }
  }
  newState.inbox = filterDuplicates(newState.inbox);
  return newState;
}

function addCreatedLocalNotificationToState(state, action) {
  const newState = JSON.parse(JSON.stringify(state));
  const msg = action.payload;
  // TODO: validate the msg object, if fails validation do not push.
  // it needs to include:
  // messageID: string
  // itemtype: string
  // title: string
  // text: string
  // from: string ("tg" or user uuid)
  msg.age = 0;
  msg.type = 'local';
  newState.inbox.push(msg);
  newState.inbox = filterDuplicates(newState.inbox);
  return newState;
}

function removeCardFromState(state, action) {
  const newState = JSON.parse(JSON.stringify(state));
  if (action.payload.messageId) {
    newState.inbox = newState.inbox.filter(msg => msg.messageID !== action.payload.messageId);
  }
  return newState;
}

// eslint-disable-next-line default-param-last
export default (state = JSON.parse(JSON.stringify(initialState)), action) => {
  state = validatePersistedState(state, initialState);
  switch (action.type) {
    case types.RECEIVED_INBOX:
      return addInboxToState(state, action);
    case types.DISMISS_INBOX_CARD:
      return removeCardFromState(state, action);
    case types.CREATE_LOCAL_NOTIFICATION:
      return addCreatedLocalNotificationToState(state, action);
    case PURGE:
      return JSON.parse(JSON.stringify(initialState));
    default:
      return state;
  }
};
