import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import { peopleActions, peopleSelectors } from 'state/ducks/people';

import ChipAutocomplete from 'Components/Library/Shared/ChipAutocomplete';
import UserChip from 'Components/Library/UserChip';

class SearchOverlay extends Component {
  state = {
    searchString: '',
    suggestions: [],
    multiSection: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { people, filterFunc = null } = nextProps;
    const results = [];

    const { searchString } = prevState;
    if (searchString.length > 1) {
      for (const sub in people.subs) {
        if (!filterFunc || filterFunc(sub)) {
          if (Object.hasOwnProperty.call(people.subs, sub) && people.subs[sub].ok) {
            if (
              people.subs[sub].fullName &&
              people.subs[sub].fullName.toLowerCase().includes(searchString.toLowerCase())
            ) {
              const person = JSON.parse(JSON.stringify(people.subs[sub])); // Clone object
              results.push({ sub: person.sub, text: person.fullName });
            }
          }
        }
      }
    } else if (!!nextProps.showRecent) {
      // use filter function to also filter recentPeople
      let { recentPeople } = nextProps;
      if (filterFunc) {
        recentPeople = recentPeople.filter(id => filterFunc(id));
      }
      return {
        multiSection: true,
        suggestions: [
          {
            title: 'recent',
            suggestions: recentPeople.map(sub => ({
              sub,
              text: nextProps.selectFullName(sub),
            })),
          },
        ],
      };
    }
    return { suggestions: results, multiSection: false };
  }

  getPeople = searchString => {
    this.setState({ searchString });
    if (searchString.length > 1) {
      this.props.dispatch(peopleActions.searchPeople({ searchstring: searchString }));
    }
  };

  onSelect = suggestion => {
    this.props.dispatch(peopleActions.addRecentUser({ sub: suggestion.sub }));
    this.props.onSelect(suggestion.sub);
  };

  clearSearch = () => {
    this.setState({ searchString: '' });
  };

  render() {
    return (
      <ChipAutocomplete
        name={this.props.name}
        selected={this.props.selected}
        suggestions={this.state.suggestions}
        onSelect={this.onSelect}
        multiSection={this.state.multiSection}
        alwaysRenderSuggestions={this.state.multiSection}
        renderSuggestion={(suggestion, { isHighlighted }) => (
          <UserChip
            name="employeesearch-menuitem"
            id={`employeesearch-result-${suggestion.sub}`}
            sub={suggestion.sub}
            color={isHighlighted ? 'grey' : 'transparent'}
            actionable
          />
        )}
        onClearRequested={this.clearSearch}
        onFetchRequested={this.getPeople}
        id={this.props.id}
        placeholder={this.props.t('general.peopleSearchLabel')}
      />
    );
  }
}

const mapStateToProps = state => ({
  people: state.main.people,
  recentPeople: peopleSelectors.selectRecentUsers(state.main.people),
  selectFullName: sub => peopleSelectors.selectFullName(state.main.people, sub),
});

SearchOverlay.propTypes = {
  selected: PropTypes.object,
  onSelect: PropTypes.func,
  recentPeople: PropTypes.array,
  selectFullName: PropTypes.func,
  people: PropTypes.object,
  dispatch: PropTypes.func,
  filterFunc: PropTypes.func,
  t: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
  showRecent: PropTypes.bool,
};

export default withTranslation()(connect(mapStateToProps)(SearchOverlay));
