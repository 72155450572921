import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { nanoid } from 'nanoid';
import RichTextEditor from 'Components/Library/RichTextEditor';
import { SCHEMA_FULL } from 'Components/Library/RichTextEditor/schemas';
import Form from 'Components/Library/Forms/Form';
import FormRichTextField from 'Components/Library/Forms/Elements/FormRichTextField';
import FormButton from 'Components/Library/Forms/Elements/FormButton';
import PersonasAvatar from 'Components/Common/PersonasAvatar';

import { commentsActions } from 'state/ducks/comments';
import SaveCommentButton from './SaveCommentButton';

const schema = {
  type: 'object',
  properties: {
    content: { richtextschema: SCHEMA_FULL, minLength: 1, maxLength: 8000 },
  },
};

function CommentEditor({ nodeId, embeddedNodeId }) {
  const { t } = useTranslation();
  const sub = useSelector(state => state.auth.userID);

  // The rich text editor is not a controlled component,
  // so clearing it is difficult
  // Work around this by setting a random key, and resetting
  // the key when a submit happens - this will remount the Form
  // and clear the field.
  const [key, setKey] = useState(nanoid(10));

  const additionalProperties = {
    target_node_id: nodeId,
  };
  if (embeddedNodeId) {
    additionalProperties.sub_reference = embeddedNodeId;
  }

  const fullNodeId = nodeId + (embeddedNodeId ? `-${embeddedNodeId}` : '');
  return (
    <Form
      schema={schema}
      name={`comment-form-${fullNodeId}`}
      submitActionCreator={commentsActions.addComment}
      initialValues={{ content: null }}
      additionalProperties={additionalProperties}
      formIdentifier={fullNodeId}
      key={key}
      stateSlice="main.comments"
      onSubmitSuccess={() => {
        // set new key to remount (clear) the Form
        setKey(nanoid(10));
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'stretch',
          alignItems: 'flex-start',
          mb: 2,
          overflowX: 'hidden',
        }}
      >
        <Box
          sx={{
            alignSelf: 'stretch',
            flexDirection: 'column',
            justifyContent: 'stretch',
            alignItems: 'center',
            flexShrink: 0,
            width: '32px',
            flexBasis: 32,
            flexGrow: 0,
            display: 'flex',
            paddingRight: 1,
          }}
        >
          <PersonasAvatar sub={sub} size="xtiny" />
          <Box
            sx={{
              width: '1px',
              mt: 1,
              backgroundColor: theme => theme.palette.primary[400],
              flexGrow: 100,
            }}
          />
        </Box>
        {!nodeId ? (
          <Skeleton height={136} width="100%" variant="rounded" />
        ) : (
          <FormRichTextField
            fieldName="content"
            render={fieldProps => (
              <RichTextEditor
                {...fieldProps}
                label={t('general.comments.commentEditorPlaceHolder')}
                placeholder={t('general.comments.commentEditorPlaceHolder')}
                submitButton={
                  <FormButton render={buttonProps => <SaveCommentButton {...buttonProps} />} />
                }
                toolbarVariant="bottom"
                variant="filled"
                schema="comment"
              />
            )}
          />
        )}
      </Box>
    </Form>
  );
}

CommentEditor.propTypes = {
  nodeId: PropTypes.string,
  embeddedNodeId: PropTypes.string,
};

export default CommentEditor;
