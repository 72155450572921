import 'regenerator-runtime';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import {
  createStateSyncMiddleware,
  initMessageListener,
  withReduxStateSync,
} from 'redux-state-sync';
import logger from 'redux-logger';
import storage from 'redux-persist/lib/storage';
import { persistReducer, createTransform } from 'redux-persist';
import * as Sentry from '@sentry/react';
import * as apiconstants from 'state/constants/api';
import { isEnabled } from 'config/flags';
import authReducer from './state/ducks/auth/reducers';
import * as duckReducers from './state';
import logicMiddleware from './state/logic';

const filter = (slice, key) => {
  const iterate = (obj, path) => {
    for (const property in obj) {
      if (
        Object.hasOwnProperty.call(obj, property) &&
        typeof obj[property] === 'object' &&
        !!obj[property]
      ) {
        const childPath = `${path}.${property}`;
        const child = obj[property];
        if (
          Object.hasOwnProperty.call(child, 'fetchStatus') &&
          child.fetchStatus !== apiconstants.OK
        ) {
          // Let's not rehydrate failures
          obj[property] = {};
        } else {
          iterate(child, childPath);
        }
      }
    }
  };

  iterate(slice, key);
};

const rehydrateTransform = createTransform(
  inboundState => inboundState,
  (outboundState, key) => {
    filter(outboundState, key);
    return outboundState;
  },
  {
    whitelist: [
      'programs',
      'objectives',
      'people',
      'inbox',
      'insights',
      'facilitation',
      'gameplans',
      'context',
      'comments',
      'forms',
    ],
  },
);

export const rootPersistConfig = {
  key: 'main',
  storage,
  blacklist: ['connection'],
  transforms: [rehydrateTransform],
};

export const authPersistConfig = {
  key: 'auth',
  storage,
  blacklist: ['swTokenConfirmed'],
};

const reducer = combineReducers({ ...duckReducers });

const reducers = withReduxStateSync(
  combineReducers({
    main: persistReducer(rootPersistConfig, reducer),
    auth: persistReducer(authPersistConfig, authReducer),
  }),
);

const middleware = [
  // Sync new tokens between tabs
  createStateSyncMiddleware({
    whitelist: ['TOKEN_FETCH_SUCCESS', 'TOKEN_REFRESH_SUCCESS'],
  }),
  logicMiddleware,
];

if (isEnabled('REDUX-LOGGING')) {
  middleware.push(logger);
}

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = {};
  }
  return reducers(state, action);
};

// return nothing from redux for now
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: () => null,
  actionTransformer: () => null,
});

const store = createStore(
  rootReducer,
  undefined,
  compose(applyMiddleware(...middleware), sentryReduxEnhancer),
);
initMessageListener(store);

export const getStore = () => store;

export default store;
