import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const urlRegex =
  /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9$\-_.+!*'(),;?&=]|(?:%[a-fA-F0-9]{2})){1,64}(?::(?:[a-zA-Z0-9$\-_.+!*'(),;?&=]|(?:%[a-fA-F0-9]{2})){1,25})?@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?::\d{1,5})?)(\/(?:(?:[a-zA-Z0-9;/?:@&=#~\-.+!*'(),_])|(?:%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;

const linkify = text => {
  let match;
  let curIndex = 0;
  const retVal = [];
  let matches = 0;
  // Eslint doesn't seem to appreciate assignments in the while condition, although it's perfectly valid
  // eslint-disable-next-line
  while (match = urlRegex.exec(text)) {
    retVal.push(text.substring(curIndex, match.index));
    let url = match[0];
    if (!url.includes('://')) {
      url = `//${url}`;
    }
    retVal.push(
      <a rel="noopener noreferrer" href={url} target="_blank" key={`linkified_link_${matches}`}>
        {match[0]}
      </a>,
    );
    curIndex = match.index + match[0].length;
    matches += 1;
  }
  if (matches === 0) {
    retVal.push(text);
  } else if (curIndex < text.length) {
    retVal.push(text.substring(curIndex));
  }
  return retVal;
};

class LinkifiedTypography extends React.Component {
  render() {
    const { children, id, variant, className } = this.props;
    let child = children;
    if (typeof children === 'string' || children instanceof String) {
      child = linkify(children);
    }
    return (
      <Typography id={id} variant={variant} className={className}>
        {child}
      </Typography>
    );
  }
}

LinkifiedTypography.propTypes = {
  children: PropTypes.string,
  id: PropTypes.string,
  variant: PropTypes.string,
};

export default LinkifiedTypography;
