import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import ProfileIcon from '@mui/icons-material/AccountCircle';
import LanguageIcon from '@mui/icons-material/Language';
import CloseIcon from '@mui/icons-material/Close';
import NotificationsIcon from '@mui/icons-material/Notifications';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import StandardDialog from 'Components/Library/StandardDialog';
import { peopleSelectors } from 'state/ducks/people';

import UserIntegrations from 'screens/Features/Admin/IntegrationsView/UserIntegrations';

import FeatureFlag from 'Components/Library/FeatureFlag';
import ThemeTab from 'Components/Common/DialogUserSettings2/ThemeTab';
import ProfileTab from './ProfileTab';
import LanguageTab from './LanguageTab';
import NotificationsTab from './NotificationsTab';

const styles = theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  menu: {
    backgroundColor: theme.palette.primary[200],
    flexBasis: 120,
    flexShrink: 0,
    flexGrow: 0,
    padding: theme.spacing(3),
  },
  content: {
    flexBasis: 120,
    flexGrow: 3,
    padding: theme.spacing(3),
    minHeight: 480,
    overflowY: 'auto',
  },
  closeBtn: {
    position: 'absolute',
    top: 4,
    right: 4,
  },
});

class DialogUserSettingsContainer extends Component {
  state = {
    tab: 'profile',
  };

  setTab = tab => {
    this.setState({ tab });
  };

  render() {
    const { t, classes, onClose } = this.props;
    const { tab } = this.state;
    return (
      <StandardDialog open size="medium" fixedHeight onBackdropClick={onClose}>
        <div className={classes.wrapper}>
          <div className={classes.menu}>
            <Typography variant="h6">{t('usersettings.dialogHeader')}</Typography>
            <List>
              <ListItem
                button
                key="profile"
                selected={tab === 'profile'}
                onClick={() => this.setTab('profile')}
                name="menu-profile-opt"
              >
                <ListItemIcon>
                  <ProfileIcon />
                </ListItemIcon>
                <ListItemText primary={t('usersettings.profile.tabTitle')} />
              </ListItem>

              <FeatureFlag flag="SLACK">
                <ListItem
                  button
                  key="notifications"
                  selected={tab === 'notifications'}
                  onClick={() => this.setTab('notifications')}
                  name="menu-notifications-opt"
                >
                  <ListItemIcon>
                    <NotificationsIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('usersettings.notifications.tabTitle')} />
                </ListItem>
              </FeatureFlag>
              <ListItem
                button
                key="language"
                selected={tab === 'language'}
                onClick={() => this.setTab('language')}
                name="menu-language-opt"
              >
                <ListItemIcon>
                  <LanguageIcon />
                </ListItemIcon>
                <ListItemText primary={t('usersettings.language.tabTitle')} />
              </ListItem>
              <FeatureFlag flag="DARKMODE">
                <ListItem
                  button
                  key="theme"
                  selected={tab === 'theme'}
                  onClick={() => this.setTab('theme')}
                  name="menu-theme-opt"
                >
                  <ListItemIcon>
                    <RemoveRedEyeIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('usersettings.theme.tabTitle')} />
                </ListItem>
              </FeatureFlag>
              <ListItem
                button
                key="integrations"
                selected={tab === 'integrations'}
                onClick={() => this.setTab('integrations')}
                name="menu-integrations-opt"
              >
                <ListItemIcon>
                  <IntegrationInstructionsIcon />
                </ListItemIcon>
                <ListItemText primary={t('usersettings.integrations.tabTitle')} />
              </ListItem>
            </List>
          </div>

          <div className={classes.content}>
            {tab === 'profile' && <ProfileTab />}
            {tab === 'language' && <LanguageTab />}
            {tab === 'notifications' && <NotificationsTab />}
            {tab === 'theme' && <ThemeTab />}
            {tab === 'integrations' && <UserIntegrations />}
            <div className={classes.closeBtn}>
              <IconButton
                name="settings-dialog-close-btn"
                onClick={onClose}
                size="large"
                aria-label={t('general.close')}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </div>
      </StandardDialog>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const isSelf = ownProps.sub === state.auth.userID;

  return {
    actionlog: state.main.people.actionlog,
    isSelf,
    userDetails: peopleSelectors.selectUser(state.main.people, ownProps.sub),
  };
};

DialogUserSettingsContainer.propTypes = {
  classes: PropTypes.exact({
    wrapper: PropTypes.string,
    menu: PropTypes.string,
    content: PropTypes.string,
    closeBtn: PropTypes.string,
  }),
  onClose: PropTypes.func,
  t: PropTypes.func,
};

export default withTranslation()(
  connect(mapStateToProps)(withStyles(styles)(DialogUserSettingsContainer)),
);
