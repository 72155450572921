import React, { Component } from 'react';
import clsx from 'clsx';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell';
import PropTypes from 'prop-types';
import { getKeyResultCompletionPct, getLastModifiedString } from 'config/helpers';
import { isKrOwned } from 'state/ducks/objectives/helpers';
import { KR_OUTDATED_TRESHOLD } from 'config/constants';

import KeyresultChip from '../KeyresultChip';

const styles = theme => ({
  cell: {
    overflow: 'hidden',
    minWidth: 20,
    paddingTop: theme.spacing(),
    borderBottom: 'none',
  },
  updatedCell: {
    textAlign: 'center',
    paddingRight: 4,
    paddingLeft: 4,
  },
  topAlignedCell: {
    verticalAlign: 'top',
    overflow: 'hidden',
  },
  keyResultCell: {
    width: '50%',
    maxWidth: 400,
    paddingRight: theme.spacing(),
    '& button': {
      width: '100%',
    },
  },
  noPaddingTop: {
    paddingTop: 1,
  },
  noPaddingBottom: {
    paddingBottom: 1,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%',
  },
  outdated: {
    color: theme.palette.error.main,
  },
  red: {
    backgroundColor: theme.palette.confidence.red,
  },
  amber: {
    backgroundColor: theme.palette.confidence.amber,
  },
  green: {
    backgroundColor: theme.palette.confidence.green,
  },
  avatar: {
    marginRight: 8,
    flexShrink: 0,
    flexGrow: 0,
  },
  krName: {
    flexGrow: 2,
    flexShrink: 1,
    paddingRight: 8,
  },
  overlay: {
    opacity: 0,
    backgroundColor: '#FFF',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  centered: {
    textAlign: 'center',
  },
  committedChip: {
    height: 24,
    ...theme.shape,
    margin: 0,
    padding: 0,
    marginRight: theme.spacing(),
  },
});

const isOutdated = (keyresult, objectiveData) =>
  keyresult.lastModifiedDays >= KR_OUTDATED_TRESHOLD &&
  getKeyResultCompletionPct(keyresult) < 100 &&
  objectiveData.stage === 'LIVE';

class KeyresultCells extends Component {
  render() {
    const {
      keyresult,
      classes,
      domain,
      objectiveData,
      openAddKeyResult,
      canEdit,
      t,
      stperiod,
      isLast,
      isFirst,
    } = this.props;

    if (!keyresult) {
      return (
        <React.Fragment>
          <TableCell
            sx={{ width: '66%' }}
            className={clsx(classes.cell, !isFirst && classes.noPaddingTop)}
          >
            {canEdit && (
              <Button
                color="secondary"
                name="okr-table-add-kr-button"
                variant="text"
                onClick={evt => openAddKeyResult(evt, objectiveData.objectiveID)}
              >
                {`+ ${t('objectives.addkeyresultcta')}`}
              </Button>
            )}
          </TableCell>
          <TableCell className={clsx(classes.cell, !isFirst && classes.noPaddingTop)} />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <TableCell
          name="okr-table-kr-cell"
          id={`${domain.t}-${domain.d}-${keyresult.keyresultID}`}
          className={clsx(
            classes.cell,
            classes.keyResultCell,
            classes.topAlignedCell,
            !isFirst && classes.noPaddingTop,
            !isLast && classes.noPaddingBottom,
          )}
        >
          <KeyresultChip
            color="white"
            keyresult={keyresult}
            ContextIcon={null}
            stperiod={stperiod}
            sx={{
              opacity: isKrOwned(domain, objectiveData, keyresult) ? 1 : 0.5,
            }}
          />
        </TableCell>
        <TableCell
          name="okr-table-kr-modified-cell"
          className={clsx(
            classes.cell,
            classes.updatedCell,
            !isFirst && classes.noPaddingTop,
            !isLast && classes.noPaddingBottom,
          )}
        >
          <Chip
            color={isOutdated(keyresult, objectiveData) ? 'error' : 'default'}
            label={getLastModifiedString(keyresult.lastModifiedDays, true)}
            name={isOutdated(keyresult, objectiveData) ? 'red-okr-chip' : 'grey-okr-chip'}
            size="medium"
          />
        </TableCell>
      </React.Fragment>
    );
  }
}

KeyresultCells.propTypes = {
  classes: PropTypes.exact({
    cell: PropTypes.string,
    updatedCell: PropTypes.string,
    topAlignedCell: PropTypes.string,
    keyResultCell: PropTypes.string,
    row: PropTypes.string,
    noPaddingTop: PropTypes.string,
    noPaddingBottom: PropTypes.string,
    outdated: PropTypes.string,
    red: PropTypes.string,
    amber: PropTypes.string,
    green: PropTypes.string,
    avatar: PropTypes.string,
    krName: PropTypes.string,
    overlay: PropTypes.string,
    centered: PropTypes.string,
    committedChip: PropTypes.string,
  }),
  keyresult: PropTypes.object,
  keyResultCell: PropTypes.string,
  domain: PropTypes.object,
  objectiveData: PropTypes.object,
  openAddKeyResult: PropTypes.func,
  canEdit: PropTypes.bool,
  t: PropTypes.func,
  stperiod: PropTypes.string,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
};

export default withTranslation()(withStyles(styles)(KeyresultCells));
