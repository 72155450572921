import { PURGE } from 'redux-persist';
import { validatePersistedState, copyState } from 'state/helpers';
import * as types from './types';

export const initialState = {
  VERSION: 1.02,
  forms: {},
};

const storeFormState = (prevState, payload) => {
  const newState = copyState(prevState);
  if (!!payload.formIdentifier) {
    // The form is using another identifier in addition to the form name
    // This is used when the same form can appear in many contexts,
    // eg when creating insights surveys, it could be done for
    // different teams and transformation programs
    if (!(payload.formName in newState.forms)) {
      newState.forms[payload.formName] = {};
    }
    newState.forms[payload.formName][payload.formIdentifier] = payload.formState;
  } else {
    newState.forms[payload.formName] = payload.formState;
  }
  return newState;
};

const clearFormState = (prevState, payload) => {
  const newState = copyState(prevState);
  if (!!payload.formIdentifier) {
    // The form is using another identifier in addition to the form name
    // This is used when the same form can appear in many contexts,
    // eg when creating insights surveys, it could be done for
    // different teams and transformation programs
    if (!(payload.formName in newState.forms)) {
      newState.forms[payload.formName] = {};
    }
    newState.forms[payload.formName][payload.formIdentifier] = {};
  } else {
    newState.forms[payload.formName] = {};
  }
  return newState;
};

// eslint-disable-next-line default-param-last
export default (state = JSON.parse(JSON.stringify(initialState)), action) => {
  state = validatePersistedState(state, initialState);
  switch (action.type) {
    case types.SAVE_FORM:
      return storeFormState(state, action.payload);
    case types.CLEAR_FORM:
      return clearFormState(state, action.payload);
    case PURGE:
      return JSON.parse(JSON.stringify(initialState));
    default:
      return state;
  }
};
