import React from 'react';
import PropTypes from 'prop-types';
import { RemirrorRenderer, Doc, TextHandler, Heading } from '@remirror/react';
import { withTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import HelperText from 'Components/Common/HelperText';
import { SCHEMA_FULL } from 'Components/Library/RichTextEditor/schemas';
import UserSpan from './UserSpan';
import TaskListItem from './TaskListItem';
import TaskList from './TaskList';
import ListItem from './ListItem';
import { DEFAULT_FONT_VARIANT_MAPPING } from '../RichTextEditor/styles';

const TYPE_MAP_SCHEMA_COMMENT = {
  blockquote: 'p',
  bulletList: 'ul',
  taskList: TaskList,
  doc: Doc,
  heading: 'p',
  paragraph: 'p',
  hardBreak: 'br',
  orderedList: 'ol',
  text: TextHandler,
  mentionAtom: UserSpan,
  listItem: ListItem,
  taskListItem: TaskListItem,
};

const TYPE_MAP_SCHEMA_FULL = {
  blockquote: 'p',
  bulletList: 'ul',
  taskList: TaskList,
  doc: Doc,
  heading: Heading,
  paragraph: 'p',
  hardBreak: 'br',
  orderedList: 'ol',
  text: TextHandler,
  mentionAtom: UserSpan,
  listItem: ListItem,
  taskListItem: TaskListItem,
};

class RichTextRenderer extends React.Component {
  state = {
    errorCaught: false,
  };

  static getDerivedStateFromError() {
    return { errorCaught: true };
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.log(
      'Failed to render rich text content: ',
      this.props.richTextContent,
      error,
      errorInfo,
    );
  }

  render() {
    const { t, schema, richTextContent, variant, fontVariantOverrides = {} } = this.props;
    const { errorCaught } = this.state;
    if (!richTextContent) {
      return null;
    }

    const fontVariantMap = { ...DEFAULT_FONT_VARIANT_MAPPING, ...fontVariantOverrides };

    return (
      <Box
        sx={{
          whiteSpace: 'break-spaces',
          overflowWrap: 'break-word',
          pt: variant === 'inline' ? 0.5 : 1.5,
          pl: variant === 'inline' ? 0 : 2,
          pr: variant === 'inline' ? 0 : 2,
          color: 'text.primary',
          '& p': theme => ({
            ...theme.typography[fontVariantMap.p],
            '& a': { color: theme.palette.secondary.light },
            '& a:hover': { color: theme.palette.secondary.main },
            '& a:visited': { color: theme.palette.secondary.dark },
            marginBlockStart: '0',
            marginBlockEnd: '0.5em',
          }),
          '& h1, h2, h3, h4, h5, h6': {
            marginBlockStart: '0.5em',
            marginBlockEnd: '0.5em',
            '&:first-of-type': {
              marginBlockStart: 0,
            },
          },
          '& h1': theme => ({
            ...theme.typography[fontVariantMap.h1],
          }),
          '& h2': theme => ({
            ...theme.typography[fontVariantMap.h2],
          }),
          '& h3': theme => ({
            ...theme.typography[fontVariantMap.h3],
          }),
          '& ul': theme => ({
            ...theme.typography[fontVariantMap.p],
            marginBlockStart: '0.25em',
            marginBlockEnd: '0.25em',
            paddingInlineStart: '1.75rem',
            '& p': {
              marginBlockStart: '0.25em',
              marginBlockEnd: '0.25em',
            },
          }),
          '& ol': theme => ({
            ...theme.typography[fontVariantMap.p],
            marginBlockStart: '0.25em',
            marginBlockEnd: '0.25em',
            paddingInlineStart: '1.75rem',
            '& p': {
              marginBlockStart: '0.25em',
              marginBlockEnd: '0.25em',
            },
          }),
        }}
      >
        {errorCaught ? (
          <HelperText variant="warning">{t('general.richText.renderingError')}</HelperText>
        ) : (
          <RemirrorRenderer
            json={richTextContent}
            typeMap={schema === SCHEMA_FULL ? TYPE_MAP_SCHEMA_FULL : TYPE_MAP_SCHEMA_COMMENT}
          />
        )}
      </Box>
    );
  }
}

RichTextRenderer.propTypes = {
  schema: PropTypes.string,
  richTextContent: PropTypes.object,
  t: PropTypes.func,
  fontVariantOverrides: PropTypes.object,
  variant: PropTypes.oneOf(['inline', 'filled', 'standard']),
};

RichTextRenderer.defaultProps = {
  schema: SCHEMA_FULL,
  variant: 'inline',
};

export default withTranslation()(RichTextRenderer);
