import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

function SectionLabel(props) {
  const { t } = useTranslation();
  const { section } = props;

  if (!section.title || get(section, 'suggestions', []).length === 0) {
    return null;
  }
  return (
    <Typography
      variant="tiny"
      sx={{ pl: 2, color: theme => theme.palette.primary[400] }}
      style={{ height: 34, lineHeight: '34px' }}
      name={`section-title-${section.title}`}
    >
      {t(`general.autoCompleteSectionTitles.${section.title}`)}
    </Typography>
  );
}

SectionLabel.propTypes = {
  section: PropTypes.object,
};

export default SectionLabel;
