import { createLogic } from 'state/defaultLogic';
import { instance as axios } from 'config/axios';
import { shouldFetch } from 'state/helpers';
import { API_OBJECTIVES_URL } from '../../constants/api';
import * as types from './types';
import * as actions from './actions';

import * as selectors from './selectors';

const SLICE_NAME = 'objectives';

const getTeamObjectivesFromAPI = function getTeamObjectivesFromAPIFunction(
  authToken,
  tenantID,
  payload,
) {
  const { force, ...params } = payload;
  return axios.get(`${API_OBJECTIVES_URL}/${tenantID}/getteamobjectives`, {
    params,
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

const submitTeamObjectiveToAPI = function submitTeamObjectiveToAPIFunction(
  authToken,
  tenantID,
  payload,
) {
  return axios.post(`${API_OBJECTIVES_URL}/${tenantID}/createteamobjective`, payload, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

const submitDeleteObjectiveToAPI = function submitDeleteObjectiveToAPIFunction(
  authToken,
  tenantID,
  payload,
) {
  return axios.post(`${API_OBJECTIVES_URL}/${tenantID}/deleteobjective`, payload, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

const submitCompanyObjectiveToAPI = function submitCompanyObjectiveToAPIFunction(
  authToken,
  tenantID,
  payload,
) {
  return axios.post(`${API_OBJECTIVES_URL}/${tenantID}/createcompanyobjective`, payload, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

const getObjectiveLinksFromAPI = function getObjectiveLinksFromAPIFunction(
  authToken,
  tenantID,
  payload,
) {
  const { force, ...params } = payload;
  return axios.get(`${API_OBJECTIVES_URL}/${tenantID}/getobjectivelinks`, {
    params,
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

const getObjectiveWithIdFromAPI = function getObjectiveWithIdFromAPIFunction(
  authToken,
  tenantID,
  payload,
) {
  const { force, ...params } = payload;
  return axios.get(`${API_OBJECTIVES_URL}/${tenantID}/getobjective`, {
    params,
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

const submitPersonObjectiveToAPI = function submitPersonObjectiveToAPIFunction(
  authToken,
  tenantID,
  payload,
) {
  return axios.post(`${API_OBJECTIVES_URL}/${tenantID}/createpersonalobjective`, payload, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

const submitNewKeyResultToAPI = function submitNewKeyResultToAPIFunction(
  authToken,
  tenantID,
  payload,
) {
  return axios.post(`${API_OBJECTIVES_URL}/${tenantID}/createkeyresult`, payload, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

const submitUpdatedKeyResultToAPI = function submitUpdatedKeyResultToAPIFunction(
  authToken,
  tenantID,
  payload,
) {
  return axios.post(`${API_OBJECTIVES_URL}/${tenantID}/updatekeyresult`, payload, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

const submitEditedKeyResultToAPI = function submitEditedKeyResultToAPIFunction(
  authToken,
  tenantID,
  payload,
) {
  return axios.post(`${API_OBJECTIVES_URL}/${tenantID}/editkeyresult`, payload, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

const submitUpdatedObjectiveToAPI = function submitUpdatedObjectiveToAPIFunction(
  authToken,
  tenantID,
  payload,
) {
  return axios.post(`${API_OBJECTIVES_URL}/${tenantID}/editobjective`, payload, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

const deleteObjectiveLogic = createLogic({
  type: types.DELETE_OBJECTIVE,
  process: async ({ getState, action }, dispatch, done) => {
    submitDeleteObjectiveToAPI(
      getState().auth.tokens.access_token,
      getState().auth.tenantID,
      action.payload,
    )
      .then(res => {
        const { result } = res.data;
        dispatch(actions.objectiveDeleted(result));
      })
      .catch(() => {
        dispatch(actions.errorTryAgainLater(action.payload));
      })
      .then(() => done());
  },
});

const getPeriodsLogic = createLogic({
  type: types.GET_PERIODS,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      shouldFetch(
        selectors.selectPeriodConfig(state.main[SLICE_NAME]),
        state.main.connection,
        !!action.payload && action.payload.force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    axios
      .get(`${API_OBJECTIVES_URL}/${getState().auth.tenantID}/getperiods`, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const r = res.data.result;
        dispatch(actions.periodsReceived(r));
      })
      .catch(e => {
        dispatch(actions.periodFetchFailed({ request: action.payload, response: e }));
      })
      .then(() => done());
  },
});

const getHierarchyLogic = createLogic({
  type: types.GET_HIERARCHY,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      shouldFetch(
        selectors.selectHierarchy(state.main[SLICE_NAME], action.payload.stperiod),
        state.main.connection,
        !!action.payload && action.payload.force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    axios
      .get(`${API_OBJECTIVES_URL}/${getState().auth.tenantID}/getobjectivehierarchy`, {
        params: { stperiod: action.payload.stperiod },
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const r = res.data.result;
        dispatch(actions.hierarchyReceived(r));
      })
      .catch(e => {
        dispatch(actions.hierarchyFetchFailed({ request: action.payload, response: e }));
      })
      .then(() => done());
  },
});

const getObjectiveLinksLogic = createLogic({
  type: types.GET_OBJECTIVE_LINKS,
  debounce: 2000,

  validate({ getState, action }, allow, reject) {
    const state = getState();

    if (
      selectors.selectPeriodConfig(state.main[SLICE_NAME]).ok &&
      shouldFetch(
        selectors.selectRelations(
          state.main[SLICE_NAME],
          action.payload.objectiveID,
          action.payload.stperiod,
        ),
        state.main.connection,
        !!action.payload && action.payload.force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    getObjectiveLinksFromAPI(
      getState().auth.tokens.access_token,
      getState().auth.tenantID,
      action.payload,
    )
      .then(res => {
        dispatch(actions.objectiveLinksReceived(res.data.result));
      })
      .catch(e => {
        dispatch(actions.objectiveLinksFailed({ request: action.payload, response: e }));
      })
      .then(() => done());
  },
});

const createPersonObjectiveLogic = createLogic({
  type: types.CREATE_PERSON_OBJECTIVE,
  process: async ({ getState, action }, dispatch, done) => {
    submitPersonObjectiveToAPI(
      getState().auth.tokens.access_token,
      getState().auth.tenantID,
      action.payload,
    )
      .then(res => {
        const { result } = res.data;
        result.ownerdetails = {
          firstname: getState().auth.firstName,
          lastname: getState().auth.lastName,
        };
        result.parent = action.payload.parent;
        dispatch(actions.objectiveCreated(result));
      })
      .catch(() => {
        dispatch(actions.errorTryAgainLater(action.payload));
      })
      .then(() => done());
  },
});

/* COMPANY */
const createCompanyObjectiveLogic = createLogic({
  type: types.CREATE_COMPANY_OBJECTIVE,
  process: async ({ getState, action }, dispatch, done) => {
    submitCompanyObjectiveToAPI(
      getState().auth.tokens.access_token,
      getState().auth.tenantID,
      action.payload,
    )
      .then(res => {
        const { result } = res.data;
        result.ownerdetails = {
          firstname: getState().auth.firstName,
          lastname: getState().auth.lastName,
        };
        dispatch(actions.objectiveCreated(result));
      })
      .catch(() => {
        dispatch(actions.errorTryAgainLater(action.payload));
      })
      .then(() => done());
  },
});

const getCompanyObjectivesLogic = createLogic({
  type: types.GET_COMPANY_OBJECTIVES,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      selectors.selectPeriodConfig(state.main[SLICE_NAME]).ok &&
      shouldFetch(
        selectors.selectCompanyObjectives(
          state.main[SLICE_NAME],
          action.payload && action.payload.stperiod,
        ),
        state.main.connection,
        !!action.payload && action.payload.force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    axios
      .get(`${API_OBJECTIVES_URL}/${getState().auth.tenantID}/getcompanyobjectives`, {
        params: { stperiod: action.payload.stperiod },
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const r = res.data.result;
        dispatch(actions.companyObjectivesReceived(r));
      })
      .catch(e => {
        dispatch(actions.companyObjectivesFailed({ request: action.payload, response: e }));
      })
      .then(() => done());
  },
});

const getRelatedObjectivesLogic = createLogic({
  type: types.GET_RELATED_OBJECTIVES,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      selectors.selectPeriodConfig(state.main[SLICE_NAME]).ok &&
      shouldFetch(
        selectors.selectRelatedObjectives(
          state.main[SLICE_NAME],
          action.payload.sub,
          action.payload.stperiod,
        ),
        state.main.connection,
        !!action.payload && action.payload.force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    axios
      .get(`${API_OBJECTIVES_URL}/${getState().auth.tenantID}/getrelatedobjectives`, {
        params: { stperiod: action.payload.stperiod, sub: action.payload.sub },
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const r = res.data.result;
        dispatch(actions.relatedObjectivesReceived(r));
      })
      .catch(e => {
        dispatch(actions.relatedObjectivesFailed({ request: action.payload, response: e }));
      })
      .then(() => done());
  },
});

const getTeamObjectivesLogic = createLogic({
  type: types.GET_TEAM_OBJECTIVES,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      action.payload.teamId &&
      selectors.selectPeriodConfig(state.main[SLICE_NAME]).ok &&
      shouldFetch(
        selectors.selectTeamObjectives(
          state.main[SLICE_NAME],
          action.payload.teamId,
          action.payload.stperiod,
        ),
        state.main.connection,
        !!action.payload && action.payload.force,
      )
    ) {
      allow(action);
      // reject();
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    getTeamObjectivesFromAPI(
      getState().auth.tokens.access_token,
      getState().auth.tenantID,
      action.payload,
    )
      .then(res => {
        const r = res.data.result;
        dispatch(actions.teamObjectivesReceived(r));
      })
      .catch(e => {
        dispatch(
          actions.teamObjectivesFailed({ request: { teamId: action.payload.teamId }, response: e }),
        );
      })
      .then(() => done());
  },
});

const createTeamObjectiveLogic = createLogic({
  type: types.CREATE_TEAM_OBJECTIVE,
  process: async ({ getState, action }, dispatch, done) => {
    submitTeamObjectiveToAPI(
      getState().auth.tokens.access_token,
      getState().auth.tenantID,
      action.payload,
    )
      .then(res => {
        const { result } = res.data;
        result.ownerdetails = {
          firstname: getState().auth.firstName,
          lastname: getState().auth.lastName,
        };
        result.parent = action.payload.parent;
        dispatch(actions.objectiveCreated(result));
      })
      .catch(() => {
        dispatch(actions.errorTryAgainLater(action.payload));
      })
      .then(() => done());
  },
});

const createKeyresultLogic = createLogic({
  type: types.CREATE_KEYRESULT,
  process: async ({ getState, action }, dispatch, done) => {
    submitNewKeyResultToAPI(
      getState().auth.tokens.access_token,
      getState().auth.tenantID,
      action.payload,
    )
      .then(res => {
        const result = { ...res.data.result, sub: getState().auth.userID };
        dispatch(actions.keyresultCreated(result));
      })
      .catch(() => {
        dispatch(actions.errorTryAgainLater(action.payload));
      })
      .then(() => done());
  },
});

const updateKeyresultLogic = createLogic({
  type: types.UPDATE_KEYRESULT,
  process: async ({ getState, action }, dispatch, done) => {
    submitUpdatedKeyResultToAPI(
      getState().auth.tokens.access_token,
      getState().auth.tenantID,
      action.payload,
    )
      .then(res => {
        const result = {
          ...res.data.result,
          sub: getState().auth.userID,
          comment: action.payload.comment,
        };
        dispatch(actions.keyresultUpdated(result));
      })
      .catch(() => {
        dispatch(actions.errorTryAgainLater(action.payload));
      })
      .then(() => done());
  },
});

const updateKeyresultTodosLogic = createLogic({
  type: types.UPDATE_KEYRESULT_TODOS,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(
        `${API_OBJECTIVES_URL}/${getState().auth.tenantID}/updatekeyresulttodos`,
        action.payload,
        { headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` } },
      )
      .then(res => {
        const result = { ...res.data.result, sub: getState().auth.userID };
        dispatch(actions.keyresultUpdated(result));
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

const editKeyresultLogic = createLogic({
  type: types.EDIT_KEYRESULT,
  process: async ({ getState, action }, dispatch, done) => {
    submitEditedKeyResultToAPI(
      getState().auth.tokens.access_token,
      getState().auth.tenantID,
      action.payload,
    )
      .then(res => {
        const result = { ...res.data.result, sub: getState().auth.userID };
        dispatch(actions.keyresultUpdated(result));
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        if (e.response && e.response.data) {
          errorPayload.error = e.response.data;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

const deleteKeyresultLogic = createLogic({
  type: types.DELETE_KEYRESULT,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_OBJECTIVES_URL}/${getState().auth.tenantID}/deletekeyresult`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        dispatch(actions.keyresultDeleted(result));
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

const updateObjectiveLogic = createLogic({
  type: types.UPDATE_OBJECTIVE,
  process: async ({ getState, action }, dispatch, done) => {
    submitUpdatedObjectiveToAPI(
      getState().auth.tokens.access_token,
      getState().auth.tenantID,
      action.payload,
    )
      .then(res => {
        const { result } = res.data;
        dispatch(actions.objectiveUpdated(result));
      })
      .catch(() => {
        dispatch(actions.errorTryAgainLater(action.payload));
      })
      .then(() => done());
  },
});

const gradeObjectiveLogic = createLogic({
  type: types.GRADE_OBJECTIVE,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_OBJECTIVES_URL}/${getState().auth.tenantID}/gradeobjective`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        dispatch(actions.objectiveGraded(result));
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

const updateObjectiveParentLogic = createLogic({
  type: types.UPDATE_OBJECTIVE_PARENT,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(
        `${API_OBJECTIVES_URL}/${getState().auth.tenantID}/editobjectiveparent`,
        action.payload,
        { headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` } },
      )
      .then(res => {
        const { result } = res.data;
        dispatch(actions.objectiveParentUpdated(result));
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

const getObjectiveLogic = createLogic({
  type: types.GET_OBJECTIVE,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    // Filter out objectives that have already been fetched
    const allowedRngs = [];
    for (const rng of action.payload.objectiveIDs) {
      if (
        selectors.selectPeriodConfig(state.main[SLICE_NAME]).ok &&
        shouldFetch(
          selectors.selectObjective(state.main[SLICE_NAME], rng),
          state.main.connection,
          !!action.payload && action.payload.force,
        )
      ) {
        allowedRngs.push(rng);
      }
    }
    if (allowedRngs.length > 0) {
      action.payload.objectiveIDs = allowedRngs;
      allow(action);
    } else {
      reject();
    }
  },
  process: async ({ getState, action }, dispatch, done) => {
    getObjectiveWithIdFromAPI(getState().auth.tokens.access_token, getState().auth.tenantID, {
      objectiveIDs: JSON.stringify(action.payload.objectiveIDs),
    })
      .then(res => {
        const { result } = res.data;
        dispatch(actions.objectiveReceived(result));
      })
      .catch(e => {
        dispatch(actions.objectiveFetchFailed({ request: action.payload, response: e }));
      })
      .then(() => done());
  },
});

const copyToPeriodLogic = createLogic({
  type: types.COPY_TO_PERIOD,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(
        `${API_OBJECTIVES_URL}/${getState().auth.tenantID}/copyobjectivetoperiod`,
        action.payload,
        { headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` } },
      )
      .then(res => {
        const { result } = res.data;
        dispatch(
          actions.objectiveCopiedToPeriod({
            ...result,
            sourceID: action.payload.objectiveID,
            targetStPeriod: action.payload.stperiod,
            targetLtPeriod: action.payload.ltperiod,
          }),
        );
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

export {
  createCompanyObjectiveLogic,
  getCompanyObjectivesLogic,
  createTeamObjectiveLogic,
  getTeamObjectivesLogic,
  getHierarchyLogic,
  createPersonObjectiveLogic,
  getRelatedObjectivesLogic,
  createKeyresultLogic,
  updateKeyresultLogic,
  updateKeyresultTodosLogic,
  editKeyresultLogic,
  deleteKeyresultLogic,
  deleteObjectiveLogic,
  getObjectiveLogic,
  getObjectiveLinksLogic,
  updateObjectiveLogic,
  gradeObjectiveLogic,
  updateObjectiveParentLogic,
  getPeriodsLogic,
  copyToPeriodLogic,
};
