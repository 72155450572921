import * as types from './types';

export const setLanguage = payload => ({
  type: types.SET_LOCALE,
  payload,
});

export const setDarkMode = payload => ({
  type: types.SET_DARKMODE,
  payload,
});
