import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation, Trans } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';

import Typography from '@mui/material/Typography';
import moment from 'moment';
import ActivityEntry from 'Components/Library/ActivityLog/ActivityEntry';

import { peopleSelectors } from 'state/ducks/people';

const styles = theme => ({
  green: {
    color: theme.palette.confidence.green,
  },
  amber: {
    color: theme.palette.confidence.amber,
  },
  red: {
    color: theme.palette.confidence.red,
  },
  grey: {
    // Empty for now, needs to exist
  },
});

const STATE_COLOR_MAP = {
  IN_PROGRESS: 'green',
  PENDING: 'amber',
  HELP_NEEDED: 'red',
  DONE: 'grey',
};

const NODETYPE_STATE_VALUES_TRANS_PATH = {
  COMMITMENT: 'commitments.statusValueText',
  INTERLOCK: 'interlocks.statusValueText',
};

const attrToStr = (value, attribute) => {
  if (value === null || value === '' || value === undefined) {
    return "' '";
  }
  if (attribute === 'due_date') {
    return moment.unix(value).toDate().toLocaleDateString();
  }
  return value;
};

class StandardEditEvent extends React.Component {
  getStringForLegacyEvent() {
    const updateActions = this.getUpdatedactions();
    const editedActions = this.getEditedactions();
    const actions = [...updateActions, ...editedActions];
    return [actions, updateActions.length > 0, editedActions.length > 0];
  }

  getUpdatedactions() {
    // Events are unreliable, helper function to abstract this mess
    const { t, classes, event, nodeType } = this.props;

    const actions = [];
    if (event?.changes?.status) {
      actions.push(
        <span key="krEventStatus" style={{ display: 'block', whiteSpace: 'pre-wrap' }}>
          <Trans
            i18nKey="activitylogs.valueModification"
            values={{
              name: t('activitylogs.attrNames.generic.status'),
              from: t(`${NODETYPE_STATE_VALUES_TRANS_PATH[nodeType]}.${event.changes.status}`),
              to: t(`${NODETYPE_STATE_VALUES_TRANS_PATH[nodeType]}.${event.data.status}`),
            }}
            components={[
              <b className={classes[STATE_COLOR_MAP[event.changes.status]]}>placeholder</b>,
              <b className={classes[STATE_COLOR_MAP[event.data.status]]}>placeholder</b>,
            ]}
          />
        </span>,
      );
    }
    return actions;
  }

  getEditedactions() {
    // Events are unreliable, helper function to abstract this mess
    const { t, event, selectFullName } = this.props;
    const ATTRS = ['name', 'due_date'];
    const actions = [];

    if (!!event.changes) {
      for (const attr of ATTRS) {
        if (
          Object.prototype.hasOwnProperty.call(event.data, attr) &&
          Object.prototype.hasOwnProperty.call(event.changes, attr)
        ) {
          actions.push(
            <span key={attr} style={{ display: 'block', whiteSpace: 'pre-wrap' }}>
              <Trans
                i18nKey="activitylogs.valueModification"
                values={{
                  name: t(`activitylogs.attrNames.generic.${attr}`),
                  from: attrToStr(event.changes[attr], attr),
                  to: attrToStr(event.data[attr], attr),
                }}
                components={[<b>placeholder</b>, <b>placeholder</b>]}
              />
            </span>,
          );
        }
      }
      if (
        Object.prototype.hasOwnProperty.call(event, 'owner') &&
        Object.prototype.hasOwnProperty.call(event.changes, 'owner')
      ) {
        actions.push(
          <span key="owner" style={{ display: 'block', whiteSpace: 'pre-wrap' }}>
            <Trans
              i18nKey="activitylogs.valueModification"
              values={{
                name: t(`activitylogs.attrNames.generic.owner`),
                from: selectFullName(event.changes.owner),
                to: selectFullName(event.data.owner),
              }}
              components={[<b>placeholder</b>, <b>placeholder</b>]}
            />
          </span>,
        );
      }
      // TODO: how to show the changes for very long descriptions:
      if (
        Object.prototype.hasOwnProperty.call(event, 'description') &&
        Object.prototype.hasOwnProperty.call(event.changes, 'description')
      ) {
        actions.push(
          <span key="description" style={{ display: 'block', whiteSpace: 'pre-wrap' }}>
            {t(`activitylogs.attrNames.generic.description`)}
          </span>,
        );
      }
    }
    return actions;
  }

  render() {
    const { t, event, nodeType } = this.props;
    const { owner, timestamp } = event;

    let eventContent;
    let isCreate;
    let isUpdate;
    let isEdit;

    if (event.isCreate) {
      isCreate = true;
      isUpdate = false;
      isEdit = false;
    } else {
      [eventContent, isUpdate, isEdit] = this.getStringForLegacyEvent();
      isCreate = false;
    }

    const verb = isCreate
      ? t('activitylogs.createdVerb')
      : isEdit
      ? t('activitylogs.editedVerb')
      : isUpdate
      ? t('activitylogs.updatedVerb')
      : t('activitylogs.checkedInOn');
    const title = `${verb} ${t(`activitylogs.nodeTypes.${nodeType}`)}`;
    const initialCollapseState = isUpdate || !!event.comment ? 'expanded' : 'collapsed';
    return (
      <ActivityEntry
        name="KR-UPDATE-EVT"
        sub={owner}
        title={title}
        timestamp={timestamp}
        collapseConfig={{
          collapseCta: `(${t('general.hide')})`,
          expandCta: `(${t('general.show')})`,
          initialState: initialCollapseState,
          allowChange: !!isEdit,
        }}
        message={
          !!event.comment && (
            <Typography variant="body2" component="span" display="block" gutterBottom>
              {event.comment}
            </Typography>
          )
        }
        metaDataMessage={
          <Typography
            variant="body2"
            component="span"
            display="block"
            color="text.secondary"
            gutterBottom
          >
            {eventContent}
          </Typography>
        }
      />
    );
  }
}

const mapStateToProps = state => ({
  selectFullName: sub => peopleSelectors.selectFullName(state.main.people, sub),
});

StandardEditEvent.propTypes = {
  classes: PropTypes.exact({
    green: PropTypes.string,
    amber: PropTypes.string,
    red: PropTypes.string,
    grey: PropTypes.string,
  }),
  event: PropTypes.object,
  selectFullName: PropTypes.func,
  nodeType: PropTypes.string,
  t: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(StandardEditEvent)));
