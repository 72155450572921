import { chunk, isEqual } from 'lodash';

export function emptyHash() {
  return [];
}

export function parseHash(hashStr) {
  const modalState = [];
  // ignore the following modal definitions that can have null values:
  const ALLOWED_EMPTY_VALUES = ['insights.viewsurvey', 'team.viewteam'];
  if (!!hashStr) {
    const hashArr = decodeURI(hashStr).substring(1, hashStr.length).split(';');
    hashArr.map(path => {
      const pathArr = path.substring(6, path.length).split('/');

      // if there are missing parts in the url better not show anything as it will result in broken views
      if (pathArr.length < 2) {
        return null;
      }

      switch (pathArr[0].toLowerCase()) {
        /* OKR MODALS */
        case 'objectives':
          switch (pathArr[1].toLowerCase()) {
            case 'view':
              modalState.push({
                type: 'objectives.view',
                objectiveID: pathArr[3],
                period: pathArr[2],
              });
              break;
            case 'viewkr':
              modalState.push({
                type: 'objectives.viewkr',
                keyresultID: pathArr[3],
                period: pathArr[2],
              });
              break;
            case 'add':
              // eslint-disable-next-line no-case-declarations
              const modal = {
                type: 'objectives.add',
                stperiod: pathArr[2],
                domain: pathArr[3],
              };
              if (modal.domain === 'team') {
                modal.teamId = pathArr[4];
              }
              if (hashArr[1] && hashArr[1].includes('related=')) {
                modal.related = pathArr[4].split('related=').pop();
              }
              modalState.push(modal);
              break;
            case 'addkr':
              modalState.push({
                type: 'objectives.addkr',
                objectiveID: pathArr[2],
              });
              break;
            case 'viewteam':
              modalState.push({
                type: 'objectives.dashboard',
                period: pathArr[2],
                domain: {
                  t: 'team',
                  d: pathArr[3],
                },
              });
              break;
            case 'viewperson':
              modalState.push({
                type: 'objectives.dashboard',
                period: pathArr[2],
                domain: {
                  t: 'personal',
                  d: pathArr[3],
                },
              });
              break;
            case 'dashboard':
              modalState.push({
                type: 'objectives.dashboard',
                period: pathArr[2],
                domain: {
                  t: pathArr[3],
                  d: pathArr[4],
                },
              });
              break;
            case 'reflect':
              modalState.push({
                type: 'objectives.reflect',
                objectiveID: pathArr[2],
              });
              break;
            default:
              break;
          }
          break;
        case 'csi':
          /* UNDERSTANDING MODALS */
          switch (pathArr[1].toLowerCase()) {
            case 'viewteam':
              modalState.push({
                type: 'interpretation.viewteam',
                csiID: pathArr[2],
                teamId: pathArr[3],
              });
              break;
            case 'create':
              modalState.push({
                type: 'interpretation.create',
                cpID: pathArr[2],
              });
              break;
            default:
              break;
          }
          break;
        case 'notifications':
          /* NOTIFICATION MODALS */
          switch (pathArr[1].toLowerCase()) {
            case 'view':
              modalState.push({
                type: 'notifications.view',
              });
              break;
            default:
              break;
          }
          break;
        case 'insights':
          /* INSIGHTS MODALS */
          switch (pathArr[1].toLowerCase()) {
            case 'reply':
              modalState.push({
                type: 'insights.reply',
                surveyID: pathArr[2],
              });
              break;
            case 'createtopic':
              modalState.push({
                type: 'insights.create',
                audienceType: pathArr[2],
                audienceID: pathArr[3],
              });
              break;
            case 'createsurvey':
              modalState.push({
                type: 'insights.createsurvey',
                topicID: pathArr[2],
              });
              break;
            case 'deletesurvey':
              modalState.push({
                type: 'insights.deletesurvey',
                surveyID: pathArr[2],
              });
              break;
            case 'viewsurvey':
              modalState.push({
                type: 'insights.viewsurvey',
                surveyID: pathArr[2],
                teamID: pathArr[3],
                doNotCascade: pathArr[4] && pathArr[4] !== 'f', // default to cascade for backwards compatibility
              });
              break;
            default:
              break;
          }
          break;
        case 'context':
          /* PROGRAM CONTEXT MODALS */
          switch (pathArr[1].toLowerCase()) {
            case 'view':
              modalState.push({
                type: 'context.view',
                contextID: pathArr[2],
                cardID: pathArr[3],
              });
              break;
            case 'edit':
              modalState.push({
                type: 'context.edit',
                contextID: pathArr[2],
                cardID: pathArr[3],
              });
              break;
            case 'create':
              modalState.push({
                type: 'context.create',
                contextID: pathArr[2],
              });
              break;
            /* TEAM CONTEXT MODALS */
            case 'viewteam':
              modalState.push({
                type: 'context.viewteam',
                contextID: pathArr[2],
                cardID: pathArr[3],
              });
              break;
            case 'editteam':
              modalState.push({
                type: 'context.editteam',
                contextID: pathArr[2],
                cardID: pathArr[3],
              });
              break;
            case 'createteam':
              modalState.push({
                type: 'context.createteam',
                contextID: pathArr[2],
              });
              break;
            default:
              break;
          }
          break;
        case 'facilitation':
          switch (pathArr[1].toLowerCase()) {
            case 'start':
              modalState.push({
                type: 'facilitation.start',
                teamID: pathArr[2],
              });
              break;
            case 'live':
              modalState.push({
                type: 'facilitation.live',
                teamID: pathArr[2],
                sessionID: pathArr[3],
              });
              break;
            case 'delete':
              modalState.push({
                type: 'facilitation.delete',
                teamID: pathArr[2],
                sessionID: pathArr[3],
              });
              break;
            default:
              break;
          }
          break;
        case 'programs':
          switch (pathArr[1].toLowerCase()) {
            case 'create':
              modalState.push({
                type: 'programs.create',
              });
              break;
            default:
              break;
          }
          break;
        case 'team':
          switch (pathArr[1].toLowerCase()) {
            case 'view':
              modalState.push({
                type: 'team.viewteam',
                teamId: pathArr[2],
                page: pathArr[3],
                subpage: pathArr[4],
              });
              break;
            default:
              break;
          }
          break;
        case 'commitments':
          switch (pathArr[1].toLowerCase()) {
            case 'view':
              modalState.push({
                type: 'commitments.view',
                commitmentID: pathArr[2],
              });
              break;
            case 'add':
              // eslint-disable-next-line no-case-declarations
              const modal = {
                type: 'commitments.add',
              };
              if (pathArr.length >= 4 && pathArr.length % 2 === 0) {
                modal.domains = [];
                for (const domainChunk of chunk(pathArr.slice(2), 2)) {
                  const [type, id] = domainChunk;
                  modal.domains.push({ type, id });
                }
              }
              // @TODO: being dependent on pathArr length seems pretty fragile
              // if creating related from other instance
              if (hashArr[1] && hashArr[1].includes('related=')) {
                modal.related = pathArr[2].split('related=').pop();
              }
              modalState.push(modal);
              break;
            default:
              break;
          }
          break;
        case 'interlocks':
          switch (pathArr[1].toLowerCase()) {
            case 'view':
              modalState.push({
                type: 'interlocks.view',
                interlockID: pathArr[2],
              });
              break;
            case 'add':
              // eslint-disable-next-line no-case-declarations
              const modal = {
                type: 'interlocks.add',
              };
              // @TODO: being dependent on pathArr length seems pretty fragile
              if (pathArr.length === 4) {
                modal.domainType = pathArr[2];
                modal.domainId = pathArr[3];
              }
              // @TODO: being dependent on pathArr length seems pretty fragile
              // if creating related from other instance
              if (hashArr[1] && hashArr[1].includes('related=')) {
                modal.related = pathArr[2].split('related=').pop();
              }
              modalState.push(modal);
              break;
            default:
              break;
          }
          break;
        case 'gameplans':
          switch (pathArr[1].toLocaleLowerCase()) {
            case 'edit':
              modalState.push({
                type: 'gameplans.edit',
                domainId: pathArr[2],
                gameplanId: pathArr[3],
                widgetId: pathArr[4],
              });
              break;
            case 'view':
              modalState.push({
                type: 'gameplans.view',
                domainId: pathArr[2],
                gameplanId: pathArr[3],
                widgetId: pathArr[4],
              });
              break;
            case 'add':
              modalState.push({
                type: 'gameplans.add',
                domainId: pathArr[2],
                gameplanId: pathArr[3],
              });
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      return null;
    });
  }

  // if any value of any modal is missing better not show anything as it will result in broken views, most likely missing part of the url
  for (const modal of modalState) {
    if (!ALLOWED_EMPTY_VALUES.includes(modal.type)) {
      for (const value of Object.entries(modal)) {
        if (!value[1]) {
          return [];
        }
      }
    }
  }

  return modalState;
}

export function buildHash(modalState) {
  let hashStr = '#';
  for (const modal of modalState) {
    switch (modal.type) {
      case 'objectives.viewteam':
        hashStr = `${hashStr}modal/objectives/viewteam/${modal.period}/${modal.teamId};`;
        break;
      case 'objectives.viewperson':
        hashStr = `${hashStr}modal/objectives/viewperson/${modal.period}/${modal.sub};`;
        break;
      case 'objectives.view':
        hashStr = `${hashStr}modal/objectives/view/${modal.period}/${modal.objectiveID};`;
        break;
      case 'objectives.viewkr':
        hashStr = `${hashStr}modal/objectives/viewkr/${modal.period}/${modal.keyresultID};`;
        break;
      case 'objectives.add':
        hashStr = `${hashStr}modal/objectives/add/${modal.stperiod}/${modal.domain}`;
        if (modal.domain === 'team') {
          hashStr = `${hashStr}/${modal.teamId}`;
        }
        if (modal.related) {
          hashStr = `${hashStr}/related=${modal.related}`;
        }
        hashStr = `${hashStr};`;
        break;
      case 'objectives.addkr':
        hashStr = `${hashStr}modal/objectives/addkr/${modal.objectiveID};`;
        break;
      case 'objectives.reflect':
        hashStr = `${hashStr}modal/objectives/reflect/${modal.objectiveID};`;
        break;
      case 'objectives.dashboard':
        if (!!modal.domain) {
          hashStr = `${hashStr}modal/objectives/dashboard/${modal.period}/${modal.domain.t}/${modal.domain.d};`;
        }
        break;
      case 'interpretation.viewteam':
        hashStr = `${hashStr}modal/csi/viewteam/${modal.csiID}/${modal.teamId};`;
        break;
      case 'interpretation.create':
        hashStr = `${hashStr}modal/csi/create/${modal.cpID};`;
        break;
      case 'notifications.view':
        hashStr = `${hashStr}modal/notifications/view;`;
        break;
      case 'insights.reply':
        hashStr = `${hashStr}modal/insights/reply/${modal.surveyID};`;
        break;
      case 'insights.create':
        hashStr = `${hashStr}modal/insights/createtopic/${modal.audienceType}/${modal.audienceID};`;
        break;
      case 'insights.deletesurvey':
        hashStr = `${hashStr}modal/insights/deletesurvey/${modal.surveyID};`;
        break;
      case 'insights.createsurvey':
        hashStr = `${hashStr}modal/insights/createsurvey/${modal.topicID};`;
        break;
      case 'insights.viewsurvey':
        hashStr = `${hashStr}modal/insights/viewsurvey/${modal.surveyID}/${
          !!modal.teamID ? modal.teamID : ''
        }/${modal.doNotCascade ? 't' : 'f'};`;
        break;
      case 'context.view':
        hashStr = `${hashStr}modal/context/view/${modal.contextID}/${modal.cardID};`;
        break;
      case 'context.edit':
        hashStr = `${hashStr}modal/context/edit/${modal.contextID}/${modal.cardID};`;
        break;
      case 'context.create':
        hashStr = `${hashStr}modal/context/create/${modal.contextID};`;
        break;
      case 'context.viewteam':
        hashStr = `${hashStr}modal/context/viewteam/${modal.contextID}/${modal.cardID};`;
        break;
      case 'context.editteam':
        hashStr = `${hashStr}modal/context/editteam/${modal.contextID}/${modal.cardID};`;
        break;
      case 'context.createteam':
        hashStr = `${hashStr}modal/context/createteam/${modal.contextID};`;
        break;
      case 'facilitation.start':
        hashStr = `${hashStr}modal/facilitation/start/${modal.teamID};`;
        break;
      case 'facilitation.live':
        hashStr = `${hashStr}modal/facilitation/live/${modal.teamID}/${modal.sessionID};`;
        break;
      case 'facilitation.delete':
        hashStr = `${hashStr}modal/facilitation/delete/${modal.teamID}/${modal.sessionID};`;
        break;
      case 'programs.create':
        hashStr = `${hashStr}modal/programs/create;`;
        break;
      case 'team.viewteam':
        hashStr = `${hashStr}modal/team/view/${modal.teamId}/${modal.page}`;
        if (!!modal.subpage) {
          hashStr = `${hashStr}/${modal.subpage};`;
        } else {
          hashStr = `${hashStr};`;
        }
        break;
      case 'commitments.add':
        hashStr = `${hashStr}modal/commitments/add`;
        if (!!modal.domains?.length) {
          for (const domain of modal.domains) {
            hashStr = `${hashStr}/${domain.type}/${domain.id}`;
          }
        }
        if (modal.related) {
          hashStr = `${hashStr}/related=${modal.related}`;
        }
        hashStr = `${hashStr};`;
        break;
      case 'commitments.view':
        hashStr = `${hashStr}modal/commitments/view/${modal.commitmentID};`;
        break;
      case 'interlocks.add':
        hashStr = `${hashStr}modal/interlocks/add`;
        if (modal.domainType && modal.domainId) {
          hashStr = `${hashStr}/${modal.domainType}/${modal.domainId}`;
        }
        if (modal.related) {
          hashStr = `${hashStr}/related=${modal.related}`;
        }
        hashStr = `${hashStr};`;
        break;
      case 'interlocks.view':
        hashStr = `${hashStr}modal/interlocks/view/${modal.interlockID};`;
        break;
      case 'gameplans.add':
        hashStr = `${hashStr}modal/gameplans/add/${modal.domainId}/${modal.gameplanId};`;
        break;
      case 'gameplans.edit':
        hashStr = `${hashStr}modal/gameplans/edit/${modal.domainId}/${modal.gameplanId}/${modal.widgetId};`;
        break;
      case 'gameplans.view':
        hashStr = `${hashStr}modal/gameplans/view/${modal.domainId}/${modal.gameplanId}/${modal.widgetId};`;
        break;
      default:
        break;
    }
  }

  // the point of adding the ";" at the end of every condition, and removing
  // the last one at the very end, is that there can be multiple modals open.
  // The hash portion could eg be:
  // modal/objectives/view/2023-Q2/CO_tg-dev_2023__f4a501d;modal/objectives/addkr/CO_tg-dev_2023__f4a501d
  // where we have the "view objective" dialog open, on top of which we have the "add key result" modal.
  if (!!hashStr) {
    hashStr = hashStr.substring(0, hashStr.length - 1);
  }
  return hashStr;
}

export const openTopicReplyPanel = (surveyID, force, navigate, location) => {
  let modalState = parseHash(location.hash);
  if (!!force) {
    // close all other panels
    modalState = [];
  }
  modalState.push({ type: 'insights.reply', surveyID });
  navigate(buildHash(modalState));
};

export const openSurveyDeleteDialog = (surveyID, navigate, location) => {
  const modalState = parseHash(location.hash);
  modalState.push({ type: 'insights.deletesurvey', surveyID });
  navigate(buildHash(modalState));
};

export const openTopicViewSurveyPanel = (
  surveyID,
  force,
  teamID,
  doNotCascade,
  navigate,
  location,
) => {
  if (!!force) {
    /* We want to close the reply panel if we're coming from a reply panel after a survey has ended */
    const modalState = [];
    modalState.push({
      type: 'insights.viewsurvey',
      surveyID,
      teamID,
      doNotCascade,
    });
    navigate(buildHash(modalState), { replace: true });
  } else {
    const modalState = parseHash(location.hash);
    modalState.push({
      type: 'insights.viewsurvey',
      surveyID,
      teamID,
      doNotCascade,
    });
    navigate(buildHash(modalState));
  }
};

export const openCreateTopicPanel = (audienceType, audienceID, navigate, location) => {
  const modalState = parseHash(location.hash);
  modalState.push({ type: 'insights.create', audienceType, audienceID });
  const hash = buildHash(modalState);
  navigate(hash);
};

export const openCreateSurveyPanel = (topicID, force, navigate, location) => {
  let modalState = parseHash(location.hash);
  if (!!force) {
    // close all other panels
    modalState = [];
  }
  modalState.push({ type: 'insights.createsurvey', topicID });
  const hash = buildHash(modalState);
  navigate(hash);
};

export const openCreateInterpretationPanel = (cpID, navigate, location) => {
  const modalState = parseHash(location.hash);
  modalState.push({ type: 'interpretation.create', cpID });
  const hash = buildHash(modalState);
  navigate(hash);
};

export const openViewTeamInterpretationPanel = (csiID, teamId, navigate, location) => {
  const modalState = parseHash(location.hash);
  modalState.push({ type: 'interpretation.viewteam', csiID, teamId });
  const modalHash = buildHash(modalState);
  navigate(modalHash);
};

export const openAddCommitmentPanel = (navigate, location, domains, relatedItemId) => {
  const modalState = parseHash(location.hash);
  modalState.push({ type: 'commitments.add', domains, related: relatedItemId });
  const hash = buildHash(modalState);
  navigate(hash);
};

export const openAddInterlockPanel = (navigate, location, domainType, domainId, relatedItemId) => {
  const modalState = parseHash(location.hash);
  modalState.push({ type: 'interlocks.add', domainType, domainId, related: relatedItemId });
  const hash = buildHash(modalState);
  navigate(hash);
};

export const openViewCommitmentPanel = (commitmentID, toBeReplaced, navigate, location) => {
  let modalState = parseHash(location.hash);
  if (!!toBeReplaced) {
    modalState = modalState.filter(modal => !isEqual(modal, toBeReplaced));
  }
  modalState = modalState.filter(modal => modal.type !== 'commitments.view');

  modalState.push({ type: 'commitments.view', commitmentID });
  const hash = buildHash(modalState);
  navigate(hash, { replace: Boolean(toBeReplaced) });
};

export const openViewInterlockPanel = (interlockID, toBeReplaced, navigate, location) => {
  let modalState = parseHash(location.hash);
  if (!!toBeReplaced) {
    modalState = modalState.filter(modal => !isEqual(modal, toBeReplaced));
  }
  modalState = modalState.filter(modal => modal.type !== 'interlocks.view');
  modalState.push({ type: 'interlocks.view', interlockID });
  const hash = buildHash(modalState);
  navigate(hash, { replace: Boolean(toBeReplaced) });
};

export const openViewTeamPanel = (navigate, location, teamId, page, subpage) => {
  let modalState = parseHash(location.hash);
  // Close any other potential view team panels
  modalState = modalState.filter(modal => modal.type !== 'team.viewteam');
  modalState.push({ type: 'team.viewteam', teamId, page: page || 'overview', subpage });
  const modalHash = buildHash(modalState);
  navigate(modalHash);
};

export const openNotificationsPanel = (navigate, location) => {
  const modalState = parseHash(location.hash);
  modalState.push({ type: 'notifications.view' });
  const modalHash = buildHash(modalState);
  navigate(modalHash);
};

export const openAddObjectivePanel = (options, navigate, location, relatedItemId) => {
  const modalState = parseHash(location.hash);
  const modal = {
    domain: options.type,
    stperiod: options.stperiod,
    type: 'objectives.add',
    related: relatedItemId,
  };
  if (options.type === 'team') {
    modal.teamId = options.teamId;
  }
  modalState.push(modal);
  const modalHash = buildHash(modalState);
  navigate(modalHash);
};

export const openAddKeyResultPanel = (objectiveID, navigate, location) => {
  const modalState = parseHash(location.hash);
  const modal = {
    objectiveID,
    type: 'objectives.addkr',
  };
  modalState.push(modal);
  const modalHash = buildHash(modalState);
  navigate(modalHash);
};

export const openViewObjectiveDashboard = (period, d, t, navigate, location) => {
  if (!period) {
    // Try to parse the selected objective cadence period from the url
    // "/home/objectives/2018-Q4/dashboard/company"
    period = location.pathname.split('/')[3];
  }
  let modalState = parseHash(location.hash);
  modalState = modalState.filter(modal => modal.type !== 'objectives.dashboard');
  modalState.push({ type: 'objectives.dashboard', domain: { d, t }, period });
  const modalHash = buildHash(modalState);
  navigate(modalHash);
};

export const openViewObjectivePanel = (period, rng, force, navigate, location) => {
  let modalState;
  if (!period) {
    // Try to parse the selected objective cadence period from the url
    // "/home/objectives/2018-Q4/dashboard/company"
    period = location.pathname.split('/')[3];
  }
  if (!!force) {
    /* After creating an objective we want to open the panel,
    but the back button should not take back to create objective */
    modalState = parseHash(location.hash);
    modalState = modalState.filter(modal => modal.type !== 'objectives.add');
    modalState.push({ type: 'objectives.view', objectiveID: rng, period });
    const modalHash = buildHash(modalState);
    navigate(modalHash, { replace: true });
  } else {
    modalState = parseHash(location.hash);
    modalState = modalState.filter(modal => modal.type !== 'objectives.view');
    modalState.push({ type: 'objectives.view', objectiveID: rng, period });
    const modalHash = buildHash(modalState);
    navigate(modalHash);
  }
};

export const openDialogObjectiveReflection = (objectiveID, navigate, location) => {
  const modalState = parseHash(location.hash);
  modalState.push({ type: 'objectives.reflect', objectiveID });
  const modalHash = buildHash(modalState);
  navigate(modalHash);
};

export const openViewKeyresultPanel = (period, keyresultID, force, navigate, location) => {
  let modalState = parseHash(location.hash);
  if (!period) {
    // Try to parse the selected objective cadence period from the url
    // "/home/objectives/2018-Q4/dashboard/company"
    period = location.pathname.split('/')[3];
  }
  modalState = modalState.filter(
    modal => modal.type !== 'objectives.viewkr' && modal.type !== 'objectives.addkr',
  );
  modalState.push({ type: 'objectives.viewkr', keyresultID, period });
  const modalHash = buildHash(modalState);
  if (force) {
    navigate(modalHash, { replace: true });
  } else {
    navigate(modalHash);
  }
};

export const openContextCardDialog = (contextID, contextType, cardID, navigate, location) => {
  const modalState = parseHash(location.hash);
  const typeSuffix = contextType === 'team' ? 'team' : '';

  modalState.push({
    type: `context.view${typeSuffix}`,
    contextID,
    contextType,
    cardID,
  });
  const modalHash = buildHash(modalState);
  navigate(modalHash);
};

export const openEditContextCardDialog = (contextID, contextType, cardID, navigate, location) => {
  const modalState = parseHash(location.hash);
  const typeSuffix = contextType === 'team' ? 'team' : '';

  modalState.push({
    type: `context.edit${typeSuffix}`,
    contextID,
    contextType,
    cardID,
  });
  const modalHash = buildHash(modalState);
  navigate(modalHash);
};

export const openCreateContextCardDialog = (contextID, contextType, navigate, location) => {
  const modalState = parseHash(location.hash);
  const typeSuffix = contextType === 'team' ? 'team' : '';

  modalState.push({
    type: `context.create${typeSuffix}`,
    contextType,
    contextID,
  });
  const modalHash = buildHash(modalState);
  navigate(modalHash);
};

export const closeModal = (navigate, location) => {
  const modalState = parseHash(location.hash);
  if (modalState.length !== 0) {
    modalState.pop();
    let modalHash = buildHash(modalState);
    if (modalState.length === 0) {
      modalHash = location.pathname;
    }
    navigate(modalHash, { replace: true });
  }
};

export const openStartFacilitationPanel = (teamID, navigate, location) => {
  const modalState = parseHash(location.hash);
  modalState.push({
    type: 'facilitation.start',
    teamID,
  });
  const modalHash = buildHash(modalState);
  navigate(modalHash);
};

export const openProgramsCreatePanel = (navigate, location) => {
  const modalState = parseHash(location.hash);
  modalState.push({
    type: 'programs.create',
  });
  const modalHash = buildHash(modalState);
  navigate(modalHash);
};

export const openViewLiveFacilitationPanel = (teamID, sessionID, force, navigate, location) => {
  let modalState;
  if (!!force) {
    /* After creating an objective we want to open the panel,
    but the back button should not take back to create objective */
    modalState = parseHash(location.hash);
    modalState = modalState.filter(modal => modal.type !== 'facilitation.start');
    modalState.push({
      type: 'facilitation.live',
      teamID,
      sessionID,
    });
    const modalHash = buildHash(modalState);
    navigate(modalHash, { replace: true });
  } else {
    modalState = parseHash(location.hash);
    modalState.push({
      type: 'facilitation.live',
      teamID,
      sessionID,
    });
    const modalHash = buildHash(modalState);
    navigate(modalHash);
  }
};

export const openDeleteFacilitationDialog = (teamID, sessionID, navigate, location) => {
  const modalState = parseHash(location.hash);
  modalState.push({
    type: 'facilitation.delete',
    teamID,
    sessionID,
  });
  const modalHash = buildHash(modalState);
  navigate(modalHash);
};

export const openAddGameplanCardDialog = (domainId, gameplanId, navigate, location) => {
  const modalState = parseHash(location.hash);
  modalState.push({
    type: 'gameplans.add',
    domainId,
    gameplanId,
  });
  const modalHash = buildHash(modalState);
  navigate(modalHash);
};

export const openEditGameplanCardDialog = (domainId, gameplanId, widgetId, navigate, location) => {
  const modalState = parseHash(location.hash);
  modalState.push({
    type: 'gameplans.edit',
    domainId,
    gameplanId,
    widgetId,
  });
  const modalHash = buildHash(modalState);
  navigate(modalHash);
};

export const openViewGameplanCardDialog = (
  domainId,
  gameplanId,
  widgetId,
  navigate,
  location,
  replace = false,
) => {
  let modalState = parseHash(location.hash);
  if (replace) {
    modalState = modalState.filter(modal => modal.type !== 'gameplans.view');
  }
  modalState.push({
    type: 'gameplans.view',
    domainId,
    gameplanId,
    widgetId,
  });
  const modalHash = buildHash(modalState);
  navigate(modalHash);
};
