import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { Waypoint } from 'react-waypoint';
import StandardInput from 'Components/Library/StandardInput';
import MediaQueryWrapper from 'Components/Common/MediaQueryWrapper';

const styles = theme => ({
  row: {
    marginBottom: theme.spacing(4),
    backgroundColor: theme.palette.background.box,
    ...theme.shape,
    padding: theme.spacing(3),
  },
  inputContainer: {
    flexGrow: 5,
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
});

class FormSubmitInput extends Component {
  constructor(props) {
    super(props);
    this.state = { inViewArray: new Array(22).fill(false) };
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  handleWaypointEnter = i => {
    const inViewArray = [...this.state.inViewArray];
    inViewArray[i] = true;
    this.setState({ inViewArray });
  };

  handleWaypointLeave = i => {
    const inViewArray = [...this.state.inViewArray];
    inViewArray[i] = false;
    this.setState({ inViewArray });
  };

  addAnswer(i) {
    this.props.onAddAnswer(i);
    // after a new answer has been added wait 200ms to check if the add -link is hidden. If so, scroll down by height of new answer 48px
    this.timeout = setTimeout(() => {
      if (!this.state.inViewArray[i]) {
        // depending on the screen size you have to scroll on different elements
        if (!!this.props.contentRef && !!this.props.contentRef.current && !this.props.wideScreen) {
          this.props.contentRef.current.scrollTop += 48;
        } else if (!!this.props.secondRef && !!this.props.secondRef.current) {
          this.props.secondRef.current.scrollTop += 48;
        }
      }
    }, 300);
  }

  render() {
    const {
      classes,
      facilitationData,
      onAnswerTextChange,
      onRemoveAnswer,
      answers,
      submitDisabled,
      t,
      wideScreen,
    } = this.props;
    let questions = [];
    if (facilitationData.ok) {
      // eslint-disable-next-line prefer-destructuring
      questions = facilitationData.questions;
    }

    return (
      <div name="live-dfac-panel-input-form">
        {questions &&
          questions.map((question, i) => (
            <div key={`live-dfac-panel-question-${i}`}>
              <div className={clsx([classes.row, 'live-dfac-panel-question-item'])}>
                <Typography
                  variant="h6"
                  sx={{ mb: 2 }}
                  color="text.strong"
                  component="span"
                  display="block"
                  id={`live-dfac-panel-question-${i}`}
                >
                  {`${question}`}
                </Typography>
                {answers[i].map((answer, answerIndex) => (
                  <div
                    className={classes.inputContainer}
                    key={`${i}-${answerIndex}-panel-edit-field-key`}
                  >
                    <StandardInput
                      id={`live-dfac-panel-edit-field-${i}-${answerIndex}`}
                      label={t('dfac.sessionanswerlabel')}
                      inputProps={{ maxLength: '512' }}
                      variant="standard"
                      margin="dense"
                      multiline
                      fullWidth
                      autoFocus={answerIndex > 0 || (i === 0 && answerIndex === 0)}
                      disabled={submitDisabled}
                      value={answers[i][answerIndex]}
                      onChange={evt => onAnswerTextChange(i, answerIndex, evt.target.value)}
                    />
                    {answers[i].length > 1 && (
                      <IconButton
                        id={`live-dfac-panel-delete-button-${i}-${answerIndex}`}
                        size="small"
                        onClick={() => onRemoveAnswer(i, answerIndex)}
                        aria-label={t('general.delete')}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </div>
                ))}
                <Waypoint
                  onEnter={() => this.handleWaypointEnter(i)}
                  onLeave={() => this.handleWaypointLeave(i)}
                  bottomOffset="20px"
                  // depending on the screen size the viewpoint is different
                  scrollableAncestor={!wideScreen ? this.props.contentRef.current : undefined}
                >
                  <Button
                    variant="text"
                    color="secondary"
                    onClick={() => this.addAnswer(i)}
                    id={`live-dfac-panel-add-answer-${i}`}
                  >
                    + {t('dfac.sessionAddAnswerButtonLabel')}
                  </Button>
                </Waypoint>
              </div>
            </div>
          ))}
      </div>
    );
  }
}

FormSubmitInput.propTypes = {
  onAnswerTextChange: PropTypes.func,
  onAddAnswer: PropTypes.func,
  onRemoveAnswer: PropTypes.func,
  submitDisabled: PropTypes.bool,
  facilitationData: PropTypes.object,
  answers: PropTypes.object,
  classes: PropTypes.exact({
    row: PropTypes.string,
    inputContainer: PropTypes.string,
    input: PropTypes.string,
    question: PropTypes.string,
  }),
  t: PropTypes.func,
  contentRef: PropTypes.object,
  secondRef: PropTypes.object,
  wideScreen: PropTypes.bool,
};

export default MediaQueryWrapper()(withTranslation()(withStyles(styles)(FormSubmitInput)));
