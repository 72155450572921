import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

const styles = {
  standard: {
    '& .MuiFilledInput-root': {
      backgroundColor: theme => theme.palette.background.paper,
      border: theme => `1px solid ${theme.palette.action.border}`,
      '&:hover:not(.Mui-disabled):not(.Mui-error)': {
        backgroundColor: theme => theme.palette.background.paper,
        border: theme => `1px solid ${theme.palette.action.borderActive}`,
      },
      '&.Mui-focused:not(.Mui-error)': {
        backgroundColor: theme => theme.palette.background.paper,
        border: theme => `1px solid ${theme.palette.action.borderActive}`,
      },
      '&.Mui-error': {
        backgroundColor: theme => theme.palette.background.paper,
        border: theme => `1px solid ${theme.palette.error.main}`,
      },
      '&.Mui-disabled': {
        border: theme => `1px dotted ${theme.palette.action.disabled}`,
      },
    },
  },
  filled: {
    '& .MuiFilledInput-root': {
      backgroundColor: theme => theme.palette.background.box,
      border: theme => `1px solid ${theme.palette.action.border}`,
      '&.Mui-focused': {
        border: theme => `1px solid ${theme.palette.action.borderActive}`,
      },
      '&.Mui-error': {
        border: theme => `1px solid ${theme.palette.error.main}`,
      },
      '&.Mui-disabled': {
        border: theme => `1px dotted ${theme.palette.action.disabled}`,
      },
    },
  },
  outlined: {
    '& .MuiInputBase-root fieldset.MuiOutlinedInput-notchedOutline': {
      borderWidth: 1.1,
    },
  },
};

function StandardInput(props) {
  const { variant, InputProps, sx, ...rest } = props;
  const dynamicProps = {};
  const dynamicInputProps = {};
  if (['standard', 'filled'].includes(variant)) {
    dynamicInputProps.disableUnderline = true;
    dynamicProps.variant = 'filled';
  } else {
    dynamicProps.variant = 'outlined';
  }
  // endAdornment is moved from rest to dynamicInputProps
  if ('endAdornment' in rest) {
    dynamicInputProps.endAdornment = rest.endAdornment;
    delete rest.endAdornment;
  }

  const addedSx = styles[variant];
  return (
    <TextField
      sx={{ ...addedSx, ...sx }}
      InputProps={{ ...InputProps, ...dynamicInputProps }}
      {...rest}
      {...dynamicProps}
    />
  );
}

StandardInput.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * This prop helps users to fill forms faster, especially on mobile devices.
   * The name can be confusing, as it's more like an autofill.
   * You can learn more about it [following the specification](https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill).
   */
  autoComplete: PropTypes.string,
  /**
   * If `true`, the `input` element is focused during the first mount.
   * @default false
   */
  autoFocus: PropTypes.bool,
  /**
   * @ignore
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * The color of the component.
   * It supports both default and custom theme colors, which can be added as shown in the
   * [palette customization guide](https://mui.com/material-ui/customization/palette/#adding-new-colors).
   * @default 'primary'
   */
  color: PropTypes /* @typescript-to-proptypes-ignore */.oneOfType([
    PropTypes.oneOf(['primary', 'secondary', 'error', 'info', 'success', 'warning']),
    PropTypes.string,
  ]),
  /**
   * The default value. Use when the component is not controlled.
   */
  // eslint-disable-next-line react/forbid-prop-types
  defaultValue: PropTypes.any,
  /**
   * If `true`, the component is disabled.
   * @default false
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, the label is displayed in an error state.
   * @default false
   */
  error: PropTypes.bool,
  /**
   * Props applied to the [`FormHelperText`](/material-ui/api/form-helper-text/) element.
   */
  FormHelperTextProps: PropTypes.object,
  /**
   * If `true`, the input will take up the full width of its container.
   * @default false
   */
  fullWidth: PropTypes.bool,
  /**
   * The helper text content.
   */
  helperText: PropTypes.node,
  /**
   * The id of the `input` element.
   * Use this prop to make `label` and `helperText` accessible for screen readers.
   */
  id: PropTypes.string,
  /**
   * Props applied to the [`InputLabel`](/material-ui/api/input-label/) element.
   * Pointer events like `onClick` are enabled if and only if `shrink` is `true`.
   */
  InputLabelProps: PropTypes.object,
  /**
   * [Attributes](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes) applied to the `input` element.
   */
  inputProps: PropTypes.object,
  /**
   * Props applied to the Input element.
   * It will be a [`FilledInput`](/material-ui/api/filled-input/),
   * [`OutlinedInput`](/material-ui/api/outlined-input/) or [`Input`](/material-ui/api/input/)
   * component depending on the `variant` prop value.
   */
  InputProps: PropTypes.object,
  /**
   * Pass a ref to the `input` element.
   */
  // eslint-disable-next-line react/forbid-prop-types
  inputRef: PropTypes.any,
  /**
   * The label content.
   */
  label: PropTypes.node,
  /**
   * If `dense` or `normal`, will adjust vertical spacing of this and contained components.
   * @default 'none'
   */
  margin: PropTypes.oneOf(['dense', 'none', 'normal']),
  /**
   * Maximum number of rows to display when multiline option is set to true.
   */
  maxRows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Minimum number of rows to display when multiline option is set to true.
   */
  minRows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * If `true`, a `textarea` element is rendered instead of an input.
   * @default false
   */
  multiline: PropTypes.bool,
  /**
   * Name attribute of the `input` element.
   */
  name: PropTypes.string,
  /**
   * @ignore
   */
  onBlur: PropTypes.func,
  /**
   * Callback fired when the value is changed.
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new value by accessing `event.target.value` (string).
   */
  onChange: PropTypes.func,
  /**
   * @ignore
   */
  onFocus: PropTypes.func,
  /**
   * The short hint displayed in the `input` before the user enters a value.
   */
  placeholder: PropTypes.string,
  /**
   * If `true`, the label is displayed as required and the `input` element is required.
   * @default false
   */
  required: PropTypes.bool,
  /**
   * Number of rows to display when multiline option is set to true.
   */
  rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Render a [`Select`](/material-ui/api/select/) element while passing the Input element to `Select` as `input` parameter.
   * If this option is set you must pass the options of the select as children.
   * @default false
   */
  select: PropTypes.bool,
  /**
   * Props applied to the [`Select`](/material-ui/api/select/) element.
   */
  SelectProps: PropTypes.object,
  /**
   * The size of the component.
   */
  size: PropTypes /* @typescript-to-proptypes-ignore */.oneOfType([
    PropTypes.oneOf(['medium', 'small']),
    PropTypes.string,
  ]),
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
  /**
   * Type of the `input` element. It should be [a valid HTML5 input type](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types).
   */
  type: PropTypes /* @typescript-to-proptypes-ignore */.string,
  /**
   * The value of the `input` element, required for a controlled component.
   */
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  /**
   * The variant to use.
   * @default 'outlined'
   */
  variant: PropTypes.oneOf(['standard', 'filled', 'outlined']),
};

StandardInput.defaultProps = {
  variant: 'standard',
  InputProps: {},
};

export default StandardInput;
