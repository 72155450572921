/* eslint-disable no-case-declarations */
import { PURGE } from 'redux-persist';
import { copyState, validatePersistedState, enrich, apiError, fetching } from 'state/helpers';
import * as constants from '../../constants/api';
import {
  TOKEN_REFRESH_SUCCESS,
  TOKEN_FETCH_SUCCESS,
  GET_MY_TEAMS,
  MY_TEAMS_RECEIVED,
  MY_TEAMS_FAILED,
  TOKEN_FETCH_FAILURE,
  TOKEN_REFRESH_FAILURE,
  SW_TOKEN_CONFIRMED,
} from './types';

export const initialState = {
  VERSION: 1.08,
  tokens: {
    access_token: null,
    refresh_token: null,
    id_token: null,
    expiry: null,
    refresh_failure: null,
  },
  firstName: null,
  lastName: null,
  teamID: null,
  userID: null,
  tenantID: null,
  analyticsId: null,
  isLineManager: false,
  isChangeManager: false,
  isAdmin: false,
  myTeams: [],
  loginType: null,
  clientId: null,
  cognitoSubDomain: null,
  idpName: null,
  swTokenConfirmed: false,
  username: null,
  email: null,
  actionlog: {},
  ssoRef: null,
};

function addFetchingTeamsToState(prevState) {
  return fetching({
    ...prevState,
  });
}

function addMyTeamsToState(prevState, payload) {
  return enrich({
    ...prevState,
    teamID: payload.ownTeamId,
    myTeams: payload.managedTeams,
    isLineManager: !!payload.managedTeams && payload.managedTeams.length > 0,
    fetchStatus: constants.OK,
    lastFetched: Date.now(),
    maxAge: 1000 * 60 * 5, // cache for 5 minutes
  });
}

function addFailedTeamsToState(prevState) {
  return apiError(prevState);
}

function addApiErrorToState(state, payload) {
  const newState = copyState(state);
  newState.actionlog[payload.payload.requestID] = { result: 'error' };
  if (!!payload && payload.payload) {
    newState.actionlog[payload.payload.requestID].payload = payload.payload.error.response;
  }
  return newState;
}

// eslint-disable-next-line default-param-last
export default (state = JSON.parse(JSON.stringify(initialState)), action) => {
  state = validatePersistedState(state, initialState);
  switch (action.type) {
    case GET_MY_TEAMS:
      return addFetchingTeamsToState(state, action.payload);
    case MY_TEAMS_RECEIVED:
      return addMyTeamsToState(state, action.payload);
    case MY_TEAMS_FAILED:
      return addFailedTeamsToState(state);
    case SW_TOKEN_CONFIRMED:
      return { ...state, swTokenConfirmed: true };
    case TOKEN_FETCH_SUCCESS:
      const newState = {
        ...state,
        tokens: action.payload.tokens,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        userID: action.payload.userID,
        isCEO: action.payload.isCEO,
        avatar: action.payload.avatar,
        tenantID: action.payload.tenantID,
        isChangeManager: action.payload.isChangeManager,
        isAdmin: action.payload.isAdmin,
        loginType: action.payload.loginType,
        clientId: action.payload.clientId,
        cognitoSubDomain: action.payload.cognitoSubDomain,
        idpName: action.payload.idpName,
        username: action.payload.username,
        email: action.payload.email,
        analyticsId: action.payload.analyticsId,
        ssoRef: action.payload.ssoRef,
      };
      return newState;
    case TOKEN_REFRESH_SUCCESS:
      const oldTokens = state.tokens;
      const newTokens = action.payload.tokens;
      return {
        ...state,
        tokens: { ...oldTokens, ...newTokens },
        analyticsId: action.payload.analyticsId || state.analyticsId,
      };
    case TOKEN_FETCH_FAILURE:
      return addApiErrorToState(state, action);
    case TOKEN_REFRESH_FAILURE:
      return { ...state, tokens: { ...state.tokens, refresh_failure: true } };
    case PURGE:
      return JSON.parse(JSON.stringify(initialState));
    default:
      return state;
  }
};
