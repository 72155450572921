/* eslint-disable no-console */
/* global pendo */
import { get } from 'lodash';

const eventTarget = e => (e && e.target) || e.srcElement;
const dismissGuide = e => {
  // Check if the click was outside the guide container
  if (!pendo.dom(eventTarget(e)).closest('#pendo-guide-container').length) {
    // Yes, dismiss the guide
    pendo.onGuideDismissed();
  }
};

const monkeyPatchPendo = () => {
  /* Monkey patch pendo to support closing guides from backdrop clicks */
  const guides = get(window, 'pendo.guides', []);
  guides.forEach(guide => {
    guide.steps.forEach(step => {
      step.after('show', () => {
        // attach event handler to document to monitor all clicks
        // and dismiss if the click happens outside the guide
        // (click area detection happens inside dismissGuide)
        step.attachEvent(document, 'click', dismissGuide);
      });

      step.after('teardown', () => {
        // detach event handler when the step is closed
        pendo.detachEvent(document, 'click', dismissGuide);
      });
    });
  });
};

const initPendo = config => {
  const tenantId = get(config, 'tenantId', '');
  console.log(`initPendo called with tenantId: ${tenantId}`);
  if (!!tenantId && !tenantId.includes('integrationtests')) {
    console.log('Initializing pendo, config: ', config);
    pendo.initialize({
      visitor: {
        id: config.analyticsId, // Required if user is logged in
        isChangeManager: config.isChangeManager,
        isAdministrator: config.isAdmin,
      },

      account: {
        id: config.tenantId, // Required if using Pendo Feedback
        environment: process.env.REACT_APP_STAGE,
      },
    });
    try {
      pendo.events.guidesLoaded(() => {
        monkeyPatchPendo();
      });
    } catch (e) {
      console.error('pendo.events.guidesLoaded error: ', e);
    }
  } else {
    console.log(`Skipping pendo init`);
  }
};

export const isPendoReady = () => {
  if (!pendo || typeof pendo.isReady !== 'function') {
    return false;
  }
  return pendo.isReady();
};

export const getPendoVisitorId = () => pendo.getVisitorId();

/* eslint-disable func-names */
export const pendoRegistration = apiKey => {
  (function (p, e, n, d, o) {
    // eslint-disable-next-line no-multi-assign
    o = p[d] = p[d] || {};
    o._q = o._q || [];
    const v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
    for (let w = 0, x = v.length; w < x; ++w)
      (function (m) {
        o[m] =
          o[m] ||
          function (...args) {
            o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(args, 0)));
          };
      })(v[w]);
    const y = e.createElement(n);
    y.async = !0;
    y.src = `https://cdn.eu.pendo.io/agent/static/${apiKey}/pendo.js`;
    const z = e.getElementsByTagName(n)[0];
    z.parentNode.insertBefore(y, z);
  })(window, document, 'script', 'pendo');
};

export default initPendo;
