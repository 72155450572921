import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { objectivesSelectors } from 'state/ducks/objectives';
import ObjectivesTable from './ObjectivesTable';

class ObjectivesTableContainer extends Component {
  render() {
    return (
      <ObjectivesTable
        selectObjective={this.props.selectObjective}
        objectiveIDs={this.props.objectiveIDs}
        domain={this.props.domain}
        stperiod={this.props.stperiod}
      />
    );
  }
}

const mapStateToProps = state => ({
  selectObjective: objectiveID =>
    objectivesSelectors.selectObjective(state.main.objectives, objectiveID),
});

ObjectivesTableContainer.propTypes = {
  selectObjective: PropTypes.func,
  objectiveIDs: PropTypes.object,
  stperiod: PropTypes.string,
  domain: PropTypes.object,
};

export default connect(mapStateToProps)(ObjectivesTableContainer);
