import { get } from 'lodash';
import { createLogic, createDefaultListQueryLogic } from 'state/defaultLogic';
import { instance as axios } from 'config/axios';
import { shouldFetch } from 'state/helpers';
import { CONTRIBUTOR_TYPES } from 'config/constants';
import { APIGW_URL } from '../../constants/api';
import * as types from './types';
import * as actions from './actions';
import * as selectors from './selectors';

const SLICE_NAME = 'commitments';

export const addCommitmentLogic = createLogic({
  type: types.ADD_COMMITMENT,
  process: async ({ getState, action }, dispatch, done) => {
    // Convert owner key to contributor array as expected by the API
    const { name, owner, requestID, description, contributors = [], okrs = [] } = action.payload;
    contributors.push({ type: CONTRIBUTOR_TYPES.OWNER, id: owner });
    const payload = {
      name,
      requestID,
      description: JSON.stringify(description),
      contributors,
      okrs,
      status: 'PENDING',
    };

    axios
      .post(
        `${APIGW_URL}/commitments/${getState().auth.tenantID}/addcommitment`,
        { ...payload },
        {
          headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
        },
      )
      .then(res => {
        const { result } = res.data;
        result.requestID = requestID;
        dispatch(actions.addedCommitment(result));
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        errorPayload.requestID = requestID;
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

export const editCommitmentLogic = createLogic({
  type: types.EDIT_COMMITMENT,
  process: async ({ getState, action }, dispatch, done) => {
    const state = getState();

    // eslint-disable-next-line camelcase
    const { name, requestID, description, id, status, owner, oldOwner } = action.payload;
    // eslint-disable-next-line camelcase
    const { due_date } = action.payload;

    const payload = {
      name,
      requestID,
      id,
      description: JSON.stringify(description),
      status,
      // eslint-disable-next-line camelcase
      due_date,
    };

    // the owner is changing so build contributors json for that
    let contributors = [];
    if (owner !== oldOwner) {
      contributors = [
        { data: { id: oldOwner, type: CONTRIBUTOR_TYPES.OWNER }, action: 'DELETE' },
        { data: { id: owner, type: CONTRIBUTOR_TYPES.OWNER }, action: 'ADD' },
      ];

      // If the new owner is a contributor, that would need to be handled as well
      const oldContributors = selectors.selectContributors(
        state.main[SLICE_NAME],
        action.payload.id,
      );

      const isNewOwnerContributor = get(oldContributors, 'data', []).find(
        c => c.id === owner && c.type === CONTRIBUTOR_TYPES.USER,
      );

      if (isNewOwnerContributor) {
        contributors.push({ data: { id: owner, type: CONTRIBUTOR_TYPES.USER }, action: 'DELETE' });
      }
      if (contributors.length > 0) {
        payload.contributors = contributors;
      }
    }

    axios
      .post(
        `${APIGW_URL}/commitments/${getState().auth.tenantID}/updatecommitment`,
        { ...payload },
        {
          headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
        },
      )
      .then(res => {
        const { result } = res.data;
        result.requestID = requestID;
        dispatch(actions.updatedCommitment(result));
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        errorPayload.requestID = requestID;
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

export const editContributorsLogic = createLogic({
  type: types.EDIT_CONTRIBUTORS,
  process: async ({ getState, action }, dispatch, done) => {
    const { requestID, id, contributors } = action.payload;

    const payload = {
      requestID,
      id,
      contributors,
    };

    axios
      .post(
        `${APIGW_URL}/commitments/${getState().auth.tenantID}/editcontributors`,
        { ...payload },
        {
          headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
        },
      )
      .then(res => {
        const { result } = res.data;
        result.requestID = requestID;
        result.id = id;
        dispatch(actions.updatedContributors(result));
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        errorPayload.requestID = requestID;
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

export const getCommitmentLogic = createLogic({
  type: types.FETCH_COMMITMENT,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      !!action.payload.id &&
      shouldFetch(
        selectors.selectCommitment(state.main[SLICE_NAME], action.payload.id),
        state.main.connection,
        !!action.payload && action.payload.force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    axios
      .get(`${APIGW_URL}/commitments/${getState().auth.tenantID}/commitment`, {
        params: { id: action.payload.id },
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(
          actions.fetchedCommitment({
            ...res.data.result,
          }),
        );
      })
      .catch(() => {
        dispatch(actions.failedCommitment(action.payload));
      })
      .then(() => done());
  },
});

export const getUserCommitmentsLogic = createDefaultListQueryLogic({
  endpoint: { api: `${APIGW_URL}/commitments`, method: 'usercommitments' },
  actionTypes: {
    fetch: types.FETCH_USER_COMMITMENTS,
    success: types.RECEIVED_USER_COMMITMENTS,
    fail: types.FAILED_USER_COMMITMENTS,
  },
  selector: selectors.selectUserCommitments,
  sliceName: SLICE_NAME,
});

export const getCompletedUserCommitmentsLogic = createDefaultListQueryLogic({
  endpoint: {
    api: `${APIGW_URL}/commitments`,
    method: 'usercommitments',
    additionalProperties: { filter: 'RECENTLY_COMPLETED' },
  },
  actionTypes: {
    fetch: types.FETCH_COMPLETED_USER_COMMITMENTS,
    success: types.RECEIVED_COMPLETED_USER_COMMITMENTS,
    fail: types.FAILED_COMPLETED_USER_COMMITMENTS,
  },
  selector: selectors.selectCompletedUserCommitments,
  sliceName: SLICE_NAME,
});

export const getTeamCommitmentsLogic = createDefaultListQueryLogic({
  endpoint: { api: `${APIGW_URL}/commitments`, method: 'teamcommitments' },
  actionTypes: {
    fetch: types.FETCH_TEAM_COMMITMENTS,
    success: types.RECEIVED_TEAM_COMMITMENTS,
    fail: types.FAILED_TEAM_COMMITMENTS,
  },
  selector: selectors.selectTeamCommitments,
  sliceName: SLICE_NAME,
});

export const getCompletedTeamCommitmentsLogic = createDefaultListQueryLogic({
  endpoint: {
    api: `${APIGW_URL}/commitments`,
    method: 'teamcommitments',
    additionalProperties: { filter: 'RECENTLY_COMPLETED' },
  },
  actionTypes: {
    fetch: types.FETCH_COMPLETED_TEAM_COMMITMENTS,
    success: types.RECEIVED_COMPLETED_TEAM_COMMITMENTS,
    fail: types.FAILED_COMPLETED_TEAM_COMMITMENTS,
  },
  selector: selectors.selectCompletedTeamCommitments,
  sliceName: SLICE_NAME,
});

export const getContributorsLogic = createLogic({
  type: types.FETCH_CONTRIBUTORS,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      !!action.payload.id &&
      shouldFetch(
        selectors.selectContributors(state.main[SLICE_NAME], action.payload.id),
        state.main.connection,
        action.payload?.force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    const { id } = action.payload;

    axios
      .get(`${APIGW_URL}/commitments/${getState().auth.tenantID}/contributors`, {
        params: { id },
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(
          actions.fetchedContributors({
            ...res.data.result,
            id,
          }),
        );
      })
      .catch(() => {
        dispatch(actions.failedContributors({ ...action.payload, id }));
      })
      .then(() => done());
  },
});

export const deleteCommitmentLogic = createLogic({
  type: types.DELETE_COMMITMENT,
  process: async ({ getState, action }, dispatch, done) => {
    const authToken = getState().auth.tokens.access_token;
    const { tenantID } = getState().auth;
    const { payload } = action;

    return axios
      .post(`${APIGW_URL}/commitments/${tenantID}/deletecommitment`, payload, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then(() => {
        dispatch(actions.commitmentDeleted(action.payload));
      })
      .catch(() => {
        dispatch(actions.errorTryAgainLater(payload));
      })
      .then(() => done());
  },
});
